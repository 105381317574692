
import * as React from "react";
import type { SVGProps } from "react";

const SvgReplacePdf = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} className={`w-3 h-3 ${props.className}`}>
    <path d="M18.3332 10.0003C18.3332 13.9287 18.3332 15.8929 17.1128 17.1133C15.8924 18.3337 13.9282 18.3337 9.99984 18.3337C6.07147 18.3337 4.10728 18.3337 2.88689 17.1133C1.6665 15.8929 1.6665 13.9287 1.6665 10.0003C1.6665 6.07195 1.6665 4.10777 2.88689 2.88738C4.10728 1.66699 6.07147 1.66699 9.99984 1.66699" stroke="#252525" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M1.6665 10.4165L3.12616 9.13927C3.88555 8.47481 5.03007 8.51292 5.74357 9.22642L9.31835 12.8012C9.89104 13.3739 10.7925 13.452 11.4552 12.9863L11.7037 12.8116C12.6572 12.1415 13.9473 12.2192 14.8136 12.9988L17.4998 15.4165" stroke="#252525" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M15.4685 2.44649L15.8161 2.09891C16.392 1.52302 17.3257 1.52302 17.9016 2.09891C18.4775 2.6748 18.4775 3.6085 17.9016 4.18439L17.554 4.53197M15.4685 2.44649C15.4685 2.44649 15.512 3.1851 16.1637 3.83681C16.8154 4.48852 17.554 4.53197 17.554 4.53197M15.4685 2.44649L12.2731 5.64195C12.0566 5.85839 11.9484 5.96661 11.8553 6.08593C11.7455 6.22669 11.6514 6.37898 11.5746 6.54013C11.5095 6.67673 11.4611 6.82192 11.3643 7.1123L11.0544 8.04199M17.554 4.53197L14.3585 7.72743C14.1421 7.94387 14.0339 8.05209 13.9146 8.14515C13.7738 8.25494 13.6215 8.34907 13.4604 8.42586C13.3238 8.49097 13.1786 8.53936 12.8882 8.63616L11.9585 8.94605M11.9585 8.94605L11.3569 9.14657C11.214 9.19421 11.0565 9.15701 10.95 9.0505C10.8435 8.944 10.8063 8.78645 10.8539 8.64355L11.0544 8.04199M11.9585 8.94605L11.0544 8.04199" stroke="#252525" strokeWidth="1.5" />
  </svg>

);

export default SvgReplacePdf;
