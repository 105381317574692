import React, { useState, useRef } from "react";
import { Label, TextInput, Tooltip } from "flowbite-react";
import { SvgCopy, SvgQuestion } from "../../components/icons";

type LinkInputProps = {
  label: string;
  placeholder: string;
  id: string;
  value: string;
  options?: {
    tooltip?: string;
  };
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const LinkInput: React.FC<LinkInputProps> = ({ label, placeholder, id, value, options, onChange }) => {
  const [copied, setCopied] = useState(false);
  const tooltip = options?.tooltip;

  const textRef = useRef<HTMLInputElement>(null);

  const handleCopy = () => {
    // Select the text to copy
    const textToCopy = textRef.current?.value || "";

    if (!navigator.clipboard) throw new Error("Clipboard API not available");

    navigator.clipboard
      .writeText(textToCopy)
      .then(function () {
        // Set copied state to true
        setCopied(true);

        // Reset copied state after 2 seconds
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      })
      .catch(function () {
        throw new Error("Failed to copy text to clipboard");
      });
  };

  return (
    <div>
      <div className="mb-2 flex gap-2 items-center">
        <Label htmlFor={id} value={label} className="text-neutral-800 text-sm font-bold flex gap-1 items-center" />
        {tooltip && (
          <Tooltip content={tooltip} placement="top" className="max-w-60">
            <SvgQuestion className="h-4 w-4" />
          </Tooltip>
        )}
      </div>
      <div className="w-full flex border rounded-lg border-netrual-200 overflow-hidden">
        <TextInput
          ref={textRef}
          id={id}
          name={id}
          type="text"
          defaultValue={value}
          placeholder={placeholder}
          onChange={onChange}
          theme={{
            base: "w-full",
            field: {
              input: {
                base: "w-full p-1 pl-8 text-sm text-gray-900 border border-0 rounded-lg focus:ring-0 focus:border-0",
              },
            },
          }}
        />
        <button
          onClick={handleCopy}
          className="w-10 flex items-center justify-center bg-neutral-100 hover:bg-neutral-200 px-3 py-1"
        >
          {!copied && <SvgCopy className="h-4 w-4" />}
          {copied && (
            <Tooltip content="copied" placement="top" className="max-w-60">
              <SvgCopy className="h-4 w-4" />
            </Tooltip>
          )}
        </button>
      </div>
    </div>
  );
};

export default LinkInput;
