import moment from "moment";

export const getCurrentMonth = () => moment().format("MMMM");

export const getCurrentYear = () => moment().format("YYYY");

export const htmlReplacers = [
  'agentName',
  'brokerName',
  'phoneNumber',
  'email',
  'address',
  'website',
  'profileImage',
];

export const rmMagazineStatus = {
  EDITING: "EDITING",
  MAGAZINE_GENERATED: "MAGAZINE_GENERATED",
  SENT_FOR_PRINTING: "SENT_FOR_PRINTING",
  PENDING: "PENDING",
  DONE: "DONE",
};

export const MAGAZINE_STATUS = {
  DONE: "DONE",
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
  ERROR: "ERROR",
  SENT_FOR_PRINTING: "SENT_FOR_PRINTING",
};

export const magazineStatus = {
  EDITING: "Editing",
  MAGAZINE_GENERATED: "Generated Magazine",
  SENT_FOR_PRINTING: "Revisions Sent",
  PENDING: "Pending",
  DONE: "Done",
};

export const monthsList = {
  JAN: "january",
  FEB: "february",
  MAR: "march",
  APR: "april",
  MAY: "may",
  JUN: "june",
  JUL: "july",
  AUG: "august",
  SEP: "september",
  OCT: "october",
  NOV: "november",
  DEC: "december",
};

export const rmMagzineEditorSteps = [
  'Magazine',
  'Front cover',
  'Front inside cover',
  'Back inside cover',
  'Back cover',
  'Generate magazine',
];

export const stepsList = [
  { title: 'Your personal and brokerage information', step: 0 },
  { title: 'Front cover', step: 1 },
  { title: 'Front inside cover', step: 2 },
  { title: 'Back inside cover', step: 3 },
  { title: 'Back cover', step: 4 },
  { title: 'Additional instructions', step: 5 },
];

export const formKeywordList = {
  magazine: 'magazine',
  frontCoverStrip: 'frontCoverStrip',
  frontCoverDesign: 'frontCoverDesign',
  frontInsideCover: 'frontInsideCover',
  backInsideCover: 'backInsideCover',
  backCover: 'backCover',
};

export const magazineFormikStepsList = {
  0: 'magazine',
  1: 'frontCover',
  2: 'frontInsideCover',
  3: 'backInsideCover',
  4: 'backCover',
  5: 'additionalInformation',
};

export const monthNames = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const initialValues = {
  currentStep: 0,
  selectedOption: '',
  magazine: {
    _formFields: {},
  },
  frontCover: {
    selectedOption: '',
    _formFields: {},
  },
  frontInsideCover: {
    selectedOption: '',
    _formFields: { frontInsideCoverText: '' },
  },
  backCover: {
    selectedOption: '',
    _formFields: {},
  },
  backInsideCover: {
    selectedOption: '',
    _formFields: {},
  },
};

export const optionFieldsWithWordLimit = [
  'description',
  'features',
  'testimonial1',
  'testimonial2',
  'testimonial3',
  'testimonial4',
  'testimonial5',
];

export const rmMagazineReportLink = '/rm-magazine-report';

export const numberOfSecondsInAMinute = 60000;
