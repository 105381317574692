import React, { useEffect } from 'react';
import { TextInput } from 'flowbite-react';
import { useState } from 'react';

interface PriceInputProps extends React.ComponentProps<typeof TextInput> {
  onValueChange: (value: string) => void;
  value?: string;
}

export const PriceInput: React.FC<PriceInputProps> = (props) => {
  const { onValueChange, value: fieldValue, ...rest } = props;
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(fieldValue as string)
  }, [fieldValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const sanitizedInput = value.replace(/,/g, '');

    const priceRegex = /^\$?\d{0,9}(\.\d{0,2})?$/;
    const isValid = priceRegex.test(sanitizedInput);

    if (!isValid) {
      return null;
    }

    const parts = sanitizedInput.split('.');
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const nValue = formattedIntegerPart + decimalPart;

    onValueChange(nValue);
    setValue(nValue);
  }

  return (
    <TextInput
      {...rest}
      value={value}
      onChange={handleChange}
    />
  );
}