import { Card as FlowbiteCard } from "flowbite-react";
import { ReactNode } from "react";
import { Customer } from "../../api/instances/dto/getCustomers.dto";
import React from "react";

interface CustomerCardProps {
  onClick: () => void;
  customer: Customer;
}

export default function CustomerCard({
  onClick,
  customer,
}: CustomerCardProps): ReactNode {
  return (
    <FlowbiteCard
      className="h-[146px] w-full shadow-md border-2 border-transparent hover:border-primary-500 cursor-pointer overflow-x-auto"
      onClick={() => onClick()}
    >
      <div>
        <p className="font-bold capitalize text-xl">{`${customer.firstName} ${customer.lastName}`}</p>
        <p className="text-gray-500">{customer.email}</p>
        <p className="text-gray-500">
          {customer.chargifyId ?? customer.stripeId}
        </p>
      </div>
    </FlowbiteCard>
  );
}
