import { useNavigate } from "react-router-dom";
import { ArrowRightPagination, ArrowLeft } from "../../components/icons";

const NextPrev = ({
  hasNextPage,
  nextPageToken,
  currentPage,
  goToNextPage,
}) => {
  const numCurrentPage = Number(currentPage);
  const navigate = useNavigate();

  const goToPrevPage = () => {
    navigate(-1); 
  };

  const getButtonStyle = (disabled) => ({
    width: '77px',
    height: '38px',
    paddingLeft: '6px',
    paddingRight: '6px',
    marginLeft: '10px',
    marginRight: '10px',
    backgroundColor: disabled ? '#e5e7eb' : 'white',
    borderRadius: '8px',
    border: disabled ? '1px solid #d1d5db' : '1px solid #d4d4d8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    fontFamily: 'Figtree',
    fontSize: '13px',
    fontWeight: 'bold',
    color: disabled ? '#a1a1aa' : '#1f2937',
    cursor: disabled ? 'not-allowed' : 'pointer'
  });

  return (
    <div className="flex justify-between items-center">
      <button 
        style={getButtonStyle(numCurrentPage === 1)} 
        onClick={goToPrevPage} 
        disabled={numCurrentPage === 1}
      >
        <ArrowLeft />
        <span>Prev</span> 
      </button>
      <span className="text-neutral-600 text-sm px-1 font-bold font-['Figtree'] leading-tight">{numCurrentPage}</span>
      <button 
        style={getButtonStyle(!hasNextPage)} 
        onClick={goToNextPage} 
        disabled={!hasNextPage}
      >
        <span>Next</span> 
        <ArrowRightPagination />
      </button>
    </div>
  );
};

export default NextPrev;
