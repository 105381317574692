import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required("Type offer name"),
  code: Yup.string().trim().required("Type offer code"),
  description1: Yup.string()
    .trim()
    .required("Type offer description here")
    .min(100, "Description must be atleast 100 characters long"),
  credits: Yup.number().required("Type offer Credits"),
});

export default validationSchema;
