import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function ToastNotification({
  type,
  message,
}: {
  type: string;
  message: string;
}) {
  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      style: {
        background: "black",
        borderRadius: "10px",
        color: "white",
        width: "auto",
        maxWidth: "75%",
        padding: "8px 14px",
        boxSizing: "border-box",
        fontSize: "13px",
        fontWeight: "600",
        fontFamily: "Figtree",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
      },
    });
  };

  const notifyFailure = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: "black",
        borderRadius: "10px",
        color: "white",
        width: "auto",
        maxWidth: "75%",
        padding: "8px 14px",
        boxSizing: "border-box",
        fontSize: "13px",
        fontWeight: "600",
        fontFamily: "Figtree",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
      },
    });
  };

  return type === "success" ? notifySuccess(message) : notifyFailure(message);
}

export default ToastNotification;
