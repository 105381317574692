import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import React, { ChangeEvent, useEffect, useState } from "react";
import { DateTime } from "luxon";
import {
  HiArrowCircleDown,
  HiArrowCircleUp,
  HiArrowRight,
  HiCheck,
  HiCheckCircle,
  HiOutlineDownload,
  HiUser,
  HiXCircle,
} from "react-icons/hi";
import { Modal } from "flowbite-react";
import { CSVLink } from "react-csv";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import urljoin from "url-join";
import Title from "../../../components/Title";
import {
  useGetOnboardMetricsByBooks,
  useGetOnboardMetricsByUniqueVisits,
  useGetOnboardMetricsByAutoLogin,
  useGetOnboardMetricsByPaidSales,
  useGetOnboardMetricsByCancellations,
  useGetOnboardMetrics,
  useFilterTable,
  useFilterSuggestions,
  useGetPaidSalesForExportData,
  useGetautoLoginForExportData,
  useGetUniqueVisitsForExportData,
  useGetFilterForExportData,
  useGetBooksGeneratedForExportData,
  useGetCancellationsForExportData,
} from "./hooks";
import {
  UNIQUE_VISITS,
  PAID_SALES,
  AUTO_LOGIN,
  CANCELLED,
  BOOKS_GENERATED,
  SEARCH_FILTER,
} from "./constants";
import { csvHeaders, formatFilterKey, formatSalesData } from "./utils";
import {
  formatDateFromStringDate,
  formatDateTimeWithmonthyear,
  formatDateWithTZFromIso,
} from "../../../../utils/date";
import Button from "../../../components/Button";
import SalesCard from "../../../components/SalesCard";
import PaginationFlowBite from "../../../components/PaginationFlowBite";
import SvgReportsActive from "../../../../components/icons/ReportsActive.tsx";
import SvgOpenBook from "../../../../components/icons/OpenBook.tsx";
import { ColumnDef } from "@tanstack/react-table";
import AfyTable from "../../../components/V2/Table.tsx";
import SkeletonLoader from "../../../components/SkeletonLoader.tsx";
import SkeletonSalesLoader from "../../../components/SkeletonSalesLoader.tsx";
import { isEmpty } from "lodash-es";
import noItemsImage from "../../../../assets/images/no-items.png";
import Avatar from "../../../components/Avatar";
import { cn } from "../../../utils/cn.ts";

const animatedComponents = makeAnimated();

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
  }),
  control: (provided) => ({
    ...provided,
    maxHeight: 66,
    width: 324,
    overflowY: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    position: 'relative',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    maxHeight: 66,
    position: 'sticky',
    top: 0,
    right: 0,
    backgroundColor: 'white',
  }),
};

export default function SalesReport() {
  const [salesDetailsData, setSalesDetailsData] = useState([]);
  const [salesDetailsMeta, setSalesDetailsMeta] = useState({ currentPage: 1 });
  const lastDay = DateTime.now().minus({ days: 90 }).toISO();
  const today = DateTime.now().toISO();
  const [value, setValue] = useState<DateValueType>({
    startDate: lastDay,
    endDate: today,
  });
  const dateRange = [value?.startDate, value?.endDate];
  const [onboardMetrics, setOnboardMetrics] = useState({});
  const [selected, setSelected] = useState(PAID_SALES);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState({
    field: "customerInfo.email",
    keyword: "",
  });
  const [filter, setFilter] = useState({});
  const [advancedSearchData, setAdvancedSearchData] = useState([]);
  const [searchStatus, setSearchStatus] = useState("Customer");
  const [customerEmail, setCustomerEmail] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [showViewDetailsModal, setShowViewDetailsModal] = useState(false);
  const [viewDetailsData, setViewDetailsData] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [dayCounter, setDayCounter] = useState("Last 90 days");
  const [sortByfilter, setSortByfilter] = useState({
    key: "createdAt",
    value: "-1",
  });
  // const [exportData, setExportData] = useState([]);
  const [isCardLoading, setIsCardLoading] = useState(false);

  const handleSort = (columnKey) => {
    setCurrentTablePage(1);
    if (sortByfilter.key === columnKey) {
      const descend =
        sortByfilter.key === columnKey && sortByfilter.value === "-1";
      const ascend =
        sortByfilter.key === columnKey && sortByfilter.value === "1";
      if (descend) {
        setSortByfilter({ key: columnKey, value: "1" });
      } else if (ascend) {
        setSortByfilter({ key: columnKey, value: "-1" });
      }
    } else {
      setSortByfilter({ key: columnKey, value: "1" });
    }
  };

  const handleOnboardMetricsSuccess = (response) => {
    const data = response?.data || {};

    const {
      BookGeneratedCount,
      PaidSalesCount,
      VisitsCount,
      CancellationCount,
      AutoLoginCount,
    } = data;

    setOnboardMetrics({
      bookGeneratedCount: BookGeneratedCount,
      paidSalesCount: PaidSalesCount,
      visitsCount: VisitsCount,
      cancellationCount: CancellationCount,
      autoLoginCount: AutoLoginCount,
    });
  };

  const { isLoading: isMetricsLoading } = useGetOnboardMetrics(
    dateRange,
    handleOnboardMetricsSuccess
  );

  const handleValueChange = (newValue: DateValueType) => {
    if (newValue?.startDate !== null && newValue?.endDate !== null) {
      const sDate = new Date(newValue?.startDate);
      const eDate = new Date(newValue?.endDate);
      const differenceInMilliseconds = eDate.getTime() - sDate.getTime();
      const differenceInDays = Math.ceil(
        differenceInMilliseconds / (1000 * 60 * 60 * 24)
      );
      setDayCounter(`Last ${differenceInDays + 1} days`);
      setValue(newValue);
      setCurrentTablePage(1);
      setCustomerEmail("");
    }
  };

  const handleSuccessTableUpdate = (response) => {
    setSalesDetailsData([]);
    const data = response?.data;

    const SalesDetails = data?.SalesDetails;
    const salesDetails = SalesDetails?.data;
    const meta = SalesDetails?.meta || {};

    const formattedSalesData = formatSalesData(salesDetails);

    const filteredSalesData = formattedSalesData.filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id)
    );
    
    setSalesDetailsData(filteredSalesData);
    setSalesDetailsMeta(meta);
  };

  
  // const handleSuccessExportUpdate = (response) => {
  //   setExportData([]);
  //   const data = response?.data;
  //   console.log(data?.length);

  //   const formattedSalesData = formatSalesData(data);

  //   const filteredSalesData = formattedSalesData.filter(
  //     (item, index, self) => index === self.findIndex((t) => t.id === item.id)
  //   );
  //   setExportData(filteredSalesData);
  // };

  const {
    visitsCount,
    bookGeneratedCount,
    paidSalesCount,
    cancellationCount,
    autoLoginCount,
  } = onboardMetrics;

  const {
    mutate: metricsByUnitqueVisitsMutate,
    isLoading: isUniqueVisitsLoading,
  } = useGetOnboardMetricsByUniqueVisits(
    dateRange,
    currentTablePage,
    handleSuccessTableUpdate,
    perPage.toString(),
    sortByfilter
  );

  const { mutate: metricsByAutoLoginMutate, isLoading: isAutoLoginLoading } =
    useGetOnboardMetricsByAutoLogin(
      dateRange,
      currentTablePage,
      handleSuccessTableUpdate,
      perPage.toString(),
      sortByfilter
    );

  const { mutate: metricsByPaidSalesMutate, isLoading: isPaidSalesLoading } =
    useGetOnboardMetricsByPaidSales(
      dateRange,
      currentTablePage,
      handleSuccessTableUpdate,
      perPage.toString(),
      sortByfilter
    );

  // const {
  //   mutate: exportDataByPaidSalesMutate,
  //   isLoading: isExportPaidSalesLoading,
  // } = useGetPaidSalesForExportData(dateRange, handleSuccessExportUpdate);

  // const {
  //   mutate: exportDataByAutoLoginMutate,
  //   isLoading: isExportAutoLoginLoading,
  // } = useGetautoLoginForExportData(dateRange, handleSuccessExportUpdate);

  // const { mutate: exportDataByUniqueMutate, isLoading: isExportUniqueLoading } =
  //   useGetUniqueVisitsForExportData(dateRange, handleSuccessExportUpdate);

  // const { mutate: exportDataByFilterMutate, isLoading: isExportFilterLoading } =
  //   useGetFilterForExportData(filter, dateRange, handleSuccessExportUpdate);

  // const {
  //   mutate: exportDataByBooksGeneratedMutate,
  //   isLoading: isExportBooksGeneratedLoading,
  // } = useGetBooksGeneratedForExportData(dateRange, handleSuccessExportUpdate);

  // const {
  //   mutate: exportDataByCancellationsMutate,
  //   isLoading: isExportCancellationsLoading,
  // } = useGetCancellationsForExportData(dateRange, handleSuccessExportUpdate);

  const {
    mutate: metricsByCancellationsMutate,
    isLoading: isCancellationsLoading,
  } = useGetOnboardMetricsByCancellations(
    dateRange,
    currentTablePage,
    handleSuccessTableUpdate,
    perPage.toString(),
    sortByfilter
  );

  const { mutateAsync: filterMutate, isLoading: isFilterLoading } =
    useFilterTable(
      filter,
      dateRange,
      currentTablePage,
      perPage.toString(),
      handleSuccessTableUpdate,
      sortByfilter
    );

  const { mutate: metricsByBooksMutate, isLoading: isBooksLoading } =
    useGetOnboardMetricsByBooks(
      dateRange,
      currentTablePage,
      handleSuccessTableUpdate,
      perPage.toString(),
      sortByfilter
    );

  const handleSuccessFilterSuggestions = (response) => {
    setAdvancedSearchData(response.data);
  };

  const handlePerPage = (value, firstItemIndex) => {
    const perPage = value.value;
    const pageNumber = Math.ceil(firstItemIndex / perPage) || 1;
    setPerPage(perPage);
    setCurrentTablePage(pageNumber);
  };

  const {
    mutateAsync: filterSuggestionsMutate,
    isLoading: isAdvancedSearchLoading,
  } = useFilterSuggestions(
    advancedSearch.field,
    advancedSearch.keyword,
    handleSuccessFilterSuggestions
  );

  useEffect(() => {
    filterSuggestionsMutate();
  }, [advancedSearch]);

  useEffect(() => {
    const fetchData = async () => {
      setIsCardLoading(true);
      try {
        switch (selected) {
          case UNIQUE_VISITS:
            await metricsByUnitqueVisitsMutate();
            break;
          case AUTO_LOGIN:
            await metricsByAutoLoginMutate();
            break;
          case CANCELLED:
            await metricsByCancellationsMutate();
            break;
          case SEARCH_FILTER:
            await filterMutate();
            break;
          case BOOKS_GENERATED:
            await metricsByBooksMutate();
            break;
          case PAID_SALES:
          default:
            await metricsByPaidSalesMutate();
            break;
        }
      } finally {
        setIsCardLoading(false);
      }
    };

    fetchData();
  }, [
    selected,
    value.startDate,
    value.endDate,
    currentTablePage,
    triggerSearch,
    perPage,
    customerEmail,
    sortByfilter,
  ]);

  const handleViewDetails = (customerInformation) => {
    setViewDetailsData(customerInformation);
    setShowViewDetailsModal(true);
  };

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: "Customer",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          const fullName = `${row.firstName} ${row.lastName}`;
          return (
            <>
              <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
                {fullName}
              </div>
              <div className="text-neutral-500 text-sm font-medium font-['Figtree']">
                {row.email}
              </div>
            </>
          );
        },
        customProp: "customerInfo.email",
      },
      {
        header: "Offer",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          return (
            <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
              {row.offerTitle}
            </div>
          );
        },
        customProp: "offerDetails.title",
      },
      {
        header: "Coach & meeting date",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          if (row.coachMeetingDate) {
            const coachMeetingDateFormatted = formatDateWithTZFromIso(
              row.coachMeetingDate,
              "LLL dd, yyyy h:mm a",
              "America/New_York"
            );
            return (
              <>
                <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
                  {row.coachName}
                </div>
                <div className="text-neutral-500 text-sm font-medium font-['Figtree']">
                  {coachMeetingDateFormatted}
                </div>
              </>
            );
          }
        },
        customProp: "coachingSelection.utcStart",
      },
      {
        header: "Last step",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          console.log(row.currentStep);

          return (
            <>
              {row.currentStep === "done" ||
              row.currentStep === "order confirmation" ? (
                <div className="w-[110px] h-6 px-2 py-1 bg-emerald-50 rounded-md inline-flex">
                  <div className="flex items-center justify-start text-emerald-600 text-xs font-semibold font-['Figtree'] leading-none">
                    <HiCheck style={{ width: 20, height: 15 }} />
                    Completed
                  </div>
                </div>
              ) : (
                <div className="text-neutral-800 capitalize mb-1 text-sm font-semibold font-['Figtree']">
                  {row.currentStep}
                </div>
              )}
              {row.currentStep !== "done" &&
                row.currentStep !== "order confirmation" && (
                  <Button
                    variant="OUTLINED"
                    onClick={() => handleViewDetails(row)}
                    className="h-7 w-[120px] pl-2.5 pr-2 py-1.5 rounded border border-zinc-300 text-semibold"
                  >
                    <span className="text-neutral-800 text-xs font-bold font-['Figtree'] leading-none">
                      Continue Step
                    </span>{" "}
                    <HiArrowRight className="ml-2" />
                  </Button>
                )}
            </>
          );
        },
        customProp: "currentStep",
      },
      {
        header: "Order system",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          return (
            <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
              {row.orderSystem || "-"}
            </div>
          );
        },
        customProp: "salesParameters.orderSystem",
      },
      {
        header: "Sales agent",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          if (row.salesAgent) {
            const salesData = row.salesAgent;
            const dataArray = salesData.split(" ");
            if (dataArray.length === 1) {
              return (
                <>
                  <div className="flex items-center">
                    <Avatar name={row.salesAgent} />
                    <div className="ml-2 text-neutral-800 text-sm font-semibold font-['Figtree']">
                      {row.salesAgent}
                    </div>
                  </div>
                </>
              );
            } else {
              const salesAgentEmail = dataArray.splice(-1, 1);
              const salesName = dataArray.join(" ");
              return (
                <>
                  <div className="flex items-center">
                    <Avatar name={salesName} />
                    <div className="ml-2 text-neutral-800 text-sm font-semibold font-['Figtree']">
                      {salesName}
                    </div>
                    <div className="ml-2 text-neutral-500 text-sm font-medium font-['Figtree']">
                      {salesAgentEmail}
                    </div>
                  </div>
                </>
              );
            }
          } else {
            return (
              <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
                {"-"}
              </div>
            );
          }
        },
        customProp: "salesParameters.salesAgent",
      },
      {
        header: "Channel",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          return (
            <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
              {row.channel || "-"}
            </div>
          );
        },
        customProp: "marketingParameters.channel",
      },
      {
        header: "UTM Source",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          return (
            <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
              {row.utmSource || "-"}
            </div>
          );
        },
        customProp: "marketingParameters.utmSource",
      },
      {
        header: "UTM Content",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          return (
            <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
              {row.utmContent || "-"}
            </div>
          );
        },
        customProp: "marketingParameters.utmContent",
      },
      {
        header: "UTM Medium",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          return (
            <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
              {row.utmMedium || "-"}
            </div>
          );
        },
        customProp: "marketingParameters.utmMedium",
      },
      {
        header: "UTM Term",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          return (
            <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
              {row.utmTerm || "-"}
            </div>
          );
        },
        customProp: "marketingParameters.utmTerm",
      },
      {
        header: "Onboard date",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          if (row.date) {
            const dateFormatted = formatDateFromStringDate(row.date);
            return (
              <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
                {dateFormatted}
              </div>
            );
          }
        },
        customProp: "createdAt",
      },
      {
        header: "Subscription ID",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          return (
            <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
              {row.subscriptionId || "-"}
            </div>
          );
        },
        customProp: "customerStatus.subscriptionId",
      },
      {
        header: "Cancelled date",
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          return (
            <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
              {row.cancellationDate || "-"}
            </div>
          );
        },
        customProp: "customerStatus.cancellationDate",
      },
    ],
    []
  );

  const handleFinishOnboarding = (sessionDetails) => {
    const url = urljoin(
      import.meta.env.VITE_LAUNCH_OFFER_URL,
      "onboard",
      "finish-onboarding",
      sessionDetails.code,
      sessionDetails.id
    );
    window.open(url, "_blank", "incognito=yes");
  };

  const handleCurrentPageChange = (page) => {
    setCurrentTablePage(page);
  };

  const resetFilters = () => {
    setSelected(PAID_SALES);
    setFilter({});
    setAdvancedSearchData([]);
    setSelectedOptions([]);
    setCustomerEmail("");
    setValue({ startDate: lastDay, endDate: today });
  };

  const tablePagination = {
    pageSize: salesDetailsMeta?.perPage,
    total: salesDetailsMeta?.total,
    lastPage: salesDetailsMeta?.lastPage,
    currentPage: salesDetailsMeta?.currentPage + 1 || 1,
    onChange: handleCurrentPageChange,
  };

  const handleSelectCard = (key) => {
    setFilter({});
    setCustomerEmail("");
    setSortByfilter({
      key: "createdAt",
      value: "-1",
    });
    setCurrentTablePage(1);
    setSalesDetailsMeta({ currentPage: 1 });
    setSelected(key);
  };

  const searchOptions = [
    { value: "Customer", key: "customerInfo.email" },
    { value: "Coach Name", key: "coachInfo.name" },
    { value: "Offer", key: "offerDetails.title" },
    { value: "Sales Agent", key: "salesParameters.salesAgent" },
    { value: "Channel", key: "marketingParameters.channel" },
    { value: "UTM Source", key: "marketingParameters.utmSource" },
    { value: "UTM Content", key: "marketingParameters.utmContent" },
    { value: "UTM Medium", key: "marketingParameters.utmMedium" },
    { value: "UTM Term", key: "marketingParameters.utmTerm" },
  ];

  const handleOptionSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedOptions([]);
    // setCustomerEmail("");
    const filterKey = formatFilterKey(searchOptions, value);

    setSearchStatus(value);
    setAdvancedSearch({
      field: filterKey,
      keyword: "",
    });
    setSelected(SEARCH_FILTER);
    setTriggerSearch(!triggerSearch);
  };

  useEffect(() => {
    if (searchStatus && selectedOptions.length > 0) {
      const filterKey = formatFilterKey(searchOptions, searchStatus);
      if (selectedOptions.length > 0) {
        setFilter({
          [filterKey]: selectedOptions,
        });
      } else {
        setFilter({});
      }
      setCurrentTablePage(1);
      setSelected(SEARCH_FILTER);
      setTriggerSearch(!triggerSearch);
    }
  }, [selectedOptions]);

  const handleAdvancedSearch = (value) => {
    const arr = value.map((item) => item.label);
    setSelectedOptions(arr);
    if (value.length === 0) {
      setFilter({});
      setSelected(SEARCH_FILTER);
    }
  };

  const handleSearch = (event) => {
    const inputValue = event.target?.value;

    if (inputValue.length > 3) {
      setCurrentTablePage(1);
      setCustomerEmail(inputValue);
      if (!inputValue) setSelected(PAID_SALES);
      if (inputValue && searchStatus) {
        const filterKey = formatFilterKey(searchOptions, searchStatus);
        setFilter({
          [filterKey]: [inputValue],
        });
        setSelected(SEARCH_FILTER);
        setTriggerSearch(true);
      }
    } else if (inputValue.length === 0) {
      setSelected(PAID_SALES);
    }
  };

  const filterOptions = advancedSearchData?.map((item) => {
    const key = item.replace(/ /g, "_");
    return { value: key.toString().toLowerCase(), label: item };
  });

  return (
    <div className="pt-8 mx-auto min-h-screen w-full">
      <div className="mb-8">
        <Title>Sales</Title>
      </div>

      <div className="shadow border-0 rounded-lg">
        <div className="w-full flex px-6 py-[15px] bg-white border-b rounded-t-lg border-gray-200 justify-between items-center max-[1070px]:flex-col max-[1070px]:gap-y-2">
          <div className="text-zinc-900 text-base font-semibold font-['Figtree'] leading-tight">
            Overview
          </div>
          <div className="justify-start items-center flex">
            <div className="w-[400px] h-[46px] pl-5 py-[13px] bg-white justify-start items-center flex max-[450px]:w-full">
              <div className="grow shrink basis-0 text-neutral-800 text-sm font-medium font-['Figtree'] leading-tight">
                <Datepicker
                  separator={"-"}
                  primaryColor={"cyan"}
                  value={value}
                  onChange={handleValueChange}
                  placeholder={"MM DD, YYYY to MM DD, YYYY"}
                  showShortcuts={true}
                  showFooter={true}
                  displayFormat={"MMM DD, YYYY"}
                  maxDate={new Date()}
                />
              </div>
              <div className="w-5 h-5 relative" />
            </div>
          </div>
        </div>

        {isCardLoading || isMetricsLoading ? (
          <SkeletonSalesLoader count={3} layout="horizontal" />
        ) : (
          <div className="bg-[#F9FAFB] rounded--b-lg overflow-auto">
            <div className="w-full flex max-[1070px]:flex-col">
              <div
                className={cn(
                  "w-1/3 cursor-pointer px-8 py-4 max-[1070px]:w-full",
                  {
                    "border border-cyan-500": selected === UNIQUE_VISITS,
                    "bg-white border border-transparent border-l-gray-200 border-r-gray-200 border-b-gray-200 flex-col justify-start items-start gap-2 inline-flex hover:bg-primary-50 hover:border-primary-500 max-[1070px]:rounded-none max-[1070px]:border-gray-200":
                      selected !== UNIQUE_VISITS,
                  }
                )}
                onClick={() => handleSelectCard(UNIQUE_VISITS)}
              >
                <SalesCard
                  titleIcon={HiUser}
                  title="Total unique visits"
                  value={visitsCount}
                  offerIcon={HiArrowCircleDown}
                  date={dayCounter}
                />
              </div>

              <div
                className={cn(
                  "w-1/3 cursor-pointer px-8 py-4 max-[1070px]:w-full",
                  {
                    "border border-cyan-500": selected === PAID_SALES,
                    "bg-white border border-transparent border-r-gray-200 border-b-gray-200 flex-col justify-start items-start gap-2 inline-flex hover:bg-primary-50 hover:border-primary-500 max-[1070px]:border-gray-200":
                      selected !== PAID_SALES,
                  }
                )}
                onClick={() => handleSelectCard(PAID_SALES)}
              >
                <SalesCard
                  titleIcon={SvgReportsActive}
                  title="Total sales count"
                  value={paidSalesCount}
                  offerIcon={SvgReportsActive}
                  date={dayCounter}
                />
              </div>
              <div
                className={cn(
                  "w-1/3 cursor-pointer px-8 py-4 max-[1070px]:w-full",
                  {
                    "border border-cyan-500": selected === BOOKS_GENERATED,
                    "bg-white border border-transparent border-r-gray-200 border-b-gray-200 flex-col justify-start items-start gap-2 inline-flex hover:bg-primary-50 hover:border-primary-500 max-[1070px]:border-gray-200":
                      selected !== BOOKS_GENERATED,
                  }
                )}
                onClick={() => handleSelectCard(BOOKS_GENERATED)}
              >
                <SalesCard
                  titleIcon={SvgOpenBook}
                  title="Total books generated"
                  value={bookGeneratedCount}
                  offerIcon={HiArrowCircleDown}
                  date={dayCounter}
                />
              </div>
            </div>
            <div className="w-full flex max-[1070px]:flex-col">
              <div
                className={cn(
                  "w-1/2 cursor-pointer px-8 py-4 max-[1070px]:w-full",
                  {
                    "w-1/2 cursor-pointer px-8 py-4 border border-cyan-500":
                      selected === "auto-login",
                    "w-1/2 cursor-pointer px-8 py-4 bg-white border border-transparent rounded-bl-lg border-l-gray-200 border-r-gray-200 border-b-gray-200 flex-col justify-start items-start gap-2 inline-flex hover:bg-primary-50 hover:border-primary-500 max-[1070px]:rounded-none max-[1070px]:border-gray-200":
                      selected !== "auto-login",
                  }
                )}
                onClick={() => handleSelectCard("auto-login")}
              >
                <SalesCard
                  titleIcon={HiCheckCircle}
                  title="Total completed"
                  value={autoLoginCount}
                  offerIcon={HiArrowCircleUp}
                  date={dayCounter}
                />
              </div>

              <div
                className={cn(
                  "w-1/2 cursor-pointer px-8 py-4 max-[1070px]:w-full",
                  {
                    "w-1/2 cursor-pointer px-8 py-4 border border-cyan-500":
                      selected === "cancelled",
                    "w-1/2 cursor-pointer px-8 py-4 bg-white border border-transparent rounded-br-lg border-r-gray-200 border-b-gray-200 flex-col justify-start items-start gap-2 inline-flex hover:bg-primary-50 hover:border-primary-500 max-[1070px]:rounded-bl-lg max-[1070px]:border-gray-200":
                      selected !== "cancelled",
                  }
                )}
                onClick={() => handleSelectCard("cancelled")}
              >
                <SalesCard
                  titleIcon={HiXCircle}
                  title="Total cancellations"
                  value={cancellationCount}
                  offerIcon={HiArrowCircleDown}
                  date={dayCounter}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mt-6">
        <div className="w-full shadow rounded-t-lg bg-white items-center px-4 py-6 flex max-[950px]:flex-col max-[950px]:gap-y-2">
          <div className="h-[46px] pl-5 pr-[15px] py-[13px] bg-white justify-start items-center gap-1 flex">
            <div className="grow shrink basis-0 text-neutral-800 text-sm font-medium font-['Figtree'] leading-tight">
              <select
                id="location"
                name="location"
                className="mr-4 block w-full h-[46px] rounded-md border py-2 pl-3 pr-10 text-gray-900 border-gray-300 focus:border-primary-500 sm:text-sm sm:leading-6 ring-0 focus:ring-0"
                defaultValue="customerEmail"
                onChange={handleOptionSearchChange}
              >
                {searchOptions.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="bg-white justify-start items-center flex">
            <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
              {searchStatus === "Customer" ? (
                <div className="flex">
                  <input
                    className="h-[46px] w-[324px] px-5 py-[13px] bg-white rounded-md border border-gray-200 focus:shadow-[inset_0_0_0_1px_rgba(56,189,248,1)] focus:ring-transparent focus:border-sky-400 text-sm text-gray-900 placeholder-zinc-400 outline-none mr-4"
                    disabled={!searchStatus}
                    placeholder="Search customer name or email"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              ) : (
                <div>
                  <Select
                    className="w-[324px] h-[66px] z-10 flex items-center"
                    styles={customStyles}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultValue={[]}
                    isMulti
                    options={filterOptions}
                    onChange={handleAdvancedSearch}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          {isUniqueVisitsLoading ||
          isAutoLoginLoading ||
          isPaidSalesLoading ||
          isCancellationsLoading ||
          isFilterLoading ||
          isBooksLoading ||
          isAdvancedSearchLoading ||
          isMetricsLoading ? (
            <SkeletonLoader />
          ) : (
            <div className="w-full">
              {isEmpty(salesDetailsData) ? (
                <div className="flex flex-col justify-center items-center py-16 rounded-sm bg-white">
                  <img
                    src={noItemsImage}
                    alt="No items"
                    className="mb-4 w-[150px] h-[150px]"
                  />
                  <div className="flex flex-col justify-start items-center gap-1">
                    <div className="text-center text-neutral-800 text-base font-semibold font-['Figtree'] leading-tight">
                      No results found.
                    </div>
                    <div className="text-center">
                      <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                        Try a different keyword or{" "}
                      </span>
                      <button
                        onClick={resetFilters}
                        className="text-sky-400 text-sm font-medium underline leading-[23px] focus:outline-none"
                      >
                        reset filters
                      </button>
                      <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                        .
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="rounded-b-lg">
                  <div className="bg-white justify-center items-center overflow-x-auto">
                    <AfyTable
                      data={salesDetailsData}
                      columns={columns}
                      perPage={perPage}
                      handleSort={handleSort}
                      columnName={sortByfilter}
                    />
                  </div>
                  <div className="bg-white rounded-md shadow py-4 px-6 max-[1180px]:px-0 mb-28">
                    <PaginationFlowBite
                      tablePagination={tablePagination}
                      handlePerPage={handlePerPage}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <Modal
        show={showViewDetailsModal}
        onClose={() => setShowViewDetailsModal(false)}
      >
        <Modal.Header>View Details</Modal.Header>
        <Modal.Body>
          <div className="flex flex-col">
            <div>
              Customer Name:{" "}
              {viewDetailsData.firstName + " " + viewDetailsData.lastName}
            </div>
            <div>
              Address:{" "}
              {`${viewDetailsData.address}, ${viewDetailsData.city}, ${viewDetailsData.state}, ${viewDetailsData.country}`}
            </div>
            <div>Last Step: {viewDetailsData.currentStep}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowViewDetailsModal(false)}>Cancel</Button>
          <Button onClick={() => handleFinishOnboarding(viewDetailsData)}>
            Finish Onboarding
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
