type Item = {
    _id: string;
    alias: string;
    name: string;
    category: string;
    status: string;
};

export const getUniqueCategories = (items: Item[]): string[] => {
    const categories = items.map(item => item.category);
    const uniqueCategories = Array.from(new Set(categories));
    return uniqueCategories.map(category => ({ name: category }));
}