import React, { ChangeEvent, useEffect, useState } from 'react';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import { DateTime } from 'luxon';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  useUpsellReport,
  useMutateAdvancedSearch,
  useUpsellReportUnpaginated,
} from './hooks';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import { formatFilterKey } from './utils';
import {
  formatDateTime5,
  formatDateTimeWithmonthyear,
} from '../../../../utils/date';
import { Card, Checkbox, Label, Flowbite } from 'flowbite-react';
import { debounce } from 'lodash-es';
import { CSVLink } from 'react-csv';
import { ExportIcon } from '../../../../components/icons';
import PaginationFlowBite from '../../../../modules/components/PaginationFlowBite';
import noItemsImage from '../../../../assets/images/no-items.png';
import TextInput from '../../../components/TextInput';
import { ColumnDef } from '@tanstack/react-table';
import AfyTable from '../../../components/V2/Table';
import SkeletonLoader from '../../../components/SkeletonLoader';
import { customCheckboxTheme } from '../../../../utils/customTheme';

const animatedComponents = makeAnimated();

interface HandleAdvancedSearchType {
  label: string;
}

export default function TripWireReport() {
  const lastDay = DateTime.now().minus({ days: 30 }).toISO();
  const today = DateTime.now().toISO();
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [customerEmail, setCustomerEmail] = useState('');

  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState({});
  const [value, setValue] = useState<DateValueType>({
    startDate: lastDay,
    endDate: today,
  });

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
    control: (provided) => ({
      ...provided,
      maxHeight: 66,
      width: 324,
      overflowY: 'auto',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      position: 'relative',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      maxHeight: 66,
      position: 'sticky',
      top: 0,
      right: 0,
      backgroundColor: 'white',
    }),
  };

  const [searchStatus, setSearchStatus] = useState('Customer Email');
  const [advancedSearch, setAdvancedSearch] = useState({
    field: '',
    keyword: '',
  });
  const [upsellData, setUpsellData] = useState([]);
  const [upsellMeta, setUpsellMeta] = useState({ total: 0, lastPage: 0 });
  const [excludeUpsell, setExcludeUpsell] = useState(true);

  const [sortByfilter, setSortByfilter] = useState({
    key: 'createdAt',
    value: '-1',
  });

  const [exportData, setExportData] = useState([]);
  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);

  const { mutate: mutateGetAdvancedSearch, data: advancedSearchData } =
    useMutateAdvancedSearch(advancedSearch.field, advancedSearch.keyword);

  useEffect(() => {
    const isValidSearch = advancedSearch.field !== '';
    if (isValidSearch) {
      mutateGetAdvancedSearch();
    }
  }, [advancedSearch]);

  const searchOptions = [
    { value: 'Customer Email', key: 'customerEmail' },
    { value: 'Offer', key: 'offerName' },
    { value: 'Channel', key: 'channel' },
    { value: 'UTM Source', key: 'utmSource' },
    { value: 'UTM Content', key: 'utmContent' },
    { value: 'UTM Medium', key: 'utmMedium' },
    { value: 'UTM Term', key: 'utmTerm' },
  ];

  const handleAdvancedSearch = (value: HandleAdvancedSearchType[]) => {
    const arr = value.map((item) => item.label);

    setSearchQuery({
      [advancedSearch.field]: arr,
    });
    setCurrentTablePage(1);
  };

  const filterOptions = advancedSearchData?.map((item: string) => {
    const key = item.replace(/ /g, '_');
    return { value: key.toString().toLowerCase(), label: item };
  });

  const resetFilters = () => {
    setSearchQuery({});
    setCustomerEmail('');
    setValue({ startDate: lastDay, endDate: today });
    setSearchStatus('Customer Email');
    setAdvancedSearch({ field: '', keyword: '' });
    refetch();
  };

  const handleSuccess = (response) => {
    setUpsellData(response.data.data);
    setUpsellMeta({
      total: response.data.meta.total,
      lastPage: response.data.meta.lastPage,
    });
  };

  const { refetch, isFetching } = useUpsellReport(
    value.startDate,
    value.endDate,
    currentTablePage,
    perPage,
    searchQuery,
    sortByfilter,
    handleSuccess,
    excludeUpsell,
  );

  const handleSort = (columnKey) => {
    setCurrentTablePage(1);
    if (sortByfilter.key === columnKey) {
      const descend =
        sortByfilter.key === columnKey && sortByfilter.value === '-1';
      const ascend =
        sortByfilter.key === columnKey && sortByfilter.value === '1';
      if (descend) {
        setSortByfilter({ key: columnKey, value: '1' });
      } else if (ascend) {
        setSortByfilter({ key: columnKey, value: '-1' });
      }
    } else {
      setSortByfilter({ key: columnKey, value: '1' });
    }
  };

  useEffect(() => {
    refetch();
  }, [
    currentTablePage,
    perPage,
    searchQuery,
    value,
    excludeUpsell,
    refetch,
    sortByfilter,
  ]);

  const handleValueChange = (newValue: DateValueType) => {
    setValue(newValue);
    setCurrentTablePage(1);
    refetch();
  };

  const handlePerPage = (value, firstItemIndex) => {
    const perPage = value.value;
    const pageNumber = Math.ceil(firstItemIndex / perPage) || 1;
    setPerPage(perPage);
    setCurrentTablePage(pageNumber);
  };

  const handlePageChange = (newPage) => {
    setCurrentTablePage(newPage);
  };

  const adjustCurrentPage = (total, pageSize) => {
    const newTotalPages = Math.ceil(total / pageSize);
    if (currentTablePage > newTotalPages) {
      setCurrentTablePage(Math.max(1, newTotalPages));
    }
  };

  useEffect(() => {
    adjustCurrentPage(upsellMeta.total, perPage);
  }, [upsellMeta.total, perPage]);

  const handleSearchBy = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setCurrentTablePage(1);
    setCustomerEmail('');
    setSearchStatus(value);
    setAdvancedSearch((prevState) => ({
      ...prevState,
      field: value,
    }));
  };

  const handleSearchDebounced = debounce((email) => {
    if (email.trim() === '') {
      setSearchQuery({});
    } else {
      let formattedEmail = email.trim();
      formattedEmail = formattedEmail.replace(/\+/g, '\\+');
      const filterKey = formatFilterKey(searchOptions, searchStatus);

      setSearchQuery({
        [filterKey]: [formattedEmail],
      });
    }
  }, 300);

  useEffect(() => {
    handleSearchDebounced(customerEmail);
    setCurrentTablePage(1);
  }, [customerEmail]);

  const headers = [
    { label: 'Offer', key: 'offerName' },
    { label: 'Member', key: 'customerName' },
    { label: 'Channel', key: 'channel' },
    { label: 'UTM Source', key: 'utmSource' },
    { label: 'UTM Medium', key: 'utmMedium' },
    { label: 'UTM Content', key: 'utmContent' },
    { label: 'UTM Term', key: 'utmTerm' },
    { label: 'Date', key: 'createdAt' },
  ];

  const { data, refetch: fetchFullData } = useUpsellReportUnpaginated(
    value.startDate,
    value.endDate,
    excludeUpsell,
    searchQuery,
  );

  useEffect(() => {
    fetchFullData().then(() => {
      if (data) {
        const formattedData = data.map((item) => ({
          offerName: item.offerName,
          customerName: `${item.customer?.firstName || item.firstName} ${
            item.customer?.lastName || item.lastName
          }`,
          customerEmail: item.customer?.email || item.customerEmail,
          channel: item.channel || '-',
          utmSource: item.utmSource || '-',
          utmMedium: item.utmMedium || '-',
          utmContent: item.utmContent || '-',
          utmTerm: item.utmTerm || '-',
          createdAt: item.createdAt,
          sessionId: item.sessionId,
        }));
        setExportData(formattedData);
        setIsDataReadyForExport(true);
      }
    });
  }, [fetchFullData, data]);

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: 'Offer',
        accessorKey: 'offerName',
        cell: (info) => (
          <div className="flex flex-col items-start">
            <div className="text-black text-sm font-semibold leading-tight">
              {info.getValue()}
            </div>
            <div
              className={`mt-1 px-2 py-1 h-6 justify-center items-center inline-flex text-xs leading-5 font-semibold rounded-md ${
                info.row.original.sessionId
                  ? 'bg-emerald-50 text-emerald-600'
                  : 'bg-orange-100 text-yellow-500'
              }`}
            >
              {info.row.original.sessionId ? 'Upsell' : 'One-time'}
            </div>
          </div>
        ),
        customProp: 'offerName',
      },
      {
        header: 'Member',
        accessorKey: 'customerName',
        cell: (info) => (
          <>
            <div className="text-gray-900">{`${
              info.row.original.customer?.firstName ||
              info.row.original.firstName
            } ${
              info.row.original.customer?.lastName || info.row.original.lastName
            }`}</div>
            <div className="text-zinc-500 text-sm font-medium leading-tight">
              {info.row.original.customer?.email ||
                info.row.original.customerEmail}
            </div>
          </>
        ),
        customProp: 'customerEmail',
      },
      {
        header: 'Channel',
        accessorKey: 'channel',
        cell: (info) => info.getValue() || '-',
        customProp: 'channel',
      },
      {
        header: 'UTM Source',
        accessorKey: 'utmSource',
        cell: (info) => info.getValue() || '-',
        customProp: 'utmSource',
      },
      {
        header: 'UTM Medium',
        accessorKey: 'utmMedium',
        cell: (info) => info.getValue() || '-',
        customProp: 'utmMedium',
      },
      {
        header: 'UTM Content',
        accessorKey: 'utmContent',
        cell: (info) => info.getValue() || '-',
        customProp: 'utmContent',
      },
      {
        header: 'UTM Term',
        accessorKey: 'utmTerm',
        cell: (info) => info.getValue() || '-',
        customProp: 'utmTerm',
      },
      {
        header: 'Date',
        accessorKey: 'createdAt',
        cell: (info) => (
          <div className="text-gray-500">
            {formatDateTime5(info.getValue())}
          </div>
        ),
        customProp: 'createdAt',
      },
    ],
    [],
  );

  return (
    <div className="px-8 pt-8 min-h-screen max-w-full">
      <div className="mb-6">
        <Title>Tripwire Sales Report</Title>
      </div>
      <Card className="border-none w-full mb-20">
        <div className="flex justify-between items-center gap-2 flex-wrap max-[1320px]:justify-center">
          <div className="flex items-center gap-2 max-[700px]:flex-col">
            <div className="h-[46px]">
              <select
                id="location"
                name="location"
                className="h-[46px] mr-4 block w-full rounded-md border-0 py-3 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary-500 sm:text-sm sm:leading-6"
                defaultValue="customerEmail"
                onChange={handleSearchBy}
              >
                {searchOptions.map((option) => (
                  <option key={option.key} value={option.key}>
                    {option.value}
                  </option>
                ))}
              </select>
            </div>
            {searchStatus === 'Customer Email' ||
            advancedSearch.field === 'customerEmail' ? (
              <div className="h-[46px]">
                <TextInput
                  disabled={!searchStatus}
                  placeholder="Search customer email"
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                />
              </div>
            ) : (
              <Select
                className="h-[66px] w-[324px] ml-2 rounded-md items-center"
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={[]}
                isMulti
                options={filterOptions}
                styles={customStyles}
                onChange={handleAdvancedSearch}
              />
            )}
          </div>
          <div className="flex items-center gap-4">
            <div className="h-[46px]">
              <Datepicker
                placeholder="Last 30 days"
                separator="-"
                primaryColor="sky"
                value={value}
                onChange={handleValueChange}
                useRange={true}
                showShortcuts={true}
                showFooter={true}
                displayFormat="MMM DD, YYYY"
                popoverDirection="down"
                maxDate={new Date()}
              />
            </div>
            <CSVLink
              data={exportData}
              headers={headers}
              filename={`tripwire-report-${formatDateTimeWithmonthyear(
                value.startDate,
              )}-${formatDateTimeWithmonthyear(value.endDate)}.csv`}
              className={isDataReadyForExport ? '' : 'hidden'}
            >
              <Button
                variant="outlined"
                className="p-1 bg-gray-200 border-0 font-bold"
              >
                <ExportIcon className="mr-2" />
                Export
              </Button>
            </CSVLink>
          </div>
        </div>
        <div className="flex items-center gap-4 ">
          <div className="flex items-center gap-2">
            <Flowbite theme={{ theme: customCheckboxTheme }}>
              <Checkbox
                id="excludeUpsell"
                checked={excludeUpsell}
                onChange={() => {
                  if (!isFetching) {
                    setExcludeUpsell(!excludeUpsell);
                  }
                }}
              />
            </Flowbite>
            <Label
              className="font-semibold text-neutral-800 text-sm"
              htmlFor="excludeUpsell"
            >
              Exclude Upsell
            </Label>
          </div>
        </div>
        {isFetching ? (
          <SkeletonLoader />
        ) : upsellData.length > 0 ? (
          <div className="w-full">
            <div className="w-full bg-white justify-center items-center overflow-x-auto">
              <AfyTable
                data={upsellData}
                columns={columns}
                perPage={perPage}
                handleSort={handleSort}
                columnName={sortByfilter}
              />
            </div>
            <div className="bg-white rounded-md pt-4 px-6 max-[1180px]:px-0 h-full">
              <PaginationFlowBite
                tablePagination={{
                  pageSize: perPage,
                  total: upsellMeta.total,
                  lastPage: upsellMeta.lastPage,
                  currentPage: currentTablePage,
                  onChange: handlePageChange,
                }}
                handlePerPage={handlePerPage}
              />
            </div>
          </div>
        ) : (
          <div className="w-full h-[382px] py-[100px] flex flex-col justify-center items-center gap-3.5">
            <img
              className="w-[182px] h-[121px] object-cover"
              src={noItemsImage}
              alt="No items found"
            />
            <div className="self-stretch h-[47px] flex flex-col justify-start items-center gap-1">
              <div className="self-stretch text-center text-neutral-800 text-base font-semibold font-['Figtree'] leading-tight">
                No results found
                {customerEmail ? ` for "${customerEmail}".` : '.'}
              </div>
              <div className="text-center">
                <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                  Try a different keyword or{' '}
                </span>
                <button
                  onClick={resetFilters}
                  className="text-sky-400 text-sm font-medium underline leading-[23px] focus:outline-none"
                >
                  reset filters
                </button>
                <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                  .
                </span>
              </div>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}
