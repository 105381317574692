import axios, { AxiosInstance } from "axios";

const instance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BBA_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const getInstance = (): AxiosInstance => instance;
