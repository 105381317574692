import * as React from "react";
import type { SVGProps } from "react";
const Success = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15" 
    height="16" 
    viewBox="0 0 15 16" 
    fill="none"
    {...props}
  >
    <path d="M3 8.75L5.61905 11.75L12.1667 4.25" stroke="#069668" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/>
  </svg>
);
export default Success;