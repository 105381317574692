import { AxiosResponse } from "axios";
import { get } from "lodash-es";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponseData = Record<string, any>;

export const getPayload = <T>(response: AxiosResponse<ResponseData>): T =>
  get(response, ["data", "data"], response.data);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getError = (response: unknown): any =>
  get(response, ["data"], response);

export const getStatus = (response: AxiosResponse<ResponseData>): number =>
  get(response, ["status"]);
