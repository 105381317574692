import Wrapper from "../../../components/offboarding/Wrapper";
import Search from "../../../components/offboarding/Search";
import { FormEvent, useState } from "react";
import CustomerCard from "../../../components/offboarding/CustomerCard";
import { getCustomers } from "../../../api/offboarding";
import { useQuery } from "react-query";
import NotFoundCard from "../../../components/offboarding/NotFoundCard";
import { useNavigate } from "react-router-dom";
import React from "react";

export default function Offboarding() {
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const {
    data: customers,
    refetch,
    isLoading,
    isRefetching,
  } = useQuery(["getCustomers"], () => getCustomers(searchInput), {
    enabled: false,
  });

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return refetch();
  };

  const handleDetails = (previewId: string) => {
    navigate(`/offboarding/${previewId}`);
  };

  const content = () => {
    if (customers) {
      if (customers.data.length === 0) {
        return (
          <div className="flex justify-center">
            <NotFoundCard />
          </div>
        );
      } else {
        return (
          <div className="flex flex-wrap flex-col gap-[14px]">
            {customers.data.map(({ customer, preview }, idx) => (
              <CustomerCard
                onClick={() => handleDetails(preview)}
                customer={customer}
                key={idx}
              />
            ))}
          </div>
        );
      }
    }
  };
  return (
    <div className="pt-8 min-h-screen w-full">
      <div className="mb-8 w-full">
        <h1 className="mb-8 font-extrabold text-2xl">Offboarding</h1>
        <Search
          input={searchInput}
          setInput={setSearchInput}
          onClickHandler={handleSearchSubmit}
          isLoading={isLoading || isRefetching}
        />
      </div>
      {content()}
    </div>
  );
}
