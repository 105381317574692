import React from "react";

function SvgCloseXIcon() {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4.00003L4 12M3.99997 4L11.9999 12"
        stroke="#252525"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgCloseXIcon;