import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useQuery, useMutation } from "react-query";
import { Alert, Modal } from "flowbite-react";
import { get, isEmpty } from "lodash-es";

import { MagazineFromDBContext, MagazineFromDBContextType } from "../../../../../../context/MagazineFromDBContext";
import { MagazineImageContextType } from "../../../../../../context/MagazineImageContext";
import Button from "../../../../../components/Button";
import {
  SvgAlertSolidWarning,
  SvgAlertWarning,
  SvgCloseXIcon,
  SvgPublishBook,
  SvgStepCheck,
} from "../../../../../../components/icons";
import { initialValues, stepsList, magazineFormikStepsList, formKeywordList } from "../../constants";
import validationSchema from "../schema/index";

import Magazine from "./Steps/Magazine";
import FrontCover from "./Steps/FrontCover";
import FrontInsideCover from "./Steps/FrontInsideCover";
import BackInsideCover from "./Steps/BackInsideCover";

import {
  getGeneratedMagazineAdmin,
  updateReferralMagazineAdmin,
  generateReferralMagazineAdmin,
  createSetupTicket,
} from "../../../../../api/rm-magazine-report";
import { updateGeneratedMagazineById } from "../../../../../api/referral-marketing-magazines";
import {
  QueryKeyType,
  LightBoxProps,
  UpdateMagazineRequestType,
  MagazinePageDataResponseType,
  ObjectType,
} from "../types";
import {
  convertObjectToArrayOfObject,
  fnConvertTextToList,
  getFormattedValues,
  replaceFinalPayload,
  replaceNewLineWithBR,
} from "../../utils";

import BackCover from "./Steps/BackCover";
import AdditionalInformation from "./Steps/AdditionalInformation";
import { getMagazineStepsInfo } from "../../../../../api/referral-marketing-magazines";
import PublishLoader from "../../../../../components/PublishLoader";
import { stepHavingForms } from "./Steps/constants";
import { useMagazineImageContext } from "../../../../../../context/MagazineImageHook";

// Types: only used in this file
type QueryKeyWithoutCustomerId = [unknown, string, string];

type PayloadType = {
  isPreview?: boolean;
  magazineId?: number;
  baseReplacers?: BaseReplacers;
  selection?: {
    page: number;
    formKeyword: string;
    dynamicFields?: ObjectType[];
    extraHtml?: {
      formKeyword: string;
      dynamicFields: object[];
      htmlReplacer: string;
    };
  };
};

type UpdateGeneratedMagazineByIdType = {
  id: string;
  payload: ObjectType;
};

type GenerateReferralMagazineData = {
  payload: {
    month: string;
    year: number;
    userEmail: string;
    customerId: string;
  };
  isPreview: boolean;
  magazineId: number;
};

type BaseReplacers = {
  baseReplacers?: {
    [key: string]: object;
  };
};

type PublishPayloadType = {
  [key: string]: object | string | number | boolean;
  magazineId: string;
  isPreview: boolean;
};

const MagazineEditor = () => {
  const [openModal, setOpenModal] = useState(false);
  const [forceLoading, setForceLoading] = useState(false);
  const [isPublishedError, setPublishedError] = useState(false);
  const [stepError, setStepError] = useState(-1);
  const [stepSucceed, setStepSucceed] = useState<number[]>([]);
  const [previousMagazineData, setPreviousMagazineData] = useState({});
  const [presentMagazineID, setPresentMagazineID] = useState("");
  const [currentMagazineStatus, setCurrentMagazineStatus] = useState("");
  const [magazine, setMagazine] = useState<MagazinePageDataResponseType<object>>() as [
    MagazinePageDataResponseType<object>,
    React.Dispatch<React.SetStateAction<MagazinePageDataResponseType<object>>>
  ];
  const [magazineInfo, setMagazineInfo] = useState<MagazinePageDataResponseType<object>>({
    frontCoverDesign: [],
    frontCoverStrip: [],
    backCover: [],
    frontInsideCover: [],
    backInsideCover: [],
  });
  const [showImageLightBox, setShowImageLightBox] = useState(false);
  const [magazinePreviewUrl, setMagazinePreviewUrl] = useState("");
  const [imageLightBox, setImageLightBox] = useState<LightBoxProps>({
    src: null,
    alt: "Magazine option",
    loading: "lazy",
  });

  const params = useParams();
  const location = useLocation();
  const navigateLog = useNavigate();

  const { year, month, customerId } = params;

  const isMagazineSentToPrinting = false;

  const { ctxSaveMagazineFromDB } = useContext(MagazineFromDBContext) as MagazineFromDBContextType;
  const { setMagazineParams } = useMagazineImageContext() as MagazineImageContextType;

  /**
   * Update magazine details
   *
   * @param UpdateMagazineRequestType - request to update magazine details
   * @returns current - current step + 1
   */
  const {
    mutate: updateMagazine,
    isLoading: isUpdatingMagazine,
    isSuccess: hasMagazineInfoData,
  } = useMutation(
    (req: UpdateMagazineRequestType) => {
      if (req?.payload?.selection?.formKeyword === "frontInsideCover-option-1") {
        req.payload.selection.dynamicFields = replaceFinalPayload(req);
      }

      setForceLoading(false);
      return updateReferralMagazineAdmin(req);
    },
    {
      onSuccess: async () => {
        setFieldValue("currentStep", current + 1);
        refetchMagazineFromDb();
      },
      onError: () => {},
    }
  );

  /**
   * Settings query params for fetching magazine details
   *
   * @param array - ['getGeneratedMagazineAdmin', year, month, customerId]
   * @param getGeneratedMagazineAdmin - function to fetch magazine details
   *
   * @returns object - magazine data.
   */
  const getMagazineQueryKey: QueryKeyType = ["getGeneratedMagazineAdmin", year || "", month || "", customerId || ""];

  /**
   * Fetch magazine details
   *
   * @param getMagazineQueryKey - query key for fetching magazine details
   * @requires getMagazineFn - function to fetch magazine details
   *
   */
  const {
    data: magazineFromDatabase = {},
    refetch: refetchMagazineFromDb,
    isLoading: magazineDataLoading,
  } = useQuery(getMagazineQueryKey, async (): Promise<unknown> => {
    const result = await getGeneratedMagazineAdmin({
      queryKey: getMagazineQueryKey,
    });

    setMagazinePreviewUrl(result?.url);

    return result;
  });

  /**
   * Settings query params for fetching magazine details
   *
   * @param array - ['getMagazineAdmin', year, month, customerId]
   * @param getMagazineAdmin - function to fetch magazine details
   *
   * @returns object - magazine data.
   */
  const getMagazineStepsInfoQueryKey: QueryKeyWithoutCustomerId = ["getMagazineStepsInfo", month || "", year || ""];

  /**
   * Fetch magazine steps info from strapi
   *
   * @param array - ['getMagazineStepsInfo', params.month, params.year]
   */
  const { data: magazinePageData, isLoading: magazinePageDataInfoLoading } = useQuery(
    getMagazineStepsInfoQueryKey,
    async (): Promise<unknown> => {
      const result = await getMagazineStepsInfo({
        queryKey: getMagazineStepsInfoQueryKey,
      });

      const optionsDefaultValue = result as MagazinePageDataResponseType<typeof result>;
      setMagazineInfo({ magazineInfo, ...optionsDefaultValue });

      return result;
    }
  );

  /**
   * Update generatedMagzine by ID
   *
   * @param UpdateGeneratedMagazineByIdType - request to update generated magazine bookUrl, pageUrl, flippingBookUrl, additionalInformation
   *
   */
  const { mutate: updateMagazineById, isLoading: updatingGeneratedMagazineById } = useMutation(
    (payload: UpdateGeneratedMagazineByIdType) => updateGeneratedMagazineById(payload),
    {
      onSuccess: () => {
        setForceLoading(false);
        return false;
      },
      onError: () => {},
    }
  );

  /**
   * Update generatedMagazine by email, customerId, month, year
   *
   * @param GenerateReferralMagazineData - request to update generated magazine email, customerId, month, year
   */
  const { mutate: updateGeneratedMagazine, isLoading: updatingGeneratedMagazine } = useMutation(
    (payload: GenerateReferralMagazineData) => generateReferralMagazineAdmin(payload),
    {
      onSuccess: (data) => {
        setForceLoading(false);
        return false;
      },
      onError: () => {},
    }
  );

  // Update the referral magazine status by creating a ticket.
  const { mutate: sendToPrinting } = useMutation((payload) => createSetupTicket(payload), {
    onSuccess: (data) => {
      setCurrentMagazineStatus(get(data, "status"));
      navigateLog(location.pathname);
    },
    onError: () => {},
  });

  const generatedMagazineInfo = Array.isArray(magazineFromDatabase) ? magazineFromDatabase[0] : magazineFromDatabase;

  /**
   * Formik hook for magazine data
   */
  const magazineDataFormik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema(),
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async () => {
      setForceLoading(true);
      const stepName = magazineFormikStepsList[current];
      const payload: PayloadType = {
        magazineId: generatedMagazineInfo?.magazine?.magazineId,
      };

      const generatedMagzineExists = presentMagazineID || generatedMagazineInfo?.id;
      const location = "frontInsideCover._formFields.frontInsideCoverText";
      const data = replaceNewLineWithBR(magazineDataFormik.values.frontInsideCover._formFields.frontInsideCoverText);
      await setFieldValue(location, data);

      validateStepFormFields(current);

      if (current === 0) {
        payload.baseReplacers = magazineDataFormik.values[stepName]._formFields;
        const { setFieldValue } = magazineDataFormik;
        await setFieldValue("baseReplacers", payload.baseReplacers);

        const req = {
          payload,
          ...(generatedMagzineExists && { month: params.month }),
          ...(generatedMagzineExists && { year: params.year }),
          ...(generatedMagzineExists && { customerId: params.customerId }),
        };
        return updateMagazine(req);
      }
      const selectedOption = magazine[stepName] as MagazinePageDataResponseType<object>;

      if (current === 5) {
        payload[stepName] = magazine[stepName] ?? "";
        payload.isPreview = false;

        const request = {
          bookUrl: get(magazine, "bookUrl") || generatedMagazineInfo?.bookUrl,
          pageUrl: get(magazine, "pageUrl") || generatedMagazineInfo?.pageUrl,
          flippingBookUrl: get(magazine, "flippingBookUrl") || generatedMagazineInfo?.flippingBookUrl,
          additionalInformation: get(magazine, "additionalInformation") || generatedMagazineInfo?.additionalInformation,
        };

        updateMagazineById({
          id: generatedMagazineInfo?.id,
          payload: request,
        });
      }

      payload.selection = {
        page: current,
        formKeyword: (selectedOption && selectedOption?.formKeyword) ?? stepName,
      };

      if (stepHavingForms.includes(current)) {
        const currentStepFormDataFields = magazineDataFormik.values[stepName]._formFields || {};
        payload.selection.dynamicFields = convertObjectToArrayOfObject(currentStepFormDataFields);

        const { values } = magazineDataFormik;
        for (const key of Object.keys(values)) {
          if (key === "baseReplacers") {
            const hasValues = Object.keys(values?.baseReplacers)?.length;
            if (hasValues) {
              payload.baseReplacers = values?.baseReplacers;
            }
          }
        }

        if (current === 1 && magazine) {
          const { frontCoverStrip, frontCoverDesign } = magazine as any;
          const htmlReplacer = (frontCoverStrip?.formKeyword ?? "").split("-")[0];
          payload.selection.formKeyword = frontCoverDesign?.formKeyword;
          payload.selection.extraHtml = {
            formKeyword: frontCoverStrip?.formKeyword,
            dynamicFields: [...payload.selection.dynamicFields],
            htmlReplacer,
          };
          payload.selection.dynamicFields = [];
        }

        if (["backInsideCover-option-4", "backCover-option-5"].includes(payload.selection.formKeyword)) {
          const values = magazineDataFormik.values[stepName]._formFields.features;
          if (values.length > 0) {
            const nValue = fnConvertTextToList(values);
            const nValueArray = payload.selection.dynamicFields;
            const nDynamicFields = nValueArray.map((item) => {
              if (item.keyword === "features") {
                return { ...item, value: nValue };
              } else {
                return item;
              }
            });
            payload.selection.dynamicFields = nDynamicFields;
          }
        }

        return updateMagazine({
          payload,
          ...(generatedMagzineExists && { month: params.month }),
          ...(generatedMagzineExists && { year: params.year }),
          ...(generatedMagzineExists && { customerId: params.customerId }),
        });
      }
    },
  });
  const { values: referralMagazineContext, setFieldValue } = magazineDataFormik;
  const { currentStep: current } = referralMagazineContext;

  const handleUpdateMagazine = (newValue: ObjectType, keyToUpdate: string) => {
    const latestMagazineData = {
      ...magazine,
      [keyToUpdate]: newValue,
    } as MagazinePageDataResponseType<object>;
    setMagazine(latestMagazineData);
    setFieldValue("selectedOption", newValue.formKeyword);
  };

  const onPublishMagazine = useCallback(async () => {
    const hasError = await validateStepFormFields();

    if (hasError) return;

    setForceLoading(true);

    const payload: PublishPayloadType = {
      magazineId: magazinePageData?.id,
      isPreview: false,
    };

    const stepName = magazineFormikStepsList[5];
    const theStep = magazine ? magazine[stepName] : "";
    payload[stepName] = theStep as string;
    payload.isPreview = true;

    const request = {
      bookUrl: get(magazine, "bookUrl") || generatedMagazineInfo?.bookUrl,
      pageUrl: get(magazine, "pageUrl") || generatedMagazineInfo?.pageUrl,
      flippingBookUrl: get(magazine, "flippingBookUrl") || generatedMagazineInfo?.flippingBookUrl,
      additionalInformation: get(magazine, "additionalInformation") || generatedMagazineInfo?.additionalInformation,
    };

    updateMagazineById({
      id: generatedMagazineInfo?.id,
      payload: request,
    });

    updateGeneratedMagazine({
      payload: params,
      isPreview: payload.isPreview,
    });

    const magazinePayload = {
      id: generatedMagazineInfo?.id,
    };

    setFieldValue("currentStep", -1);
    return sendToPrinting({ ...params, ...magazinePayload });
  }, [magazine]);

  const validateStepFormFields = async (step: number = 0) => {
    const stepName = magazineFormikStepsList[current];
    const { validateForm } = magazineDataFormik;

    const hasError = await validateForm().then((err) => {
      if (Object.prototype.hasOwnProperty.call(err, stepName)) {
        return true;
      }
      return false;
    });

    setPublishedError(hasError);

    if (!hasError) {
      setStepSucceed((prevStep) => [...prevStep, current]);
      setFieldValue("currentStep", step);
      setStepError(-1);
    } else {
      setStepError(current);
      setStepSucceed((prevStep) => prevStep.filter((stp) => stp !== current));
    }

    return hasError;
  };

  const handleImageLightBox = (imageForLightBox: string) => {
    setShowImageLightBox(!showImageLightBox);
    setImageLightBox({
      src: imageForLightBox,
      alt: "Magazine option",
      loading: "lazy",
    });
  };

  const updateFormikWithPreviousData = (formattedValues, formKeyword, page, magazinePageData) => {
    const lastValue = {};

    if (formKeyword === undefined) return;

    const currentStep = magazineFormikStepsList[page];
    const formKeywordText = formKeyword.split("-");
    const keyFromMagazine = formKeywordList[formKeywordText[0]];
    const currentStepMagazineData = magazinePageData[keyFromMagazine] || [];

    lastValue[keyFromMagazine] = currentStepMagazineData.find((option) => option.formKeyword === formKeyword);
    const valueToInitialize = {
      [currentStep]: {
        _formFields: formattedValues,
      },
    };

    return { lastValue, valueToInitialize };
  };

  useEffect(() => {
    if (!magazineDataLoading && !magazinePageDataInfoLoading) {
      const { magazine: magazineFromDB } = magazineFromDatabase;

      if (!isEmpty(magazineFromDB?.selections)) {
        const filledSteps = magazineFromDB?.selections;
        let magazineData = { ...magazine };
        let reInitializeFormikValues = {};

        for (let index = 0; index < filledSteps.length; index++) {
          const stepInfo = filledSteps[index];
          const { dynamicFields = [], formKeyword, page, extraHtml = {} } = stepInfo;
          const formattedValues = getFormattedValues(dynamicFields);

          if (formKeyword !== "additionalInformation") {
            const { lastValue, valueToInitialize: stepValueToReInitialize } = updateFormikWithPreviousData(
              formattedValues,
              formKeyword,
              page,
              magazinePageData
            );

            reInitializeFormikValues = {
              ...reInitializeFormikValues,
              ...stepValueToReInitialize,
            };

            magazineData = { ...magazineData, ...lastValue };

            const hasExtraInfo = Object.keys(extraHtml)?.length;
            if (hasExtraInfo) {
              const { dynamicFields: extraDynamicFields = [], formKeyword: extraInfoFormKeyword } = extraHtml;
              const extraValues = getFormattedValues(extraDynamicFields);

              const { lastValue: previousValue, valueToInitialize: extraValueToReInitialize } =
                updateFormikWithPreviousData(extraValues, extraInfoFormKeyword, page, magazinePageData);

              reInitializeFormikValues = {
                ...reInitializeFormikValues,
                ...extraValueToReInitialize,
              };
              magazineData = { ...magazineData, ...previousValue };
            }
          }
        }

        setPresentMagazineID(magazineFromDB.magazineId);
        setPreviousMagazineData(magazineFromDB);
        ctxSaveMagazineFromDB(magazineFromDB);
        setMagazine({ ...magazine, ...magazineData });
      }
    }
  }, [magazineFromDatabase, magazinePageData, magazineDataLoading, magazinePageDataInfoLoading]);

  useEffect(() => {
    setMagazineParams(params);
  }, [params]);

  const renderCurrentStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Magazine
            step="magazine"
            updateMagazineData={{
              magazine,
              magazineDataFormik,
            }}
          />
        );
      case 1:
        return (
          <FrontCover
            handleImageLightBox={handleImageLightBox}
            updateMagazineData={{
              magazine,
              magazineDataFormik,
              handleUpdateMagazine,
            }}
            frontCoverDesign={magazineInfo.frontCoverDesign}
            frontCoverStrip={magazineInfo.frontCoverStrip}
          />
        );
      case 2:
        return (
          <FrontInsideCover
            updateMagazineData={{
              magazine,
              magazineDataFormik,
              previousMagazineData,
            }}
            frontInsideCover={magazineInfo.frontInsideCover}
          />
        );
      case 3:
        return (
          <BackInsideCover
            handleImageLightBox={handleImageLightBox}
            updateMagazineData={{
              magazine,
              magazineDataFormik,
              previousMagazineData,
              handleUpdateMagazine,
              setFieldValue,
            }}
            backInsideCover={magazineInfo.backInsideCover}
          />
        );
      case 4:
        return (
          <BackCover
            handleImageLightBox={handleImageLightBox}
            updateMagazineData={{
              magazine,
              magazineDataFormik,
              previousMagazineData,
              handleUpdateMagazine,
              setFieldValue,
            }}
            backCoverList={magazineInfo.backCover}
          />
        );
      case 5:
        return (
          <AdditionalInformation
            updateMagazineData={{
              magazine,
              magazineDataFormik,
              handleUpdateMagazine,
            }}
          />
        );
      default:
        return "Default";
    }
  };

  if (isUpdatingMagazine || magazineDataLoading || forceLoading) {
    let message = "Updating magazine data...";
    magazineDataLoading && (message = "Fetching magazine data...");
    return (
      <div className="flex w-full h-[calc(100vh-12em)] flex-col pb-10">
        <div className="z-20 w-full h-full">
          <PublishLoader message={message} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col pb-10">
      <div className="w-full flex justify-between self-stretch flex-col items-start md:flex-row md:items-center">
        <div className="w-full max-w-60 text-neutral-800 text-2xl font-extrabold leading-7">RM Magazine editor</div>
        <div className="w-full flex items-center gap-2 sm:w-full mt-3 justify-between md:justify-end md:mt-0">
          <Button type="button" onClick={() => setOpenModal(true)} size="lg" variant="LIGHT">
            See sample preview
          </Button>
          <Button
            type="button"
            onClick={() => onPublishMagazine()}
            size="lg"
            variant="primary"
            className="flex items-center gap-2"
          >
            <SvgPublishBook />
            <span>Publish magazine</span>
          </Button>
        </div>
      </div>
      <Alert icon={SvgAlertWarning} className="bg-orange-100 text-orange-600 mt-6 flex flex-start">
        <span className="ms-3 flex justify-start leading-tight">
          If everything looks good, click “Publish” to update your websites and send magazine for printing.
        </span>
      </Alert>
      <div className="mt-6 rounded-lg bg-white border-neutral-300 shadow-box">
        <div className="flex items-center gap-6 self-stretch pt-[1.375rem] pb-[1.375rem] px-6 border-b border-b-neutral-200">
          <div className="frame_787 flex flex-col justify-center items-start">
            <div className="text-boxTitle font-semibold capitalize">{`${params.month} ${params.year} Magazine`}</div>
            <div className="text-neutral-500 text-sm font-medium">
              The information provided here will be used for the magazine. Customization options available in these
              steps are free of charge.
            </div>
          </div>
        </div>
        {/** Steps */}
        <div className="flex flex-col items-start self-stretch px-0">
          {stepsList.map(({ step, title }) => (
            <div key={title} className="flex flex-col items-center self-stretch px-6 py-5 border-b">
              <div key={title} className="flex items-center gap-5 self-stretch">
                {isMagazineSentToPrinting && (
                  <>
                    {isPublishedError && step === stepError ? (
                      <SvgAlertSolidWarning />
                    ) : (
                      <div className="bg-success-200 rounded-full p-1.5">
                        <SvgStepCheck />
                      </div>
                    )}
                  </>
                )}
                {!isMagazineSentToPrinting && isPublishedError && <>{step === stepError && <SvgAlertSolidWarning />}</>}
                {!isMagazineSentToPrinting && step !== stepError && (
                  <div className={`${current === step ? "bg-success-500" : "bg-success-200"} rounded-full p-1.5`}>
                    <SvgStepCheck />
                  </div>
                )}
                <div className="frame_792-1 w-full flex justify-between items-center">
                  <div
                    className={`${current === step ? "text-neutral-800" : "text-neutral-400"} text-base font-semibold`}
                  >
                    {title}
                  </div>
                  <button
                    onClick={() => {
                      validateStepFormFields(step);
                    }}
                    type="button"
                    className={`${
                      current === step ? "hidden" : "text-neutral-800"
                    } flex w-[110px] justify-center items-center border text-neutral-800 px-[18px] py-[13px] rounded-md border-solid border-neutral-200 hover:border-neutral-500 hover:bg-neutral-50`}
                  >
                    Edit
                  </button>
                </div>
              </div>
              <div className="w-full pl-0 sm:pl-[50px]">{current === step && renderCurrentStep(current)}</div>
            </div>
          ))}
        </div>
      </div>
      {/** Modal */}
      <Modal
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
        size="6xl"
        theme={{
          body: {
            base: "h-[631px] p-6",
          },
        }}
      >
        <div>
          <div className="flex justify-between items-center self-stretch p-6">
            <div className="text-neutral-800 font-bold">
              <span className="capitalize">
                {params.month} {params.year}
              </span>
              sample magazine
            </div>
            <Button
              onClick={() => setOpenModal(false)}
              type="button"
              variant="LIGHT"
              className="flex justify-center items-center gap-2 p-2.5 p-2 w-9 h-9 rounded-md bg-neutral-100 focus:ring-0"
            >
              <SvgCloseXIcon />
            </Button>
          </div>
          <div className="flex items-start gap-3 self-stretch py-2 px-3 rounded-md bg-secondary-orange-50 mx-6">
            <SvgAlertWarning />
            <div className="content flex flex-col items-start gap-2">
              <div className="list flex flex-col items-start self-stretch">
                <div className="alerts_list_item flex items-start self-stretch text text-secondary-orange-600 text-sm font-semibold">
                  This preview is a sample only and NOT your finalized version. If all inputted information is correct,
                  our setup team will send a proof link via your registered email with the accurate magazine preview
                  within 24-48 business hours upon receiving the order
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal.Body>
          {!magazineDataLoading ? <afy-flip-book key="magazine-step-pdf" pdfurl={magazinePreviewUrl} /> : null}
        </Modal.Body>
      </Modal>
      <Modal
        dismissible
        show={showImageLightBox}
        onClose={() => setShowImageLightBox(false)}
        size="2xl"
        theme={{
          body: {
            base: "relative h-full p-6 bg-black/50",
          },
        }}
      >
        <div className="relative w-50% h-50%">
          {imageLightBox.src && (
            <img
              src={imageLightBox.src}
              className="object-fit rounded-lg shadow-box border border-neutral-500 overflow-hidden"
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MagazineEditor;
