import * as React from "react";
import type { SVGProps } from "react";
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    {...props}
  >
    <path
      stroke="#3C3C3C"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M9 15.25a6 6 0 0 1 0-12"
    />
    <path
      stroke="#3C3C3C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7.5 9.25H15m0 0L12.75 7M15 9.25l-2.25 2.25"
    />
  </svg>
);
export default SvgLogout;
