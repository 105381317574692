import { useMutation, useQuery } from "react-query";
import {
  getRmMagazineMetricsBySearch,
  getRmMagazineMetricsEditing,
  getRmMagazineMetricsMagazineGenerated,
  getRmMagazineMetricsSentToPrint,
  getRmMagazineMetricsTableData,
} from "../../../../api/rm-magazine-report";

export * from "./useWait";
const useGetDefaultTableMetrics = (
  month,
  year,
  page,
  perPage,
  status,
  onSuccess,
  sortByFilter
) =>
  useMutation(
    () =>
      getRmMagazineMetricsTableData(
        month,
        year,
        page,
        perPage,
        status,
        sortByFilter
      ),
    {
      onSuccess,
    }
  );

const useGetEditingTableMetrics = (
  month,
  year,
  page,
  perPage,
  status,
  onSuccess,
  sortByFilter
) =>
  useMutation(
    () =>
      getRmMagazineMetricsEditing(
        month,
        year,
        page,
        perPage,
        status,
        sortByFilter
      ),
    {
      onSuccess,
    }
  );

const useGetInputTableMetrics = (
  searchQuery,
  month,
  year,
  page,
  perPage,
  status,
  onSuccess,
  sortByFilter
) =>
  useMutation(
    () =>
      getRmMagazineMetricsBySearch(
        searchQuery,
        month,
        year,
        page,
        perPage,
        status,
        sortByFilter
      ),
    {
      onSuccess,
    }
  );

const useGetGeneratedMagzineTableMetrics = (
  month,
  year,
  page,
  perPage,
  status,
  onSuccess,
  sortByFilter
) =>
  useMutation(
    () =>
      getRmMagazineMetricsMagazineGenerated(
        month,
        year,
        page,
        perPage,
        status,
        sortByFilter
      ),
    {
      onSuccess,
    }
  );

const useGetSentToPrintTableMetrics = (
  month,
  year,
  page,
  perPage,
  status,
  onSuccess,
  sortByFilter
) =>
  useMutation(
    () =>
      getRmMagazineMetricsSentToPrint(
        month,
        year,
        page,
        perPage,
        status,
        sortByFilter
      ),
    {
      onSuccess,
    }
  );

export {
  useGetDefaultTableMetrics,
  useGetEditingTableMetrics,
  useGetInputTableMetrics,
  useGetGeneratedMagzineTableMetrics,
  useGetSentToPrintTableMetrics,
};
