import * as React from "react";
import type { SVGProps } from "react";

const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} className={`w-3 h-3 ${props.className}`}>
    <path d="M1.25 10.25C1.25 12.3713 1.25 13.432 1.90901 14.091C2.56802 14.75 3.62868 14.75 5.75 14.75H10.25C12.3713 14.75 13.432 14.75 14.091 14.091C14.75 13.432 14.75 12.3713 14.75 10.25" stroke="#3C3C3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 1.25V11M8 11L11 7.71875M8 11L5 7.71875" stroke="#3C3C3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgDownload;
