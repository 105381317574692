import { omitBy } from "lodash-es";
import { getInstance } from "./instance";
import { DateType } from "react-tailwindcss-datepicker";

const instance = getInstance();

const getAllSessions = (startDate: DateType, endDate: DateType) => {
  const url = "/onboard/sessions";
  const params = { startDate, endDate };

  return instance
    .get(url, { params })
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

const getOnboardMetrics = (startDate: DateType, endDate: DateType) => {
  const url = "/abo/metrics/reports";
  const params = { startDate, endDate };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getOnboardMetricsByPaidSales = (
  startDate: DateType,
  endDate: DateType,
  page: string,
  perPage: string,
  sortByFilter?: { [key: string]: string }
) => {
  const url = "/abo/metrics/reports/paid-sales";
  const params = { startDate, endDate, page, perPage };
  if (sortByFilter) {
    params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
  }
  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getPaidSalesForExportData = (startDate: DateType, endDate: DateType) => {
  const url = "/onboard/metrics/reports/paid-sales-unpaginated";
  const params = { startDate, endDate };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getAutoLoginForExportData = (startDate: DateType, endDate: DateType) => {
  const url = "/onboard/metrics/reports/auto-login-unpaginated";
  const params = { startDate, endDate };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getCancellationsForExportData = (
  startDate: DateType,
  endDate: DateType
) => {
  const url = "/onboard/metrics/reports/cancellations-unpaginated";
  const params = { startDate, endDate };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getBooksGeneratedForExportData = (
  startDate: DateType,
  endDate: DateType
) => {
  const url = "/onboard/metrics/reports/books-unpaginated";
  const params = { startDate, endDate };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getFilterForExportData = (
  searchQuery: string,
  startDate: DateType,
  endDate: DateType
) => {
  const filteredTerms = omitBy(searchQuery, (item) => !item);
  const filter = JSON.stringify(filteredTerms);
  const url = "/onboard/metrics/reports/filter-unpaginated";
  const params = { filter, startDate, endDate };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getUniqueVisitsForExportData = (
  startDate: DateType,
  endDate: DateType
) => {
  const url = "/onboard/metrics/reports/unique-visits-unpaginated";
  const params = { startDate, endDate };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getOnboardMetricsByUniqueVisits = (
  startDate: DateType,
  endDate: DateType,
  page: string,
  perPage: string,
  sortByFilter?: { [key: string]: string }
) => {
  const url = "/abo/metrics/reports/unique-visits";
  const params = { startDate, endDate, page, perPage };
  if (sortByFilter) {
    params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
  }

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getOnboardMetricsByBooks = (
  startDate: DateType,
  endDate: DateType,
  page: string,
  perPage: string,
  sortByFilter?: { [key: string]: string }
) => {
  const url = "/onboard/metrics/reports/books";
  const params = { startDate, endDate, page, perPage };
  if (sortByFilter) {
    params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
  }

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getOnboardMetricsByAutoLogin = (
  startDate: DateType,
  endDate: DateType,
  page: string,
  perPage: string,
  sortByFilter?: { [key: string]: string }
) => {
  const url = "/abo/metrics/reports/auto-login";
  const params = { startDate, endDate, page, perPage };
  if (sortByFilter) {
    params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
  }

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getOnboardMetricsByCancellations = (
  startDate: DateType,
  endDate: DateType,
  page: string,
  perPage: string,
  sortByFilter?: { [key: string]: string }
) => {
  const url = "/onboard/metrics/reports/cancellations";
  const params = { startDate, endDate, page, perPage };
  if (sortByFilter) {
    params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
  }

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getOnboardMetricsBySearchQuery = (
  searchQuery: string,
  page: string,
  perPage: string
) => {
  const url = "/onboard/metrics/reports/search";
  const params = { searchQuery, page, perPage };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getOnboardMetricsByFilter = (
  terms: string,
  startDate: DateType,
  endDate: DateType,
  page: string,
  perPage: string,
  sortByFilter?: { [key: string]: string }
) => {
  const url = "/abo/metrics/reports/filter";
  try {
    const filteredTerms = omitBy(terms, (item) => !item);
    const filter = JSON.stringify(filteredTerms);
    const params = { filter, startDate, endDate, page, perPage };
    if (sortByFilter) {
      params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
    }

    return instance
      .get(url, { params })
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  } catch (e) {
    return Promise.reject(e);
  }
};

const getOnboardMetricsBySuggestions = (field: string, keyword = "") => {
  const url = "/onboard/metrics/reports/suggestions";
  const object = { [field]: keyword };
  try {
    const filter = JSON.stringify(object);
    const params = { filter };

    return instance
      .get(url, { params })
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  } catch (e) {
    return Promise.reject(e);
  }
};

const reCreateHubspotDeal = (email: string) => {
  const body = { email };
  const url = "/onboard/re-create/hubspot-deal";

  return instance.post(url, body);
};

export {
  getAllSessions,
  getOnboardMetrics,
  getOnboardMetricsByPaidSales,
  getOnboardMetricsByUniqueVisits,
  getOnboardMetricsByBooks,
  getOnboardMetricsByAutoLogin,
  getOnboardMetricsByCancellations,
  getOnboardMetricsBySearchQuery,
  getOnboardMetricsByFilter,
  getOnboardMetricsBySuggestions,
  reCreateHubspotDeal,
  getPaidSalesForExportData,
  getAutoLoginForExportData,
  getCancellationsForExportData,
  getBooksGeneratedForExportData,
  getFilterForExportData,
  getUniqueVisitsForExportData,
};
