import * as React from "react";
import type { SVGProps } from "react";
const SvgOnboardingActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="#3C3C3C"
      d="M7.917 2.167c-.69 0-1.25.56-1.25 1.25v.833c0 .69.56 1.25 1.25 1.25h4.166c.69 0 1.25-.56 1.25-1.25v-.833c0-.69-.56-1.25-1.25-1.25z"
    />
    <path
      fill="#3C3C3C"
      fillRule="evenodd"
      d="M5.417 3.864c-1.049.059-1.71.226-2.185.7-.732.732-.732 1.91-.732 4.268v5c0 2.357 0 3.535.732 4.268s1.911.732 4.268.732h5c2.357 0 3.535 0 4.268-.732.732-.733.732-1.911.732-4.268v-5c0-2.357 0-3.536-.732-4.268-.475-.474-1.136-.641-2.185-.7v.386a2.5 2.5 0 0 1-2.5 2.5H7.917a2.5 2.5 0 0 1-2.5-2.5zm7.51 7.093a.625.625 0 0 0-.853-.914l-3.145 2.935-1.003-.935a.625.625 0 0 0-.852.914l1.428 1.333c.24.224.613.224.853 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgOnboardingActive;
