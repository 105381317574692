import React from "react";
import Button from "../../../../../../components/Button";
import BrokerLogoModal from "../BrokerLogoModal";
import { ImageUploaderModalProps } from "../../types";
import { cn } from "../../../../../../utils/cn";
import { useMagazineImageContext } from "../../../../../../../context/MagazineImageHook";

const ImageUploaderModal = ({
  type,
  imageUrl,
  modalTitle,
  onSuccess,
  onCancel,
  handleOpenModal,
  onRemoveImage,
}: ImageUploaderModalProps) => {
  const { setImageType } = useMagazineImageContext();

  return (
    <>
      <BrokerLogoModal type={type} title={modalTitle} onCancel={onCancel} onSuccess={onSuccess} />
      <div>
        <div className="w-full lg:w-1/2 pr-2 flex justify-start mb-6">
          {imageUrl ? (
            <div className="flex gap-3 items-center flex-col items-center sm:flex-row">
              <div
                className={cn(
                  "w-[240px] h-[160px] border flex items-center rounded-md overflow-hidden p-0.5",
                  type === "rm_profile" && "rounded-full border-0 w-auto h-auto"
                )}
              >
                {type === "rm_profile" ? (
                  <img
                    src={imageUrl}
                    alt={type}
                    className="border border-neutral-300 rounded-full w-full h-full max-w-32 sm:w-32 sm:h-32"
                  />
                ) : (
                  <img src={imageUrl} alt={type} />
                )}
              </div>
              <div className="flex gap-2">
                <Button
                  variant="LIGHT"
                  onClick={() => {
                    setImageType(type);
                    handleOpenModal(type);
                  }}
                  className="border-neutral-200 hover:border-neutral-500 hover:bg-neutral-50 h-11"
                >
                  Change
                </Button>
                <Button
                  variant="LIGHT"
                  onClick={onRemoveImage}
                  className="border-neutral-200 hover:border-neutral-500 hover:bg-neutral-50 h-11"
                >
                  Remove
                </Button>
              </div>
            </div>
          ) : (
            <button
              onClick={() => {
                setImageType(type);
                handleOpenModal(type);
              }}
              className="w-full gap-4 py-[13px] px-[17px] border border-neutral-300 text-center rounded-lg cursor-pointer"
            >
              Upload photo
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageUploaderModal;
