import React from "react";
import { Spinner } from "../../components/icons";

type PublishLoaderProps = {
  message: string;
};

export default function PublishLoader({ message }: PublishLoaderProps) {
  return (
    <div className="absoute w-full h-full flex items-center justify-center">
      <div className="flex flex-col gap-2 items-center justify-center bg-white w-80 h-52 rounded-xl shadow-box py-8">
        <Spinner />
        <span className="text-neutral-800 text-base font-bold mt-6">
          Publishing magazine...
        </span>
        <span className="text-neutral-500 text-sm font-medium">
          {message}
        </span>
      </div>
    </div>
  )
}