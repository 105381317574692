import React, { useState, useCallback } from 'react';
import {
  ArrowBack,
  DownloadIconGreen,
  SvgDownload,
} from '../../../../../components/icons';
import Button from '../../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { TextInput } from 'flowbite-react';
import PaginationFlowBite from '../../../../components/PaginationFlowBite';
import { useQuery } from 'react-query';
import {
  getMagazineRecipientLeads,
  getOneCSVLead,
  getOneCustomerDetail,
} from '../../../../api/rm-magazine-report';
import { useParams } from 'react-router-dom';
import SkeletonLoader from '../../../../components/SkeletonLoader';
import { generateCsvDownload } from '../../../../../utils/csvDownload';
import { debounce } from 'lodash-es';

const UserLeads = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [leads, setLeads] = useState([]);
  const [metadata, setMetadata] = useState({} as any);
  const [totalLeads, setTotalLeads] = useState(null);
  const [customer, setCustomer] = useState({} as any);
  const [debouncedKeyword, setDebouncedKeyword] = useState('');
  const { userId } = useParams();

  const handlePerPage = (value, firstItemIndex) => {
    const perPage = value.value;
    const pageNumber = Math.ceil(firstItemIndex / perPage) || 1;
    setPerPage(perPage);
    setPage(pageNumber);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const { isLoading } = useQuery(
    ['getMagazineRecipientLeads', page, perPage, debouncedKeyword],
    () => getMagazineRecipientLeads(userId, page, perPage, debouncedKeyword),
    {
      cacheTime: 0,
      onSuccess: (response) => {
        setLeads(response.data);
        setMetadata(response.meta);
        if (!totalLeads) {
          setTotalLeads(response.meta.total);
        }
      },
    },
  );

  const { isLoading: isLoadingCustomerDetail } = useQuery(
    'getCustomerDetail',
    () => getOneCustomerDetail(userId),
    {
      onSuccess: (response) => {
        setCustomer(response);
      },
      cacheTime: 0,
    },
  );

  const handleExportOne = (customerId) => {
    getOneCSVLead(customerId).then((response) =>
      generateCsvDownload(response, customerId),
    );
  };

  const handleTextInput = (e) => {
    debouncedSearch(e.target.value);
  };

  const tablePagination = {
    pageSize: metadata.perPage,
    total: metadata.total,
    lastPage: metadata.lastPage,
    currentPage: metadata.currentPage + 1,
    onChange: handlePageChange,
  };

  const debouncedSearch = useCallback(
    debounce((search) => {
      setDebouncedKeyword(search);
    }, 500),
    [],
  );

  return (
    <div className="min-h-screen pt-3 pb-[100px]">
      <button
        className="flex items-center gap-2 py-[10px] px-2 hover:cursor-pointer hover:underline"
        onClick={() => {
          navigate('/magazine-recipient');
        }}
      >
        <ArrowBack />
        <span className="text-sm text-neutral-600">Magazine recipients</span>
      </button>

      {!isLoadingCustomerDetail && (
        <div className="flex justify-between mt-6">
          <div>
            <div className="flex gap-2 items-center">
              <span className="font-semibold text-[18px]">{`${customer.firstName} ${customer.lastName}`}</span>
              <span className="text-xs font-600 text-secondary-orange-600 bg-secondary-orange-50 py-1 px-2 rounded-md h-fit">
                {totalLeads} leads
              </span>
            </div>
            <span className="text-sm text-neutral-500">
              {customer.email + ' | ' + customer.phone}
            </span>
          </div>
          <Button
            variant="LIGHT"
            className="border border-neutral-200 whitespace-nowrap gap-2 font-bold"
            onClick={() => handleExportOne(userId)}
          >
            <SvgDownload className="w-5 h-5 mr-2" />
            Export
          </Button>
        </div>
      )}

      <div className="shadow-sm bg-white rounded-lg mt-6">
        <div className="flex justify-between border-b border-neutral-200 py-[22px] px-6 gap-6">
          <TextInput
            placeholder="Search for name or email"
            className="w-[494px]"
            onChange={handleTextInput}
          />
        </div>
        {!isLoading ? (
          <>
            <div className="p-6 flex flex-col gap-[14px]">
              {leads.map((lead) => {
                const {
                  firstName,
                  lastName,
                  email,
                  phone,
                  createdAt,
                  address = { address1: '', address2: '', city: '', zip: '' },
                } = lead;
                const { address1, address2, city, zip } = address;
                const createdDate = new Date(createdAt).toLocaleDateString(
                  'en-US',
                  {
                    month: 'short',
                    year: 'numeric',
                  },
                );

                return (
                  <div className="border border-neutral-200 rounded-md py-[14px] px-6 flex flex-col">
                    <span className="text-sm font-bold">{`${firstName} ${lastName}`}</span>
                    <span className="text-sm text-neutral-500">{email}</span>
                    <span className="text-sm text-neutral-500">{phone}</span>
                    <span className="text-sm text-neutral-500">
                      {[address1, address2, city, zip]
                        .filter((x) => x)
                        .join(', ')}
                    </span>
                    <div className="border border-neutral-200 w-fit px-2 py-1 rounded-md flex gap-2 mt-2">
                      <DownloadIconGreen />
                      <span className="text-xs font-semibold">
                        Added since {createdDate}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="border-t border-neutral-200 px-6 py-[22px]">
              <PaginationFlowBite
                tablePagination={tablePagination}
                handlePerPage={handlePerPage}
              />
            </div>
          </>
        ) : (
          <SkeletonLoader />
        )}
      </div>
    </div>
  );
};

export default UserLeads;
