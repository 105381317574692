import { getInstance } from './instanceBBA';
import { getPCInstance } from './instance';
import { OfferStatus } from '../../utils/enums';
import { AxiosResponse } from 'axios';

const pcInstance = getPCInstance();

const instance = getInstance();

type QueryKeyType = [unknown, string[]];

export const getBookPackages = ({ queryKey }: { queryKey: QueryKeyType }) => {
  const [, bookIds] = queryKey;
  let params = `?key=${import.meta.env.VITE_BBA_APIKEY}`;
  if (bookIds.length) {
    params = params + `&bookIds=${bookIds}`;
  }
  return instance
    .get(`abo/book/packages${params}`)
    .then((response) => response.data.data)
    .catch((error) => Promise.reject(error));
};

export const previewTemplate = async (
  templateId: string,
  inputs: Record<string, string>,
): Promise<AxiosResponse<any>> => {
  try {
    const formattedInputs = { ...inputs };

    if (inputs.benefits) {
      try {
        formattedInputs.benefits = JSON.stringify(JSON.parse(inputs.benefits));
      } catch (jsonError) {
        console.error('Error parsing or stringifying benefits:', jsonError);
        throw new Error('Invalid benefits format');
      }
    } else {
      formattedInputs.benefits = JSON.stringify([]);
    }

    const response = await pcInstance.post('/templates/render', {
      id: templateId,
      inputs: formattedInputs,
    });
    return response;
  } catch (error) {
    console.error('Error in previewTemplate:', error);
    throw error;
  }
};

export const createOffer = async (offerData: {
  name: string;
  template: string;
  valueInCents: number;
  templateVariables: { handle: string; value: string; isObject?: boolean }[];
  offerCode: string;
  product: string;
  trialParameters?: {
    priceInCents: number;
    credits: number;
    interval: number;
    intervalUnit: 'month' | 'day';
  };
  emphasis: string[];
  packages?: string[];
  country: string[];
}) => {
  try {
    const response = await pcInstance.post('/offers', offerData);
    return response;
  } catch (error) {
    console.error('Error in createOffer:', error);
    throw error;
  }
};

export const getProductCatalogOffersList = (perPage, page) => {
  const params = { page, perPage };
  return pcInstance
    .get('/offers/list', { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getPreSignedUrl = async (
  fileName: string,
  fileExtension: string,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await pcInstance.post('offers/upload/pre-signed-url', {
      fileName,
      fileExtension,
    });
    return response;
  } catch (error) {
    console.error('Error in getPreSignedUrl:', error);
    throw error;
  }
};
