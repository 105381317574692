import Input from "./Input";
import Label from "./Label";
import Button from "./Button";
import React from 'react';

interface SearchProps {
  input: string;
  setInput: (input: string) => void;
  onClickHandler: () => void;
  isLoading: boolean;
}

export default function Search({
  input,
  setInput,
  onClickHandler,
  isLoading,
}: SearchProps) {
  return (
    <div className="p-8 bg-white rounded-lg shadow-md">
      <Label>Search member to off board</Label>
      <div className="flex gap-4 mt-4 align-middle items-center">
        <form onSubmit={onClickHandler} className="flex gap-4 flex-wrap">
          <Input
            placeholder={"Search for name, email or subscription ID"}
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <Button
            onClick={onClickHandler}
            isProcessing={isLoading}
            disabled={isLoading}
          >
            Search
          </Button>
        </form>
      </div>
    </div>
  );
}
