import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

export class DatadogManager {
  logConfiguration: any;
  rumConfiguration: any;

  constructor() {
    this.logConfiguration = {
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      forwardErrorsToLogs: true,
      forwardConsoleLogs: "all",
      sampleRate: 100,
    };

    this.rumConfiguration = {
      applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "afy-backoffice-ui",
      version: import.meta.env.VITE_APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: "allow",
      enableExperimentalFeatures: ["clickmap", "feature_flags"],
      allowedTracingUrls: [
        (url) => url.startsWith("https://scheduler.authorify.com"),
        (url) => url.startsWith("https://bba.authorify.com"),
        (url) => url.startsWith("https://ds.authorify.com"),
        (url) => url.startsWith("https://offboarding.authorify.com"),
      ],
      // beforeSend: this.beforeSendRoutine.bind(this),
    };
  }

  init() {
    datadogLogs.init(this.logConfiguration);
    datadogRum.init({
      ...this.rumConfiguration,
      env: import.meta.env.VITE_DATADOG_ENV,
    });

    // this.initializeUser();
    datadogRum.startSessionReplayRecording();
  }

  // beforeSendRoutine(event, context) {
  //   try {
  //     if (event.type === "view" && event?.view?.action?.count > 0) {
  //       const { url } = event.view;
  //       const urlParts = url
  //         .replace("http://", "")
  //         .replace("https://", "")
  //         .split("/");
  //     }
  //   } catch (err) {
  //     console.error("Error in beforeSendRoutine");
  //   }
  //   return true;
  // }

  // initializeUser() {
  //   try {
  //     const user = getUser();
  //     if (user?.name && user?.email && user?.email !== "anon@authorify.com") {
  //       datadogRum.setUser({ name: user.name, email: user.email });
  //     }
  //   } catch (err) {
  //     console.error("Error in initializeUser");
  //   }
  // }
}

const datadogManager = new DatadogManager();
datadogManager.init();
