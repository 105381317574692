import React, { ChangeEvent, ReactNode, useState } from 'react';
import { Modal, Textarea } from 'flowbite-react';
import Button from '../Button';
import TextInput from '../TextInput';
import { SvgGreenCircle } from '../../../components/icons';
import { defaultTextAreaTheme } from '../Theme/TextArea';
import IconGalleryModal from './IconGalleryModal';

interface BaseBenefitModalProps {
  show: boolean;
  onClose: () => void;
  title: string;
  children?: ReactNode;
  onSave: () => void;
  isEdit?: boolean;
  description?: string;
  setDescription?: (value: string) => void;
  hasIcon?: boolean;
  icon?: string;
  setIcon?: (value: string) => void;
  benefitTitle?: string;
  setBenefitTitle?: (value: string) => void;
}

const BaseBenefitModal: React.FC<BaseBenefitModalProps> = ({
  show,
  onClose,
  title,
  children,
  onSave,
  isEdit = false,
  description = '',
  setDescription,
  hasIcon = false,
  icon = '',
  setIcon,
  benefitTitle = '',
  setBenefitTitle,
}) => {
  const [descriptionLength, setDescriptionLength] = useState<number>(
    description.length,
  );
  const [showIconGallery, setShowIconGallery] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (setDescription) {
      setDescription(value);
      setDescriptionLength(value.length);

      if (value.length <= 300) {
        setError('');
      }
    }
  };

  const handleIconChange = (url: string) => {
    if (setIcon) {
      setIcon(url);
      setShowIconGallery(false);
    }
  };

  const handleUploadIcon = (url: string) => {
    if (setIcon) {
      setIcon(url);
    }
  };

  const handleSave = () => {
    if (descriptionLength > 300) {
      setError('Description cannot exceed 300 characters.');
      return;
    }
    onSave();
  };

  return (
    <>
      <Modal show={show} onClose={onClose} size="lg">
        <Modal.Header>
          <div className="flex justify-between items-center w-full">
            <h2 className="text-neutral-800 text-base font-bold leading-tight">
              {title}
            </h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="text-neutral-800 text-sm font-medium leading-tight mb-4">
            This will appear below the “What you’ll get” section of the template
          </div>
          {hasIcon && (
            <div className="flex flex-col gap-3.5">
              <div className="flex flex-col gap-1">
                <div className="text-neutral-800 text-sm font-semibold leading-tight">
                  Icon
                </div>
                {icon && (
                  <img src={icon} alt="Selected icon" className="w-12 h-12" />
                )}
                <div className="flex items-center gap-1">
                  <div className="text-neutral-500 text-sm font-medium leading-tight flex items-center">
                    Recommended size: 280 x 280. By default this icon will be{' '}
                    <SvgGreenCircle className="ml-1 inline-block align-middle" />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <Button
                  className="w-full"
                  variant="OUTLINED"
                  onClick={() => setShowIconGallery(true)}
                >
                  Change icon
                </Button>
              </div>
            </div>
          )}
          <div className="flex flex-col gap-1 mt-4">
            <div className="flex items-center gap-1">
              <div className="text-neutral-800 text-sm font-semibold leading-tight">
                Title
              </div>
              <div className="text-red-500 text-sm font-semibold leading-tight">
                *
              </div>
            </div>
            <TextInput
              value={benefitTitle}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setBenefitTitle && setBenefitTitle(e.target.value)
              }
              className="w-full"
            />
          </div>
          <div className="flex flex-col gap-1 mt-4">
            <div className="text-neutral-800 text-sm font-semibold leading-tight">
              Description
            </div>
            <Textarea
              value={description}
              onChange={handleDescriptionChange}
              className={defaultTextAreaTheme.base}
            />
            <div className="text-zinc-400 text-sm font-normal leading-tight self-end">
              {descriptionLength}/300
            </div>
            {error && (
              <div className="text-red-500 text-sm font-medium leading-tight">
                {error}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end gap-2 w-full">
            <Button variant="OUTLINED" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="DARK" onClick={handleSave}>
              {isEdit ? 'Save changes' : 'Add benefit'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <IconGalleryModal
        show={showIconGallery}
        onClose={() => setShowIconGallery(false)}
        onSelectIcon={handleIconChange}
        onUploadIcon={handleUploadIcon}
      />
    </>
  );
};

export default BaseBenefitModal;
