/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { TextInput, Label } from 'flowbite-react';
import { isEmpty, get } from 'lodash-es';

import ImageUploaderModal from '../ImageUploaderModal';
import Button from '../../../../../../components/Button';
import SwitchButton from '../../../../../../components/SwitchButton';
import { defaultTextInputTheme } from '../../../../../../components/Theme/TextInput';
import {
  MagazineFromDBContext,
  MagazineFromDBContextType,
} from '../../../../../../../context/MagazineFromDBContext';
import { MagazineImageContextType } from '../../../../../../../context/MagazineImageContext';
import { useMagazineImageContext } from '../../../../../../../context/MagazineImageHook';

type MagazineDBType = { [key: string]: object };

const Magazine = ({ step = 'magazine', updateMagazineData }) => {
  const { magazineDataFormik } = updateMagazineData;
  const [brokerLogoModal, setBrokerLogoModal] = useState<string | null>(null);
  const [isEditImage, setIsEditImage] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [toggleBrokerInfo, setToggleBrokerInfo] = useState(false);

  const { values = {}, setFieldValue, errors } = magazineDataFormik || {};
  const targetLocation = step.concat('.', '_formFields');

  const { ctxMagazineFromDB } = React.useContext(
    MagazineFromDBContext,
  ) as MagazineFromDBContextType<object>;
  const { setShowModal, setImageType, magazineType, uploadedImage } =
    useMagazineImageContext() as MagazineImageContextType;

  const allowedResolutionsTextProfile = { width: 520, height: 520 };
  const allowedResolutionsTextLogo = { width: 540, height: 192 };

  const profileImage = `${targetLocation}.profileImage`;
  const brokerageLogo = `${targetLocation}.brokerageLogo`;

  useEffect(() => {
    const {
      _formFields: { brokerName, address, brokerageLogo },
    } = values.magazine;

    if (brokerName || address || brokerageLogo) {
      setToggleBrokerInfo(true);
    }
  }, [values]);

  /**
   * Set Intiaial values to customer information form fields
   * @dependency ctxMagazineFromDB -
   */
  useEffect(() => {
    if (!isEmpty(ctxMagazineFromDB)) {
      const magazineDB: MagazineDBType =
        ctxMagazineFromDB[0] as MagazineFromDBContextType<object>;
      const baseReplacers = magazineDB.baseReplacers[0];
      setFieldValue(targetLocation, baseReplacers);
    }
  }, [ctxMagazineFromDB]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name = '', value = '', type = '' },
    } = e || {};

    const locationToSave = step.concat('.', '_formFields', '.', name);
    let newValue = value;

    if (type === 'tel') {
      const strippedInput = value.replace(/[^\d]/g, '');
      let maskedPhoneNumber = '';
      for (let i = 0; i < strippedInput.length && i < 10; i++) {
        if (i === 3 || i === 6) {
          maskedPhoneNumber += '-';
        }
        maskedPhoneNumber += strippedInput[i];
      }
      newValue = maskedPhoneNumber;
    }

    setFieldValue(locationToSave, newValue);
  };

  const handleKeyup = async () => {
    const { validateForm } = magazineDataFormik || {};
    await validateForm().then((err: object) => {
      if (Object.prototype.hasOwnProperty.call(err, step)) {
        return true;
      }
      return false;
    });
  };

  const toggleBrokerLogoModal = () => {
    const brokerLogo = brokerLogoModal === 'photo' ? 'logo' : 'photo';
    setShowModal(Boolean(brokerLogo));
  };

  const toggleCancelEdit = () => {
    setIsEditImage(!isEditImage);
  };

  // Error messages.
  const errorMsg = {
    agentName: get(errors, `${targetLocation}.agentName`),
    brokerName: get(errors, `${targetLocation}.brokerName`),
    website: get(errors, `${targetLocation}.website`),
    email: get(errors, `${targetLocation}.email`),
    phoneNumber: get(errors, `${targetLocation}.phoneNumber`),
    address: get(errors, `${targetLocation}.address`),
  };

  /**
   * Handle image upload, set value to formikData, toggle modal
   *
   * @param value
   * @param keyName
   */
  const handleImageUpload = async (value: string, keyName: string) => {
    if (keyName === magazineType) {
      const locationToSave =
        keyName === 'rm_profile'
          ? profileImage
          : `${targetLocation}.${keyName}`;
      await setFieldValue(locationToSave, value);
    }
  };

  /**
   * Remove image, set value to formik, reset image url
   *
   * @param path
   */
  const onRemoveImage = (path: string) => {
    setFieldValue(path, '');
    setImageUrl('');
    setIsEditImage(false);
  };

  /**
   * On edit image, set image url, toggle modal.
   *
   * @param type
   */
  const onEditImage = (type: string) => {
    setIsEditImage(true);
    if (type === 'rm_profile') {
      setImageUrl(get(values, profileImage));
    } else if (type === 'brokerageLogo') {
      setImageUrl(get(values, brokerageLogo));
    }
  };

  /**
   * Reset fields to base replacers if previous data is available
   * else reset to empty string
   *
   * @returns void
   */
  const onHandleResetFormFields = () => {
    // eslint-disable-next-line no-underscore-dangle
    const formFields = values.magazine._formFields;
    const magazineDB: MagazineDBType =
      ctxMagazineFromDB[0] as MagazineFromDBContextType<object>;
    const baseReplacers = magazineDB.baseReplacers[0];

    if (isEmpty(formFields)) return;

    Object.keys(formFields).forEach((field) => {
      const locationToSave = `${targetLocation}.${field}`;
      if (isEmpty(ctxMagazineFromDB)) {
        setFieldValue(locationToSave, '');
      } else {
        setFieldValue(locationToSave, baseReplacers[field]);
      }
    });
    setFieldValue('currentStep', -1);
  };

  return (
    <div className="transition-all duration-500">
      <div className="left flex flex-col justify-center items-start self-stretch mt-[37px]">
        <div className="flex flex-col items-start">
          <div className="text-neutral-800 text-sm font-semibold">
            Your photo
          </div>
          <div className="text-neutral-500 text-sm font-medium">{`Recommended size: ${allowedResolutionsTextProfile.width} x ${allowedResolutionsTextProfile.height}`}</div>
        </div>
        <div className="flex items-center gap-6 self-stretch mt-3.5">
          <div className="w-full">
            <ImageUploaderModal
              type="rm_profile"
              modalTitle="Profile Image"
              imageUrl={get(values, profileImage)}
              onSuccess={(value) => handleImageUpload(value, 'rm_profile')}
              onCancel={toggleBrokerLogoModal}
              handleOpenModal={() => {
                setShowModal(true);
              }}
              onRemoveImage={(e: { stopPropagation: () => void }) => {
                onRemoveImage(profileImage);
                e.stopPropagation();
              }}
              onEditImage={(e: { stopPropagation: () => void }) => {
                onEditImage('rm_profile');
                setBrokerLogoModal('photo');
                e.stopPropagation();
              }}
              imageUrlForEdit={imageUrl}
              isEditImage={isEditImage}
              toggleCancelEdit={toggleCancelEdit}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="w-full flex flex-col gap-4">
          <div className="w-full flex-col gap-y-4 lg:flex lg:flex-row gap-4">
            <div className="w-full flex flex-col gap-0">
              <div className="w-full flex flex-col gap-2">
                Agent name
                <TextInput
                  theme={defaultTextInputTheme}
                  id="agentName"
                  name="agentName"
                  type="text"
                  value={get(values, `${targetLocation}.agentName`) || ''}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
              <div className="text-red-500 py-2 text-sm">
                {errorMsg.agentName}
              </div>
            </div>
            <div className="w-full flex flex-col gap-0">
              <div className="w-full flex flex-col gap-2">
                Phone number
                <TextInput
                  theme={defaultTextInputTheme}
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  inputMode="tel"
                  value={get(values, `${targetLocation}.phoneNumber`) || ''}
                  onChange={handleChange}
                  onKeyUp={handleKeyup}
                  placeholder="___-___-____"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  maxLength={12}
                />
              </div>
              <div className="text-red-500 py-2 text-sm">
                {errorMsg.phoneNumber}
              </div>
            </div>
          </div>
          <div className="w-full flex-col gap-y-4 lg:flex lg:flex-row gap-4">
            <div className="w-full flex flex-col gap-0">
              <div className="w-full flex flex-col gap-2">
                Email address
                <TextInput
                  theme={defaultTextInputTheme}
                  id="email"
                  name="email"
                  type="email"
                  inputMode="email"
                  placeholder=""
                  value={get(values, `${targetLocation}.email`) || ''}
                  onChange={handleChange}
                  onKeyUp={handleKeyup}
                />
              </div>
              <div className="text-red-500 py-2 text-sm">{errorMsg.email}</div>
            </div>
            <div className="w-full flex flex-col gap-0">
              <div className="w-full flex flex-col gap-2">
                Website
                <TextInput
                  theme={defaultTextInputTheme}
                  id="website"
                  name="website"
                  type="url"
                  value={get(values, `${targetLocation}.website`) || ''}
                  onChange={handleChange}
                  onKeyUp={handleKeyup}
                  placeholder="Optional"
                />
              </div>
              <div className="text-red-500 py-2 text-sm">
                {errorMsg.website}
              </div>
            </div>
          </div>
          <div className="w-full my-[34px] h-[1px] border-t" />
          <div className="w-full flex gap-4">
            <div className="inline-flex items-center cursor-pointer">
              <SwitchButton
                id="toggle-broker"
                checked={toggleBrokerInfo}
                setChecked={setToggleBrokerInfo}
              />
              <Label htmlFor="toggle-broker">
                <span className="ms-3 text-sm font-normal text-neutral-900">
                  I want to add my brokerage information
                </span>
              </Label>
            </div>
          </div>
          <div
            className={`${
              toggleBrokerInfo ? 'block' : 'hidden'
            } mt-6 flex flex-col gap-[8px]`}
          >
            <div className="flex flex-col items-start">
              <div className="text-neutral-800 text-sm font-semibold">
                Broker company logo
              </div>
              <div className="text-neutral-500 text-sm font-medium">{`Recommended size: ${allowedResolutionsTextLogo.width}x${allowedResolutionsTextLogo.height}`}</div>
            </div>
            <div>
              <ImageUploaderModal
                type="brokerageLogo"
                imageUrl={get(values, brokerageLogo)}
                onSuccess={(value) => handleImageUpload(value, 'brokerageLogo')}
                onCancel={toggleBrokerLogoModal}
                handleOpenModal={(type) => {
                  setImageType(type);
                  setShowModal(true);
                }}
                onRemoveImage={(e: { stopPropagation: () => void }) => {
                  onRemoveImage(brokerageLogo);
                  e.stopPropagation();
                }}
                onEditImage={(e: { stopPropagation: () => void }) => {
                  onEditImage('brokerageLogo');
                  setBrokerLogoModal('logo');
                  e.stopPropagation();
                }}
                imageUrlForEdit={imageUrl}
                isEditImage={isEditImage}
                toggleCancelEdit={toggleCancelEdit}
              />
            </div>
            <div className="w-full flex-col gap-y-4 lg:flex lg:flex-row  gap-4">
              <div className="w-full flex flex-col gap-0">
                <div className="w-full flex flex-col gap-2">
                  Broker name
                  <TextInput
                    theme={defaultTextInputTheme}
                    style={errorMsg.brokerName}
                    id="brokerName"
                    name="brokerName"
                    type="text"
                    value={get(values, `${targetLocation}.brokerName`) || ''}
                    onChange={handleChange}
                    placeholder="Optional"
                  />
                </div>
                <div className="text-red-500 py-2 text-sm">
                  {errorMsg.brokerName}
                </div>
              </div>
              <div className="w-full flex flex-col gap-0">
                <div className="w-full flex flex-col gap-2">
                  Brokerage address
                  <TextInput
                    theme={defaultTextInputTheme}
                    style={errorMsg.address}
                    id="address"
                    name="address"
                    type="text"
                    value={get(values, `${targetLocation}.address`) || ''}
                    onChange={handleChange}
                    placeholder="Optional"
                  />
                </div>
                <div className="text-red-500 py-2 text-sm">
                  {errorMsg.address}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2 justify-start mt-6">
        <Button type="button" variant="LIGHT" onClick={onHandleResetFormFields}>
          Cancel
        </Button>
        <Button
          variant="DARK"
          type="submit"
          onClick={magazineDataFormik.handleSubmit}
        >
          Save changes
        </Button>
      </div>
    </div>
  );
};

export default Magazine;
