import React, { useContext } from 'react';
import { OfferContext } from '../utils/OfferProvider';
import OfferDetails from './OfferDetails';
import SelectTemplate from './SelectTemplate';
import OfferPreview from './Preview';

const CreateOfferSteps = () => {
  const { step } = useContext(OfferContext);

  return (
    <>
      {step === 1 && <OfferDetails />}
      {step === 2 && <SelectTemplate />}
      {step === 3 && <OfferPreview />}
    </>
  );
};

export default CreateOfferSteps;
