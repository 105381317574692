import { getInstance } from "./instance";

const instance = getInstance();

type QueryKeyType = [unknown, string, string];

interface PayloadReq {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

interface UpdateReferralMagazineType {
  month: string;
  year: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

interface ParamsTypes {
  month: string;
  year: string;
}

interface QueryKeysType {
  params: ParamsTypes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

interface UpdateGeneratedMagazineByIdType {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

export const getMagazineStepsInfo = ({
  queryKey,
}: {
  queryKey: QueryKeyType;
}) => {
  const [, month, year] = queryKey;
  return instance
    .get(`referral-marketing/magazines/pages?month=${month}&year=${year}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const createReferralMagazine = (req: PayloadReq) => {
  return instance
    .post("/referral-marketing/magazines", req.payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const updateReferralMagazine = (req: UpdateReferralMagazineType) => {
  const { month, year, ...rest } = req;

  return instance
    .patch(`/referral-marketing/magazines/${year}/${month}`, rest?.payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getGeneratedMagazine = (year: string, month: string) => {
  return instance
    .get(`/referral-marketing/generated-magazines/${year}/${month}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getGeneratedMagazineMonth = ({
  queryKey,
}: {
  queryKey: QueryKeyType;
}) => {
  const [, year, month] = queryKey;
  let params = "";
  if (month && year) {
    params = `/${year}/${month}`;
  }
  return instance
    .get(`/referral-marketing/generated-magazines${params}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getGeneratedMagazineYear = (year: string, status: string) => {
  return instance
    .get(`/referral-marketing/magazines?year=${year}&status=${status}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getMagazine = (year: string, month: string) => {
  return instance
    .get(`/referral-marketing/magazines/${year}/${month}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const generateReferralMagazine = ({
  isPreview = true,
  payload = {},
}) => {
  return instance
    .post(
      `/referral-marketing/generated-magazines?isPreview=${isPreview}`,
      payload
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const updateGeneratedReferralMagazine = ({
  params,
  payload,
}: QueryKeysType) => {
  const { month, year } = params;
  return instance
    .patch(`/referral-marketing/generated-magazines/${year}/${month}`, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const updateGeneratedMagazineById = ({
  id,
  payload,
}: UpdateGeneratedMagazineByIdType) => {
  return instance
    .patch(`/referral-marketing/generated-magazines/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const sendToPrint = ({ id }: { id: string }) => {
  return instance
    .post(`/referral-marketing/generated-magazines/${id}/print`, {})
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const sendBackToEdit = ({
  id,
  status,
}: {
  id: string;
  status: string;
}) => {
  const url = `/referral-marketing/magazines/update-status/${id}`;
  const body = { status };
  return instance
    .patch(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getGeneratedMagazineById = (id: string) => {
  return instance
    .get(
      `/referral-marketing/generated-magazines/generatedMagazine/status/${id}`
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getMagazinePreview = (id, email, year, month) => {
  const url = '/referral-marketing/generated-magazines/magazine-preview';
  const params = { id, email, year, month };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};
