import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import React from "react";

interface ConfirmationModalProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  submitHandler: () => void;
  isLoading: boolean;
}

export default function ConfirmationModal({
  openModal,
  setOpenModal,
  submitHandler,
  isLoading,
}: ConfirmationModalProps) {
  const handleSubmit = () => {
    submitHandler();
    setOpenModal(false);
  };
  return (
    <>
      <Button
        onClick={() => setOpenModal(true)}
        isProcessing={isLoading}
        disabled={isLoading}
        color={"failure"}
      >
        Offboard
      </Button>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Offboarding this member will lose their access.
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="gray" onClick={() => setOpenModal(false)}>
                Cancel
              </Button>
              <Button color="failure" onClick={handleSubmit}>
                {"Yes, Offboard"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
