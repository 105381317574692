import React, { ChangeEventHandler, FocusEventHandler, useState } from 'react';
import classnames from 'classnames';

interface InputWithInitialValuesProps {
  mask?: string;
  prefix?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  value?: string;
  disabled?: boolean;
  isValid?: boolean;
  id?: string;
  name?: string;
  placeholder?: string;
  error?: boolean;
  fixedValue?: string;
}

const InputWithInitialValues = ({
  prefix,
  value = '',
  id,
  name,
  placeholder,
  error,
  fixedValue,
  onChange,
  onBlur,
  onFocus,
  disabled = false,
}: InputWithInitialValuesProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    setIsFocused(true);
    if (onFocus) onFocus(e);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setIsFocused(false);
    if (onBlur) onBlur(e);
  };

  return (
    <div
      className={classnames(
        'flex rounded-md border w-full items-center',
        isFocused ? 'border-2 border-primary-500' : error ? 'border-2 border-red-500' : 'border-neutral-200',        
      )}
    >
      {prefix && (
        <div className="w-fit min-w-[238px] h-10 max-sm:min-w-[180px] inline-flex items-center rounded-l-md border-r border-neutral-200 bg-neutral-50 px-4 text-sm font-medium">
          {prefix}
        </div>
      )}
      {fixedValue && <span className="pl-3.5">{fixedValue}</span>}
      <input
        className={classnames("h-10 pl-3.5 pr-4 rounded-md border-none text-sm font-medium placeholder:text-neutral-300 focus:border-none focus:outline-none focus:ring-0 focus:ring-transparent w-full",
          disabled ? 'cursor-not-allowed' : 'cursor-text'
        )}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
      />
    </div>
  );
};

export default InputWithInitialValues;
