import { OfferContextData } from './OfferProvider';

interface TemplateConfig {
  [key: string]: {
    variables: {
      handle: string;
      contextKey: keyof OfferContextData;
      isObject?: boolean;
    }[];
  };
}

export const templateConfig: TemplateConfig = {
  '668b9ee38cd3037dded6d8fd': {
    variables: [
      { handle: 'offerTitle', contextKey: 'productTitle' },
      { handle: 'offerDescription', contextKey: 'productDescription' },
      { handle: 'trialOrOfferPrice', contextKey: 'trialOrOfferPrice' },
      { handle: 'offerPrice', contextKey: 'productValueInCents' },
      { handle: 'offerTrialFreeBooks', contextKey: 'offerTrialCredits' },
      { handle: 'benefits', contextKey: 'benefits', isObject: true },
      { handle: 'productHeroImage', contextKey: 'productHeroImage' },
    ],
  },
  '668bacbd64df85d2005fc180': {
    variables: [
      { handle: 'heroTitle', contextKey: 'productTitle' },
      { handle: 'benefits', contextKey: 'benefits', isObject: true },
      {
        handle: 'productBackgroundImage',
        contextKey: 'productBackgroundImage',
      },
    ],
  },
};
