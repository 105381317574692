import * as React from "react";
const SvgCopyLinkIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g stroke="#252525" strokeLinecap="round" strokeWidth={1.5}>
      <path d="M6 8h4M6 12h-.667a4 4 0 0 1 0-8H6M10 4h.667a4 4 0 0 1 0 8H10" />
    </g>
  </svg>
);
export default SvgCopyLinkIcon;
