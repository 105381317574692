import OffboardingResults from "./OffboardingResults";
import { PublicationsDto } from "../../api/instances/dto/getCustomers.dto";
import { Carousel } from "flowbite-react";
import React, { useState } from "react";
import ConfirmationModal from "./ConfirmationModal";
import { useMutation, useQueryClient } from "react-query";
import { executeOffboardingPlan } from "../../api/offboarding";
import { useNavigate, useParams } from "react-router-dom";

interface OffboardingCardDetailsProps {
  title: string;
  hubspotProperties?: object;
  data: {
    publications?: PublicationsDto[];
    deals: {
      id: string;
      properties: {
        chargify_subscription_id;
        createdate: string;
        dealname: string;
        hubspot_owner_id: string;
      };
      dealOwnerDetails: {
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        userId: number;
        createdAt: string;
      };
    }[];
    subscriptions: {
      subscriptionId: string;
      name: string;
      handle: string;
      state: string;
    }[];
  };
}

const theme = {
  root: {
    base: "relative h-full w-full",
    leftControl:
      "absolute left-[-80px] top-0 flex h-full items-center justify-center focus:outline-none max-[500px]:left-[-40px]",
    rightControl:
      "absolute right-[-80px] top-0 flex h-full items-center justify-center focus:outline-none max-[500px]:right-[-40px]",
  },
  indicators: {
    active: {
      off: "bg-white/50 hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800",
      on: "bg-white dark:bg-gray-800",
    },
    base: "h-3 w-3 rounded-full",
    wrapper: "visibility: hidden",
  },
  item: {
    base: "absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
    wrapper: {
      off: "w-full flex-shrink-0 transform cursor-default snap-center",
      on: "w-full flex-shrink-0 transform cursor-grab snap-center",
    },
  },
  control: {
    base: "inline-flex h-8 w-8 items-center justify-center rounded-full group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-focus:ring-gray-800/70 sm:h-10 sm:w-10",
    icon: "h-5 w-5 text-neutral-600 dark:text-gray-800 sm:h-6 sm:w-6",
  },
  scrollContainer: {
    base: "flex h-full snap-mandatory overflow-hidden scroll-smooth rounded-lg",
    snap: "snap-x",
  },
};

export default function OffboardingCardDetails({
  title,
  data,
}: OffboardingCardDetailsProps) {
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation({
    mutationKey: "executeOffboardingPlan",
    mutationFn: () => {
      return executeOffboardingPlan(
        id,
        data.publications.map((p) => p.publicationId)
      ).then(() => navigate("/offboarding"));
    },
  });

  const renderCarousel = () => {
    return data.publications?.reduce((acc, p) => {
      if (p?.details) {
        acc.push(
          <a href={p.details.link} target={"_blank"}>
            <img src={p.details.thumbnail} alt={"flippingbook"} />
          </a>
        );
      }
      return acc;
    }, []);
  };

  const render = () => {
    const activeSubscription = data.subscriptions.find(
      (sub) => sub.state === "active" || sub.state === "trialing"
    );
    const flippingBooks = renderCarousel()?.length.toString() ?? "";
    let statusDescription = null;

    if (activeSubscription?.state === "trialing") {
      statusDescription = "Has active subscription (trialing)";
    } else if (activeSubscription?.state === "active") {
      statusDescription = "Has active subscription";
    } else {
      statusDescription = "No active subscription";
    }

    const dealDetails = data.deals.find(
      (deal) =>
        deal.properties.chargify_subscription_id ==
        activeSubscription?.subscriptionId
    );

    const shouldHide = data.publications.length < 1 && !activeSubscription;
    if (shouldHide) {
      return null;
    }
    return (
      <div className={"border w-1/2 rounded-lg p-4 max-[1220px]:w-full"}>
        <div className={"flex justify-between pb-4 items-center"}>
          <p className={"text-sm font-semibold"}>{title}</p>
          <ConfirmationModal
            setOpenModal={setOpenModal}
            openModal={openModal}
            submitHandler={mutate}
            isLoading={isLoading}
          />
        </div>
        <div className={"pt-4 border-t"}>
          <OffboardingResults title={"Status"} values={statusDescription} />
          {dealDetails && (
            <OffboardingResults
              title={"Deal name"}
              values={`${dealDetails.properties.dealname}`}
            />
          )}
          {dealDetails && (
            <OffboardingResults
              title={"Deal owner"}
              values={`${dealDetails.dealOwnerDetails.firstName} ${dealDetails.dealOwnerDetails.lastName} (${dealDetails.dealOwnerDetails.email})`}
            />
          )}
          <OffboardingResults title={"Flipping books"} values={flippingBooks} />
          <div className={"mt-4"}>
            <div
              className={
                "flex justify-center w-full p-4 bg-primary-50 rounded-lg"
              }
            >
              <Carousel
                className={"h-[400px] w-1/2 min-w-[256px]"}
                slide={false}
                theme={theme}
              >
                {renderCarousel()}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <>{render()}</>;
}
