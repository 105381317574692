import * as React from "react";
import type { SVGProps } from "react";

const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} className={`w-3 h-3 ${props.className}`}>
    <path d="M5 8.88235C5 7.05219 5 6.13711 5.64437 5.56856C6.28873 5 7.32582 5 9.4 5H11.6C13.6742 5 14.7113 5 15.3556 5.56856C16 6.13711 16 7.05219 16 8.88235V12.1176C16 13.9478 16 14.8629 15.3556 15.4314C14.7113 16 13.6742 16 11.6 16H9.4C7.32582 16 6.28873 16 5.64437 15.4314C5 14.8629 5 13.9478 5 12.1176V8.88235Z" stroke="#252525" strokeWidth="1.5" />
    <path d="M3.2 12C1.98497 12 1 11.1309 1 10.0588V6.17647C1 3.73626 1 2.51615 1.85915 1.75808C2.71831 1 4.10109 1 6.86667 1H9.8C11.015 1 12 1.86909 12 2.94118" stroke="#252525" strokeWidth="1.5" />
  </svg>
);

export default SvgCopy;