import * as React from 'react';
const SvgGreenCircle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#069668"
      fillRule="evenodd"
      d="M11.273 21.6c4.98 0 9.018-4.298 9.018-9.6s-4.038-9.6-9.018-9.6S2.254 6.698 2.254 12s4.038 9.6 9.019 9.6m4.179-11.151c.44-.469.44-1.229 0-1.697a1.08 1.08 0 0 0-1.595 0l-3.712 3.951-1.457-1.551a1.08 1.08 0 0 0-1.594 0 1.255 1.255 0 0 0 0 1.697l2.254 2.4a1.08 1.08 0 0 0 1.594 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGreenCircle;
