import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { Modal, Label } from "flowbite-react";

import { getUploadedImages, fetchPresetLogos } from "../../../../../../../api/uploads";
import { addKeyToArrayOfObject } from "../../../../utils";
import { MagazineImageContextType } from "../../../../../../../../context/MagazineImageContext";
import { useMagazineImageContext } from "../../../../../../../../context/MagazineImageHook";
import env from "../../../../../../../../environments";
import { get } from "lodash-es";

import GalleryComponent from "../Gallery";
import LogoComponent from "../Logo";
import { SvgPlusIcon } from "../../../../../../../../components/icons";

type UploadPhotoProps = {
  type: string;
  uploadText: string;
  title: string;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

type TabActiveType = "upload" | "photo-gallery" | "logo";

const UploadPhotoComponent: React.FC<UploadPhotoProps> = ({
  type = "",
  title = "Upload Photo",
  uploadText,
  handleFileChange,
}: UploadPhotoProps) => {
  const [tabActive, setTabActive] = useState<TabActiveType>("upload");
  const [photoGalleryList, setPhotoGalleryList] = useState<[]>([]);
  const [bookLogoList, setBookLogoList] = useState<[]>([]);

  const { magazineParams } = useMagazineImageContext() as MagazineImageContextType;

  const { mutate: fetchPhotoGallery } = useMutation((payload: string) => getUploadedImages(payload), {
    onSuccess: (data) => {
      if (data?.length) {
        const structuredImagesList = addKeyToArrayOfObject(data, "downloadUrl", "imageUrl") as [];
        setPhotoGalleryList(structuredImagesList);
      }
    },
    onError: () => {},
  });

  const { mutate: fetchBookLogo } = useMutation(() => fetchPresetLogos(), {
    onSuccess: (data) => {
      const files = get(data, "data.data.files");
      if (files?.length) {
        const structuredImagesList = addKeyToArrayOfObject(
          files,
          "logourl.url",
          "imageUrl",
          (newVal) => env.GRAPHQL_URL + newVal
        ) as [];
        setBookLogoList(structuredImagesList);
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    if (!magazineParams) return;

    if (tabActive === "photo-gallery") {
      const { customerId } = magazineParams;
      fetchPhotoGallery(customerId);
    }

    if (tabActive === "logo") {
      fetchBookLogo();
    }
  }, [magazineParams, tabActive]);

  let fileUploadDescriptions: string;
  switch (type) {
    case "rm_profile":
      fileUploadDescriptions =
        "Works with any .PNG, .JPG, or .JPEG file from the web </br> Recommended size: 520 x 520";
      break;
    case "brokerageLogo":
      fileUploadDescriptions =
        "Works with any .PNG, .JPG, or .JPEG file from the web </br> Recommended size: 540 x 192";
      break;
    default:
      fileUploadDescriptions = "Drag/drop or upload your image here";
  }

  return (
    <>
      <Modal.Header className="pt-8 pb-0">
        <div className="text-neutral-800 text-base font-semibold">{title}</div>
        <ul className="flex gap-3 mt-4 text-sm px-1 cursor-pointer">
          <li className={`pb-3 ${tabActive === "upload" && "border-primary-500 border-b-4"}`}>
            <button onClick={() => setTabActive("upload")}>Upload</button>
          </li>
          <li className={`pb-3 ${tabActive === "photo-gallery" && "border-primary-500 border-b-4"}`}>
            <button onClick={() => setTabActive("photo-gallery")}>Photo gallery</button>
          </li>
          {type === "brokerageLogo" && (
            <li className={`pb-3 ${tabActive === "logo" && "border-primary-500 border-b-4"}`}>
              <button onClick={() => setTabActive("logo")}>Logo</button>
            </li>
          )}
        </ul>
      </Modal.Header>
      <Modal.Body>
        <div
          className={`w-full items-center justify-center max-h-[500px] ${tabActive === "upload" ? "flex" : "hidden"}`}
        >
          <Label
            htmlFor="dropzone-file"
            className="flex h-80 w-full cursor-pointer flex-col items-center justify-center rounded-lg border border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              <SvgPlusIcon />
              <div className="action flex items-center gap-2">
                <div className="flex items-center text-primary-500 text-sm font-semibold">{uploadText}</div>
                <div className="text-neutral-600 text-sm font-semibold">or drag and drop</div>
              </div>
              <p
                className="text-neutral-400 text-center text-xs font-medium"
                dangerouslySetInnerHTML={{ __html: fileUploadDescriptions }}
              />
            </div>
            <input
              type="file"
              id="dropzone-file"
              className="hidden"
              accept="image/png, image/jpg, image/jpeg, image/webp"
              onChange={handleFileChange}
            />
          </Label>
        </div>
        <div className={`w-full items-center justify-center ${tabActive === "photo-gallery" ? "flex" : "hidden"}`}>
          <GalleryComponent type={type} photoGallery={photoGalleryList} />
        </div>
        <div className={`w-full items-center justify-center ${tabActive === "logo" ? "flex" : "hidden"}`}>
          <LogoComponent type={type} photoGallery={bookLogoList} />
        </div>
      </Modal.Body>
    </>
  );
};

export default UploadPhotoComponent;
