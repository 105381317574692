import * as React from 'react';
const SvgMobileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#FAFAFA"
      fillRule="evenodd"
      d="M10.043 2.167h-.086c-1.4 0-2.51 0-3.378.119-.893.122-1.616.38-2.186.96S3.57 4.56 3.45 5.47c-.117.883-.117 2.012-.117 3.436v3.188c0 1.425 0 2.554.117 3.437.12.908.373 1.644.943 2.224s1.293.838 2.186.96c.868.119 1.977.119 3.378.119h.086c1.4 0 2.509 0 3.377-.12.893-.121 1.616-.379 2.186-.959s.824-1.316.944-2.224c.116-.883.116-2.012.116-3.437V8.907c0-1.424 0-2.553-.116-3.436-.12-.909-.373-1.644-.944-2.225-.57-.58-1.293-.837-2.186-.96-.868-.118-1.977-.118-3.377-.118m-2.9 13.76c0-.321.255-.582.571-.582h4.571c.316 0 .572.26.572.582a.576.576 0 0 1-.572.581H7.714a.576.576 0 0 1-.571-.581"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMobileIcon;
