import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash-es";
import { useQuery, useQueryClient } from "react-query";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Title from "../../../components/Title";
import { getRmMagazineMetrics } from "../../../api/rm-magazine-report";
import edit from "../../../../assets/images/edit.svg";
import magazine from "../../../../assets/images/magazine.svg";
import print from "../../../../assets/images/print.svg";
import { formatMagazinesData, tableColumns } from "./utils";
import {
  useGetDefaultTableMetrics,
  useGetEditingTableMetrics,
  useGetGeneratedMagzineTableMetrics,
  useGetInputTableMetrics,
  useGetSentToPrintTableMetrics,
} from "./hooks";
import AfyTable from "../../../components/V2/Table";
import PaginationFlowBite from "../../../components/PaginationFlowBite";
import SkeletonLoader from "../../../components/SkeletonLoader";
import RMCardSkeletonLoader from "../../../components/RMCardSkeletonLoader";
import noItemsImage from "../../../../assets/images/no-items.png";
import TextInput from "../../../components/TextInput";
import { cn } from "../../../utils/cn";

interface RmMagazineMetrics {
  magazineGenerated: number;
  pageVisited: number;
  sentToPrint: number;
}

type GenerateReferralMagazineData = {
  payload: {
    month: string;
    year: number;
    userEmail: string;
    customerId: string;
  };
  isPreview: boolean;
};

function RmMagaineReport() {
  const queryClient = useQueryClient();
  const [salesDetailsData, setSalesDetailsData] = useState([]);
  const [salesDetailsMeta, setSalesDetailsMeta] = useState({ currentPage: 1 });
  const [selectedCard, setSelectedCard] = useState("default");
  const [inputText, setInputText] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isCardLoading, setIsCardLoading] = useState(false);

  const currentSelectedDate = new Date();
  const [selectedDate, setSelectedDate] = useState(
    currentSelectedDate.setMonth(currentSelectedDate.getMonth() + 1)
  );

  const currentDate = new Date();
  const incomingMonth = new Date(
    currentDate.setMonth(currentDate.getMonth() + 1)
  );
  const [year, setYear] = useState(incomingMonth.getFullYear());
  const defaultMonth = incomingMonth
    .toLocaleString("default", { month: "short" })
    .toUpperCase();

  const cardIncomingMonth = new Date(
    currentDate.setMonth(currentDate.getMonth() + 1)
  );
  const cardDefaultMonth = cardIncomingMonth.toLocaleString("default", {
    month: "short",
  });
  const [cardMonth, setCardMonth] = useState(cardDefaultMonth);
  const [cardYear, setCardYear] = useState(cardIncomingMonth.getFullYear());

  const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
  const [rmMagazineMetrics, setRmMagazineMetrics] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [sortByfilter, setSortByfilter] = useState({
    key: "updatedDate",
    value: "-1",
  });

  const handleSort = (columnKey) => {
    setCurrentTablePage(1);
    if (sortByfilter.key === columnKey) {
      const descend =
        sortByfilter.key === columnKey && sortByfilter.value === "-1";
      const ascend =
        sortByfilter.key === columnKey && sortByfilter.value === "1";
      if (descend) {
        setSortByfilter({ key: columnKey, value: "1" });
      } else if (ascend) {
        setSortByfilter({ key: columnKey, value: "-1" });
      }
    } else {
      setSortByfilter({ key: columnKey, value: "1" });
    }
  };

  const handleSuccessTableUpdate = (response) => {
    setSalesDetailsData([]);
    const result = response?.data?.magazinesDetails;

    const reportDetails = result?.data;
    const meta = result?.meta || {};

    const formattedSalesData = formatMagazinesData(reportDetails);

    setSalesDetailsData(formattedSalesData);
    setSalesDetailsMeta(meta);
  };

  const { isFetching: isRmMagazineMetricsLoading } = useQuery(
    ["getRmMagazineMetrics", selectedMonth, year],
    () => getRmMagazineMetrics(selectedMonth, year),
    {
      onSuccess: (response) => {
        const { data } = response;
        const { MagazineGeneratedCount, PageVistedCount, SentToPrintCount } =
          data;

        setRmMagazineMetrics({
          magazineGenerated: MagazineGeneratedCount,
          pageVisited: PageVistedCount,
          sentToPrint: SentToPrintCount,
        });
      },
    }
  );

  const { mutate: metricsDefaultMutate, isLoading: isDefaultTableData } =
    useGetDefaultTableMetrics(
      selectedMonth,
      year,
      currentTablePage,
      perPage,
      statusValue,
      handleSuccessTableUpdate,
      sortByfilter
    );

  const { mutate: metricsEditingMutate, isLoading: isEditingTableData } =
    useGetEditingTableMetrics(
      selectedMonth,
      year,
      currentTablePage,
      perPage,
      statusValue,
      handleSuccessTableUpdate,
      sortByfilter
    );

  const {
    mutate: metricsGeneratedMagazineMutate,
    isLoading: isGeneratedMagazineTableData,
  } = useGetGeneratedMagzineTableMetrics(
    selectedMonth,
    year,
    currentTablePage,
    perPage,
    statusValue,
    handleSuccessTableUpdate,
    sortByfilter
  );

  const {
    mutate: metricsSentToPrintMutate,
    isLoading: isSentToPrintTableData,
  } = useGetSentToPrintTableMetrics(
    selectedMonth,
    year,
    currentTablePage,
    perPage,
    statusValue,
    handleSuccessTableUpdate,
    sortByfilter
  );

  const { mutate: metricsInpurMutate, isLoading: isInputTableData } =
    useGetInputTableMetrics(
      inputText,
      selectedMonth,
      year,
      currentTablePage,
      perPage,
      statusValue,
      handleSuccessTableUpdate,
      sortByfilter
    );

  useEffect(() => {
    const fetchData = async () => {
      setIsCardLoading(true);
      try {
        switch (selectedCard) {
          case "default":
            await metricsDefaultMutate();
            break;
          case "input":
            await metricsInpurMutate();
            break;
          case "editing":
            await metricsEditingMutate();
            break;
          case "magazine-generated":
            await metricsGeneratedMagazineMutate();
            break;
          case "sent-to-print":
            await metricsSentToPrintMutate();
            break;
          default:
            await metricsDefaultMutate();
            break;
        }
      } finally {
        setIsCardLoading(false);
      }
    };

    fetchData();
  }, [
    selectedCard,
    selectedMonth,
    year,
    currentTablePage,
    perPage,
    inputText,
    statusValue,
    sortByfilter,
  ]);

  const handlePerPage = (value, firstItemIndex) => {
    const perPage = value.value;
    const pageNumber = Math.ceil(firstItemIndex / perPage) || 1;
    setPerPage(perPage);
    setCurrentTablePage(pageNumber);
  };

  const handleChange = (date: Date) => {
    setSelectedCard("default");
    setInputText("");
    const month = date
      .toLocaleString("default", { month: "long" })
      .slice(0, 3)
      .toUpperCase();
    const year = date.getFullYear();
    const cardDate = new Date(date);
    const cardIncomingMonth = new Date(
      cardDate.setMonth(cardDate.getMonth() + 1)
    );
    const cardDefaultMonth = cardIncomingMonth.toLocaleString("default", {
      month: "short",
    });

    setCardMonth(cardDefaultMonth);
    setCardYear(cardIncomingMonth.getFullYear());

    setYear(year);
    setSelectedMonth(month);
    setSelectedDate(date);
    setCurrentTablePage(1);
    queryClient.invalidateQueries([
      "getRmMagazineMetrics",
      selectedMonth,
      year,
    ]);
  };

  const handleCardChange = (value: string) => {
    setSelectedCard(value);
    setCurrentTablePage(1);
    setPerPage(10);
    setInputText("");
  };

  const handleSearch = (event) => {
    const inputValue = event.target?.value;
    if (inputValue !== "") {
      setCurrentTablePage(1);
    }
    setInputText(inputValue);
    setIsSearching(true);
    setSelectedCard("input");
    if (inputValue === "") {
      setSelectedCard("default");
      setIsSearching(false);
    }
  };

  const resetFilters = () => {
    setSelectedCard("default");
    setInputText("");
    setCurrentTablePage(1);
    setPerPage(10);
  };

  const handleCurrentPageChange = (page) => {
    setCurrentTablePage(page);
  };

  const tablePagination = {
    pageSize: salesDetailsMeta?.perPage,
    total: salesDetailsMeta?.total,
    lastPage: salesDetailsMeta?.lastPage,
    currentPage: salesDetailsMeta?.currentPage + 1 || 1,
    onChange: handleCurrentPageChange,
  };

  const { magazineGenerated, pageVisited, sentToPrint }: RmMagazineMetrics =
    rmMagazineMetrics as RmMagazineMetrics;

  return (
    <div className="py-4 min-h-screen w-full">
      <div className="flex w-full">
        <div className="w-full">
          <Title>RM Magazine Report</Title>
        </div>
      </div>
      <div className="flex w-full justify-between items-center bg-neutral-0 py-[15px] px-[24px] rounded-t-lg border-b-2 mt-[24px] shadow">
        <p className="font-semibold">Overview</p>
        <div>
          <DatePicker
            selected={selectedDate}
            onChange={handleChange}
            dateFormat="MMMM YYYY"
            showMonthYearPicker
            className="border border-gray-300 rounded-lg"
          />
        </div>
      </div>
      {isCardLoading || isRmMagazineMetricsLoading ? (
        <div className="flex w-full items-center bg-neutral-0 rounded-b-lg mb-[24px] shadow max-[1235px]:flex-col">
          <RMCardSkeletonLoader />
          <RMCardSkeletonLoader />
          <RMCardSkeletonLoader />
        </div>
      ) : (
        <div className="flex w-full items-center bg-neutral-0 rounded-b-lg mb-[24px] shadow max-[1235px]:flex-col">
          <div
            className={cn(
              "w-1/3 p-[36px] cursor-pointer max-[1235px]:w-full max-[1235px]:border-r-0 max-[1235px]:border-b-2 max-[1235px]:items-center max-[1235px]:flex  max-[1235px]:flex-col",
              {
                "bg-gray-200 border border-cyan-500":
                  selectedCard === "editing",
                "border-r-2 hover:bg-gray-200": selectedCard !== "editing",
              }
            )}
            onClick={() => handleCardChange("editing")}
          >
            <div className="flex items-center gap-2 text-sm">
              <img src={edit} alt="EditIcon" /> Total in editing
            </div>
            <p className="font-bold text-[30px]">{pageVisited}</p>
          </div>
          <div
            className={cn(
              "w-1/3 p-[36px] cursor-pointer max-[1235px]:w-full max-[1235px]:border-r-0 max-[1235px]:border-b-2 max-[1235px]:items-center max-[1235px]:flex  max-[1235px]:flex-col",
              {
                "bg-gray-200 border border-cyan-500":
                  selectedCard === "magazine-generated",
                "border-r-2 hover:bg-gray-200":
                  selectedCard !== "magazine-generated",
              }
            )}
            onClick={() => handleCardChange("magazine-generated")}
          >
            <div className="flex items-center gap-2 text-sm">
              <img src={magazine} alt="MagazineIcon" /> Total magazines for{" "}
              {`${cardMonth} ${cardYear}`}
            </div>
            <p className="font-bold text-[30px]">{magazineGenerated}</p>
          </div>
          <div
            className={cn(
              "w-1/3 p-[36px] cursor-pointer max-[1235px]:w-full max-[1235px]:border-r-0 max-[1235px]:items-center max-[1235px]:flex  max-[1235px]:flex-col",
              {
                "bg-gray-200 border border-cyan-500":
                  selectedCard === "sent-to-print",
                "border-r-2 hover:bg-gray-200":
                  selectedCard !== "sent-to-print",
              }
            )}
            onClick={() => handleCardChange("sent-to-print")}
          >
            <div className="flex items-center gap-2 text-sm">
              <img src={print} alt="PrintIcon" />
              Total print order
            </div>
            <p className="font-bold text-[30px]">{sentToPrint}</p>
          </div>
        </div>
      )}

      <div className="w-full h-[90px] px-6 py-[22px] rounded-t-lg shadow border-b-0 bg-white items-center gap-6 flex">
        <div className="flex w-full bg-white items-center inline-flex max-[800px]:justify-center">
          <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
            <TextInput
              placeholder="Search for name or email"
              value={inputText}
              onChange={(e) => handleSearch(e)}
            />
          </div>
        </div>
      </div>
      <div>
        {isRmMagazineMetricsLoading ||
        isDefaultTableData ||
        isEditingTableData ||
        isGeneratedMagazineTableData ||
        isSentToPrintTableData ||
        isInputTableData ? (
          <div className="bg-white rounded-lg shadow border-t-0">
            <SkeletonLoader />
          </div>
        ) : (
          <div>
            {isEmpty(salesDetailsData) ? (
              <div className="w-full h-[382px] py-[100px] bg-white flex flex-col justify-center items-center gap-3.5">
                <img
                  className="w-[182px] h-[121px] object-cover"
                  src={noItemsImage}
                  alt="No items found"
                />
                <div className="self-stretch h-[47px] flex flex-col justify-start items-center gap-1">
                  <div className="self-stretch text-center text-neutral-800 text-base font-semibold font-['Figtree'] leading-tight">
                    No results found
                  </div>
                  <div className="text-center">
                    <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                      Try a different keyword or{" "}
                    </span>
                    <button
                      onClick={resetFilters}
                      className="text-sky-400 text-sm font-medium underline leading-[23px] focus:outline-none"
                    >
                      reset filters
                    </button>
                    <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                      .
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full bg-white shadow justify-between items-center inline-flex overflow-x-auto">
                  <AfyTable
                    data={salesDetailsData}
                    columns={tableColumns}
                    perPage={perPage}
                    handleSort={handleSort}
                    columnName={sortByfilter}
                  />
                </div>
                {!isEmpty(salesDetailsData) && (
                  <div className="w-full bg-white rounded-b-lg py-4 px-6 shadow max-[1180px]:px-0 h-full mb-24">
                    <PaginationFlowBite
                      tablePagination={tablePagination}
                      handlePerPage={handlePerPage}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default RmMagaineReport;
