import React from 'react';
import get from 'lodash/get';
import { TextInput } from 'flowbite-react';
import { defaultTextInputTheme } from '../../../../../../components/Theme/TextInput';

const SocialMediaForm = ({ formikHandler, step = '' }) => {
  const { values = {}, setFieldValue } = formikHandler || {};

  const targetLocation = step.concat('.', '_formFields');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name = '', value = '' },
    } = e || {};
    const locationToSave = step.concat('.', '_formFields', '.', name);
    setFieldValue(locationToSave, value);
  };

  return (
    <div className=''>
      <div className="w-full flex flex-col lg:flex-row gap-4">
        <div className="w-full flex flex-col gap-1">
          <span className="font-semibold text-neutral-800 text-sm">Facebook username</span>
          <TextInput
            theme={defaultTextInputTheme}
            id="facebookUsername"
            name="facebookUsername"
            type="text"
            value={get(values, `${targetLocation}.facebookUsername`) || ''}
            onChange={handleChange}
            placeholder="Optional"
            className="font-figtres text-sm font-medium bg-[white!important]"
          />
        </div>
        <div className="w-full flex flex-col gap-1">
          <span className="font-semibold text-neutral-800 text-sm">X username</span>
          <TextInput
            theme={defaultTextInputTheme}
            id="twitterUsername"
            name="twitterUsername"
            type="text"
            value={get(values, `${targetLocation}.twitterUsername`) || ''}
            onChange={handleChange}
            placeholder="Optional"
          />
        </div>
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-4 mt-4">
        <div className="w-full flex flex-col gap-1">
          <span className="font-semibold text-neutral-800 text-sm">Linkedin username</span>
          <TextInput
            theme={defaultTextInputTheme}
            id="linkedInUsername"
            name="linkedInUsername"
            type="text"
            value={get(values, `${targetLocation}.linkedInUsername`) || ''}
            onChange={handleChange}
            placeholder="Optional"
          />
        </div>
        <div className="w-full flex flex-col gap-1">
          <span className="font-semibold text-neutral-800 text-sm">Instagram username</span>
          <TextInput
            theme={defaultTextInputTheme}
            id="instagramUsername"
            name="instagramUsername"
            type="text"
            placeholder="Optional"
            value={get(values, `${targetLocation}.instagramUsername`) || ''}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialMediaForm;
