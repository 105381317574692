import React from "react";
import Badge from "./Badge";

interface OffboardingResultsProps {
  title: string;
  values: string;
}

export default function OffboardingResults({
  title,
  values,
}: OffboardingResultsProps) {
  const isStatus = title === "Status";
  const isSuccess = values?.startsWith("Has active subscription");
  return (
    <div className="flex mb-[14px]">
      <div className="w-[98px] mr-6">
        <p className="text-gray-500 first-letter:uppercase lowercase text-sm">
          {title}
        </p>
      </div>
      {isStatus ? (
        <Badge value={values} isSuccess={isSuccess} />
      ) : (
        <div className={"text-wrap"}>
          <p className="capitalize text-sm font-semibold ">{values}</p>
        </div>
      )}
    </div>
  );
}
