import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  offerName: Yup.string()
    .trim()
    .min(2, 'Offer name needs to have 2 characters or more')
    .max(128, 'Offer name cannot exceed 128 characters')
    .required('Please enter offer name'),
  offerCode: Yup.string()
    .trim()
    .min(2, 'Offer code needs to have 2 characters or more')
    .max(20, 'Offer code cannot exceed 20 characters')
    .matches(
      /^[A-Za-z0-9-]+$/,
      'Offer code can only contain alphanumeric characters and "-"',
    )
    .required('Please enter offer code'),
  productCountry: Yup.array()
    .of(Yup.string().trim().min(1).required())
    .min(1, 'At least one country must be selected'),
  productCode: Yup.string()
    .trim()
    .min(1)
    .required('Choose an existing product'),
  bookPackages: Yup.array()
    .of(Yup.string().trim().min(1).required())
    .min(1, 'Choose a book package'),
  offerTrialEnabled: Yup.boolean().required(),
  trialCredits: Yup.string()
    .trim()
    .matches(/^\d+$/, 'Free credits must contain only numbers')
    .required('Free credits must be set to 0 or more'),
  trialPrice: Yup.string()
    .trim()
    .matches(/^\d+$/, 'Trial price must contain only numbers')
    .when('offerTrialEnabled', {
      is: true,
      then: (schema) => schema.required('Trial price is required'),
    }),
});

export default validationSchema;
