import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { ArrowRight, ZoomSmall, XIcon, ArrowDown } from "../../../../components/icons";
import Avatar from "../../../components/Avatar";
import PaginationFlowBite from "../../../components/PaginationFlowBite";
import {
  getPlayZoomUrl,
  getZoomRecordsByEmail,
  getZoomTeamMembersList,
} from "../../../api/zoom";
import Loader from "../../../components/Loaders";
import TextInput from "../../../components/TextInput";
import noItemsImage from "../../../../assets/images/no-items.png";

export default function TeamMeetingReport() {
  const navigate = useNavigate();
  const { teamMemberEmail } = useParams<{ teamMemberEmail: string }>();
  const decodedEmail = decodeURIComponent(teamMemberEmail);

  const [fullName, setFullName] = useState("");
  const [isLoadingFullName, setIsLoadingFullName] = useState(true);
  const [fullNameError, setFullNameError] = useState(null);
  const [topicValue, setTopicValue] = useState("");
  const [dateRange, setDateRange] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setIsLoadingFullName(true);
    getZoomTeamMembersList()
      .then((members) => {
        const member = members.find((m) => m.email === decodedEmail);
        if (member) {
          setFullName(member.fullName);
        } else {
          setFullNameError("No member found with the specified email");
        }
        setIsLoadingFullName(false);
      })
      .catch((error) => {
        setFullNameError(error.toString());
        setIsLoadingFullName(false);
      });
  }, [decodedEmail]);

  const queryKey = [
    "getZoomRecordsByEmail",
    decodedEmail,
    currentTablePage,
    perPage,
    topicValue,
    dateRange.startDate,
    dateRange.endDate,
  ];

  const {
    data: meetingsData,
    refetch,
    isLoading,
    isError,
    error,
  } = useQuery(
    queryKey,
    () =>
      getZoomRecordsByEmail(
        decodedEmail,
        currentTablePage,
        perPage,
        topicValue,
        dateRange.startDate,
        dateRange.endDate
      ),
    {
      keepPreviousData: true,
      onSuccess: (data) => {
      },
    }
  );

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate());

  const disabledDates = [
    {
      startDate: tomorrow,
      endDate: new Date("2099-12-31"),
    },
  ];

  const { mutate: getPlayZoomUrlMutate } = useMutation(getPlayZoomUrl, {
    onSuccess: (data) => {
      window.open(data.preSignedUrl, "_blank");
    },
    onError: (error) => {
      console.error("Download failed:", error);
      alert("Failed to download the file. Please try again.");
    },
  });

  const handleOpen = (meetingId) => {
    getPlayZoomUrlMutate(meetingId);
  };

  const handleValueChange = (newValue: DateValueType) => {
    setDateRange(newValue);
    setIsSearching(true);
    refetch();
  };

  const resetFilters = () => {
    setTopicValue("");
    setDateRange({ startDate: null, endDate: null });
    setCurrentTablePage(1);
    setPerPage(10);
    setIsSearching(false);
    refetch();
  };

  const handlePageChange = (newPage) => {
    setCurrentTablePage(newPage);
    refetch();
  };

  const tablePagination = {
    pageSize: perPage,
    total: meetingsData?.meta.total || 0,
    lastPage: meetingsData?.meta.lastPage || 0,
    currentPage: meetingsData?.meta.currentPage + 1,
    onChange: handlePageChange,
  };

  const handlePerPage = (value, firstItemIndex) => {
    const perPage = value.value;
    const pageNumber = Math.ceil(firstItemIndex / perPage) || 1;
    setPerPage(perPage);
    setCurrentTablePage(pageNumber);
  };

  const navigateToTeamMeetings = () => navigate("/team-meetings");

  const groupedMeetings = meetingsData?.data.reduce((acc, meeting) => {
    const date = new Date(meeting.startTime).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(meeting);
    return acc;
  }, {});

  const clearInput = () => {
    setTopicValue('');
  };

  const customToggleIcon = () => {
    return <ArrowDown/>;
  };

  if (isLoading) return <Loader />;
  if (isError) return <div>Error: {error.message}</div>;

  const hasMeetings = Object.keys(groupedMeetings || {}).length > 0;

  return (
    <div className="w-full min-h-screen flex flex-col justify-start items-start gap-6 pl-8 pt-8 mb-24">
      <div
        className="flex items-center gap-2.5 cursor-pointer"
        style={{ marginTop: "34px" }}
      >
        <div
          className="text-sm font-medium text-neutral-600"
          onClick={navigateToTeamMeetings}
        >
          Team Meetings
        </div>
        <ArrowRight className="w-4 h-4 text-neutral-500" />
        <div className="text-sm font-semibold text-neutral-700 border-b border-zinc-300">
          {fullName}
        </div>
      </div>
      <div className="flex items-center gap-3.5">
        <Avatar name={fullName} />
        <div className="flex flex-col items-start gap-1">
          <div className="text-2xl font-extrabold text-neutral-800">
            {fullName}
          </div>
          <div className="text-sm font-medium text-zinc-500">
            {decodedEmail}
          </div>
        </div>
      </div>
      <div className="flex flex-col self-stretch bg-white rounded-lg shadow w-full">
        <div className="flex items-center justify-between self-stretch px-6 py-4 border-b border-gray-200 max-[640px]:flex-col max-[640px]:justify-center gap-y-2">
          <div className="relative w-[330px] max-[450px]:w-full">
            <TextInput
              placeholder="Search for topic"
              value={topicValue}
              onChange={(e) => {
                setTopicValue(e.target.value);
                setIsSearching(true);
                refetch();
              }}
            />
            {topicValue && (
              <button
                onClick={clearInput}
                className="absolute inset-y-0 right-0 pr-4 flex items-center"
              >
                <XIcon className="h-5 w-5 text-gray-500" />
              </button>
            )}
          </div>
          <div>
            <Datepicker
              separator="-"
              primaryColor="sky"
              value={dateRange}
              onChange={handleValueChange}
              showShortcuts={true}
              showFooter={true}
              displayFormat="MMM DD, YYYY"
              placeholder="All time"
              popoverDirection="down"
              disabledDates={disabledDates}
              toggleIcon={customToggleIcon}
              readOnly={true}
              inputClassName="w-auto h-[46px] pl-5 pr-[15px] py-[13px] bg-white rounded-md border border-gray-200 focus:shadow-none focus:border-2 focus:ring-transparent focus:border-sky-400 text-sm text-neutral-800"
            />
          </div>
        </div>
        {hasMeetings ? (
          Object.entries(groupedMeetings).map(([date, meetings], idx) => (
            <div
              key={date}
              className={`flex flex-col justify-start items-start gap-2 py-6 p-6 border-b border-gray-200 ${
                idx % 2 === 0 ? "bg-white" : "bg-[#FAFAFA]"
              }`}
            >
              <div className="self-stretch">
                <div className="text-neutral-800 text-sm font-semibold">
                  {date}
                </div>
              </div>
              {meetings.map((meeting, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if (!meeting.deletedAt) {
                      handleOpen(meeting.id);
                    }
                  }}
                  className="self-stretch p-3.5 bg-white rounded-lg border border-gray-200 flex-col justify-start items-start gap-3.5 flex hover:border hover:border-sky-400 cursor-pointer hover:shadow-[inset_0_0_0_1px_rgba(56,189,248,1)]"
                  style={{ cursor: meeting.deletedAt ? "default" : "pointer" }}
                >
                  <div className="self-stretch justify-start items-center gap-3.5 inline-flex">
                    <div className="flex grow items-center gap-2 flex-wrap">
                      <div
                        className={`w-[22px] h-[22px] rounded-full p-1 shadow flex justify-center items-center ${
                          meeting.fileLocation ? "bg-sky-400" : "bg-gray-400"
                        }`}
                      >
                        <ZoomSmall className="text-white" />
                      </div>
                      <span className="text-sm font-semibold text-neutral-800">
                        {new Date(meeting.startTime).toLocaleTimeString(
                          "en-US",
                          { hour: "2-digit", minute: "2-digit", hour12: true }
                        )}{" "}
                        EST
                      </span>
                      <span className="text-sm font-semibold text-neutral-800">
                        ·
                      </span>
                      <span className="text-sm font-medium text-neutral-500 grow">
                        {meeting.topic}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="w-full h-[382px] py-[100px] flex flex-col justify-center items-center gap-3.5">
            <img
              className="w-[182px] h-[121px] object-cover"
              src={noItemsImage}
              alt="No items found"
            />
            <div className="self-stretch h-[47px] flex flex-col justify-start items-center gap-1">
              <div className="self-stretch text-center text-neutral-800 text-base font-semibold font-['Figtree'] leading-tight">
                No results found.
              </div>
              <div className="text-center">
                <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                  Try a different keyword or{" "}
                </span>
                <button
                  onClick={resetFilters}
                  className="text-sky-400 text-sm font-medium underline leading-[23px] focus:outline-none"
                >
                  reset filters
                </button>
                <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                  .
                </span>
              </div>
            </div>
          </div>
        )}
        {hasMeetings && (
          <div className="w-full bg-white rounded-b-lg py-4 px-6 shadow max-[1180px]:px-0 h-full">
            <PaginationFlowBite
              tablePagination={tablePagination}
              handlePerPage={handlePerPage}
            />
          </div>
        )}
      </div>
    </div>
  );
}
