import React from 'react';
import { TextInput } from 'flowbite-react';

import { defaultTextInputTheme } from '../../../../../../components/Theme/TextInput';
import { SvgQRCode } from '../../../../../../../components/icons';
import get from 'lodash/get';

const QRCode = ({ formikHandler, step = '' }) => {
  const { values = {}, setFieldValue } = formikHandler || {};

  const targetLocation = step.concat('.', '_formFields');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name = '', value = '' },
    } = e || {};
    const locationToSave = step.concat('.', '_formFields', '.', name);
    setFieldValue(locationToSave, value);
  };

  return (
    <div className="flex gap-5">
      <SvgQRCode />
      <div className=''>
        <label className="text-neutral-800 text-sm font-semibold">
          Scanning the QR code will show this information
        </label>
        <TextInput
          theme={defaultTextInputTheme}
          id="qrCode"
          name="qrCode"
          type="text"
          value={get(values, `${targetLocation}.qrCode`) || ''}
          onChange={handleChange}
          placeholder="Type any link, email, website, phone number, etc."
        />
      </div>
    </div>
  );
};

export default QRCode;
