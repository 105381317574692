import React from 'react';
import { getFormattedValues } from '../modules/pages/reports/rmMagazineReport/utils';

export type StateType<T> = {
  [key: string]: T;
}

export type MagazineFromDBContextType<T> = {
  ctxMagazineFromDB: StateType<T>;
  ctxSelections: object[];
  ctxSaveMagazineFromDB: (magazineFromDB: StateType<T>) => void;
};

interface ExtraHtmlType<T> {
  extraHtml: { [key: string]: T }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MagazineFromDBContext = React.createContext<MagazineFromDBContextType<any> | null>(null);

const MagazineFromDBProvider: React.FC<{ children: React.ReactNode }> = <T,>({ children }) => {
  const [ctxMagazineFromDB, saveMagazineFromDB] = React.useState<StateType<T>[]>([]);
  const [ctxSelections, saveMagazineSelections] = React.useState<object[]>([]);

  // const ctxSaveMagazineFromDB = <T extends { length: number }>(magazineFromDB: StateType<T>) => {
  const ctxSaveMagazineFromDB = (magazineFromDB: StateType<T>) => {

    const filledSteps = magazineFromDB.selections as StateType<T>[];
    const selectedValues: object[] = [];
    for (let index = 0; index < filledSteps?.length; index++) {
      const stepInfo = filledSteps[index];
      const { dynamicFields = [], formKeyword, page, extraHtml = {} } = stepInfo as StateType<T> & ExtraHtmlType<T>;
      const formattedValues = getFormattedValues(dynamicFields);

      selectedValues.push({ page, formKeyword, formattedValues });

      const hasExtraInfo = Object.keys(extraHtml)?.length;
      if (hasExtraInfo) {
        const {
          dynamicFields: extraDynamicFields = [],
          formKeyword: extraInfoFormKeyword,
        } = extraHtml;
        const extraValues = getFormattedValues(extraDynamicFields);
        selectedValues.push({ page, formKeyword: extraInfoFormKeyword, formattedValues: extraValues });
      }
    }

    saveMagazineSelections(selectedValues);
    saveMagazineFromDB([magazineFromDB]);
  };

  return <MagazineFromDBContext.Provider value={{ ctxMagazineFromDB, ctxSelections, ctxSaveMagazineFromDB }}>{children}</MagazineFromDBContext.Provider>;
};

export default MagazineFromDBProvider;