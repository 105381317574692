import * as React from "react";
import type { SVGProps } from "react";

const SvgQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} className={`w-3 h-3 ${props.className}`}>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.67 8.335C16.67 12.9383 12.9383 16.67 8.335 16.67C3.73171 16.67 0 12.9383 0 8.335C0 3.73171 3.73171 0 8.335 0C12.9383 0 16.67 3.73171 16.67 8.335ZM8.33501 5.20937C7.95069 5.20937 7.6136 5.41693 7.43189 5.73107C7.14376 6.22915 6.50642 6.39935 6.00834 6.11123C5.51026 5.8231 5.34006 5.18576 5.62818 4.68768C6.16704 3.75615 7.17678 3.12563 8.33501 3.12563C10.0612 3.12563 11.4606 4.52501 11.4606 6.25125C11.4606 7.61217 10.5909 8.76994 9.37689 9.19902V9.37687C9.37689 9.95229 8.91043 10.4187 8.33501 10.4187C7.7596 10.4187 7.29314 9.95229 7.29314 9.37687V8.335C7.29314 7.75959 7.7596 7.29313 8.33501 7.29313C8.91043 7.29313 9.37689 6.82666 9.37689 6.25125C9.37689 5.67584 8.91043 5.20937 8.33501 5.20937ZM8.335 13.5444C8.91041 13.5444 9.37687 13.0779 9.37687 12.5025C9.37687 11.9271 8.91041 11.4606 8.335 11.4606C7.75959 11.4606 7.29313 11.9271 7.29313 12.5025C7.29313 13.0779 7.75959 13.5444 8.335 13.5444Z" fill="#D7D7D7" />
  </svg>
);

export default SvgQuestion;
