import * as Yup from "yup";

const coachingSegmentsEnum = ["REALTOR", "DENTIST"];

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  meetingLink: Yup.string()
    .url("Meeting Link must be a valid URL")
    .required("Meeting Link is required"),
  coachingSegments: Yup.array()
    .required("Coaching Segments are required")
    .of(Yup.string().oneOf(coachingSegmentsEnum).label("Coaching Segment")),
  enabled: Yup.boolean().required("This option is required"),
  image: Yup.string().optional(),
});

export default validationSchema;
