import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { forEach } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';
import { ToastContainer } from 'react-toastify';

import Title from '../../../components/Title';
import Button from '../../../components/Button';
import Loader from '../../../components/Loaders';
import { getBookPackages } from '../../../api/offer';
import { getOnboardUrl } from '../utils/getOnboardUrl';
import ToastNotification from '../../../components/ToastNotification';
import offerImage from '../../../../assets/images/offerImage.png';
import { ArrowRight } from '../../../../components/icons';

const SingleOffer = () => {
  const navigate = useNavigate();
  const [copyStatus, setCopyStatus] = useState(false);
  const [copyOfferId, setCopyOfferId] = useState(false);
  const [componentId, setComponentId] = useState(false);
  const [productHandle, setProductHandle] = useState(false);
  const [priceHandle, setPriceHandle] = useState(false);

  const location = useLocation();
  const offers = location.state;

  const isProductCatalogOffer = offers?.isProductCatalog || false;

  const handleOfferInclusions = () => {
    if (!offers?.whatsIncluded) return '';
    return (
      <div className="grow shrink basis-0 text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
        {offers?.whatsIncluded.map((point, index) => (
          <React.Fragment key={index}>
            {index < offers?.whatsIncluded.length - 1
              ? `- ${point}`
              : `- ${point}`}
            {index < offers?.whatsIncluded.length - 1 && <br />}
          </React.Fragment>
        ))}
      </div>
    );
  };

  const offerId = offers?._id || ''; // TODO: check if we need offerId for product catalog offers
  const offerName = offers?.title || offers?.name || ''; // TODO update the offer name when it is ready
  const offerCode = offers?.code || offers?.offerCode || '';
  const offerCredits = offers?.credits || ''; // TODO update the offer credits when it is ready
  const offerDescription = offers?.description1 || ''; // TODO update the offer description when it is ready
  const offerInclusions = handleOfferInclusions();
  const hasTrial = offers?.trial > 0;
  const trialPrice = offers?.trialPrice || 7;

  const productName = offers?.product?.name || '';

  const isPublished = offers?.status === 'PUBLISHED';

  const offerTemplate = offers?.templateVariables || [];

  const leadingBookPackages = () => {
    if (!offers?.emphasis) return '';
    return (
      <div className="grow shrink basis-0 text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
        {offers?.emphasis.map((category) => (
          <div className="w-fit h-6 px-2 mr-2 py-1 bg-neutral-100 rounded-md justify-center items-center inline-flex">
            <div className="text-center text-neutral-800 text-xs font-semibold font-['Figtree'] leading-none">
              {category}
            </div>
          </div>
        ))}
      </div>
    );
  };

  let heroImage = '';
  let productTitle = '';
  let productDescription = '';
  let benefits;

  const handleBenefitsList: React.FC = (benefitsString) => {
    const benefitsParse = JSON.parse(benefitsString);
    benefitsParse.sort((a, b) => a.position - b.position);

    return (
      <div>
        {benefitsParse.map((benefit) => (
          <div
            key={benefit.position}
            className="flex px-5 py-3.5 gap-4 justify-start items-start"
          >
            <img src={benefit.icon} alt="check" />
            <div>
              <p className="text-lg font-semibold">{benefit.title}</p>
              <p className="text-sm text-neutral-500">{benefit.type}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  offerTemplate?.map((template) => {
    if (template?.handle === 'productHeroImage') {
      heroImage = template?.value;
    }
    if (template?.handle === 'offerTitle') {
      productTitle = template?.value;
    }
    if (template?.handle === 'offerDescription') {
      productDescription = template?.value;
    }
    if (template?.handle === 'benefits') {
      benefits = handleBenefitsList(template.value) || '';
    }
  });

  const { data: bookPackagesData, isLoading: isDataLoading } = useQuery(
    ['getBookPackages', []],
    // @ts-expect-error not handling this right because of time constraints
    getBookPackages,
  );

  const onCopyText = () => {
    ToastNotification({
      type: 'success',
      message: 'Link copied to clipboard',
    });
  };

  const getCopyUrl = (offers) => {
    let obj;
    if (offers?.isProductCatalog) {
      obj = {
        offerCode: offers.code,
        isProductCatalogOffer: true,
      };
    } else {
      obj = {
        offerCode: offers.code,
        launchOfferType: offers.launchOfferType,
      };
    }

    const url = getOnboardUrl(obj);
    return url;
  };

  if (isDataLoading) {
    return <Loader />;
  }

  const bookPackagesList = [];
  forEach(offers?.packages, (bookId) => {
    // @ts-expect-error not handling this right because of time constraints
    const bookName = bookPackagesData?.books.find(
      ({ id }) => id === bookId,
    )?.name;
    if (bookName) {
      // @ts-expect-error not handling this right because of time constraints
      bookPackagesList.push(bookName);
    }
    return bookPackagesList;
  });

  const bookPackages = bookPackagesList || []; // TODO update the book packages when it is ready

  const handleBadge = () => {
    if (offers.isProductCatalog) {
      const typeLabel = offers.status === 'DRAFT' ? 'Draft' : 'Product Catalog';
      const bgColor = typeLabel === 'Draft' ? 'bg-gray-300' : 'bg-success-400';
      return (
        <div className="h-6 px-2 py-1 bg-white rounded-md border border-gray-200 justify-center items-center gap-2 inline-flex">
          <div className="w-2 h-2 relative">
            <div
              className={`w-1.5 h-1.5 left-[1px] top-[1px] absolute ${bgColor} rounded-full`}
            />
          </div>
          <div className="text-center text-neutral-800 text-xs font-semibold font-['Figtree'] leading-none">
            {typeLabel}
          </div>
        </div>
      );
    }
    return (
      <>
        {offers?.type === 'main' ? (
          <div className="h-6 px-2 py-1 bg-white rounded-md border border-gray-200 justify-center items-center gap-2 inline-flex">
            <div className="w-2 h-2 relative">
              <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-sky-300 rounded-full" />
            </div>
            <div className="text-center text-neutral-800 text-xs font-semibold font-['Figtree'] leading-none">
              Main
            </div>
          </div>
        ) : (
          <div className="h-6 px-2 py-1 bg-white rounded-md border border-gray-200 justify-center items-center gap-2 inline-flex">
            <div className="w-2 h-2 relative">
              <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-amber-400 rounded-full" />
            </div>
            <div className="text-center text-neutral-800 text-xs font-semibold font-['Figtree'] leading-none">
              Add-on
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="pl-4 pt-4 min-h-screen w-full">
      <div className="mb-4">
        <div
          className="flex items-center gap-2.5 cursor-pointer"
          onClick={() => navigate('/offers')}
        >
          <div className="text-sm font-medium text-neutral-600">Offers</div>
          <ArrowRight className="w-4 h-4 text-neutral-500" />
          <div className="text-sm font-semibold text-neutral-700 border-b border-zinc-300">
            {offerName}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mb-2 w-full max-[550px]:flex-col max-[550px]:gap-y-2">
        <div className="flex justify-start gap-2 items-center pr-16 max-[550px]:pr-0">
          <Title>{offerName}</Title>
          {handleBadge()}
        </div>
        <div>
          <CopyToClipboard
            text={getCopyUrl(offers)}
            onCopy={onCopyText}
            className={'cursor-pointer'}
          >
            <Button variant="DARK" disabled={!isPublished}>
              <MdContentCopy />
              {copyStatus ? 'copied' : 'Copy Link'}
            </Button>
          </CopyToClipboard>
        </div>
      </div>

      <div className="shadow rounded-lg mt-6 mb-4">
        <div className="bg-white rounded-t-lg">
          <div className="w-full h-16 px-6 py-[22px] border-b rounded-t-lg border-gray-200 justify-start items-center gap-6 inline-flex">
            <div className="text-zinc-900 text-base font-semibold font-['Figtree'] leading-tight">
              Details
            </div>
          </div>
        </div>
        <div className="bg-white rounded-b-lg">
          <div className="w-full p-6 flex-col justify-start items-start gap-3.5 inline-flex">
            {offerId && (
              <div className="w-full h-[34px] pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
                <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                  <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                    Offer ID
                  </div>
                </div>
                {offerId && (
                  <>
                    <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-3.5 inline-flex">
                      <div className="grow shrink basis-0 justify-start items-start gap-2 inline-flex">
                        <div className="flex items-center text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                          {offerId}
                          <CopyToClipboard
                            text={offerId}
                            onCopy={onCopyText}
                            className={'cursor-pointer'}
                          >
                            <div className="flex gap-1">
                              <MdContentCopy />
                              {copyOfferId && 'copied'}
                            </div>
                          </CopyToClipboard>
                        </div>
                        <div className="w-5 h-5 justify-center items-center flex">
                          <div className="w-5 h-5 relative flex-col justify-start items-start flex" />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
              <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                  Name
                </div>
              </div>
              <div className="grow shrink basis-0 text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                {offerName}
              </div>
            </div>
            <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
              <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                  Code
                </div>
              </div>
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-3.5 inline-flex">
                <div className="text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                  {offerCode}
                </div>
              </div>
            </div>
            {!isProductCatalogOffer ? (
              <>
                <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
                  <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                    <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                      Credits
                    </div>
                  </div>
                  <div className="grow shrink basis-0 text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                    {offerCredits}
                  </div>
                </div>
                <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
                  <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                    <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                      Description
                    </div>
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start gap-3.5 inline-flex">
                    <div className="self-stretch text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                      {offerDescription}
                    </div>
                  </div>
                </div>
                <div className="self-stretch pb-3.5 justify-between items-start inline-flex">
                  <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                    <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                      Inclusions
                    </div>
                  </div>
                  {offerInclusions}
                </div>
              </>
            ) : (
              <>
                <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
                  <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                    <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                      Product
                    </div>
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start gap-3.5 inline-flex">
                    <div className="self-stretch text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                      <div>
                        <p>{productName}</p>
                        <p className="text-sm text-neutral-400 font-medium">{`$${offers?.value} billed monthly`}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
                  <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                    <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                      Leading book packages
                    </div>
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start gap-3.5 inline-flex">
                    <div className="self-stretch text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                      {leadingBookPackages()}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {!isProductCatalogOffer && (
        <>
          <div className="shadow rounded-lg">
            <div className="bg-white rounded-t-lg mt-2">
              <div className="w-full h-16 px-6 py-[22px] border-b border-gray-200 justify-start items-center gap-6 inline-flex">
                <div className="text-zinc-900 text-base font-semibold font-['Figtree'] leading-tight">
                  Book Package
                </div>
              </div>
            </div>
            <div className="bg-white rounded-b-lg">
              <div className="w-full p-6 flex-col justify-start items-start gap-3.5 inline-flex">
                <div className="w-full pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
                  <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                    <div className="text-zinc-500 text-sm w-full font-medium font-['Figtree'] leading-tight">
                      Book Lists
                    </div>
                  </div>
                  <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-3.5 inline-flex">
                    <div className="grow shrink basis-0 justify-start items-start gap-2 inline-flex">
                      <div className="text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                        {bookPackages.map((name, index) =>
                          index < bookPackages.length - 1
                            ? `- ${name}`
                            : `- ${name}`,
                        )}

                        {bookPackages.map((point, index) => (
                          <React.Fragment key={index}>
                            {index < offers?.whatsIncluded.length - 1
                              ? `- ${point}`
                              : `- ${point}`}
                            {index < offers?.whatsIncluded.length - 1 && <br />}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow rounded-lg">
            <div className="bg-white mt-4 rounded-t-lg">
              <div className="w-full h-16 px-6 py-[22px] border-b border-gray-200 justify-start items-center gap-6 inline-flex">
                <div className="text-zinc-900 text-base font-semibold font-['Figtree'] leading-tight">
                  Product
                </div>
              </div>
            </div>
            <div className="bg-white rounded-b-lg">
              <div className="p-6 w-full flex-col justify-start items-start gap-3.5 inline-flex">
                <div className="w-full pb-3.5 border-b border-gray-200 justify-between items-start inline-flex max-[650px]:flex-col">
                  <div className="w-1/3 h-5 justify-start items-center flex max-[650px]:w-full">
                    <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                      Product
                    </div>
                  </div>
                  <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-3.5 inline-flex">
                    <div className="grow shrink basis-0 justify-start items-start gap-2 inline-flex">
                      <div className="text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                        {offerName}
                      </div>
                      <div className="w-5 h-5 justify-center items-center flex">
                        <div className="w-5 h-5 relative flex-col justify-start items-start flex" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-start inline-flex max-[650px]:flex-col">
                  <div className="w-1/3 h-5 justify-start items-center flex max-[650px]:w-full">
                    <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                      Chargify component ID
                    </div>
                  </div>
                  <div className="flex items-center gap-1 grow shrink basis-0 text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                    {offers?.products[0]?.chargifyComponentId || ' -'}
                    {offers?.products[0]?.chargifyComponentId && (
                      <CopyToClipboard
                        text={offers?.products[0]?.chargifyComponentId}
                        onCopy={onCopyText}
                        className={'cursor-pointer'}
                      >
                        <div className="flex gap-1">
                          <MdContentCopy />
                          {componentId && 'copied'}
                        </div>
                      </CopyToClipboard>
                    )}
                  </div>
                </div>

                <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-start inline-flex max-[650px]:flex-col">
                  <div className="w-1/3 h-5 justify-start items-center gap-2 flex max-[650px]:w-full">
                    <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                      Chargify product handle
                    </div>
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start gap-3.5 inline-flex">
                    <div className="flex items-center text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                      {offers?.products[0]?.chargifyProductHandle || '-'}
                      {offers?.products[0]?.chargifyProductHandle && (
                        <CopyToClipboard
                          text={offers?.products[0]?.chargifyProductHandle}
                          onCopy={onCopyText}
                          className={'cursor-pointer'}
                        >
                          <div className="flex gap-1">
                            <MdContentCopy />
                            {productHandle && 'copied'}
                          </div>
                        </CopyToClipboard>
                      )}
                    </div>
                  </div>
                </div>

                <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-start inline-flex max-[650px]:flex-col">
                  <div className="w-1/3 h-5 justify-start items-center gap-2 flex max-[650px]:w-full">
                    <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                      Chargify price handle
                    </div>
                  </div>
                  <div className="flex items-center grow shrink basis-0 text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                    {offers?.products[0]?.chargifyProductPriceHandle || '-'}
                    {offers?.products[0]?.chargifyProductPriceHandle && (
                      <CopyToClipboard
                        text={offers?.products[0]?.chargifyProductPriceHandle}
                        onCopy={onCopyText}
                        className={'cursor-pointer'}
                      >
                        <div className="flex gap-1">
                          <MdContentCopy />
                          {priceHandle && 'copied'}
                        </div>
                      </CopyToClipboard>
                    )}
                  </div>
                </div>
                <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-start inline-flex max-[650px]:flex-col">
                  <div className="w-1/3 h-5 justify-start items-center gap-2 flex max-[650px]:w-full">
                    <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                      Description
                    </div>
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start gap-3.5 inline-flex">
                    <div className="self-stretch text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                      {offers?.products[0]?.title || '-'}
                    </div>
                  </div>
                </div>
                <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-start inline-flex max-[650px]:flex-col">
                  <div className="w-1/3 h-5 justify-start items-center gap-2 flex max-[650px]:w-full">
                    <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                      Price
                    </div>
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start gap-3.5 inline-flex">
                    <div className="self-stretch text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                      {offers?.products[0]?.value
                        ? '$' + offers?.products[0]?.value
                        : '-'}
                    </div>
                  </div>
                </div>
                <div className="self-stretch pb-3.5 justify-between items-start inline-flex max-[650px]:flex-col">
                  <div className="w-1/3 h-5 justify-start items-center gap-2 flex max-[650px]:w-full">
                    <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                      Price Description
                    </div>
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start gap-3.5 inline-flex">
                    <div className="self-stretch text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                      {offers?.products[0]?.priceProperty || '-'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {hasTrial && (
        <div className="shadow rounded-lg mt-6 mb-4">
          <div className="bg-white rounded-t-lg">
            <div className="w-full h-16 px-6 py-[22px] border-b rounded-t-lg border-gray-200 justify-start items-center gap-6 inline-flex">
              <div className="text-zinc-900 text-base font-semibold font-['Figtree'] leading-tight">
                Trial
              </div>
            </div>
          </div>
          <div className="bg-white rounded-b-lg">
            <div className="w-full p-6 flex-col justify-start items-start gap-3.5 inline-flex">
              <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
                <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                  <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                    Trial price
                  </div>
                </div>
                <div className="grow shrink basis-0 text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                  {`$ ${trialPrice}`}
                </div>
              </div>
              <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
                <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                  <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                    Trial credits (Free printed books)
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-3.5 inline-flex">
                  <div className="text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                    {offers?.freeBooks}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isProductCatalogOffer ? (
        <div className="shadow rounded-lg">
          <div className="bg-white mt-4 rounded-t-lg">
            <div className="h-16 px-6 py-[22px] border-b w-full border-gray-200 justify-start items-center gap-6 inline-flex">
              <div className="text-zinc-900 text-base font-semibold font-['Figtree'] leading-tight">
                Webinar
              </div>
            </div>
          </div>
          <div className="bg-white rounded-b-lg p-6 justify-start items-start gap-3.5 inline-flex max-[1200px]:flex-col">
            <div className="flex max-[1200px]:items-center max-[1200px]:justify-center max-[1200px]:w-full">
              {offers?.webinar?.image ? (
                <img
                  className="rounded-md"
                  src={offers?.webinar?.image}
                  alt="image"
                  width="322"
                />
              ) : (
                <img
                  className="rounded-md"
                  src={offerImage}
                  alt="image"
                  width="322"
                />
              )}
            </div>
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-3.5 inline-flex">
              <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-start inline-flex max-[600px]:flex-col">
                <div className="w-1/6 h-5 justify-start items-center gap-2 flex">
                  <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                    Title
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-3.5 inline-flex">
                  <div className="self-stretch text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                    Use Credibility Stacking To Win 4-5 Expired Listings Each
                    Month - Without Cold Calling
                  </div>
                </div>
              </div>
              <div className="self-stretch pb-3.5 border-b border-gray-200 justify-between items-start inline-flex max-[600px]:flex-col">
                <div className="w-1/6 h-5 justify-start items-center gap-2 flex">
                  <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                    Code
                  </div>
                </div>
                <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-3.5 inline-flex">
                  <div className="text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                    lRmTHP
                  </div>
                </div>
              </div>
              <div className="self-stretch pb-3.5 justify-between items-start inline-flex max-[600px]:flex-col">
                <div className="w-1/6 h-5 justify-start items-center gap-2 flex">
                  <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
                    Description
                  </div>
                </div>
                <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-3.5 inline-flex">
                  <div className="self-stretch text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                    On this training we'll show 5 of our best set-and-forget
                    strategies for getting sales with your books. We'll also
                    provide you with links to training on the membership site
                    that shows you how to execute each step quickly and easily.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="shadow rounded-lg">
          <div className="bg-white rounded-t-lg mt-2">
            <div className="w-full h-16 px-6 py-[22px] border-b border-gray-200 justify-start items-center gap-6 inline-flex">
              <div className="text-zinc-900 text-base font-semibold font-['Figtree'] leading-tight">
                Template
              </div>
            </div>
          </div>
          <div className="bg-white rounded-b-lg">
            <div className="w-full p-6 flex-col justify-start items-start gap-3.5 inline-flex">
              {/* {offerTemplateName === 'Template Classico' ? (
                <> */}
              <div className="w-full pb-3.5 border-b border-gray-200 justify-between items-start inline-flex">
                <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                  <div className="text-zinc-500 text-sm w-full font-medium font-['Figtree'] leading-tight">
                    Hero image
                  </div>
                </div>
                <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-3.5 inline-flex">
                  <div className="grow shrink basis-0 justify-start items-start gap-2 inline-flex">
                    <div className="text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                      {heroImage && (
                        <img
                          src={heroImage}
                          alt="hero-image"
                          className="w-[322px] max-h-[190px] object-contain rounded"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
                <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                  <div className="text-zinc-500 text-sm w-full font-medium font-['Figtree'] leading-tight">
                    Product title
                  </div>
                </div>
                <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-3.5 inline-flex">
                  <div className="grow shrink basis-0 justify-start items-start gap-2 inline-flex">
                    <div className="text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                      {productTitle}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
                <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                  <div className="text-zinc-500 text-sm w-full font-medium font-['Figtree'] leading-tight">
                    Product description
                  </div>
                </div>
                <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-3.5 inline-flex">
                  <div className="grow shrink basis-0 justify-start items-start gap-2 inline-flex">
                    <div className="text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                      {productDescription}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full pb-3.5 border-b border-gray-200 justify-between items-start inline-flex">
                <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                  <div className="text-zinc-500 text-sm w-full font-medium font-['Figtree'] leading-tight">
                    Benefits
                  </div>
                </div>
                <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-3.5 inline-flex">
                  <div className="grow shrink basis-0 justify-start items-start gap-2 inline-flex">
                    <div className="text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                      {benefits}
                    </div>
                  </div>
                </div>
              </div>
              {/* </>
              ) : (
                <>
                  <div className="w-full pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
                    <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                      <div className="text-zinc-500 text-sm w-full font-medium font-['Figtree'] leading-tight">
                        Hero background image
                      </div>
                    </div>
                    <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-3.5 inline-flex">
                      <div className="grow shrink basis-0 justify-start items-start gap-2 inline-flex">
                        <div className="text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                          test
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
                    <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                      <div className="text-zinc-500 text-sm w-full font-medium font-['Figtree'] leading-tight">
                        Hero title
                      </div>
                    </div>
                    <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-3.5 inline-flex">
                      <div className="grow shrink basis-0 justify-start items-start gap-2 inline-flex">
                        <div className="text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                          test
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full pb-3.5 border-b border-gray-200 justify-between items-center inline-flex">
                    <div className="w-1/3 h-5 justify-start items-center gap-2 flex">
                      <div className="text-zinc-500 text-sm w-full font-medium font-['Figtree'] leading-tight">
                        Benefits
                      </div>
                    </div>
                    <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-3.5 inline-flex">
                      <div className="grow shrink basis-0 justify-start items-start gap-2 inline-flex">
                        <div className="text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                          test
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )} */}
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default SingleOffer;
