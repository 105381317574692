import * as React from "react";
const SvgBackArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g id="Iconer">
      <path
        id="Vector"
        d="M16 10H4M4 10L9 5M4 10L9 15"
        stroke="#3C3C3C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default SvgBackArrow;
