import { Card } from "flowbite-react";

interface NotFoundCardProps {}

export default function NotFoundCard({}: NotFoundCardProps) {
  return (
    <Card>
      <div className={"flex justify-center"}>
        <p className={"font-bold text-xl"}>We couldn't find any member</p>
      </div>
      <p className={"text-gray-500"}>
        Try a different keyword and make sure that you are searching with name,
        email, or subscription ID
      </p>
    </Card>
  );
}
