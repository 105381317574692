import * as React from "react";
const SvgMarketingActive = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#252525"
      d="m1.969 9.81 5.52-3.532.008-.002L13.8 2.233a1.21 1.21 0 0 1 1.093-.113 1.2 1.2 0 0 1 .747.802l2.998 10.191a1.226 1.226 0 0 1-.823 1.52 1.4 1.4 0 0 1-.352.056l-6.666.01.253.861a1.226 1.226 0 0 1-.83 1.522l-2.354.692a1.226 1.226 0 0 1-1.522-.83l-.655-2.227-2.276.005a1.23 1.23 0 0 1-1.178-.884l-.778-2.646a1.23 1.23 0 0 1 .512-1.381M7.52 16.6l2.353-.692-.353-1.199H6.965zm-4.11-3.106 2.747-.008 3.002-.005-1.687-5.736-2.42 1.55-2.42 1.551z"
    />
  </svg>
);
export default SvgMarketingActive;
