import * as React from "react";
import type { SVGProps } from "react";
const SvgPhoneOrange = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#D9630B"
      d="m10.063 8.85-.529-.532zm.285-.284.528.532zm1.51-.198-.358.66zm1.194.65-.358.658zm.337 2.034.528.532zm-.888.883-.53-.532zm-.828.418.07.747zM5.51 9.704l.528-.532zM2.502 4.091l-.749.043zm4.046.907.53.532zm.098-1.658.6-.45zm-.788-1.053-.6.45zm-1.945-.178.53.532zm-.98.976-.53-.532zm4.606 4.6.53-.531zm3.053 1.696.284-.283L9.82 8.035l-.285.283zm.908-.354 1.194.65.716-1.318-1.194-.65zm1.36 1.493-.888.883 1.058 1.063.887-.882zm-1.257 1.087c-.839.078-3.096.02-5.565-2.435l-1.057 1.064c2.84 2.823 5.563 2.976 6.761 2.864zM6.038 9.172c-2.37-2.356-2.741-4.314-2.787-5.124l-1.498.086c.067 1.173.592 3.48 3.228 6.102zm.86-3.464.18-.178L6.02 4.467l-.18.178zm.349-2.818-.788-1.053-1.201.9.788 1.052zM3.385 1.577l-.981.976L3.46 3.617l.981-.976zm2.984 3.6c-.529-.532-.53-.532-.53-.53h-.002l-.021.024a1 1 0 0 0-.093.117 1.2 1.2 0 0 0-.133.258c-.08.216-.12.488-.07.812.098.626.518 1.393 1.49 2.36l1.058-1.064c-.874-.87-1.039-1.358-1.065-1.526-.012-.075.003-.086-.009-.053a.449.449 0 0 1-.093.13l-.002.002s-.001.001-.53-.53m.642 3.04c.973.968 1.742 1.384 2.37 1.48.322.05.594.012.81-.068a1.2 1.2 0 0 0 .375-.223l.02-.019.003-.003.001-.001c.001-.001.002-.002-.527-.534-.529-.531-.528-.532-.527-.533v-.001l.04-.036a.5.5 0 0 1 .09-.056c.031-.012.019.004-.059-.008-.173-.027-.665-.192-1.539-1.061zm-.552-6.38C5.725.858 4.274.693 3.385 1.577l1.057 1.064c.204-.202.595-.2.816.095zM3.25 4.048c-.007-.113.043-.265.21-.431L2.404 2.553c-.375.372-.689.92-.651 1.58zm8.72 7.355c-.139.138-.265.194-.368.204l.14 1.493c.54-.05.974-.323 1.287-.633zM7.078 5.53c.72-.715.768-1.84.17-2.64l-1.201.9a.515.515 0 0 1-.026.677zm5.617 4.146c.358.195.384.627.166.844l1.057 1.064c.981-.976.67-2.586-.507-3.225zm-1.818-.578a.525.525 0 0 1 .624-.07l.716-1.319a2.02 2.02 0 0 0-2.397.326z"
    />
  </svg>
);
export default SvgPhoneOrange;
