import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "flowbite-react";
import { isEmpty } from "lodash-es";

import { validateImageURL } from "../utils";
import UploadPhotoComponent from "./ModalBody/UploadPhoto";
import CropImageComponent from "./ModalBody/CropImage";
import ToastNotification from "../../../../../../components/ToastNotification";
import { MagazineImageContextType } from "../../../../../../../context/MagazineImageContext";
import { allowedUploadImages, imageAllowedDimensions } from "../../../../rmMagazineReport/MagazineDetails/constants";
import { useMagazineImageContext } from "../../../../../../../context/MagazineImageHook";
import env from "../../../../../../../environments";
import { createUploads } from "../../../../../../api/uploads";

const BrokerLogoModal = ({ type, onSuccess, onCancel, title = "Upload Photo", uploadText = "Browse Files" }) => {
  const [openModal, setOpenModal] = useState(false);

  const {
    magazineType,
    imageSize,
    imageSrc,
    uploadedImage,
    isModalOpen,
    magazineParams,
    setUploadImageSource,
    setUploadImageSize,
    setSelectedUploadedImage,
    setShowModal,
    setImageType,
  } = useMagazineImageContext() as MagazineImageContextType;

  useEffect(() => {
    setOpenModal(isModalOpen);
  }, [isModalOpen]);

  useEffect(() => {
    if (!isEmpty(uploadedImage) && type === magazineType) {
      setUploadImageSource(null);
      setSelectedUploadedImage("");
      onSuccess(uploadedImage);
    }
  }, [uploadedImage, type]);

  const onCloseModal = useCallback(() => {
    setUploadImageSize({ width: 0, height: 0 });
    setUploadImageSource("");
    setImageType("");
    setShowModal(false);
    setSelectedUploadedImage("");
    // Check if in backcover upload photo, need to clear the image, prevent double click button before modal is closed.open.
    if (["backInsideCoverImageOption4", "backCoverImageOption4", "backCoverImageOption5"].includes(magazineType)) {
      onCancel();
    }
  }, [magazineType]);

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files) {
        const file = files[0];
        if (file) {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = async function () {
            setUploadImageSize({ width: img.width, height: img.height });
            await validateImageURL(img.width, img.height, magazineType)
              .then(async () => {
                if (!isEmpty(img.src)) {
                  if (
                    img.width === imageAllowedDimensions[magazineType].width &&
                    img.height === imageAllowedDimensions[magazineType].height
                  ) {
                    const file_extension = file.type.split("/")[1];
                    const req = {
                      payload: {
                        bucket: env.DS_BUCKET,
                        isPrivate: false,
                        ext: file_extension,
                        contentType: file.type,
                        path: "",
                        customer: {
                          _id: magazineParams?.customerId,
                        },
                      },
                      callbackTargetKey: "uploadUrl",
                      callbackPayload: file,
                    };
                    const response = await createUploads(req);

                    if (response) {
                      ToastNotification({
                        type: "success",
                        message: "Image upload successful.",
                      });
                      setSelectedUploadedImage(response);
                    } else {
                      setUploadImageSource(null);
                      setUploadImageSize({ width: 0, height: 0 });
                      setImageType("");
                      ToastNotification({
                        type: "error",
                        message: "Image upload failed.",
                      });
                    }
                    e.target.value = "";
                    return;
                  }
                  setUploadImageSource(img.src);
                }
              })
              .catch((error) => {
                e.target.value = "";
                setUploadImageSource(null);
                setUploadImageSize({ width: 0, height: 0 });
                setImageType("");
                ToastNotification({
                  type: "error",
                  message: error.message,
                });
              });
          };
          img.onerror = function () {
            ToastNotification({
              type: "error",
              message: `not a valid file: ${file.type}`,
            });
          };
          if (img.complete) {
            e.target.value = "";
          }
        }
      }
    },
    [imageSrc, imageSize, magazineType]
  );

  if (!allowedUploadImages.includes(magazineType)) return;

  if (
    !isEmpty(imageSrc) &&
    !isEmpty(imageSize) &&
    imageSize.width > imageAllowedDimensions[magazineType].width &&
    imageSize.height > imageAllowedDimensions[magazineType].height
  ) {
    return (
      <Modal
        show={openModal}
        onClose={() => onCloseModal()}
        theme={{
          root: {
            sizes: {
              "2xl": "max-w-4xl",
            },
          },
        }}
      >
        <CropImageComponent title={title} imageType={magazineType} />
      </Modal>
    );
  }

  return (
    <>
      <Modal show={openModal} onClose={() => onCloseModal()}>
        <UploadPhotoComponent
          type={magazineType}
          title={title}
          uploadText={uploadText}
          handleFileChange={handleFileChange}
        />
      </Modal>
    </>
  );
};

export default BrokerLogoModal;
