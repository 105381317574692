import React from 'react';

type imageSizeProps = {
  width: number;
  height: number;
}

export type MagazineImageContextType = {
  setUploadImageSource: (imageSrc: string | null) => void;
  setUploadImageSize: (imageSize: imageSizeProps) => void;
  setSelectedUploadedImage: (uploadedImage: string) => void;
  setImageType: (imageType: string) => void;
  setMagazineParams: (magazineParams: object) => void;
  setShowModal: (isModalOpen: boolean) => void;
  imageSize: imageSizeProps
  imageSrc: string;
  uploadedImage: object;
  magazineType: string;
  magazineParams?: {
    [key: string]: string | number;
    customerId: string;
    month: string;
    year: number
  };
  isModalOpen: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MagazineImageContext = React.createContext<MagazineImageContextType | null>(null);

const MagazineImageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [imageSize, saveMagazineImage] = React.useState<imageSizeProps>();
  const [imageSrc, saveMagazineImageSrc] = React.useState<string | null>(null);
  const [uploadedImage, saveCroppedImage] = React.useState<object>();
  const [imageType, saveImageType] = React.useState<string | null>(null);
  const [magazineParams, saveMagazineParams] = React.useState<object>();
  const [isStateModalOpen, setStateShowModal] = React.useState<boolean>(false);


  const setShowModal = (isModalOpen: boolean) => { 
    setStateShowModal(isModalOpen);
  }

  /**
   * Set image source
   * 
   * @param imageSrc 
   */
  const setUploadImageSource = (imageSrc: string) => {
    saveMagazineImageSrc(imageSrc);
  };

  /**
   * Set image size dimensions
   * 
   * @param imageSize 
   */
  const setUploadImageSize = (imageSize: imageSizeProps) => {
    saveMagazineImage(imageSize);
  }

  /**
 * Set image type
 * 
 * @param imageSrc 
 */
  const setImageType = (imageSrc: string) => {
    saveImageType(imageSrc);
  };

  /**
   * Set cropped image data
   * 
   * @param uploadedImage 
   */
  const setSelectedUploadedImage = (uploadedImage: object) => {
    saveCroppedImage(uploadedImage);
  }

    /**
   * Set cropped image data
   * 
   * @param uploadedImage 
   */
    const setMagazineParams = (uploadedImage: object) => {
      saveMagazineParams(uploadedImage);
    }

  /**
   * Image context provider
   * 
   */
  return <MagazineImageContext.Provider value={{
    setUploadImageSource,
    setUploadImageSize,
    setSelectedUploadedImage,
    setImageType,
    setMagazineParams,
    setShowModal,
    imageSize,
    imageSrc,
    uploadedImage,
    magazineType: imageType,
    magazineParams,
    isModalOpen: isStateModalOpen
  }}>{children}</MagazineImageContext.Provider>;
};

export default MagazineImageProvider;