import React from "react";
import { Success, Xmark } from '../../../components/icons';

interface BadgeProps {
  value: string;
  isSuccess: boolean;
}


export default function Badge({
  value,
  isSuccess
}: BadgeProps) {
  const colorBadge = isSuccess ? 'bg-success-50 text-success-500' : 'bg-danger-50 text-danger-500';
  return (
    <div className={`flex items-center rounded-md px-2 py-1 w-fit ${colorBadge}`}>
      { isSuccess ? <Success /> : <Xmark /> }
      <p className="ml-2 capitalize text-xs font-semibold">{value}</p>
    </div>    
  );
}