import { CustomFlowbiteTheme } from 'flowbite-react';

export const customTheme: CustomFlowbiteTheme = {
  pagination: {
    base: 'flex overflow-x-auto sm:justify-center',
    layout: {
      table: {
        base: 'text-sm text-gray-700',
        span: 'font-semibold text-gray-900',
      },
    },
    pages: {
      base: 'inline-flex items-center -space-x-px',
      showIcon: 'inline-flex',
      previous: {
        base: 'mr-2 rounded-md border border-gray-200 pl-3.5 pr-4 py-1 bg-white text-neutral-800 text-xs font-bold leading-none',
        icon: 'h-4 w-4 mt-[5.5px] mr-1',
      },
      next: {
        base: 'ml-2 rounded-md border border-gray-200 pl-3.5 pr-4 py-1 bg-white text-neutral-800 text-xs font-bold leading-none',
        icon: 'h-4 w-4 mt-[5.5px] ml-1',
      },
      selector: {
        base: 'px-3.5 mx-1 py-1 text-neutral-800 text-xs font-bold leading-none',
        active: 'bg-neutral-800 text-neutral-50 rounded-md',
        disabled: 'cursor-not-allowed opacity-50',
      },
    },
  },
};

export const customCheckboxTheme: CustomFlowbiteTheme = {
  checkbox: {
    root: {
      base: 'h-4 w-4 rounded border border-gray-300 bg-gray-100 focus:ring-1 dark:border-gray-600 dark:bg-gray-700',
      color: {
        default:
          'text-primary-500 focus:ring-primary-500 dark:ring-offset-gray-800 dark:focus:ring-primary-500',
      },
    },
  },
};
