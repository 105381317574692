import React, {
  createContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import {
  BookPackageProps,
  BookPackageOptionProps,
  BenefitsProps,
} from './types';

interface OfferProviderProps {
  children: React.ReactNode;
}

export interface OfferContextData {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  templateId: string;
  setTemplateId: Dispatch<SetStateAction<string>>;
  offerName?: string;
  setOfferName?: Dispatch<SetStateAction<string>>;
  offerCode?: string;
  setOfferCode?: Dispatch<SetStateAction<string>>;
  offerCountry?: string[];
  setOfferCountry?: Dispatch<SetStateAction<string[]>>;
  offerProduct?: string;
  setOfferProduct?: Dispatch<SetStateAction<string>>;
  productValueInCents?: number;
  setProductValueInCents?: Dispatch<SetStateAction<number>>;
  packagesOptions?: BookPackageOptionProps[];
  setPackagesOptions?: Dispatch<SetStateAction<BookPackageOptionProps[]>>;
  booksOptions?: BookPackageOptionProps[];
  setBooksOptions?: Dispatch<SetStateAction<BookPackageOptionProps[]>>;
  offerBookPackages?: BookPackageProps[];
  setOfferBookPackages?: Dispatch<SetStateAction<BookPackageProps[]>>;
  offerBookCategories?: BookPackageProps[];
  setOfferBookCategories?: Dispatch<SetStateAction<BookPackageProps[]>>;
  offerBookPackageNames?: string;
  setOfferBookPackageNames?: Dispatch<SetStateAction<string[]>>;
  offerTrialEnabled?: boolean;
  setOfferTrialEnabled?: Dispatch<SetStateAction<boolean>>;
  offerTrialPrice?: string;
  setOfferTrialPrice?: Dispatch<SetStateAction<string>>;
  offerTrialCredits?: number;
  setOfferTrialCredits?: Dispatch<SetStateAction<number>>;
  productHeroImage: string;
  setProductHeroImage: Dispatch<SetStateAction<string>>;
  productBackgroundImage: string;
  setProductBackgroundImage: Dispatch<SetStateAction<string>>;
  productTitle: string;
  setProductTitle: Dispatch<SetStateAction<string>>;
  productDescription?: string;
  setProductDescription?: Dispatch<SetStateAction<string>>;
  benefits: BenefitsProps[];
  setBenefits: Dispatch<SetStateAction<BenefitsProps[]>>;
  templateVariables: { handle: string; value: string; isObject?: boolean }[];
  setTemplateVariables: Dispatch<
    SetStateAction<{ handle: string; value: string; isObject?: boolean }[]>
  >;
  emphasis: string[];
  setEmphasis: Dispatch<SetStateAction<string[]>>;
  selectedTemplate: 'template1' | 'template2';
  setSelectedTemplate: Dispatch<SetStateAction<'template1' | 'template2'>>;
  defaultPackages: null | [];
  setDefaultPackages: Dispatch<SetStateAction<null | []>>
  selectedBooks: string[];  
  setSelectedBooks: Dispatch<SetStateAction<string[]>>;
  selectedPackageNames: string[];  
  setSelectedPackageNames: Dispatch<SetStateAction<string[]>>
  availableBookPackages: string[];  
  setAvailableBookPackages: Dispatch<SetStateAction<string[]>>
  allowedCategories: string[];  
  setAllowedCategories: Dispatch<SetStateAction<string[]>>
  bookIdPackages: null | string[]; 
}

export const OfferContext = createContext<Partial<OfferContextData>>({});

export const OfferProvider = ({ children }: OfferProviderProps) => {
  const [step, setStep] = useState<number>(1);
  const [templateId, setTemplateId] = useState<string>(
    '668b9ee38cd3037dded6d8fd',
  );

  const [offerName, setOfferName] = useState<string>('');
  const [offerCode, setOfferCode] = useState<string>('');
  const [offerCountry, setOfferCountry] = useState<string[]>([]);
  const [offerProduct, setOfferProduct] = useState<string>('');
  const [productValueInCents, setProductValueInCents] = useState<number>(0);
  const [offerBookCategories,   setOfferBookCategories] = useState<
  BookPackageProps[]
  >([]);
  const [offerBookPackages, setOfferBookPackages] = useState<
    BookPackageProps[]
  >([]);
  const [offerBookPackageNames, setOfferBookPackageNames] =
    useState<string>('');
  const [offerTrialEnabled, setOfferTrialEnabled] = useState<boolean>(false);
  const [offerTrialPrice, setOfferTrialPrice] = useState<string>('');
  const [offerTrialCredits, setOfferTrialCredits] = useState<number | null>(
    null,
  );
  const [benefits, setBenefits] = useState<BenefitsProps[]>([]);
  const [productHeroImage, setProductHeroImage] = useState<string>('');
  const [productBackgroundImage, setProductBackgroundImage] =
    useState<string>('');
  const [productTitle, setProductTitle] = useState<string>('');
  const [productDescription, setProductDescription] = useState<string>('');
  const [templateVariables, setTemplateVariables] = useState<
    { handle: string; value: string; isObject?: boolean }[]
  >([]);
  const [emphasis, setEmphasis] = useState<string[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<
    'template1' | 'template2'
  >('template1');
  const [packagesOptions, setPackagesOptions] = useState<
    BookPackageOptionProps[]
  >([]);
  const [booksOptions, setBooksOptions] = useState<
    BookPackageOptionProps[]
  >([]);
  const [defaultPackages, setDefaultPackages] = useState<null | []>(null)
  const [bookIdPackages, setBookIdPackages] = useState<string[] | null>(null)
  
  const [availableBookPackages, setAvailableBookPackages] = useState<string[]>(
    [],
  );
  const [allowedCategories, setAllowedCategories] = useState<string[]>(
    [],
  );
  const [selectedBooks, setSelectedBooks] = useState<string[]>(
    [],
  );
  const [selectedPackageNames, setSelectedPackageNames] = useState<string[]>(
    [],
  );

  useEffect(() => {
    if (offerBookPackages && offerBookPackages.length > 0) {
      const bookIds = offerBookPackages.map(({_id}) => _id);
      setBookIdPackages(bookIds);
    }
  }, [offerBookPackages]);

  const contextData: OfferContextData = {
    step,
    setStep,
    templateId,
    setTemplateId,
    offerName,
    setOfferName,
    offerCode,
    setOfferCode,
    offerCountry,
    setOfferCountry,
    offerProduct,
    setOfferProduct,
    productValueInCents,
    setProductValueInCents,
    packagesOptions,
    setPackagesOptions,
    booksOptions,
    setBooksOptions,
    offerBookCategories,
    setOfferBookCategories,
    offerBookPackages,
    setOfferBookPackages,
    offerBookPackageNames,
    setOfferBookPackageNames,
    offerTrialEnabled,
    setOfferTrialEnabled,
    offerTrialPrice,
    setOfferTrialPrice,
    offerTrialCredits,
    setOfferTrialCredits,
    benefits,
    setBenefits,
    productBackgroundImage,
    setProductBackgroundImage,
    productHeroImage,
    setProductHeroImage,
    productTitle,
    setProductTitle,
    productDescription,
    setProductDescription,
    templateVariables,
    setTemplateVariables,
    emphasis,
    setEmphasis,
    selectedTemplate,
    setSelectedTemplate,
    defaultPackages,
    setDefaultPackages,
    availableBookPackages,
    setAvailableBookPackages,
    allowedCategories,
    setAllowedCategories,
    selectedBooks,
    setSelectedBooks,
    selectedPackageNames,
    setSelectedPackageNames,
    bookIdPackages,
  };

  const memoizedContextData = useMemo(
    () => contextData,
    [
      step,
      templateId,
      offerName,
      offerCode,
      offerCountry,
      offerProduct,
      productValueInCents,
      packagesOptions,
      booksOptions,
      offerBookCategories,
      offerBookPackages,
      offerTrialEnabled,
      offerTrialPrice,
      offerTrialCredits,
      benefits,
      productBackgroundImage,
      productHeroImage,
      productTitle,
      productDescription,
      templateVariables,
      emphasis,
      selectedTemplate,
      defaultPackages,
      selectedBooks,
      selectedPackageNames,
      availableBookPackages,
      allowedCategories,
      bookIdPackages,
    ],
  );

  return (
    <OfferContext.Provider value={memoizedContextData}>
      {children}
    </OfferContext.Provider>
  );
};
