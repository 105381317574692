import * as React from "react";
import type { SVGProps } from "react";
const SvgMembers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <circle cx={7.5} cy={5.5} r={3.333} stroke="#757575" strokeWidth={1.5} />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M12.5 8a2.5 2.5 0 0 0 0-5"
    />
    <ellipse
      cx={7.5}
      cy={14.666}
      stroke="#757575"
      strokeWidth={1.5}
      rx={5.833}
      ry={3.333}
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M15 12.167c1.462.32 2.5 1.132 2.5 2.083 0 .858-.845 1.603-2.083 1.976"
    />
  </svg>
);
export default SvgMembers;
