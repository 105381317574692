import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useMutation } from "react-query";

import {
  generateReferralMagazineAdmin,
  getGeneratedMagazineAdmin,
} from "../../../../api/rm-magazine-report";
import MagazineEditor from "./components/MagazineEditor";
import { rmMagazineReportLink, numberOfSecondsInAMinute } from "../constants";
import { SvgBreadCrumbArrow } from "../../../../../components/icons";
import ContentMagazineDetails from "./components/ContentMagazineDetails";
import PublishLoader from "../../../../components/PublishLoader";
import { stat } from "fs";
import { ToastContainer } from "react-toastify";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "afy-flip-book": {
        key: string;
        pdfurl: string;
      };
    }
  }
}

type GenerateReferralMagazineData = {
  payload: {
    month: string | undefined;
    year: string | undefined;
    customerId: string | undefined;
    userEmail?: string;
  };
  isPreview: boolean;
};

type Querykey = [unknown, string, string, string];

function MagazineDetails() {
  const [failCheck, setFailCheck] = useState(false);
  const location = useLocation();
  const params = useParams();
  const queryParams = new URLSearchParams(location.search);

  const { year, month, customerId } = params;
  const status = queryParams.get("status");

  const {
    mutateAsync: getGeneratedMagazine,
    isLoading: isGeneratingMagazineData,
  } = useMutation(
    (payload: Querykey) => getGeneratedMagazineAdmin({ queryKey: payload }),
    {
      onError: (error) => {
        const err = error?.response?.data?.errors[0];
        if (err === "generated magazine not found") {
          setFailCheck(true);
        }
      },
    }
  );

  useEffect(() => {
    if (status !== "generate" || !failCheck) return;

    const payload: GenerateReferralMagazineData = {
      payload: {
        month: month,
        year: year,
        customerId: customerId,
      },
      isPreview: false,
    };
    updateGeneratedMagazine(payload);
  }, [failCheck, status]);

  const {
    mutate: updateGeneratedMagazine,
    isLoading: updatingGeneratedMagazine,
  } = useMutation(
    (payload: GenerateReferralMagazineData) =>
      generateReferralMagazineAdmin(payload),
    {
      onSuccess: () => {
        setTimeout(() => {
          const url = location.pathname;
          window.location.href = url;
          window.location.reload();
        }, numberOfSecondsInAMinute);
      },
      onError: (error) => {},
    }
  );

  async function fnGeneratedMagazinesData(data: Querykey) {
    await getGeneratedMagazine(data);
    const url = location.pathname;
    window.location.href = url;
  }

  if (status === "generate") {
    fnGeneratedMagazinesData([
      "getMagazineAdmin",
      year,
      month,
      customerId,
    ] as Querykey);
  }

  const renderDetailsComponent = () => {
    switch (status) {
      case "edit":
        return <MagazineEditor />;
      default:
        return <ContentMagazineDetails />;
    }
  };

  if (updatingGeneratedMagazine || isGeneratingMagazineData) {
    const message = isGeneratingMagazineData
      ? "Generating magazine"
      : "Loading magazine data";
    return (
      <div className="pt-10 w-full h-[calc(100%-200px)] px-8">
        <div className="flex flex-col w-full h-full">
          <div className="w-full">
            <div className="text-sm flex items-center gap-2 bg-none">
              <Link to={rmMagazineReportLink}>
                <span className="text-neutral-600">RM Magazine report</span>
              </Link>{" "}
              <SvgBreadCrumbArrow />{" "}
              <span className="text-neutral-700 font-bold">Details</span>
            </div>
          </div>
          <div className="flex mt-8 h-full relative">
            <PublishLoader message={message} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="pt-10 w-full h-full px-4 lg:px-0">
        <div className="flex flex-col w-full h-full">
          <div className="w-full">
            <div className="text-sm flex items-center gap-2 bg-none">
              <Link to={rmMagazineReportLink}>
                <span className="text-neutral-600">RM Magazine report</span>
              </Link>{" "}
              <SvgBreadCrumbArrow />{" "}
              <span className="text-neutral-700 font-bold">Details</span>
            </div>
          </div>
          <div className="flex mt-8 h-full relative">
            {renderDetailsComponent()}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default MagazineDetails;
