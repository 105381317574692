import * as React from "react";
const SvgCancelIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g stroke="#E4483D" strokeWidth={1.5} clipPath="url(#cancel-icon_svg__a)">
      <path strokeLinecap="round" d="m12.333 3.667-8.666 8.666" />
      <circle cx={8} cy={8} r={6.667} />
    </g>
    <defs>
      <clipPath id="cancel-icon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCancelIcon;
