import * as React from "react";
import type { SVGProps } from "react";
const SvgOpenBook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Iconer">
      <g id="Vector">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.00006 13.4531V4.16492C2.00006 3.24977 2.73864 2.50635 3.65206 2.56249C4.46571 2.6125 5.42797 2.71134 6.16673 2.90628C7.04107 3.13701 8.08015 3.62842 8.90194 4.06296C9.15786 4.19829 9.43001 4.29215 9.70854 4.34506V16.9939C9.45604 16.9383 9.20969 16.8485 8.97707 16.7245C8.14417 16.2805 7.06794 15.7657 6.16673 15.5279C5.43614 15.3351 4.48696 15.2363 3.67911 15.1858C2.7551 15.128 2.00006 14.3789 2.00006 13.4531ZM4.65179 6.89374C4.31692 6.81002 3.97759 7.01362 3.89387 7.3485C3.81015 7.68337 4.01375 8.0227 4.34862 8.10642L7.68196 8.93975C8.01683 9.02347 8.35616 8.81987 8.43988 8.485C8.5236 8.15013 8.32 7.81079 7.98513 7.72708L4.65179 6.89374ZM4.65179 10.2271C4.31692 10.1434 3.97759 10.347 3.89387 10.6818C3.81015 11.0167 4.01375 11.356 4.34862 11.4398L7.68196 12.2731C8.01683 12.3568 8.35616 12.1532 8.43988 11.8183C8.5236 11.4835 8.32 11.1441 7.98513 11.0604L4.65179 10.2271Z"
          fill="#252525"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9585 16.9939C11.2109 16.9382 11.4572 16.8484 11.6897 16.7245C12.5226 16.2805 13.5989 15.7657 14.5001 15.5279C15.2307 15.3351 16.1798 15.2363 16.9877 15.1858C17.9117 15.128 18.6667 14.3789 18.6667 13.4531V4.11108C18.6667 3.21737 17.9615 2.48376 17.0684 2.51487C16.1279 2.54763 14.9558 2.6451 14.0834 2.90628C13.327 3.13272 12.4461 3.5869 11.7283 4.00238C11.4854 4.14293 11.2258 4.24617 10.9585 4.31164V16.9939ZM16.3185 8.10642C16.6533 8.0227 16.8569 7.68337 16.7732 7.3485C16.6895 7.01362 16.3502 6.81002 16.0153 6.89374L12.682 7.72708C12.3471 7.81079 12.1435 8.15013 12.2272 8.485C12.3109 8.81987 12.6503 9.02347 12.9851 8.93975L16.3185 8.10642ZM16.3185 11.4398C16.6533 11.356 16.8569 11.0167 16.7732 10.6818C16.6895 10.347 16.3502 10.1434 16.0153 10.2271L12.682 11.0604C12.3471 11.1441 12.1435 11.4835 12.2272 11.8183C12.3109 12.1532 12.6503 12.3568 12.9851 12.2731L16.3185 11.4398Z"
          fill="#252525"
        />
      </g>
    </g>
  </svg>
);
export default SvgOpenBook;
