/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { validateImageURL } from "../../../components/utils";
import { useMagazineImageContext } from "../../../../../../../../context/MagazineImageHook";
import ToastNotification from "../../../../../../../components/ToastNotification";
import { MagazineImageContextType } from "../../../../../../../../context/MagazineImageContext";

type GalleryComponentProps = {
  type: string;
  photoGallery: [];
};

const GalleryComponent = ({ type, photoGallery }: GalleryComponentProps) => {
  const { setUploadImageSource, setUploadImageSize, setSelectedUploadedImage, setShowModal } =
    useMagazineImageContext() as MagazineImageContextType;

  const onImageSelect = (src: string) => {
    if (!src) return;

    const img = new Image();
    // Create image source.
    img.src = src;
    img.onload = async function () {
      setUploadImageSize({ width: img.width, height: img.height });
      await validateImageURL(img.width, img.height, type)
        .then((data) => {
          setUploadImageSource(null);
          setShowModal(false);
          setSelectedUploadedImage(img.src);
        })
        .catch((error) => {
          ToastNotification({
            type: "error",
            message: error.message,
          });
          setUploadImageSource(null);
          setUploadImageSize({ width: 0, height: 0 });
        });
    };
  };

  return (
    <div className="w-full mb-6 min-h-[295px] max-h-[410px]">
      <ul className="w-full grid grid-cols-4 gap-4 m-0 pb-5">
        {photoGallery.map(({ downloadUrl, _id }) => (
          <li key={_id}>
            <button
              onClick={(e) => onImageSelect(downloadUrl)}
              className="h-36 w-36 cursor-pointer hover:opacity-70 transition-all delay-100 ease-in-out p-0"
            >
              <img src={downloadUrl} alt="Photo" className="w-full h-full object-contain rounded-lg border p-0" />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GalleryComponent;
