import classNames from 'classnames';
import React from 'react';

interface BoxProps {
  children: React.ReactNode;
  className?: string;
}

const Box = ({ children, className }: BoxProps) => (
  <div className={classNames('bg-white w-full h-fit rounded-lg shadow-box mb-2', className)}>
    {children}
  </div>
);

export default Box;
