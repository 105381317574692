import React, { useEffect, useRef } from "react";
import get from "lodash/get";

import { MagazineFromDBContext } from "../../../../../../../context/MagazineFromDBContext";
import Button from "../../../../../../components/Button";
import { ListingDetailsComponent, QRCodeComponent, RenderOptionsComponent, SocialMediaFormComponent } from "../StepsComponent";
import isEmpty from "lodash/isEmpty";
import { frontCoverStripOptionFormFields, frontCoverStripOptionsHeading } from "./constants";
import { CoverOptionType, ObjectType, SelectionsType } from "../../types";

const FrontCover = ({ handleImageLightBox, frontCoverDesign, frontCoverStrip, updateMagazineData }) => {
  const { ctxMagazineFromDB } = React.useContext(MagazineFromDBContext);

  const { magazine = {}, handleUpdateMagazine, magazineDataFormik, previousMagazineData } = updateMagazineData || {};

  const { values = {}, setFieldValue } = magazineDataFormik || {};
  const selectedCoverOption = get(magazine, "frontCoverDesign");
  const selectedCoverStripOption = get(magazine, "frontCoverStrip");

  const lastSelectedCoverStripOption = useRef(selectedCoverStripOption?.formKeyword);

  /**
   * Set initial value of selected option
   */
  useEffect(() => {
    setFieldValue("frontCover.selectedOption", selectedCoverStripOption?.formKeyword);
  }, []);

  /**
   * Update magazineDataFormik values on selected option change
   * If cancel button is clicked, reset the form fields to previous values
   * If magazine data is present in context, set the values from context
   */
  useEffect(() => {
    const currentSelectedOption = get(selectedCoverStripOption, "formKeyword");
    const lastSelectedOption = get(lastSelectedCoverStripOption, "current");

    if (lastSelectedOption !== currentSelectedOption) {
      lastSelectedCoverStripOption.current = currentSelectedOption;
      const initialValueOfSelctedOptionForm = frontCoverStripOptionFormFields[currentSelectedOption];
      setFieldValue("frontCover._formFields", initialValueOfSelctedOptionForm);
      setFieldValue("frontCover.selectedOption", currentSelectedOption);

      if (!isEmpty(ctxMagazineFromDB)) {
        const objectResponse: any = ctxMagazineFromDB[0];
        const { selections }: SelectionsType = objectResponse as SelectionsType;
        const coverSelection = selections.find((selection: ObjectType) => selection.page === 1);
        if (!isEmpty(coverSelection)) {
          coverSelection.dynamicFields.map((field: ObjectType) => {
            const { keyword, value } = field;
            setFieldValue(`frontCover._formFields.${keyword}`, value);
          });
        }
      }
    }
  }, [selectedCoverStripOption, magazineDataFormik, ctxMagazineFromDB]);

  useEffect(() => {
    if (!isEmpty(ctxMagazineFromDB)) {
      const objectResponse: any = ctxMagazineFromDB[0];
      const { selections }: SelectionsType = objectResponse as SelectionsType;
      const coverSelection = selections.find((selection: ObjectType) => selection.page === 1);

      if (!isEmpty(coverSelection)) {
        const {
          extraHtml: { formKeyword: formStripKeyword },
        } = coverSelection;
        const frontStrip = frontCoverStrip.find((strip: ObjectType) => strip.formKeyword === formStripKeyword);

        if (!isEmpty(frontStrip)) {
          coverSelection.dynamicFields.map((field: ObjectType) => {
            const { keyword, value } = field;
            setFieldValue(`frontCover._formFields.${keyword}`, value);
          });
        }
      }
    }
  }, []);

  const handleCoverOptionChange = (option: CoverOptionType) => {
    handleUpdateMagazine(option, "frontCoverDesign");
    setFieldValue("frontCover.selectedOption", option.formKeyword);
  };

  const handleCoverStripOptionChange = (option: CoverOptionType) => {
    handleUpdateMagazine(option, "frontCoverStrip");
    setFieldValue("frontCover.selectedOption", option.formKeyword);
  };

  /**
   * Reset the form fields to previous values
   * If magazine data is present in context, set the values from context
   * if previous magazine data is present, set the values from previous data
   */
  const onHandleResetFormFields = () => {
    if (!isEmpty(previousMagazineData)) {
      const coverSelection = previousMagazineData.selections.find((selection: ObjectType) => selection.page === 1);

      if (!isEmpty(coverSelection)) {
        const {
          formKeyword,
          extraHtml: { formKeyword: formStripKeyword },
        } = coverSelection;
        const frontCover = frontCoverDesign.find((design: ObjectType) => design.formKeyword === formKeyword);
        const frontStrip = frontCoverStrip.find((strip: ObjectType) => strip.formKeyword === formStripKeyword);

        if (!isEmpty(frontCover)) {
          handleUpdateMagazine(frontCover, "frontCoverDesign");
        }

        if (!isEmpty(frontStrip)) {
          handleUpdateMagazine(frontStrip, "frontCoverStrip");
          setFieldValue("frontCover.selectedOption", formStripKeyword);
        }
      }
    } else if (!isEmpty(ctxMagazineFromDB)) {
      const objectResponse: any = ctxMagazineFromDB[0];
      const { selections }: SelectionsType = objectResponse as SelectionsType;
      const coverSelection = selections.find((selection: ObjectType) => selection.page === 1);

      if (!isEmpty(coverSelection)) {
        const {
          formKeyword,
          extraHtml: { formKeyword: formStripKeyword },
        } = coverSelection;

        const frontCover = frontCoverDesign.find((design: ObjectType) => design.formKeyword === formKeyword);
        const frontStrip = frontCoverStrip.find((strip: ObjectType) => strip.formKeyword === formStripKeyword);

        coverSelection.dynamicFields.map((field: ObjectType) => {
          const { keyword, value } = field;
          setFieldValue(`frontCover._formFields.${keyword}`, value);
        });

        if (!isEmpty(frontCover)) {
          handleUpdateMagazine(frontCover, "frontCoverDesign");
        }

        if (!isEmpty(frontStrip)) {
          handleUpdateMagazine(frontStrip, "frontCoverStrip");
        }
        setFieldValue("currentStep", -1);
      }
    } else {
      handleUpdateMagazine(frontCoverDesign[0], "frontCoverDesign");
      handleUpdateMagazine(frontCoverStrip[0], "frontCoverStrip");
      setFieldValue("frontCover.selectedOption", frontCoverStrip[0].formStripKeyword);
      setFieldValue("currentStep", -1);
    }
  };

  const allowedResolutionsTextLogo = { width: 540, height: 192 };

  const renderAdditionalData = (type) => {
    switch (type) {
      case "frontCoverStrip-option-2":
        return (
          <ListingDetailsComponent
            imageDimensions={allowedResolutionsTextLogo}
            step="frontCover"
            uploadImageType="brokerageLogo"
            uploadImageTitle="Broker company logo"
            propertyKey="brokerageLogo"
            presentImageLocation={get(values, "frontCover._formFields.brokerageLogo")}
            formikHandler={magazineDataFormik}
            hasInputFields={false}
            hasTextArea={false}
          />
        );
      case "frontCoverStrip-option-3":
        return <SocialMediaFormComponent formikHandler={magazineDataFormik} step="frontCover" />;
      case "frontCoverStrip-option-4":
        return <QRCodeComponent formikHandler={magazineDataFormik} step="frontCover" />;
      default:
        return null;
    }
  };

  const selectedCoverStripOptionName = get(selectedCoverStripOption, "name");
  const selectedOptionTitle = frontCoverStripOptionsHeading[get(selectedCoverStripOption, "formKeyword")];

  return (
    <div className="transition-all duration-500">
      <h5 className="text-neutral-800 text-sm font-semibold mt-[37px]">Select front cover</h5>

      <RenderOptionsComponent onImageClick={handleImageLightBox} optionsList={frontCoverDesign} handleChange={handleCoverOptionChange} selectedOption={selectedCoverOption} displayType="magazine" />

      <h5 className="text-neutral-800 text-sm font-semibold mt-[37px]">Select front cover strip</h5>

      <RenderOptionsComponent
        onImageClick={handleImageLightBox}
        optionsList={frontCoverStrip}
        handleChange={handleCoverStripOptionChange}
        selectedOption={selectedCoverStripOption}
        displayType="strip"
      />

      <h5 className="text-neutral-800 text-base font-semibold mt-8 mb-6">
        {selectedOptionTitle && `${selectedCoverStripOptionName}: ${selectedOptionTitle}`}
        {selectedCoverStripOptionName?.toLowerCase() === "option 4" && (
          <span className="overflow-hidden text-neutral-400 text-ellipsis  text-sm font-medium">A special QR code will generate once the order has been placed.</span>
        )}
      </h5>

      <div className="w-full">{renderAdditionalData(selectedCoverStripOption?.formKeyword)}</div>

      <div className="flex gap-2 justify-start mt-6">
        <Button variant="LIGHT" onClick={onHandleResetFormFields}>
          Cancel
        </Button>
        <Button variant="DARK" type="submit" onClick={magazineDataFormik.handleSubmit}>
          Save changes
        </Button>
      </div>
    </div>
  );
};

export default FrontCover;
