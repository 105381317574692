import React, { useState } from "react";
import { useMutation } from "react-query";
import { useDropzone } from "react-dropzone";
import { get, noop } from "lodash-es";

import camera from "../../../assets/images/camera.svg";
import trash from "../../../assets/images/trash.svg";
import { createUploads, uploadImage } from "../../api/uploads";
import Button from "../Button";

interface ImageData {
  url: string;
  name: string;
  size: string;
}

const ImageUploader = ({
  hasErrors = false,
  label = "Upload your headshot photo",
  callback = noop,
  imagePlaceholder = "",
}) => {
  const [avatarURL, setAvatarURL] = useState(imagePlaceholder);
  const [previousImage, setPreviousImage] = useState<ImageData>({
    url: "",
    name: "",
    size: "",
  });
  const [imageProps, setImageProps] = useState({
    name: "",
    size: "",
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (file: any) => {
    if (!file?.[0]) return;
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: any = {
        payload: {
          bucket: "stg-afy-ds-uploads",
          isPrivate: false,
          contentType: file?.[0].type,
          ext: file?.[0].type.replace("image/", ""),
          path: "",
        },
        callbackTargetKey: "uploadUrl",
        callbackPayload: file?.[0],
      };
      const response = await createUploads(req);
      if (response) {
        setAvatarURL(response);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const convertBytes = (bytes: number) => {
    let convertedBytes = "";
    if (bytes < 50000) convertedBytes = `${(bytes / 1000).toFixed(1)} KB`;
    else convertedBytes = `${(bytes / (1000 * 1000)).toFixed(1)} MB`;
    return convertedBytes;
  };

  const { mutateAsync: mutateImageUpload } = useMutation(uploadImage, {
    onSuccess: ({ Location: url }) => {
      callback(url);
    },
    onError: ({ response }) => {
      const error = get(response, "data.error", "Error");
      throw new Error(error);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleConfirm = async (newFile: any) => {
    const file = new Blob([newFile], { type: newFile.type });
    const formData = new FormData();
    formData.append("file", file);
    mutateImageUpload(formData);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: { "image/jpeg": [".jpeg", ".jpg"], "image/png": [".png"] },
    onDrop: (res) => {
      handleSubmit(res);
      handleConfirm(res);
      setImageProps({
        name: res[0].name,
        size: convertBytes(res[0].size),
      });
    },
  });

  if (avatarURL)
    localStorage.setItem(
      "avatar",
      JSON.stringify({
        url: avatarURL,
        name: imageProps.name,
        size: imageProps.size,
      })
    );

  return (
    <div
      className="flex justify-between items-center mb-8 dropzone"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {previousImage || avatarURL ? (
        <div className="flex items-center justify-between w-full h-28 text-base p-14 px-12 bg-white border border-gray-300 rounded-md">
          <div className="flex items-center gap-10">
            <div className="w-20 h-20 flex justify-center items-center cursor-pointer">
              <img src={avatarURL || previousImage.url} alt="author-cover" />
            </div>
            <div className="px-10 py-20 text-base font-normal">
              <span>{imageProps.name || previousImage.name}</span>
              <br />
              <span className="text-gray-400">
                {imageProps.size || previousImage.size}
              </span>
            </div>
          </div>
          <div>
            <Button
              onClick={() => {
                setAvatarURL("");
                setPreviousImage({ url: "", name: "", size: "" });
              }}
            >
              <img className="mr-4 cursor-pointer" src={trash} alt="trash" />
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-2 w-full h-28 text-base p-14 px-12 border border-dashed border-gray-300 border-2 rounded-md cursor-pointer">
          <img src={camera} alt="camera" />
          <br />
          <span className="text-gray-400">{label}</span>
        </div>
      )}
      {hasErrors ? <span>Invalid file type</span> : null}
    </div>
  );
};

export default ImageUploader;