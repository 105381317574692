import * as React from "react";
import type { SVGProps } from "react";
const SvgReportsActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="#252525"
      fillRule="evenodd"
      d="M2.887 3.387c-1.22 1.22-1.22 3.185-1.22 7.113s0 5.893 1.22 7.113 3.185 1.22 7.113 1.22 5.893 0 7.113-1.22 1.22-3.184 1.22-7.113c0-3.928 0-5.892-1.22-7.113-1.22-1.22-3.185-1.22-7.113-1.22s-5.893 0-7.113 1.22m11.76 5.847a.625.625 0 0 0-.96-.8l-1.498 1.796c-.309.371-.499.597-.655.738a.6.6 0 0 1-.145.105l-.006-.003h-.003a.6.6 0 0 1-.136-.102c-.157-.141-.346-.367-.655-.738l-.244-.292c-.274-.328-.52-.625-.751-.833-.25-.226-.567-.428-.983-.428s-.732.202-.983.428c-.23.208-.477.505-.75.833l-1.525 1.829a.625.625 0 1 0 .96.8l1.498-1.797c.309-.37.499-.596.655-.737a.6.6 0 0 1 .142-.104l.003-.002.01.004a.6.6 0 0 1 .135.102c.157.141.346.366.655.737l.244.293c.274.328.52.624.751.832.25.227.567.429.983.429s.732-.202.983-.428c.23-.209.477-.505.75-.833z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgReportsActive;
