// Flowbyte React base Theme:: https://flowbite-react.com/docs/components/forms#text-area-theme

const defaultTextAreaTheme = {
  base:
    'bg-[white!important] block w-full rounded-lg border text-sm disabled:cursor-not-allowed disabled:opacity-50',
  colors: {
    gray:
      'border-neutral-300 bg-white text-neutral-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-neutral-600 dark:bg-neutral-700 dark:text-white dark:placeholder-neutral-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500',
  },
};

export { defaultTextAreaTheme };
