import * as React from "react";
import type { SVGProps } from "react";
const SvgHomeLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={29}
    fill="none"
    {...props}
  >
    <g fill="#4CC9E1">
      <path d="M8.435 25.513c.92.313 1.902.476 2.91.476a9.1 9.1 0 0 0 2.89-.467v-.901l-5.8-.009zM11.8 11.099l-.01 5.27c.323.089.613.262.836.516l.061.072.063.077.028.05q.044.076.08.156l.05.099.01.037c.241.669-.027 1.427-.701 1.821-.792.459-1.835.235-2.33-.496-.496-.735-.256-1.702.535-2.16.155-.092.32-.147.487-.188l.01-5.256-.76-.002c-.572 3.15-2.14 4.816-4.17 6.917l2.447 5.997 5.802.009 2.47-5.988c-2.22-2.359-3.474-3.903-4.145-6.93z" />
      <path d="M20.389 12.028 11.346.5 2.303 12.028c-3.259 4.155-2.78 9.806 1.135 13.44 2.18 2.022 5.044 3.032 7.907 3.032 2.865 0 5.728-1.01 7.908-3.033 3.916-3.633 4.395-9.284 1.136-13.439m-2.611 12.07a9.2 9.2 0 0 1-3.542 2.037 9.7 9.7 0 0 1-2.89.434 9.8 9.8 0 0 1-2.911-.441 9.2 9.2 0 0 1-3.52-2.03c-3.187-2.954-3.575-7.552-.925-10.93l7.357-9.379 7.356 9.378c2.65 3.38 2.26 7.978-.925 10.931" />
    </g>
  </svg>
);
export default SvgHomeLogo;
