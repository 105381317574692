import React from 'react';

const colors = ['#70D4E7', '#FFC229', '#FF8A33', '#38AB86', '#E75A50', '#3FA1FF'];

const Avatar = ({ name }) => {
    const getInitials = (name) => {
        const parts = name.split(' ');
        const initials = parts.reduce((acc, part, index, array) => {
            if (index === 0 || index === array.length - 1) {
                return acc + part.charAt(0).toUpperCase();
            }
            return acc;
        }, "");
        return initials;
    };

    const initials = getInitials(name);
    const getColorIndex = (initials) => {
        let sum = 0;
        for (let i = 0; i < initials.length; i++) {
            sum += initials.charCodeAt(i);
        }
        return sum % colors.length;
    }

    const colorIndex = getColorIndex(initials);
    const backgroundColor = colors[colorIndex];

    return (
        <div className={`w-[40px] h-[40px] rounded-full flex justify-center items-center text-white text-lg font-semibold`} style={{ backgroundColor }}>
            {initials}
        </div>
    );
};

export default Avatar;
