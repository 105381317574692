import * as React from "react";
const SvgGreenCheck = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#069668"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3.2 8.8 5.994 12l6.984-8"
    />
  </svg>
);
export default SvgGreenCheck;
