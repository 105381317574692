import { useFusionAuth } from "@fusionauth/react-sdk";
import Button from "./modules/components/Button";

export const LoginPage = () => {
  const { login } = useFusionAuth();

  return (
    <div className="flex flex-col justify-center items-center h-screen rounded-sm">
      <div>
        <h1>Welcome, please log in or register</h1>
      </div>
      <div className="border border-gray-300 px-4 py-2 rounded-lg">
        <Button onClick={login}>Login Here</Button>
      </div>
    </div>
  );
};

export default LoginPage;
