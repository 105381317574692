import { useMutation, useQuery } from "react-query";
import {
  getUpsellReport,
  getAdvancedSearch,
  getUpsellReportUnpaginated,
} from "../../../../api/upsell";

export const useUpsellReport = (
  startDate: string,
  endDate: string,
  page: number,
  perPage: number,
  searchQuery: { [key: string]: string[] },
  sortByFilter: { [key: string]: string },
  onSuccess,
  excludeUpsell: boolean
) => {
  return useQuery(
    [
      "upsellReport",
      startDate,
      endDate,
      page,
      perPage,
      searchQuery,
      sortByFilter,
    ],
    () =>
      getUpsellReport(
        startDate,
        endDate,
        page,
        perPage,
        excludeUpsell,
        searchQuery,
        sortByFilter
      ),
    {
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        console.error("Fetch upsell report failed:", error);
      },
    }
  );
};

export const useMutateAdvancedSearch = (field: string, keyword: string) => {
  return useMutation(["getAdvancedSearch", field, keyword], () =>
    getAdvancedSearch(field, keyword)
  );
};

export const useUpsellReportUnpaginated = (
  startDate: string,
  endDate: string,
  excludeUpsell: boolean,
  searchQuery: { [key: string]: string[] }
) => {
  return useQuery(
    ["upsellReportUnpaginated", startDate, endDate, excludeUpsell, searchQuery],
    () => getUpsellReportUnpaginated(startDate, endDate, excludeUpsell, searchQuery),
    {
      onError: (error) => {
        console.error("Fetch full upsell report failed:", error);
      }
    }
  );
};
