import * as React from "react";
const SvgMarketingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m6.313 14.085.75 2.588c.045.151.146.28.283.355a.59.59 0 0 0 .449.051l2.262-.656a.59.59 0 0 0 .351-.283.6.6 0 0 0 .049-.45L7.94 7.013m6.947-3.528 2.865 9.88a.6.6 0 0 1-.093.52.59.59 0 0 1-.469.238l-13.501-.05a.59.59 0 0 1-.57-.43l-.744-2.565a.6.6 0 0 1 .251-.668l11.38-7.265a.584.584 0 0 1 .748.103.6.6 0 0 1 .133.237"
    />
  </svg>
);
export default SvgMarketingIcon;
