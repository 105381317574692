import { useState } from 'react';
import axios from 'axios';
import { getPreSignedUrl } from '../api/productCatalog/actions';

const useFileUpload = (
  onUpload: (url: string) => void,
  onClose: () => void,
) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImageSrc(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  const clearImage = () => {
    setImageSrc(null);
  };

  const showImage = async () => {
    setIsSaving(true);
    try {
      if (!imageSrc) throw new Error('No image selected');
      const file = dataURLtoFile(imageSrc, 'uploaded-image.png');
      const fileUrl = await uploadFile(file);
      setImageSrc(null);
      onUpload(fileUrl);
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  const uploadFile = async (file: File) => {
    try {
      const response = await getPreSignedUrl(
        file.name,
        file.type.split('/')[1],
      );
      const url = response.data.url;

      await axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      const fileUrl = url.split('?')[0];
      return fileUrl;
    } catch (error) {
      console.error(error);
      throw new Error('File upload failed');
    }
  };

  const dataURLtoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(',');
    const mimeMatch = arr[0].match(/^data:(image\/[a-zA-Z0-9-.+]+);base64$/);
    if (!mimeMatch) {
      throw new Error('Invalid data URL');
    }
    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return {
    imageSrc,
    isSaving,
    handleFileChange,
    clearImage,
    showImage,
  };
};

export default useFileUpload;
