import * as React from "react";
const SvgOffboardingActive = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <g fill="#252525">
      <path d="M10 18.833a8.333 8.333 0 0 0 6.318-13.768L4.566 16.817A8.3 8.3 0 0 0 10 18.833M10 2.166a8.333 8.333 0 0 0-6.318 13.768L15.434 4.18A8.3 8.3 0 0 0 10 2.166" />
    </g>
  </svg>
);
export default SvgOffboardingActive;
