import * as React from "react";
import type { SVGProps } from "react";
const SvgOnboarding = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      stroke="#757575"
      strokeWidth={1.5}
      d="M13.333 3.833c1.813.01 2.794.09 3.435.73.732.733.732 1.912.732 4.269v5c0 2.357 0 3.535-.732 4.267-.733.733-1.911.733-4.268.733h-5c-2.357 0-3.536 0-4.268-.733-.732-.732-.732-1.91-.732-4.267v-5c0-2.357 0-3.536.732-4.268.64-.64 1.622-.72 3.435-.731"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7.5 11.667 8.929 13 12.5 9.667"
    />
    <path
      stroke="#757575"
      strokeWidth={1.5}
      d="M6.667 3.417c0-.69.56-1.25 1.25-1.25h4.166c.69 0 1.25.56 1.25 1.25v.833c0 .69-.56 1.25-1.25 1.25H7.917c-.69 0-1.25-.56-1.25-1.25z"
    />
  </svg>
);
export default SvgOnboarding;
