import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required("Enter new password")
    .matches(/[a-z]+/, "One lowercase character")
    .matches(/[A-Z]+/, "One uppercase character")
    .matches(
      /[@$!%*#?&]+|\d+/,
      "Must contain at least one special character or one number"
    )
    .min(8, "Must be 8 characters or more"),
});

export const validationSchemaEmail = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
});
