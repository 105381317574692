import React from "react";

function SvgPlusIcon() {
  return (
    <svg width={30} height={30} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={20} cy={20} r={20} fill="#E9E9E9" />
      <path
        d="M19.8 12.9977L13.4 12.9977M13.4 12.9977L7 12.9977M13.4 12.9977L13.4 6.59766M13.4 12.9977L13.4 19.3977"
        stroke="#535353"
        strokeWidth={2}
        strokeLinecap="round"
        transform="translate(6, 7)"
      />
    </svg>
  );
}

export default SvgPlusIcon;