import { map } from "lodash-es";
import { DateTime } from "luxon";

export const formatSalesData = (saleData) => {
  const arrAux = [];

  saleData.forEach((sale) => {
    if (sale.customerInfo) {
      arrAux.push(sale);
    }
  });

  const saleArray = map(arrAux, (detailSales) => {
    const sale = {};

    Object.entries(detailSales).forEach(([saleKey, saleValue]) => {
      switch (saleKey) {
        case "_id":
          sale.id = saleValue;
          break;

        case "createdAt":
          sale.date = DateTime.fromISO(saleValue, {
            zone: "America/New_York",
          }).toFormat("yyyy-MM-dd hh:mm:ss a");
          break;
        case "currentStep":
          sale.currentStep = saleValue.replaceAll("_", " ");
          break;
        case "dealDetails":
          sale.dealDetails = saleValue;
          break;
        case "customer":
          sale.customerId = saleValue;
          break;

        case "customerInfo":
          Object.entries(saleValue).forEach(
            ([customerInfoKey, customerInfoValue]) => {
              switch (customerInfoKey) {
                case "firstName":
                  sale.firstName = customerInfoValue;
                  break;
                case "lastName":
                  sale.lastName = customerInfoValue;
                  break;
                case "email":
                  sale.email = customerInfoValue;
                  break;
                case "stripeId":
                  sale.stripeId = customerInfoValue;
                  break;
                case "billing":
                  Object.entries(customerInfoValue).forEach(
                    ([billingKey, billingValue]) => {
                      switch (billingKey) {
                        case "address1":
                          sale.address = billingValue;
                          break;
                        case "city":
                          sale.city = billingValue;
                          break;
                        case "state":
                          sale.state = billingValue;
                          break;
                        case "country":
                          sale.country = billingValue;
                          break;
                        default:
                          break;
                      }
                    }
                  );
                  break;
                default:
                  break;
              }
            }
          );
          break;
        case "offerDetails":
          Object.entries(saleValue).forEach(
            ([offerDetailsKey, offerDetailsValue]) => {
              switch (offerDetailsKey) {
                case "title":
                  sale.offerTitle = offerDetailsValue;
                  break;
                case "trial":
                  sale.trial = offerDetailsValue;
                  break;
                case "code":
                  sale.code = offerDetailsValue;
                  break;
                default:
                  break;
              }
            }
          );
          break;
        case "marketingParameters":
          Object.entries(saleValue).forEach(
            ([marketingParametersKey, marketingParametersValue]) => {
              switch (marketingParametersKey) {
                case "utmContent":
                  sale.utmContent = marketingParametersValue;
                  break;
                case "utmMedium":
                  sale.utmMedium = marketingParametersValue;
                  break;
                case "utmSource":
                  sale.utmSource = marketingParametersValue;
                  break;
                case "utmTerm":
                  sale.utmTerm = marketingParametersValue;
                  break;
                case "channel":
                  sale.channel = marketingParametersValue;
                  break;
                default:
                  break;
              }
            }
          );
          break;
        case "salesParameters":
          Object.entries(saleValue).forEach(
            ([salesParametersKey, salesParametersValue]) => {
              switch (salesParametersKey) {
                case "orderSystem":
                  sale.orderSystem = salesParametersValue;
                  break;
                case "salesAgent":
                  sale.salesAgent = salesParametersValue;
                  break;
                default:
                  break;
              }
            }
          );
          break;
        case "customerStatus":
          Object.entries(saleValue).forEach(
            ([customerStatusKey, customerStatusValue]) => {
              switch (customerStatusKey) {
                case "status":
                  sale.status = customerStatusValue;
                  break;
                case "subscriptionId":
                  sale.subscriptionId = customerStatusValue;
                  break;
                case "updatedAt":
                  if (sale.status === "canceled") {
                    sale.cancellationDate = new Date(customerStatusValue)
                      .toISOString()
                      .substring(0, 10);
                  }
                  break;
                default:
                  break;
              }
            }
          );
          break;
        case "customerEvents":
          sale.autoLogin = "auto-login-success";
          break;

        case "coachInfo":
          Object.entries(saleValue).forEach(
            ([coachInfoKey, coachInfoValue]) => {
              switch (coachInfoKey) {
                case "email":
                  sale.coachEmail = coachInfoValue;
                  break;
                case "name":
                  sale.coachName = coachInfoValue;
                  break;
                default:
                  break;
              }
            }
          );
          break;
        case "coachingSelection":
          Object.entries(saleValue).forEach(
            ([coachingSelectionKey, coachingSelectionValue]) => {
              switch (coachingSelectionKey) {
                case "selectedTz":
                  sale.coachMeetingTimeZone = coachingSelectionValue;
                  break;
                case "utcStart":
                  sale.coachMeetingDate = coachingSelectionValue;
                  break;
                default:
                  break;
              }
            }
          );
          break;
        default:
          break;
      }

      return sale;
    });

    return sale;
  });

  return saleArray;
};

export const formatFilterKey = (searchOptions, filterValue) => {
  const filterKey = searchOptions.filter(
    (option) => option.value === filterValue
  );
  return filterKey[0].key;
};

export const getPercentage = (value, total) => {
  const percentage = (value * 100) / total;
  return percentage.toFixed(0);
};

export const csvHeaders = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Customer Email", key: "email" },
  { label: "Offer", key: "offerTitle" },
  { label: "Coaching Date", key: "coachMeetingDate" },
  { label: "Coach Name", key: "coachName" },
  { label: "Current Step", key: "currentStep" },
  { label: "Order System", key: "orderSystem" },
  { label: "Sales Agent", key: "salesAgent" },
  { label: "Channel", key: "channel" },
  { label: "UTM Source", key: "utmSource" },
  { label: "UTM Content", key: "utmContent" },
  { label: "UTM Medium", key: "utmMedium" },
  { label: "UTM Term", key: "utmTerm" },
  { label: "Onboard Date", key: "date" },
  { label: "Subscription Id", key: "subscriptionId" },
  { label: "Cancelled Date", key: "cancellationDate" },
];
