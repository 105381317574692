import React, { useState, useEffect } from "react";
import { Dropdown, Navbar } from "flowbite-react";
import { useFusionAuth } from "@fusionauth/react-sdk";
import Cookies from "js-cookie";
import { AuthorifyLogo } from "../../components/icons";
import { LogoutIcon, MenuMobileIcon } from "../../components/icons";
import { useNavigate } from "react-router-dom";

type UserInfo = {
  applicationId: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  roles: string[];
  scope: string;
  sid: string;
  sub: string;
  tid: string;
};

const navbarTheme = {
  base: "bg-white px-6 py-2 border-b border-gray-200 fixed top-0 left-0 right-0 z-10 dark:border-gray-700 dark:bg-gray-800",
  brand: "flex items-center",
};

function TopNavbar({ mobileDevice }) {
  const userDataStr = Cookies.get("user");
  const userData: UserInfo = userDataStr ? JSON.parse(userDataStr) : null;
  const { email } = userData || { email: "" };
  const { logout, user } = useFusionAuth();
  const { picture } = user || { picture: "/default-profile.jpg" };
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <div className="z-20">
      <Navbar fluid className={`${navbarTheme.base} sm:px-6 z-20 border-t-4 border-t-primary-500`}>
        { windowSize < 1024 ? <MenuMobileIcon onClick={mobileDevice} className="mr-3 cursor-pointer"/> : null }   
        <Navbar.Brand href="#" onClick={() => navigate("/home")}>
          <AuthorifyLogo className="h-10 w-8" />
        </Navbar.Brand>
        <div className="flex-grow">
          <div className="flex justify-end items-center gap-5">
            <Dropdown
              arrowIcon={false}
              inline
              label={
                <img
                  alt=""
                  src={picture}
                  className="w-10 h-10 rounded-full border border-sky-400"
                  referrerPolicy="no-referrer"
                />
              }
            >
              <Dropdown.Header>
                <span className="block text-sm">Signed in as</span>
                <span className="block truncate text-sm font-medium">
                  {email}
                </span>
              </Dropdown.Header>
              <Dropdown.Item icon={LogoutIcon} onClick={logout}>
                Log out
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      </Navbar>
    </div>
  );
}

export default TopNavbar;
