import React, { ChangeEvent, FocusEventHandler } from 'react';
import classnames from 'classnames';

interface TextInputProps {
  id: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: string;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  multiline?: boolean;
  rows?: number; // Only applicable for textarea
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  type = 'text',
  className = '',
  disabled = false,
  error = false,
  multiline = false,
  rows = 4,
}) => {
  const commonProps = {
    id,
    value,
    onChange,
    placeholder,
    onBlur,
    className: classnames(
      'flex border w-full pl-3.5 pr-4 rounded-md text-sm font-medium placeholder:text-neutral-300 focus:border-2 focus:border-primary-500 focus:outline-none focus:ring-0 focus:ring-transparent',
      error ? 'border-2 border-red-500' : 'border-neutral-200',
      className,
    ),
    disabled,
  };

  if (multiline) {
    return <textarea {...commonProps} rows={rows} />;
  }

  return <input {...commonProps} type={type} />;
};

export default TextInput;