import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_14338_18246" fill="white">
      <path d="M0 0H32V32H0V0Z" />
    </mask>
    <path d="M0 0H32V32H0V0Z" fill="white" />
    <path
      d="M1 32V0H-1V32H1Z"
      fill="none"
      mask="url(#path-1-inside-1_14338_18246)"
    />
    <path
      d="M14.1138 10.6663C14.3883 9.88955 15.1292 9.33301 16 9.33301C16.8708 9.33301 17.6116 9.88955 17.8862 10.6663"
      stroke="#3C3C3C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M21.6666 12H10.3333"
      stroke="#252525"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20.5554 13.667L20.2488 18.2664C20.1308 20.0363 20.0718 20.9213 19.4952 21.4608C18.9185 22.0003 18.0315 22.0003 16.2577 22.0003H15.7421C13.9682 22.0003 13.0813 22.0003 12.5046 21.4608C11.928 20.9213 11.869 20.0363 11.751 18.2664L11.4443 13.667"
      stroke="#252525"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M14.3333 15.333L14.6666 18.6663"
      stroke="#252525"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M17.6666 15.333L17.3333 18.6663"
      stroke="#252525"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
export default SvgTrashIcon;
