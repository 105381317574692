import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { PhoneIcon, XIcon } from "../../../../components/icons";
import Avatar from "../../../components/Avatar";
import Title from "../../../components/Title";
import { getZoomTeamMembersList } from "../../../api/zoom";
import TextInput from "../../../components/TextInput";
import SkeletonCardLoader from "../../../components/SkeletonCardLoader";


export default function TeamPhoneCall() {
    const [emailValue, setEmailValue] = useState("");
    const [filteredMembers, setFilteredMembers] = useState([]);
    const navigate = useNavigate();

    const { data: allTeamMembers, isError, error, isLoading } = useQuery(
        'getZoomTeamMembersList',
        getZoomTeamMembersList,
        { keepPreviousData: true, refetchOnWindowFocus: false }
    );

    useEffect(() => {
        if (allTeamMembers) {
            const processedMembers = allTeamMembers.map(member => ({
                ...member,
                searchKey: `${member.fullName.toLowerCase()} ${member.email.toLowerCase()}`
            }));
            filterMembers(processedMembers, emailValue);
        }
    }, [allTeamMembers, emailValue]);

    const filterMembers = (members, filter) => {
        const lowercasedFilter = filter.toLowerCase();
        const filteredData = members.filter(member =>
            member.searchKey.includes(lowercasedFilter)
        );
        setFilteredMembers(filteredData);
    };

    const onChangeEmailInput = (e) => {
        setEmailValue(e.target.value);
    };

    const clearInput = () => {
        setEmailValue('');
    };

    const handleNavigate = (email) => {
        navigate(`/team-phone-calls/${encodeURIComponent(email).replace(/\./g, "%2E")}/first/1`, { replace: true });
    };

    if (isError) return <div>Error: {error.message}</div>;

    return (
        <div className="max-w-[2500px]">
            <div className="pt-8 w-full">
                <Title>Team Phone Calls</Title>
            </div>
            <div className="pt-8 pb-5 w-full bg-[#F9FAFB] min-h-screen">
                <div className="w-full bg-white rounded-lg shadow">
                    <div className="px-6 py-5 border-b border-gray-200 flex justify-between items-center max-[800px]:justify-center">
                        <div className="relative w-[341px] max-[450px]:w-full">
                            <TextInput
                                value={emailValue}
                                onChange={onChangeEmailInput}
                                placeholder="Search name or email"
                            />
                            {emailValue && (
                                <button onClick={clearInput} className="absolute inset-y-0 right-0 pr-6 flex items-center">
                                    <XIcon className="h-5 w-5 text-gray-500" />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="p-6 grid gap-4 grid-cols-[repeat(auto-fill,minmax(350px,1fr))] overflow-x-auto">
                        {isLoading ? (
                           <SkeletonCardLoader count={4} />
                        ) : filteredMembers.length > 0 ? (
                            filteredMembers.map((member, idx) => (
                                <div key={idx}
                                     className={`w-full h-[86px] bg-white rounded-lg border border-gray-200 flex-col justify-start items-start hover:shadow-[inset_0_0_0_1px_rgba(56,189,248,1)] cursor-pointer hover:border-sky-400 cursor-pointer' : 'border-gray-200 opacity-50'} flex-col justify-start items-start inline-flex`}
                                     style={{ padding: '22px 24px' }}
                                     onClick={() => handleNavigate(member.email)}>
                                    <div className="self-stretch justify-start items-center gap-4 inline-flex w-full">
                                            <Avatar name={member.fullName} />
                                        <div className="flex-grow flex-col justify-center items-start gap-0.5">
                                            <div className="text-zinc-900 text-sm truncate max-w-[150px] font-semibold">{member.fullName}</div>
                                            <div className="text-neutral-500 text-sm truncate" style={{ maxWidth: '150px' }}>{member.email}</div>
                                        </div>
                                        <div className="flex-none w-[68px] flex justify-end items-center pr-4">
                                            <PhoneIcon className="w-4 h-4 text-black" />
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="text-sm text-neutral-500 col-span-3 text-center">No team members found.</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}