const UNIQUE_VISITS = "unique-visits";
const PAID_SALES = "paid-sales";
const AUTO_LOGIN = "auto-login";
const CANCELLED = "cancelled";
const BOOKS_GENERATED = "books-generated";
const SEARCH_FILTER = "search-filter";

export {
  UNIQUE_VISITS,
  PAID_SALES,
  AUTO_LOGIN,
  CANCELLED,
  BOOKS_GENERATED,
  SEARCH_FILTER,
};
