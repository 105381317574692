import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Title({ children }: { children: any }) {
  return (
    <div className="text-neutral-800 text-2xl font-extrabold font-['Figtree'] leading-7">
      {children}
    </div>
  );
}

export default Title;
