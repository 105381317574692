import { getInstance } from "./instanceScheduler";

const instance = getInstance();

const getAllCoaches = (page, perPage) => {
  const url = `coaches?page=${page}&perPage=${perPage}`;
  return instance
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => Promise.reject(error));
};

const searchCoaches = (query) => {
  const url = `coaches?search=${encodeURIComponent(query)}`;
  return instance
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => Promise.reject(error));
};

const getCoachById = (coachId: string) => {
  const url = `coaches/${coachId}`;
  return instance
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => Promise.reject(error));
};

const createCoach = (body = {}) => {
  const url = "coaches";

  return instance
    .post(url, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.data.message) {
        throw new Error(error.response.data.message);
      }
      return Promise.reject(error);
    });
};


const updateCoach = (coachId: string, body = {}) => {
  const url = `coaches/${coachId}`;
  return instance.patch(url, body)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.data.message) {
        throw new Error(error.response.data.message);
      }
      return Promise.reject(error);
    });
};


const deleteCoaches = (coachId: string) => {
  const url = `coaches/${coachId}`;

  return instance.delete(url);
};

const validateCoach = (email: string) => {
  const url = `coaches/validate/${email}`;

  return instance
    .post(url, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => Promise.reject(error));
};

export {
  getAllCoaches,
  getCoachById,
  createCoach,
  updateCoach,
  deleteCoaches,
  validateCoach,
  searchCoaches
};
