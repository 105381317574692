import get from "lodash/get";
import { Alert, Textarea } from "flowbite-react";
import Button from "../../../../../../components/Button";
import { defaultTextAreaTheme } from "../../../../../../components/Theme/TextArea";
import { SvgInfoIcon } from "../../../../../../../components/icons";

const AdditionalInformation = ({ updateMagazineData }) => {
  const { magazine, handleUpdateMagazine, magazineDataFormik } = updateMagazineData;

  const handleTextChange = (e) => {
    const value = get(e, "target.value");
    const name = get(e, "target.name");
    handleUpdateMagazine(value, name);
  };

  const onHandleResetFormFields = () => {
    handleUpdateMagazine("", "additionalInformation");
    magazineDataFormik.setFieldValue("currentStep", -1);
  };

  return (
    <div className="flex flex-col gap-6">
      <Alert color="info" className="mt-8">
        <div className="flex w-full gap-3 items-start">
          <SvgInfoIcon fill="#1F8599" />
          <span>
            For additional instructions or request, fill in the form below or contact support for help. Any extensive
            customization may be subject for review first and to a professional fee starting at $39.
          </span>
        </div>
      </Alert>
      <div className="w-full flex flex-col gap-2">
        Additional instructions or request
        <Textarea
          theme={defaultTextAreaTheme}
          id="additionalInformation"
          name="additionalInformation"
          onChange={handleTextChange}
          value={get(magazine, "additionalInformation")}
          rows={3}
          placeholder="Optional"
        />
      </div>
      <div className="flex justify-start w-full gap-2">
        <Button variant="LIGHT" onClick={onHandleResetFormFields}>
          Cancel
        </Button>
        <Button variant="DARK" onClick={magazineDataFormik.handleSubmit}>
          Save changes
        </Button>
      </div>
    </div>
  );
};

export default AdditionalInformation;
