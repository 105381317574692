import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DateTime } from "luxon";

dayjs.extend(utc);
dayjs.extend(timezone);

const DEFAULT_DATE_FORMAT_IN_LUXON = "LL/dd/yyyy - HH:mm";

export const getCurrentTimeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

const DEFAULT_DATE_FORMAT = "MM/DD/YYYY - HH:mm";

export const formatDateWithTimeZone = (
  date: string,
  format = DEFAULT_DATE_FORMAT,
  newTimeZone = getCurrentTimeZone
) => dayjs(date).tz(newTimeZone).format(format);

export const formatDateTime = (date: Date) => {
  return dayjs(date).format("MMM DD, YYYY h:mm A");
};

// Jan 22, 2024
export const formatDateTime5 = (date: Date) => {
  return dayjs(date).format("MMM DD, YYYY");
};

export const formatDateWithTZFromIso = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date: any,
  format = DEFAULT_DATE_FORMAT_IN_LUXON,
  newTimeZone = getCurrentTimeZone
) => {
  const formatedDate = DateTime.fromISO(date)
    .setZone(newTimeZone)
    .toFormat(format);
  return formatedDate;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatDate = (date: any, format = DEFAULT_DATE_FORMAT) =>
  dayjs(date).format(format);

export const formatDateFromStringDate = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date: any
) => {
  const formatedData = convertToISO8601(date);
  if (!formatedData) {
    return '';
  }
  return new Date(formatedData).toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

function convertToISO8601(dateStr) {
  const parsedDate = DateTime.fromFormat(dateStr, 'yyyy-MM-dd hh:mm:ss a');

  return parsedDate.toISO();
}

export const formatDateTimeWithmonthyear = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date: any
) => {
  const dateNew = new Date(date);
  const monthAbbreviation = new Intl.DateTimeFormat("en-US", {
    month: "short",
  }).format(dateNew);
  const dayOfMonth = dateNew.getDate();
  return `${monthAbbreviation}-${dayOfMonth}`;
};
