import { includes } from "lodash-es";

import { AVATAR_MIN_WIDTH, AVATAR_MIN_HEIGHT, loadImageDetails } from "./helpers";

export const getValidationSchema = (imageType) => {
  switch (imageType) {
    case "background":
      return {
        fileSize: {
          value: 4000000,
          message: "File size must not exceed 4 MB.",
        },
        mimeTypes: {
          value: ["image/jpeg", "image/png"],
          message: "Invalid file type selected.",
        },
        dimensions: {
          minWidth: 2000,
          minHeight: 2000,
          message: "Image dimensions must be or more than  greater than 2000px",
        },
      };
    case "preview-book-image":
      return {
        fileSize: {
          value: 15000000,
          message: "File size must not exceed 15 MB.",
        },
        mimeTypes: {
          value: ["image/jpeg", "image/png"],
          message: "Invalid file type selected.",
        },
        dimensions: {
          maxWidth: "100%",
          maxHeight: "100%",
        },
      };

    case "profile":
      return {
        fileSize: {
          value: 2000000,
          message: "File size must not exceed 2 MB.",
        },
        mimeTypes: {
          value: ["image/jpeg", "image/png"],
          message: "Invalid file type selected.",
        },
        dimensions: {
          minWidth: AVATAR_MIN_WIDTH,
          minHeight: AVATAR_MIN_HEIGHT,
          message: "Image dimensions must be or more than  greater than 500px",
        },
      };

    case "rm_profile":
      return {
        fileSize: {
          value: 2000000,
          message: "File size must not exceed 2 MB.",
        },
        mimeTypes: {
          value: ["image/jpeg", "image/png"],
          message: "Invalid file type selected.",
        },
        dimensions: {
          maxWidth: 540,
          maxHeight: 200,
          message: "Image dimensions must be or more than  520 x 520",
        },
        allowedResolutions: {
          width: 520,
          height: 520,
        },
      };

    case "brokerageLogo":
      return {
        fileSize: {
          value: 5000000,
          message: "File size must not exceed 5 MB.",
        },
        mimeTypes: {
          value: ["image/jpeg", "image/png"],
          message: "Invalid file type selected.",
        },
        dimensions: {
          maxWidth: 540,
          maxHeight: 200,
          message: "Image dimensions must be or more than  540px x 192px",
        },
        allowedResolutions: {
          width: 540,
          height: 192,
        },
      };

    case "backInsideCoverImageOption3":
    case "backInsideCoverImageOption4":
      return {
        fileSize: {
          value: 5000000,
          message: "File size must not exceed 5 MB.",
        },
        mimeTypes: {
          value: ["image/jpeg", "image/png"],
          message: "Invalid file type selected.",
        },
        dimensions: {
          maxWidth: 1440,
          maxHeight: 960,
          message: "Image dimensions must be or more than  1440 x 960",
        },
        allowedResolutions: {
          width: 1440,
          height: 960,
        },
      };

    case "backCoverImageOption4":
    case "backCoverImageOption5":
      return {
        fileSize: {
          value: 5000000,
          message: "File size must not exceed 5 MB.",
        },
        mimeTypes: {
          value: ["image/jpeg", "image/png"],
          message: "Invalid file type selected.",
        },
        dimensions: {
          maxWidth: 960,
          maxHeight: 750,
          message: "Image dimensions must be or more than  960 x 750",
        },
        allowedResolutions: {
          width: 960,
          height: 750,
        },
      };
    case "backInsideCoverImageOption6":
      return {
        fileSize: {
          value: 5000000,
          message: "File size must not exceed 5 MB.",
        },
        mimeTypes: {
          value: ["image/jpeg", "image/png"],
          message: "Invalid file type selected.",
        },
        dimensions: {
          maxWidth: 960,
          maxHeight: 672,
          message: "Image dimensions must be or more than  960 x 672",
        },
        allowedResolutions: {
          width: 960,
          height: 672,
        },
      };

    case "backCoverImageOption3":
      return {
        fileSize: {
          value: 5000000,
          message: "File size must not exceed 5 MB.",
        },
        mimeTypes: {
          value: ["image/jpeg", "image/png"],
          message: "Invalid file type selected.",
        },
        dimensions: {
          maxWidth: 600,
          maxHeight: 960,
          message: "Image dimensions must be or more than  600 x 960",
        },
        allowedResolutions: {
          width: 600,
          height: 960,
        },
      };

    default:
      return {
        fileSize: {
          value: 2000000,
          message: "File size must not exceed 2 MB.",
        },
        mimeTypes: {
          value: ["image/jpeg", "image/png"],
          message: "Invalid file type selected.",
        },
        dimensions: {
          minWidth: AVATAR_MIN_WIDTH,
          minHeight: AVATAR_MIN_HEIGHT,
          message: "Image dimensions must be or more than  greater than 500px",
        },
      };
  }
};

const difference = (num1, num2) => {
  return Math.abs(num1 - num2);
};

const includesXY = (allowedResolutions, width, height) => {
  const widthDiff = difference(allowedResolutions.width, width);
  const heightDiff = difference(allowedResolutions.height, height);
  return {
    widthDiff,
    heightDiff,
  };
};

export const validateImage = async (file, mimeTypes, imageType = "default") => {
  const validationSchema = getValidationSchema(imageType);

  const mime = mimeTypes || validationSchema.mimeTypes.value;
  const { type, size } = file;

  if (size > validationSchema.fileSize.value) {
    throw new Error(validationSchema.fileSize.message);
  }

  if (!includes(mime, type)) {
    throw new Error(validationSchema.mimeTypes.message);
  }

  try {
    const image = await loadImageDetails(file);
    const { width, height } = image;

    if (imageType === "preview-book-image") {
      if (width > validationSchema.dimensions.maxWidth || height > validationSchema.dimensions.minHeight) {
        throw new Error(validationSchema.dimensions.message);
      }
    }

    if (imageType === "rm_profile" || imageType === "default") {
      if (width < validationSchema.dimensions.minWidth || height < validationSchema.dimensions.minHeight) {
        throw new Error(validationSchema.dimensions.message);
      }
    }

    return image;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const validateImageURL = async (width, height, imageType) => {
  const validationSchema = getValidationSchema(imageType);

  try {
    if (imageType === "preview-book-image") {
      if (width > validationSchema.dimensions.maxWidth || height > validationSchema.dimensions.minHeight) {
        throw new Error(validationSchema.dimensions.message);
      }
    }

    if (
      imageType === "rm_profile" ||
      imageType === "brokerageLogo" ||
      imageType === "backInsideCoverImageOption3" ||
      imageType === "backInsideCoverImageOption4" ||
      imageType === "backInsideCoverImageOption6" ||
      imageType === "backCoverImageOption4" ||
      imageType === "backCoverImageOption5" ||
      imageType === "backCoverImageOption3"
    ) {
      if (width < validationSchema.allowedResolutions?.width || height < validationSchema.allowedResolutions?.height) {
        throw new Error(validationSchema.dimensions.message);
      }
    }

    if (imageType === "profile" || imageType === "default") {
      if (width < validationSchema.dimensions.minWidth || height < validationSchema.dimensions.minHeight) {
        throw new Error(validationSchema.dimensions.message);
      }
    }

    return true;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0, flip = { horizontal: false, vertical: false }) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/png");
  });
}

export async function getRotatedImage(imageSrc, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
  if (orientationChanged) {
    canvas.width = image.height;
    canvas.height = image.width;
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
  }

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((rotation * Math.PI) / 180);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);

  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/png");
  });
}
