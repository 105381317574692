import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SubTitle({ children }: { children: any }) {
  return (
    <div className="text-zinc-900 text-base font-semibold font-['Figtree'] leading-tight">
      {children}
    </div>
  );
}

export default SubTitle;
