import React from 'react';

interface SkeletonSalesLoaderProps {
  count?: number;
  layout?: 'horizontal' | 'vertical';
}

const SkeletonSalesLoader: React.FC<SkeletonSalesLoaderProps> = ({ count = 3, layout = 'horizontal' }) => {
  return (
    <div className={`flex p-4 w-full items-center bg-white rounded-b-lg mb-[24px] shadow ${layout === 'horizontal' ? 'flex-row' : 'flex-col'} gap-4`}>
      {[...Array(count)].map((_, index) => (
        <div
          key={index}
          className={`w-1/3 p-[36px] bg-neutral-100 rounded-lg flex flex-col justify-start animate-pulse ${layout === 'horizontal' ? 'max-[1235px]:w-full max-[1235px]:flex-col' : ''}`}
        >
          <div className="flex items-center gap-2 text-sm mb-2">
            <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
            <div className="w-3/4 h-4 bg-gray-300 rounded"></div>
          </div>
          <div className="w-1/2 h-8 bg-gray-300 rounded"></div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonSalesLoader;
