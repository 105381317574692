import * as React from "react";
const SvgArrowUpBlack = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#252525"
      fillRule="evenodd"
      d="M2.305 6.795a.7.7 0 0 1 0-.99l4.2-4.2a.7.7 0 0 1 .99 0l4.2 4.2a.7.7 0 0 1-.99.99L7.7 3.79v8.11a.7.7 0 1 1-1.4 0V3.79L3.295 6.795a.7.7 0 0 1-.99 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowUpBlack;
