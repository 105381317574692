export const imageAllowedDimensions = {
  rm_profile: { width: 520, height: 520 },
  book_profile: { width: 520, height: 520 },
  brokerageLogo: { width: 520, height: 192 },
  backInsideCoverImageOption3: { width: 1440, height: 960 },
  backInsideCoverImageOption4: { width: 1440, height: 960 },
  backInsideCoverImageOption6: { width: 960, height: 672 },  
  backCoverImageOption3: { width: 600, height: 960 },
  backCoverImageOption4: { width: 960, height: 750 },
  backCoverImageOption5: { width: 960, height: 750 },
}

export const allowedUploadImages = [
  'rm_profile',
  'book_profile',
  'brokerageLogo',
  'backInsideCoverImageOption3',
  'backInsideCoverImageOption4',
  'backInsideCoverImageOption6',
  'backCoverImageOption4',
  'backCoverImageOption5',
  'backCoverImageOption3'
]