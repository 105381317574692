import React from "react";
import MagazineFromDBProvider from "./context/MagazineFromDBContext.tsx";
import MagazineImageProvider from "./context/MagazineImageContext.tsx";
import DashboardLayout from "./modules/dashboardLayout/index.tsx";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-tooltip/dist/react-tooltip.css";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MagazineFromDBProvider>
        <MagazineImageProvider>
          <DashboardLayout />
        </MagazineImageProvider>
      </MagazineFromDBProvider>
    </QueryClientProvider>
  );
}

export default App;
