import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { nanoid } from 'nanoid';
import { checkIcon } from '../../../../../../assets/images';
import ToastNotification from '../../../../../components/ToastNotification';
import { BackArrow } from '../../../../../../components/icons';
import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  InputLabel,
  InputWithInitialValues,
  SwitchButton,
  TextInput,
} from '../../../../../components';
import { OfferContext } from '../../utils/OfferProvider';
import validationSchema from './validationSchema';
import {
  getProductFamilies,
  getProductPackages,
  checkOfferCodeExists,
} from '../../../../../api/productCatalog/actions';
import BookPackgesModal from '../../components/BookPackagesModal';
import ChangeProductModal from '../../components/ChangeProductModal';
import { BookPackageOptionProps } from '../../utils/types';

interface InitialValues {
  offerName: string;
  offerCode: string;
  productCountry: string[];
  productCode: string;
  bookPackages: string[];
  offerTrialEnabled: boolean;
  trialPrice: string;
  trialCredits: string;
}

const OfferDetails: React.FC = () => {
  const {
    step,
    setStep,
    offerName,
    setOfferName,
    offerCode,
    setOfferCode,
    offerCountry,
    setOfferCountry,
    offerProduct,
    setOfferProduct,
    setProductValueInCents,
    offerBookCategories,
    setOfferBookCategories,
    offerBookPackages,
    setOfferBookPackageNames,
    offerTrialEnabled,
    setOfferTrialEnabled,
    offerTrialPrice,
    setOfferTrialPrice,
    offerTrialCredits,
    setOfferTrialCredits,
    packagesOptions,
    setPackagesOptions,
    setBooksOptions,
    defaultPackages,
    setDefaultPackages,
    selectedBooks,
    setSelectedBooks,
    availableBookPackages,
    setAvailableBookPackages,
    allowedCategories,
    selectedPackageNames,
    setSelectedPackageNames,
  } = useContext(OfferContext);

  const navigate = useNavigate();
  const { data: productsFamiliesData } = useQuery(
    ['getProductsByFamilyGroup0'],
    getProductFamilies,
  );
  const [offerCodeExists, setOfferCodeExists] = useState(false);
  const families = productsFamiliesData || [];
  const [selectedCountries, setSelectedCountries] = useState({
    US: false,
    CA: false,
  });
  const [showBookPackagesModal, setShowBookPackagesModal] = useState(false)
  const [showChangeProductModal, setShowChangeProductModal] = useState(false);
  const [defaultSelectedBooks, setDefaultSelectedBooks] = useState([]);
  const [productTemporaryValue, setProductTemporaryValue] = useState('');

  const productsOptions = Object.entries(families).reduce((acc, [k, v]) => {
    const family = k;
    acc.push({
      id: uniqueId(),
      key: uniqueId(),
      label: (
        <span className="text-xs text-neutral-500 font-semibold uppercase">
          {family}
        </span>
      ),
      value: family,
      notAllowed: true,
    });

    (v).forEach(
      ({ _id, isProductCatalogAllowed, title, type, product, value }) => {
        if (isProductCatalogAllowed) {
          acc.push({
            label: (
              <div>
                <p>{title}</p>
                <p className="text-neutral-400">{type}</p>
              </div>
            ),
            value: _id,
            productData: {
              title,
              type,
              value: value,
            },
            notAllowed: false,
            selectedLabel: product,
            key: _id,
          });
        }
      },
    );
    return acc;
  }, [] as BookPackageOptionProps[]);

  const countryOptions = [
    {
      id: nanoid(),
      label: (
        <label
          className="flex items-center"
          onClick={(e) => e.preventDefault()}
          key={`us-${selectedCountries.US}`}
        >
          <Checkbox id="checkbox-us" checked={selectedCountries.US} readOnly />
          <span className="ml-2">United States</span>
        </label>
      ),
      value: 'US',
      selectedLabel: 'United States',
    },
    {
      id: nanoid(),
      label: (
        <label
          className="flex items-center"
          onClick={(e) => e.preventDefault()}
          key={`ca-${selectedCountries.CA}`}
        >
          <Checkbox id="checkbox-ca" checked={selectedCountries.CA} readOnly />
          <span className="ml-2">Canada</span>
        </label>
      ),
      value: 'CA',
      selectedLabel: 'Canada',
    },
  ];

  useEffect(() => {
    if (offerCountry?.length) {
      setSelectedCountries({
        US: offerCountry?.some((value) => value === 'United States'),
        CA: offerCountry?.some((value) => value === 'Canada'),
      });
    }
  }, [offerCountry]);

  useEffect(() => {
    if (allowedCategories?.length) {
      handleCategoriesOptions(allowedCategories);

      // Get the names from offerBookCategories that are in allowedCategories
      const names = offerBookCategories
        ?.filter((pkg) =>
          allowedCategories.some(
            (allowedCategory) =>
              allowedCategory.name.trim().toLowerCase() === pkg.name.trim().toLowerCase()
          )
        )
        .map((pkg) => pkg.name);

      setSelectedPackageNames(names);
    } else {
      handleCategoriesOptions(availableBookPackages);
    }
  }, [offerBookCategories, availableBookPackages, allowedCategories, offerProduct]);

  useEffect(() => {
    if (!offerBookPackages.length && defaultPackages) {
      const defaultBooks = defaultPackages.flatMap(category =>
        category.packages.map(book => book.alias)
      );
      setSelectedBooks(defaultBooks);
      setDefaultSelectedBooks(defaultBooks)
    }
    handleBookOptions(defaultPackages);
  }, [offerBookPackages, defaultPackages, offerProduct]);

  const initialValues: InitialValues = {
    offerName: offerName || '',
    offerCode: offerCode || '',
    productCountry: offerCountry || [],
    productCode: offerProduct || '',
    bookPackages: offerBookCategories?.map((pkg) => pkg?.name) || [],
    offerTrialEnabled: offerTrialEnabled || false,
    trialPrice: offerTrialPrice || '',
    trialCredits:
      offerTrialCredits !== null && offerTrialCredits !== undefined
        ? offerTrialCredits
        : '',
  };

  const handleSubmit = async (values) => {
    try {
      await checkOfferCodeExists(values.offerCode);
      setOfferCodeExists(false);

      setOfferName(values.offerName);
      setOfferCode(values.offerCode);

      const selectedProductLabel = values.productCode;

      const selectedProduct = productsOptions.find(
        (product) => product.selectedLabel === selectedProductLabel,
      );

      if (selectedProduct && selectedProduct.productData) {
        setOfferProduct(selectedProduct.selectedLabel);
        setProductValueInCents(selectedProduct.productData.value);
      } else {
        console.error('Selected Product or Product Data is undefined');
      }

      const currentOfferBookPackages = offerBookCategories;
      setOfferBookPackageNames(currentOfferBookPackages?.map((pkg) => pkg?.name));
      setStep(step + 1);
    } catch (error) {
      if (error.message === 'Offer code already exists') {
        ToastNotification({
          type: 'failure',
          message: 'Offer code already exists. Please choose a different code.',
        });
        setOfferCodeExists(true);
      } else {
        console.error('Error in handleSubmit:', error);
      }
    }
  };

  const handleSelectBookCategories = (selectedValues) => {
    setOfferBookCategories((prev) => {
      const updatedCategories = selectedValues
        .map((name) => findOrCreateCategoriesPackage(name, prev, availableBookPackages))
        .filter(Boolean);
      return updatedCategories;
    });
    setSelectedPackageNames(selectedValues);
  };



  const findOrCreateCategoriesPackage = (name, prevPackages, availablePackages) => {
    const existing = prevPackages.find((pkg) => pkg?.name === name);
    if (existing) {
      return existing;
    }

    const newPackage = availablePackages.find((pkg) => pkg?.name === name);

    if (newPackage) {
      return newPackage;
    } else {
      return null;
    }
  };

  const handleCategoriesOptions = (packages) => {
    setPackagesOptions(
      packages.map((category) => {
        const name = category?.name;
        const id = nanoid();
        const isSelected = offerBookCategories?.some((pkg) => pkg?.name === name);
        return {
          id: id,
          key: id,
          label: (
            <label
              className="flex items-center"
              htmlFor={`checkbox-${id}`}
              onClick={(e) => e.preventDefault()}
            >
              <Checkbox id={`checkbox-${id}`} checked={isSelected} readOnly />
              <span className="ml-2">{name}</span>
            </label>
          ),
          value: name,
          selectedLabel: name,
        };
      }),
    );
  };

  const handleBookOptions = (books) => {
    let options: { id: string; key: string; label: JSX.Element; value: string; selectedLabel: string }[] = [];
    let bookValues: string[] = [];
    books?.forEach((category) => {
      const name = category?.name;
      const packages = category?.packages;
      const titleLabel = {
        id: name,
        key: name,
        label: (
          <span className='font-semibold text-neutral-500 text-sm uppercase'>{name}</span>
        ),
        value: name,
        selectedLabel: name,
        notAllowed: true,
      };
      options.push(titleLabel);

      packages.forEach((pkg) => {
        const pkgId = nanoid();
        const bookName = pkg?.alias;

        const isSelected = offerBookPackages?.length > 0 ? offerBookPackages?.some((book) => book?.alias === bookName) : true;

        const bookLabel = {
          id: pkgId,
          key: pkgId,
          label: (
            <label
              className="flex items-center"
              htmlFor={`checkbox-${pkgId}`}
              onClick={(e) => e.preventDefault()}
            >
              <Checkbox id={`checkbox-${pkgId}`} checked={isSelected} />
              <span className="ml-2">{bookName}</span>
            </label>
          ),
          value: bookName,
          selectedLabel: bookName,
        };

        options.push(bookLabel);
        bookValues.push(bookName)
      });
    });

    setBooksOptions(options);
    setOfferBookPackageNames(bookValues)
  };



  const findPackagesUsingProduct = async (value: string) => {
    const findProduct = Object.entries(families).reduce((acc, [k, v]) => {
      const found = v.find(({ product }) => product == value);
      if (found) {
        acc = found;
      }
      return acc;
    }, null);
    if (findProduct) {
      const packages = await getProductPackages(findProduct?._id);
      let defaultSelectedBook = [];
      packages?.data?.forEach((pkg) => {
        const packages = pkg?.packages;

        packages?.map((book) => {
          defaultSelectedBook.push(book?.alias)
        })
      })

      setAvailableBookPackages(packages?.data);
      handleCategoriesOptions(packages?.data);
      setDefaultPackages(packages?.data)
    }
  };

  const handleOfferCodeBlur = async (element) => {
    try {
      const offerCode = element.target.value;
      await checkOfferCodeExists(offerCode);
      setOfferCodeExists(false);
    } catch (error) {
      if (error.message === 'Offer code already exists') {
        if (offerCode) {
          ToastNotification({
            type: 'failure',
            message: 'Offer code already exists. Please choose a different code.',
          });
        }
        setOfferCodeExists(true);
      } else {
        console.error('Error in onBlur:', error);
      }
    }
  };

  const showPackagesList = () => {
    return (
      <div className='ease-in-out bg-neutral-100 flex flex-col  border border-neutral-200 rounded-md w-full mt-1 px-6 py-3.5 cursor-pointer' onClick={() => setShowBookPackagesModal(true)} role='button'>
        <div className='flex flex-wrap gap-3 w-full max-md:justify-evenly max-sm:justify-between'>
          {Object.entries(availableBookPackages).map(([key, packages]) => {
            const filteredPackages = packages?.packages?.filter(pkg => selectedBooks?.includes(pkg.alias));

            if (filteredPackages.length === 0) {
              return null;
            }

            return (
              <div key={key}>
                <span className="font-semibold">{packages?.name}</span>
                <div className="flex flex-col my-2 w-[214px]">
                  {filteredPackages.map((pkg) => (
                    <div key={pkg._id} className='flex gap-2'>
                      <img src={checkIcon} alt="check" />
                      <span className="text-neutral-500 font-medium capitalize">
                        {pkg.alias}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        <span className='mt-3 w-fit font-semibold text-neutral-700 border-b border-neutral-300 border-dashed'>Edit packages</span>
      </div>
    )
  }

  return (
    <div className="pt-8 min-h-screen w-full">
      <ToastContainer />
      <button
        onClick={() => navigate('/offers')}
        className="flex gap-2 items-center px-2.5 py-2 mb-3.5"
      >
        <BackArrow />
        Offers
      </button>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          errors,
          touched,
          isValid,
          setFieldError,
        }) => {
          useEffect(() => {
            setFieldValue('bookPackages', selectedPackageNames);
          }, [selectedPackageNames, setFieldValue]);

          const productCodeIsValid = !!(
            !errors.productCode &&
            values.productCode &&
            values.productCode.length > 0
          );

          const bookPackagesIsValid = !(offerBookCategories?.length <= 0);
          const isDisabled =
            !isValid ||
            !productCodeIsValid ||
            !bookPackagesIsValid ||
            offerCodeExists;

          const showBookPackages =
            !!(availableBookPackages.length > 0) ||
            (offerProduct && offerProduct?.length > 0);

          const handleSelectedCountries = (values) => {
            setSelectedCountries({
              US: values?.some((value) => value === 'United States'),
              CA: values?.some((value) => value === 'Canada'),
            });
            setOfferCountry(values);
            setFieldValue('productCountry', values);
          };

          const handleChangeProductCode = (value?: string) => {
            if (allowedCategories.length > 0 || selectedPackageNames.length > 0) {
              setShowChangeProductModal(true);
            } else {
              handleChange({
                target: { name: 'productCode', value: value || productTemporaryValue },
              });
              setDefaultPackages(availableBookPackages);
              setBooksOptions(availableBookPackages);
              findPackagesUsingProduct(value as string);
              setOfferProduct(value as string);
            }
          };

          return (
            <Box>
              <div className="px-6 py-[22px] border-b border-neutral-200  font-semibold flex items-center gap-4 leading-5">
                <div
                  className={classNames(
                    'rounded-full flex justify-center items-center w-[30px] h-[30px]',
                    isDisabled
                      ? 'bg-neutral-200 '
                      : 'bg-success-500 text-white',
                  )}
                >
                  {step}
                </div>
                <span>Offer details</span>
              </div>
              <Form onSubmit={handleSubmit}>
                <div className="p-6">
                  <div className="max-w-[740px] flex flex-col gap-6">
                    <div>
                      <InputLabel
                        label="Offer name"
                        description="Add a brief name that will help you easily identify this offer."
                        required
                      />
                      <TextInput
                        id="offerName"
                        placeholder="eg: Facebook Digital Offer"
                        onChange={(element) => {
                          handleChange(element);
                          setOfferName(element.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.offerName}
                        error={!!(errors.offerName && touched.offerName)}
                      />
                      <div className="w-full flex justify-between">
                        {errors.offerName && touched.offerName && (
                          <div className="text-error-500 text-sm">
                            {errors.offerName}
                          </div>
                        )}
                        <div
                          className={classNames(
                            'flex justify-end text-sm ',
                            errors.offerName && touched.offerName
                              ? 'text-error-500'
                              : 'w-full text-neutral-400',
                          )}
                        >
                          {values.offerName.length || 0}/128
                        </div>
                      </div>
                    </div>
                    <div>
                      <InputLabel
                        label="Offer code"
                        description="This will be the URL for the offer."
                        required
                      />
                      <InputWithInitialValues
                        prefix="app.authorify.com/offers/"
                        id="offerCode"
                        placeholder="eg: offer-1234"
                        onChange={(element) => {
                          const { value } = element.target;
                          handleChange(element);
                          setOfferCode(value);
                        }}
                        onBlur={handleOfferCodeBlur}
                        value={values.offerCode}
                        error={
                          !!(errors.offerCode && touched.offerCode) ||
                          (offerCodeExists && offerCode)
                        }
                      />

                      {(errors.offerCode && touched.offerCode) ||
                        (offerCodeExists && offerCode) ? (
                        <div className="text-error-500 text-sm">
                          {!offerCodeExists
                            ? errors.offerCode
                            : 'Offer code already exists. Please choose a different code.'}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <div className="my-6">
                        <InputLabel
                          label="Select the countries eligible for this offer"
                          required
                        />
                        <Dropdown
                          id="productCountry"
                          onSelect={(values) => {
                            handleSelectedCountries(values);
                          }}
                          placeholder="Select one or more countries"
                          options={countryOptions}
                          onBlur={handleBlur}
                          selectedValues={offerCountry}
                          multiple
                          error={
                            !!(errors.productCountry && touched.productCountry)
                          }
                        />
                        {errors.productCountry && touched.productCountry && (
                          <div className="text-error-500 text-sm">
                            {errors.productCountry}
                          </div>
                        )}
                      </div>

                      <div className="my-6">
                        <InputLabel
                          label="Select product for this offer"
                          required
                        />
                        <Dropdown
                          id="productCode"
                          options={productsOptions}
                          placeholder="Select product"
                          onSelect={(value) => {
                            setProductTemporaryValue(value);
                            handleChangeProductCode(value);
                          }}
                          onBlur={handleBlur}
                          selectedValues={offerProduct}
                          error={!!(errors.productCode && touched.productCode)}
                        />
                        {errors.productCode && touched.productCode && (
                          <div className="text-error-500 text-sm">
                            {errors.productCode}
                          </div>
                        )}

                        {selectedBooks.length > 0 && showPackagesList()}
                      </div>
                      {showBookPackages && (
                        <div>
                          <InputLabel
                            label="What book packages are you leading with?"
                            description="This can include books within or outside the product range that you want to emphasize."
                            required
                          />
                          <Dropdown
                            id="bookPackages"
                            options={packagesOptions}
                            placeholder="Select one or more packages"
                            onSelect={(values) => {
                              handleSelectBookCategories(values);
                            }}
                            onBlur={handleBlur}
                            selectedValues={selectedPackageNames}
                            multiple
                            error={
                              !!(errors.bookPackages && touched.bookPackages)
                            }
                          />
                          {errors.bookPackages && touched.bookPackages && (
                            <div className="text-error-500 text-sm">
                              {errors.bookPackages}
                            </div>
                          )}
                        </div>
                      )}
                      <div className="mt-6">
                        <InputLabel
                          label="Free credits (Free printed books)"
                          description="Number of free credits provided to the user. This amount should be equivalent to free printed books."
                          required
                        />
                        <TextInput
                          id="trialCredits"
                          placeholder="12"
                          onChange={(element) => {
                            handleChange(element);
                            setOfferTrialCredits(element.target.value);
                            setFieldValue('trialCredits', element.target.value);
                          }}
                          onBlur={handleBlur}
                          value={values.trialCredits}
                          error={
                            !!(errors.trialCredits && touched.trialCredits)
                          }
                        />
                        {errors.trialCredits && touched.trialCredits && (
                          <div className="text-error-500 text-sm">
                            {errors.trialCredits}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-6 pt-6 border-t border-neutral-200">
                      <div className="text-sm font-semibold flex gap-3 items-center">
                        <SwitchButton
                          checked={offerTrialEnabled}
                          onChange={() => {
                            setOfferTrialEnabled(!offerTrialEnabled);
                            setFieldValue(
                              'offerTrialEnabled',
                              !offerTrialEnabled,
                            );
                          }}
                        />
                        Add 30-day Trial to this offer
                      </div>
                      {offerTrialEnabled && (
                        <>
                          <p className="my-[34px] font-semibold">
                            Price your offer
                          </p>
                          <div className="max-w-[740px] flex flex-col gap-6">
                            <div>
                              <InputLabel
                                label="Trial price"
                                description="Price for the first 30 days. After the trial period, the price will revert to the base price for the selected product"
                                required
                              />
                              <InputWithInitialValues
                                id="trialPrice"
                                fixedValue="$ "
                                onChange={(element) => {
                                  const { value } = element.target;
                                  handleChange(element);
                                  setOfferTrialPrice(value);
                                }}
                                value={values.trialPrice}
                                placeholder="7"
                                onBlur={handleBlur}
                                error={
                                  !!(errors.trialPrice && touched.trialPrice)
                                }
                              />
                              {errors.trialPrice && touched.trialPrice && (
                                <div className="text-error-500 text-sm">
                                  {errors.trialPrice}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      <Button
                        type="submit"
                        className="mt-6"
                        disabled={isDisabled}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
                <BookPackgesModal
                  showModal={showBookPackagesModal}
                  setShowModal={setShowBookPackagesModal}
                  handleBlur={handleBlur}
                  availableBookPackages={availableBookPackages}
                  defaultSelectedBooks={defaultSelectedBooks}
                />
                <ChangeProductModal
                  showModal={showChangeProductModal}
                  setShowModal={setShowChangeProductModal}
                  productTemporaryValue={productTemporaryValue}
                  handleChange={() => handleChange}
                  findPackagesUsingProduct={findPackagesUsingProduct}
                  setSelectedPackageNames={setSelectedPackageNames}
                />
              </Form>
            </Box>
          );
        }}
      </Formik>
      <Box className="flex items-center justify-between gap-5 px-6 py-3.5 my-6">
        <div className="flex items-center gap-5 font-semibold text-neutral-400 h-[46px]">
          <div className="rounded-full flex justify-center items-center w-[30px] h-[30px] bg-neutral-200 ">
            {step + 1}
          </div>
          <div>
            <span>Select template</span>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default OfferDetails;
