import * as React from "react";
import type { SVGProps } from "react";
const SvgZoomIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#252525"
      d="M1.933 7.5v.68c0 2.235 0 3.353.618 4.106q.17.207.377.377c.752.617 1.87.617 4.105.617s3.354 0 4.106-.617a2.7 2.7 0 0 0 .377-.377c.477-.582.585-1.381.61-2.746l.455.224c1.323.661 1.985.992 2.469.693s.483-1.039.483-2.518v-.198c0-1.48 0-2.22-.483-2.518-.484-.3-1.146.031-2.469.693l-.455.224c-.025-1.365-.133-2.164-.61-2.746a2.7 2.7 0 0 0-.377-.377C10.387 2.4 9.269 2.4 7.033 2.4s-3.353 0-4.105.617a2.7 2.7 0 0 0-.377.377c-.618.753-.618 1.87-.618 4.106"
    />
  </svg>
);
export default SvgZoomIcon;
