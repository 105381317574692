import React, { useEffect, useState } from "react";
import { Toast } from "flowbite-react";
import { HiCheck, HiOutlineExclamation, HiX } from "react-icons/hi";

interface NotificationProps {
  type: "SUCCESS" | "FAILURE" | "OTHER";
  message: string;
}

const Notification: React.FC<NotificationProps> = ({ type, message }) => {
  const [visible, setVisible] = useState(true);

  let icon: React.ReactElement;
  let bgColor: string;
  let iconColor: string;
  let textColor: string;

  switch (type) {
    case "SUCCESS":
      icon = <HiCheck className="h-5 w-5" />;
      bgColor = "bg-green-100";
      textColor = "text-emerald-600";
      iconColor = "text-green-500 dark:bg-green-800 dark:text-green-200";
      break;
    case "FAILURE":
      icon = <HiOutlineExclamation className="h-5 w-5" />;
      bgColor = "bg-red-100";
      textColor = "text-red-600";
      iconColor = "text-red-500 dark:bg-red-800 dark:text-red-200";
      break;
    default:
      icon = <HiX className="h-5 w-5" />;
      bgColor = "bg-orange-100";
      textColor = "text-orange-600";
      iconColor = "text-orange-500 dark:bg-orange-700 dark:text-orange-200";
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000); // 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {visible && (
        <div className="my-4">
          <Toast
            className={`${bgColor} w-[600px] h-10 px-3 py-2.5 rounded-md justify-start items-start gap-3 inline-flex`}
            style={{ maxWidth: "80%" }}
          >
            <div
              className={`inline-flex items-center justify-center rounded-lg ${bgColor} ${iconColor}`}
            >
              {icon}
            </div>
            <div className={`ml-3 text-sm font-normal ${textColor}`}>
              {message}
            </div>
            <Toast.Toggle />
          </Toast>
        </div>
      )}
    </>
  );
};

export default Notification;
