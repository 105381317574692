import * as React from "react";
import type { SVGProps } from "react";
const SvgMarketing = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path d="M26.6667 9.33334L5.33342 9.33334" stroke="#252525" strokeWidth="2" strokeLinecap="round"/>
    <path d="M26.6667 16L5.33342 16" stroke="#252525" strokeWidth="2" strokeLinecap="round"/>
    <path d="M26.6667 22.6667L5.33342 22.6667" stroke="#252525" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);
export default SvgMarketing;
