import { OfferProvider } from './utils/OfferProvider';
import CreateOfferSteps from './steps';
import React from 'react';

const CreateOffer = () => {
  return (
    <OfferProvider>
      <CreateOfferSteps />
    </OfferProvider>
  );
};

export default CreateOffer;
