import React, { useState, FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Sidebar, Flowbite } from 'flowbite-react';
import { useEffect } from 'react';
import {
  MarketingIcon,
  MarketingActive,
  MembersIcon,
  MembersActive,
  OffboardingIcon,
  OffboardingActive,
  OnboardingIcon,
  OnboardingActive,
  ReportsIcon,
  ReportsActive,
  ArrowDown,
  ArrowUp,
} from '../../components/icons';
import { cn } from '../utils/cn';
import { boolean } from '../utils/boolean';

function SidebarNav({ openMobileDevice, menuMobileClicked }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const showMagazineRecipients = boolean(import.meta.env.VITE_MAGAZINE_RECIPIENTS_FEATURE);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowSize < 1024;

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const renderChevron = (open) =>
    open ? (
      <ArrowUp aria-hidden="true" className="w-5 h-5" />
    ) : (
      <ArrowDown aria-hidden="true" className="w-5 h-5" />
    );

  const handleItemClick = (path) => {
    navigate(path);
    if (isMobile) menuMobileClicked();
  };

  const iconHOC =
    (Icon: FC<React.SVGProps<SVGSVGElement>>, active: boolean) =>
    (props?: React.SVGProps<SVGSVGElement>) =>
      (
        <Icon
          {...props}
          className={`h-5 w-5 ${active ? 'text-black' : 'text-gray-400'}`}
          style={{ width: '20px', height: '20px' }}
        />
      );

  const chooseIcon = (
    defaultIcon: FC<React.SVGProps<SVGSVGElement>>,
    activeIcon: FC<React.SVGProps<SVGSVGElement>>,
    isActive: boolean,
  ) => {
    return isActive
      ? iconHOC(activeIcon, isActive)
      : iconHOC(defaultIcon, isActive);
  };

  const onboardingPaths = [
    '/coach-maintenance',
    '/sales-report',
    '/team-meetings',
    '/team-phone-calls',
  ];
  const marketingPaths = ['/offers'];
  const isOnboardingActive = onboardingPaths.some((path) =>
    activePath.startsWith(path),
  );
  const isMarketingActive = marketingPaths.some((path) =>
    activePath.startsWith(path),
  );
  const isReportsActive = [
    '/tripwire-report',
    '/rm-magazine-report',
    '/magazine-recipient',
  ].some((path) => activePath.startsWith(path));
  const isOffboardingActive = activePath.includes('/offboarding');
  const isMembersActive = activePath.includes('/member-password-change');

  return (
    <div className="fixed top-14 left-0 max-[1024px]:w-full max-[1024px]:px-1 items-center">
      <Flowbite>
        <Sidebar
          aria-label="Sidebar with dynamic navigation items"
          className={cn('border-gray-200 bg-white overflow-x-visible', {
            'h-fit border w-[calc(100%-8px)] shadow-box rounded-b-lg ml-1':
              isMobile,
            'border-r border-t h-screen w-[272px]': !isMobile,
            hidden: !openMobileDevice && isMobile,
          })}
        >
          <Sidebar.Items className="border-none">
            <Sidebar.ItemGroup className="border-none mt-0">
              <Sidebar.Collapse
                icon={chooseIcon(
                  OnboardingIcon,
                  OnboardingActive,
                  isOnboardingActive,
                )}
                label="Onboarding"
                className="hover:bg-[#EDFAFC] sidebar-text-style py-[9.5px]"
                renderChevronIcon={(theme, open) => renderChevron(open)}
              >
                {onboardingPaths.map((path, index) => (
                  <Sidebar.Item
                    key={index}
                    onClick={() => handleItemClick(path)}
                    className={`hover:bg-[#EDFAFC] ${
                      path === activePath || activePath.startsWith(path)
                        ? 'bg-sidebar-item-selected relative sidebar-item-active'
                        : ''
                    } sidebar-text-style pl-7 py-[9.5px]`}
                  >
                    {
                      [
                        'Coach Maintenance',
                        'Sales Report',
                        'Team Meetings',
                        'Team Phone Calls',
                      ][index]
                    }
                  </Sidebar.Item>
                ))}
              </Sidebar.Collapse>
              <Sidebar.Collapse
                icon={chooseIcon(
                  MarketingIcon,
                  MarketingActive,
                  isMarketingActive,
                )}
                label="Marketing"
                className="hover:bg-[#EDFAFC] sidebar-text-style py-[9.5px]"
                renderChevronIcon={(theme, open) => renderChevron(open)}
              >
                <Sidebar.Item
                  onClick={() => handleItemClick('/offers')}
                  className={`hover:bg-[#EDFAFC] ${
                    isMarketingActive
                      ? 'bg-sidebar-item-selected relative sidebar-item-active'
                      : ''
                  } sidebar-text-style pl-7 py-[9.5px]`}
                >
                  Offers
                </Sidebar.Item>
              </Sidebar.Collapse>
              <Sidebar.Collapse
                icon={chooseIcon(ReportsIcon, ReportsActive, isReportsActive)}
                label="Reports"
                className="hover:bg-[#EDFAFC] sidebar-text-style py-[9.5px]"
                renderChevronIcon={(theme, open) => renderChevron(open)}
              >
                {[
                  { route: '/tripwire-report', title: 'Tripwire Sales Report' },
                  { route: '/rm-magazine-report', title: 'RM Magazine' },
                  showMagazineRecipients ? { route: '/magazine-recipient', title: 'Magazine Recipients' } : null,
                ].filter((subItem): subItem is { route: string; title: string } => subItem !== null).map((subItem) => (
                  <Sidebar.Item
                    key={subItem.route}
                    onClick={() => handleItemClick(subItem.route)}
                    className={`hover:bg-[#EDFAFC] ${
                      subItem.route === activePath ||
                      activePath.startsWith(subItem.route)
                        ? 'bg-sidebar-item-selected relative sidebar-item-active'
                        : ''
                    } sidebar-text-style pl-7 py-[9.5px]`}
                  >
                    {subItem.title}
                  </Sidebar.Item>
                ))}
              </Sidebar.Collapse>
              <Sidebar.Item
                icon={chooseIcon(
                  OffboardingIcon,
                  OffboardingActive,
                  isOffboardingActive,
                )}
                onClick={() => handleItemClick('/offboarding')}
                className={`hover:bg-[#EDFAFC] ${
                  '/offboarding' === activePath
                    ? 'bg-sidebar-item-selected relative sidebar-item-active'
                    : ''
                } sidebar-text-style py-[9.5px]`}
              >
                Offboarding
              </Sidebar.Item>
              <Sidebar.Item
                icon={chooseIcon(MembersIcon, MembersActive, isMembersActive)}
                onClick={() => handleItemClick('/member-password-change')}
                className={`hover:bg-[#EDFAFC] ${
                  '/member-password-change' === activePath
                    ? 'bg-sidebar-item-selected relative  sidebar-item-active'
                    : ''
                } sidebar-text-style py-[9.5px]`}
              >
                Members
              </Sidebar.Item>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
      </Flowbite>
    </div>
  );
}

export default SidebarNav;
