import React, {FocusEventHandler} from 'react';
import { Button as FlowButton } from 'flowbite-react';
import type { CustomFlowbiteTheme } from 'flowbite-react';
import classNames from 'classnames';

const customTheme: CustomFlowbiteTheme['button'] = {
  color: {
    primary:
      'bg-primary-500 hover:bg-primary-400 focus:shadow-outline-white shadow-outline-blue',
    dark: 'border border-transparent bg-[#252525] text-white focus:ring-4 focus:ring-gray-300 enabled:hover:bg-[#3C3C3C] dark:border-gray-700 dark:bg-gray-800 dark:focus:ring-gray-800 dark:enabled:hover:bg-gray-700',
    light:
      'border border-neutral-200 bg-white text-neutral-800 focus:border-2 focus:border-primary-400',
    failure:
      'border border-transparent bg-error-500 text-white focus:ring-4 focus:ring-error-300 enabled:hover:bg-error-700',
    modal: 'bg-error-500 hover:bg-error-600',
    gray:
      'border-none bg-neutral-100 text-neutral-800 hover:bg-neutral-200 focus:text-bold',
  },
};

interface ButtonProps {
  type?: 'submit' | 'button' | 'reset';
  disabled?: boolean;
  onClick?: () => void;
  variant?: string;
  size?: string;
  className?: string;
  children: React.ReactNode;
  isProcessing?: boolean;
  onBlur?:  FocusEventHandler<HTMLInputElement>;
}

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  disabled = false,
  onClick,
  variant = 'PRIMARY',
  size = 'md',
  className = '',
  children,
  isProcessing,
  onBlur,
}) => {
  let color: string;

  switch (variant) {
    case 'PRIMARY':
      color = 'primary';
      break;
    case 'OUTLINED':
      color = 'light';
      break;
    case 'DARK':
      color = 'dark';
      break;
    case 'LIGHT':
      color = 'light';
      break;
    case 'DANGER':
      color = 'failure';
      break;
    case 'MODAL':
      color = 'modal';
      break;
    case 'GRAY':
      color = 'gray';
      break;
    default:
      color = 'primary';
  }



  return (
    <FlowButton
      type={type}
      disabled={disabled}
      size={size}
      onClick={onClick}
      onBlur={onBlur}
      theme={customTheme}
      color={color}
      className={classNames('font-bold', !disabled ? 'focus:border-primary-400' : 'cursor-not-allowed',className)}
      isProcessing={isProcessing}
    >
      {children}
    </FlowButton>
  );
};

export default Button;
