import axios, { AxiosInstance } from 'axios';

const dsInstance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_DIGITAL_SERVICES_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `afy-api-key ${import.meta.env.VITE_DS_API_KEY}`,
    Accept: 'application/json',
  },
});

const pcInstance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_PRODUCT_CATALOG_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `prc-api-key ${import.meta.env.VITE_PRC_API_KEY}`,
    Accept: 'application/json',
  },
});

export const getInstance = (): AxiosInstance => dsInstance;
export const getPCInstance = (): AxiosInstance => pcInstance;
