import { getOffboardingInstance } from "./instances/offboarding";
import {
  GetPreviewDto,
  GetPreviewsDto,
} from "./instances/dto/getCustomers.dto";

const instance = getOffboardingInstance();

export const getCustomers = async (query: string) => {
  const url = "offboarding";
  const params = { query };

  return instance
    .get<GetPreviewsDto>(url, { params })
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getPreview = async (previewId: string) => {
  const url = `offboarding/preview/${previewId}`;
  return instance
    .get<GetPreviewDto>(url)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const createPreviewPlan = async (previewId: string) => {
  const url = "offboarding/preview";
  const body = { id: previewId };
  return instance
    .post(url, body)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const executeOffboardingPlan = async (
  previewId: string,
  publicationIds: string[]
) => {
  const url = "offboarding/execute";
  const body = {
    previewId,
    publicationIds,
  };
  return instance
    .post(url, body)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};
