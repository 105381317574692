import React, { ChangeEvent } from 'react';
import { Checkbox as CheckboxBase } from "flowbite-react";

const defaultCheckboxTheme = {
    root: {
      base: 'h-4 w-4 rounded border border-neutral-300 focus:ring-2',
      color: {
        default: 'text-primary-500 focus:ring-primary-400',
        checked: 'text-primary-700 bg-primary-500 border-transparent',
      },
    },
};

interface CheckboxProps {
    id: string;
    checked: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    readOnly?: boolean;
}
  
const Checkbox = ({id, checked, onChange, readOnly}: CheckboxProps) => {
    return <CheckboxBase theme={defaultCheckboxTheme}  id={id} checked={checked} onChange={onChange} readOnly={readOnly} />;
};

export default Checkbox;