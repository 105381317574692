import * as React from "react";
import type { SVGProps } from "react";
const SvgExportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#252525"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path d="M2.5 12.5c0 2.357 0 3.535.732 4.268s1.911.732 4.268.732h5c2.357 0 3.535 0 4.268-.732.732-.733.732-1.911.732-4.268M10 2.5v10.833m0 0 3.333-3.646M10 13.333 6.667 9.687" />
    </g>
  </svg>
);
export default SvgExportIcon;
