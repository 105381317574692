import React, { useState } from 'react';
import { Label } from 'flowbite-react';
import { SvgPlusIcon } from '../../../components/icons';
import GalleryComponent from './GalleryComponent';
import UploadModalTemplate from './UploadModalTemplate';
import useFileUpload from '../../utils/useFileUpload';

interface photoGalleryItem {
  _id: string;
  url: string;
  createdAt?: string;
  updatedAt?: string;
}
interface UploadPhotoModalProps {
  show: boolean;
  onClose: () => void;
  onUpload: (url: string) => void;
  photoGalleryList?: photoGalleryItem[] | null;
}

const UploadPhotoModal: React.FC<UploadPhotoModalProps> = ({
  show,
  onClose,
  onUpload,
  photoGalleryList = null,
}) => {
  const [tabActive, setTabActive] = useState<'upload' | 'photo-gallery'>(
    'upload',
  );
  const { imageSrc, isSaving, handleFileChange, clearImage, showImage } =
    useFileUpload(onUpload, () => {
      clearImage();
      onClose();
    });

  return (
    <UploadModalTemplate
      show={show}
      onClose={() => {
        clearImage();
        onClose();
      }}
      title="Upload Photo"
      isSaving={isSaving}
      onSave={showImage}
    >
      <ul className="flex gap-8 text-sm text-neutral-600 font-semibold px-1 cursor-pointer border-neutral-200 border-b mb-6">
        <li
          className={`pb-4 px-1 ${
            tabActive === 'upload' &&
            'border-primary-500 border-b-[2px] text-neutral-800'
          }`}
        >
          <button onClick={() => setTabActive('upload')}>Upload</button>
        </li>
        {photoGalleryList && (
          <li
            className={`pb-4 px-1 ${
              tabActive === 'photo-gallery' &&
              'border-primary-500 border-b-[2px] text-neutral-800'
            }`}
          >
            <button onClick={() => setTabActive('photo-gallery')}>
              Photo gallery
            </button>
          </li>
        )}
      </ul>
      {tabActive === 'upload' && !imageSrc && (
        <div className="w-full items-center justify-center max-h-[500px] flex">
          <Label
            htmlFor="dropzone-file"
            className="flex h-80 w-full cursor-pointer flex-col items-center justify-center rounded-lg border border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              <SvgPlusIcon />
              <div className="action flex items-center gap-2">
                <div className="flex items-center text-primary-500 text-sm font-semibold">
                  Browse files
                </div>
                <div className="text-neutral-600 text-sm font-semibold">
                  or drag and drop
                </div>
              </div>
              <p className="text-neutral-400 text-center text-xs font-medium">
                Works with any .PNG, .JPG, or .JPEG file.
              </p>
            </div>
            <input
              type="file"
              id="dropzone-file"
              className="hidden"
              accept="image/png, image/jpg, image/jpeg, image/webp"
              onChange={handleFileChange}
            />
          </Label>
        </div>
      )}
      {tabActive === 'upload' && imageSrc && (
        <div className="w-full flex flex-col items-center">
          <img
            src={imageSrc}
            alt="Selected for upload"
            className="max-w-full h-auto"
          />
        </div>
      )}
      {photoGalleryList && tabActive === 'photo-gallery' && (
        <div className="w-full items-center justify-center flex border border-neutral-300 border-dashed p-[22px] rounded-md">
          <GalleryComponent
            type="photo-gallery"
            photoGallery={photoGalleryList}
          />
        </div>
      )}
    </UploadModalTemplate>
  );
};

export default UploadPhotoModal;
