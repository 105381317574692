import * as React from "react";
import type { SVGProps } from "react";
const SvgOutbound = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12px"
    height="12px"
    fill="white"
    {...props}
  >
    <path
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="m3 9 6-6m0 0H4.5M9 3v4.5"
    />
  </svg>
);
export default SvgOutbound;
