import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { PhoneOrange, ArrowRight, Inbound, Outbound, ArrowDown, XIcon, InfoIcon } from "../../../../components/icons";
import Avatar from "../../../components/Avatar";

import { getPlayCallZoomUrl, getZoomCallRecordsByEmail, getZoomTeamMembersList  } from "../../../api/zoom";
import { formatDateWithTimeZone } from "../../../../utils/date";
import NextPrev from "../../../components/NextPrev";
import Loader from "../../../components/Loaders";
import { subMonths, addMonths, differenceInCalendarMonths } from 'date-fns';
import noItemsImage from '../../../../assets/images/no-items.png';
import TextInput from "../../../components/TextInput";


export default function TeamPhoneCallsReport() {
    const navigate = useNavigate();
    const { teamMemberEmail, currToken = 'first', currPage = '1' } = useParams();
    const [fullName, setFullName] = useState('');
    const [agentNumber, setAgentNumber] = useState('');
    const decodeEmail = decodeURIComponent(teamMemberEmail);
    const currentTablePage = parseInt(currPage, 10);
    const [dateRange, setDateRange] = useState<DateValueType>({ startDate: null, endDate: null });
    const [filterResult, setFilterResult] = useState('');
    const [searchNumber, setSearchNumber] = useState('');
    const [perPage, setPerPage] = useState(20);
    
    const [nextPageToken, setNextPageToken] = useState(currToken || 'first');
    const [hasNextPage, setHasNextPage] = useState(false);

    useEffect(() => {
        if (currToken) {
            setNextPageToken(currToken);
        }
    }, [currToken]);

    const { data: teamMembers, isLoading: isLoadingMembers } = useQuery(
        'getZoomTeamMembersList',
        getZoomTeamMembersList,
        {
            onSuccess: data => {
                const member = data.find(m => m.email === decodeEmail);
                if (member) {
                    setFullName(member.fullName);
                }
            }
        }
    );

    const queryKey = ["getZoomCallRecordsByEmail", decodeEmail, currentTablePage, perPage, dateRange.startDate, dateRange.endDate, nextPageToken, searchNumber];

    const { data: callData, isLoading, isError, error, refetch } = useQuery(
        queryKey,
        () => {
            if (nextPageToken) {
                return getZoomCallRecordsByEmail(decodeEmail, currentTablePage, perPage, dateRange.startDate, dateRange.endDate, nextPageToken, searchNumber);
            }
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: false,
            onSuccess: data => {
                if (data && data.meta && data.data) {
                    setHasNextPage(!!data.data.next_page_token);
                } else {
                    console.error('Unexpected API response format:', data);
                }
            },
            onError: (err) => {
                console.error('Failed to fetch call records:', err);
                if (err.response && err.response.status === 500 && err.response.data.errors.includes("Cannot read properties of undefined (reading 'map')")) {
                    if (!agentNumber){
                        setAgentNumber("Number Not Available");
                    }
                } 
            }
        }
    );

    useEffect(() => {
        if (callData && callData.data && callData.data.call_logs.length > 0) {
            const firstName = fullName.split(' ')[0];
    
            for (const call of callData.data.call_logs) {
                if (call.callee_name === fullName || call.callee_name === firstName) {
                    setAgentNumber(call.callee_number);
                    return;
                } else if (call.caller_name === fullName || call.caller_name === firstName) {
                    setAgentNumber(call.caller_number);
                    return;
                }
            }
                setAgentNumber("Number Not Available");
        }
    }, [callData, fullName]);

    useEffect(() => {
        refetch();
    }, [currentTablePage, refetch, nextPageToken]);

    const goToNextPage = () => {
        const nextPageToken = callData.data.next_page_token;
        if (nextPageToken) { 
            const nextPageNumber = currentTablePage + 1;
            navigate(`/team-phone-calls/${encodeURIComponent(decodeEmail)}/${nextPageToken}/${nextPageNumber}`);
        }
    };

    const { mutate: getPlayZoomUrlMutate } = useMutation(getPlayCallZoomUrl);

    const callLogs = Array.isArray(callData?.data?.call_logs) ? callData.data.call_logs : [];
    const results = [...new Set(callLogs.map(log => log.result))].sort();

    const filteredCalls = callLogs.filter(call =>
        (filterResult ? call.result === filterResult : true) &&
        (searchNumber ? (call.direction === 'inbound' ? call.caller_number.includes(searchNumber) : call.callee_number.includes(searchNumber)) : true)
    );

    const groupedCalls = filteredCalls.reduce((acc, call) => {
        const date = formatDateWithTimeZone(call.date_time, "MMMM DD, YYYY", "America/New_York");
        if (!acc[date]) acc[date] = [];
        acc[date].push(call);
        return acc;
    }, {});

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const validateDateRange = (startDate, endDate) => {
        const sixMonthsAgo = subMonths(new Date(), 6);
        const oneMonthLater = addMonths(new Date(startDate), 1);
    
        if (startDate < sixMonthsAgo || endDate > new Date()) {
            return "Date range should be within the last 6 months.";
        } else if (differenceInCalendarMonths(endDate, startDate) > 0 || endDate < startDate) {
            return "Date range should not exceed one month.";
        }
        return null;
    };

    const handleDateChange = (newRange) => {
        if (!newRange.startDate && !newRange.endDate) {
            setDateRange({ startDate: null, endDate: null });
            navigate(`/team-phone-calls/${encodeURIComponent(decodeEmail)}/first/1`);
            return;
        }
    
        const error = validateDateRange(newRange.startDate, newRange.endDate);
        if (error) {
            alert(error);
            return;
        }
    
        setDateRange(newRange);
        navigate(`/team-phone-calls/${encodeURIComponent(decodeEmail)}/first/1`);
    };

    const handleOpen = (id, recordingId) => {
        if (recordingId) {
            getPlayZoomUrlMutate(id, {
                onSuccess: (data) => {
                    const link = document.createElement("a");
                    link.href = data;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            });
        }
    };

    const formatDuration = (duration, recordingId) => {
        if (!duration) return "No Recording";
        const displayDuration = `${Math.floor(duration / 60) > 0 ? `${Math.floor(duration / 60)} min, ` : ""}${duration % 60} sec`;
        return recordingId ? displayDuration : `${displayDuration} (No Recording)`;
    };

    const getCallIcon = (call) => {
        const IconComponent = call.direction === 'inbound' ? Inbound : Outbound;
        const noRecordingStatuses = ["No Answer", "Call Cancelled", "Answered by Other Member", "Busy"];
        const isNoRecording = noRecordingStatuses.includes(call.result);
        return (
            <div className={`w-[22px] h-[22px] ${isNoRecording || !call.recording_id ? 'bg-gray-400' : 'bg-sky-400'} rounded-full p-1 shadow flex justify-center items-center`}>
                <IconComponent className="text-white" />
            </div>
        );
    };

    const customToggleIcon = () => {
      return <ArrowDown/>;
    };

    const clearInput = () => {
      setSearchNumber('');
    };

    if (isLoading) return <Loader />

    return (
        <div className="w-full flex flex-col justify-start items-start gap-6 px-8 pt-16 mb-24">
            <div className="flex items-center gap-2.5 cursor-pointer" onClick={() => navigate("/team-phone-calls")}>
                <div className="text-sm font-medium text-neutral-600">Team Phone Calls</div>
                <ArrowRight className="w-4 h-4 text-neutral-500" />
                <div className="text-sm font-semibold text-neutral-700 border-b border-zinc-300">{fullName}</div>
            </div>
            <div className="flex items-center gap-3.5">
                <Avatar name={fullName} />
                <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-2">
                        <div className="text-2xl font-extrabold text-neutral-800">{fullName}</div>
                        <div className="flex items-center px-2 py-1 bg-orange-100 rounded-md">
                            <PhoneOrange className="w-4 h-4 text-orange-500 mr-2" />
                            <div className="text-xs font-semibold text-orange-600">{agentNumber || "Not Available"}</div>
                        </div>
                    </div>
                    <div className="text-sm font-medium text-zinc-500">{decodeEmail}</div>
                </div>
            </div>
            <div className="flex flex-col self-stretch bg-white rounded-lg shadow w-full">
                <div className="flex items-center justify-between self-stretch px-6 py-4 border-b border-gray-200 flex-wrap gap-2">
                      <div className="relative w-[330px] max-[450px]:w-full">
                        <TextInput
                          placeholder="Search for number"
                          value={searchNumber}
                          onChange={(e) => setSearchNumber(e.target.value)}
                        />
                        {searchNumber && (
                          <button
                              onClick={clearInput}
                              className="absolute inset-y-0 right-0 pr-3 flex items-center"
                          >
                              <XIcon className="h-5 w-5 text-gray-500" />
                          </button>
                        )}
                      </div>
                    <select
                        className="h-[46px] focus:shadow-none focus:border-2 focus:ring-transparent focus:border-sky-400 bg-white border border-gray-200 rounded-md px-5 text-sm text-neutral-800 mr-auto"
                        value={filterResult}
                        onChange={e => setFilterResult(e.target.value)}
                      >
                        <option value="">All status</option>
                        {results.map(result => (
                                <option key={result} value={result}>{result}</option>
                        ))}
                      </select>
                    <div><Datepicker
                        placeholder="All time"
                        separator="-"
                        primaryColor="sky"
                        value={dateRange}
                        onChange={handleDateChange}
                        showShortcuts={true}
                        showFooter={true}
                        readOnly={true}
                        popoverDirection="down"
                        displayFormat="MMM DD, YYYY"
                        minDate={subMonths(new Date(), 6)}
                        maxDate={new Date()}
                        toggleIcon={customToggleIcon}
                        inputClassName="w-auto h-[46px] pl-5 pr-[15px] py-[13px] focus:shadow-none focus:border-2 focus:ring-transparent focus:border-sky-400 bg-white rounded-md border border-gray-200 text-sm text-neutral-800"
                    /></div>
                </div>
                <div className="h-10 max-[450px]:h-14 px-3 py-2.5 bg-neutral-100 justify-start items-start gap-3 inline-flex">
                    <InfoIcon/>
                    <span className="text-neutral-700 text-sm font-semibold">Records older than 6 months are not stored.</span>
                </div>
                {Object.keys(groupedCalls).length === 0 || isError? (
                    <div className="w-full h-[382px] py-[100px] flex flex-col justify-center items-center gap-3.5">
                        <img className="w-[182px] h-[121px] object-cover" src={noItemsImage} alt="No items found" />
                        <div className="self-stretch h-[47px] flex flex-col justify-center items-center gap-1">
                            <div className="self-stretch text-center text-neutral-800 text-base font-semibold">
                                No records found.
                            </div>
                        </div>
                    </div>
                ) : Object.entries(groupedCalls).map(([date, calls], idx) => (
                    <div key={date} className={`flex flex-col gap-2 p-6 border-b border-gray-200 ${idx % 2 === 0 ? 'bg-white' : 'bg-[#FAFAFA]'} rounded-lg shadow w-full`}>
                            <div className="text-neutral-800 text-sm font-semibold">{date}</div>
                            {calls.map((call, index) => (
                                <div
                                key={call.id}
                                tabIndex={0}
                                role="button"
                                className={`flex items-center gap-3.5 p-3.5 rounded-lg border ${call.recording_id ? 'hover:shadow-[inset_0_0_0_1px_rgba(56,189,248,1)] hover:border-sky-400 cursor-pointer border-gray-200 bg-white' : 'cursor-default border-gray-200 bg-[#FAFAFA]'}`}
                                onClick={() => call.recording_id && handleOpen(call.id, call.recording_id)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === 'Space') {
                                    call.recording_id && handleOpen(call.id, call.recording_id);
                                    e.preventDefault();
                                    }
                                }}
                                >
                                    <div className="flex grow items-center gap-2 flex-wrap">
                                        {getCallIcon(call)}
                                        <span className="text-sm font-semibold text-neutral-800">{formatDateWithTimeZone(call.date_time, "hh:mm A", "America/New_York")} EST</span>
                                        <span className="text-sm font-semibold text-neutral-800">·</span>
                                        <div className={`px-2 py-1 rounded-md text-xs font-semibold ${["No Answer", "Answered by Other Member", "Call Cancelled", "Busy"].includes(call.result) ? 'bg-neutral-100 text-neutral-800' : 'bg-emerald-50 text-emerald-600'}`}>
                                            <div className="text-center">{call.result}</div>
                                        </div>
                                        <span className="text-sm font-semibold text-neutral-800">·</span>
                                        <span className="text-sm font-medium text-neutral-500">{call.direction === 'inbound' ? call.caller_number : call.callee_number}</span>
                                        <span className="text-sm font-semibold text-neutral-800">·</span>
                                        <span className="text-sm font-medium text-neutral-500">{formatDuration(call.duration, call.recording_id)}</span>
                                    </div>
                                </div>
                          ))}
                    </div>
                ))}
                <div className="mx-auto py-4">
                {Object.keys(groupedCalls).length > 0 && (
                    <div className="mx-auto py-4">
                        <NextPrev
                            hasNextPage={hasNextPage}
                            decodeEmail={decodeEmail}
                            nextPageToken={nextPageToken}
                            currentPage={currentTablePage.toString()}
                            perPage={perPage.toString()}
                            goToNextPage={goToNextPage}
                        />
                    </div>
                )}
            </div>
            </div>
            
        </div>
    );
}
