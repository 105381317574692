import React from "react";

function SvgQRCode() {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M41.3124 5H41.483C43.6924 4.99998 45.4743 4.99996 46.9087 5.13635C48.388 5.277 49.6768 5.57491 50.8357 6.28512C52.0092 7.00421 52.9958 7.99081 53.7149 9.16426C54.4251 10.3232 54.723 11.612 54.8636 13.0913C55 14.5257 55 16.3076 55 18.5171V18.6879C55 20.1397 55.0001 21.3512 54.9065 22.3352C54.8087 23.364 54.5964 24.3227 54.0576 25.202C53.5302 26.0626 52.8067 26.7861 51.9462 27.3134C51.0669 27.8523 50.1082 28.0645 49.0794 28.1623C48.0954 28.2559 46.8838 28.2558 45.432 28.2558L42.6697 28.2558C40.5802 28.2559 38.8379 28.2559 37.4547 28.07C35.9947 27.8737 34.6766 27.4419 33.6173 26.3827C32.5581 25.3234 32.1263 24.0053 31.93 22.5453C31.7441 21.1621 31.7441 19.4197 31.7442 17.3302L31.7442 14.5681C31.7442 13.1164 31.7441 11.9046 31.8377 10.9206C31.9355 9.89184 32.1477 8.93313 32.6866 8.05379C33.2139 7.19326 33.9374 6.46976 34.798 5.94242C35.6773 5.40356 36.636 5.19132 37.6648 5.09351C38.6488 4.99994 39.8605 4.99997 41.3124 5ZM43.3721 19.5349C42.1735 19.5349 41.5742 19.5349 41.151 19.2346C41.0017 19.1287 40.8713 18.9983 40.7654 18.849C40.4651 18.4258 40.4651 17.8265 40.4651 16.6279C40.4651 15.4293 40.4651 14.83 40.7654 14.4068C40.8713 14.2575 41.0017 14.1271 41.151 14.0212C41.5742 13.7209 42.1735 13.7209 43.3721 13.7209C44.5707 13.7209 45.17 13.7209 45.5932 14.0212C45.7425 14.1271 45.8729 14.2575 45.9788 14.4068C46.2791 14.83 46.2791 15.4293 46.2791 16.6279C46.2791 17.8265 46.2791 18.4258 45.9788 18.849C45.8729 18.9983 45.7425 19.1287 45.5932 19.2346C45.17 19.5349 44.5707 19.5349 43.3721 19.5349Z" fill="#3C3C3C" />
      <path fillRule="evenodd" clipRule="evenodd" d="M25.202 5.94242C24.3227 5.40356 23.364 5.19132 22.3352 5.09351C21.3512 4.99994 20.1396 4.99997 18.6877 5H18.5171C16.3077 4.99998 14.5257 4.99996 13.0913 5.13635C11.612 5.277 10.3232 5.57491 9.16426 6.28512C7.99081 7.00421 7.00421 7.99081 6.28512 9.16426C5.57491 10.3232 5.277 11.612 5.13635 13.0913C4.99996 14.5257 4.99998 16.3076 5 18.517V18.6876C4.99997 20.1395 4.99994 21.3512 5.09351 22.3352C5.19132 23.364 5.40356 24.3227 5.94242 25.202C6.46976 26.0626 7.19326 26.7861 8.05379 27.3134C8.93313 27.8523 9.89184 28.0645 10.9206 28.1623C11.9046 28.2559 13.1161 28.2558 14.5678 28.2558L17.3302 28.2558C19.4197 28.2559 21.1621 28.2559 22.5453 28.07C24.0053 27.8737 25.3234 27.4419 26.3827 26.3827C27.4419 25.3234 27.8737 24.0053 28.07 22.5453C28.2559 21.1621 28.2559 19.4198 28.2558 17.3303L28.2558 14.5681C28.2558 13.1165 28.2559 11.9046 28.1623 10.9206C28.0645 9.89184 27.8523 8.93313 27.3134 8.05379C26.7861 7.19326 26.0626 6.46976 25.202 5.94242ZM14.4068 19.2346C14.83 19.5349 15.4293 19.5349 16.6279 19.5349C17.8265 19.5349 18.4258 19.5349 18.849 19.2346C18.9983 19.1287 19.1287 18.9983 19.2346 18.849C19.5349 18.4258 19.5349 17.8265 19.5349 16.6279C19.5349 15.4293 19.5349 14.83 19.2346 14.4068C19.1287 14.2575 18.9983 14.1271 18.849 14.0212C18.4258 13.7209 17.8265 13.7209 16.6279 13.7209C15.4293 13.7209 14.83 13.7209 14.4068 14.0212C14.2575 14.1271 14.1271 14.2575 14.0212 14.4068C13.7209 14.83 13.7209 15.4293 13.7209 16.6279C13.7209 17.8265 13.7209 18.4258 14.0212 18.849C14.1271 18.9983 14.2575 19.1287 14.4068 19.2346Z" fill="#3C3C3C" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5453 31.93C24.0053 32.1263 25.3234 32.5581 26.3827 33.6173C27.4419 34.6766 27.8737 35.9947 28.07 37.4547C28.2559 38.8379 28.2559 40.5803 28.2558 42.6698L28.2558 45.4319C28.2558 46.8837 28.2559 48.0954 28.1623 49.0794C28.0645 50.1082 27.8523 51.0669 27.3134 51.9462C26.7861 52.8067 26.0626 53.5302 25.202 54.0576C24.3227 54.5964 23.364 54.8087 22.3352 54.9065C21.3512 55.0001 20.1394 55 18.6877 55H18.5171C16.3076 55 14.5257 55 13.0913 54.8636C11.612 54.723 10.3232 54.4251 9.16426 53.7149C7.99081 52.9958 7.00421 52.0092 6.28512 50.8357C5.57491 49.6768 5.277 48.388 5.13635 46.9087C4.99996 45.4743 4.99998 43.6924 5 41.4829V41.3123C4.99997 39.8605 4.99994 38.6488 5.09351 37.6648C5.19132 36.636 5.40356 35.6773 5.94242 34.798C6.46976 33.9374 7.19326 33.2139 8.05379 32.6866C8.93313 32.1477 9.89184 31.9355 10.9206 31.8377C11.9046 31.7441 13.1162 31.7442 14.5679 31.7442L17.3302 31.7442C19.4197 31.7441 21.1621 31.7441 22.5453 31.93ZM16.6279 46.2791C15.4293 46.2791 14.83 46.2791 14.4068 45.9788C14.2575 45.8729 14.1271 45.7425 14.0212 45.5932C13.7209 45.17 13.7209 44.5707 13.7209 43.3721C13.7209 42.1735 13.7209 41.5742 14.0212 41.151C14.1271 41.0017 14.2575 40.8713 14.4068 40.7654C14.83 40.4651 15.4293 40.4651 16.6279 40.4651C17.8265 40.4651 18.4258 40.4651 18.849 40.7654C18.9983 40.8713 19.1287 41.0017 19.2346 41.151C19.5349 41.5742 19.5349 42.1735 19.5349 43.3721C19.5349 44.5707 19.5349 45.17 19.2346 45.5932C19.1287 45.7425 18.9983 45.8729 18.849 45.9788C18.4258 46.2791 17.8265 46.2791 16.6279 46.2791Z" fill="#3C3C3C" />
      <path d="M31.7442 41.5387V41.6279H35.2326C35.2326 39.9585 35.2347 38.8377 35.3216 37.9833C35.4055 37.158 35.5539 36.7767 35.7225 36.5245C35.9346 36.2071 36.2071 35.9346 36.5245 35.7225C36.7767 35.5539 37.158 35.4055 37.9833 35.3216C38.8377 35.2347 39.9585 35.2326 41.6279 35.2326H46.2791V31.7442H41.5387C39.9816 31.7441 38.6822 31.7441 37.6303 31.8511C36.5292 31.9631 35.5074 32.2066 34.5865 32.822C33.8882 33.2886 33.2886 33.8882 32.822 34.5865C32.2067 35.5074 31.9631 36.5292 31.8511 37.6303C31.7441 38.6822 31.7441 39.9816 31.7442 41.5387Z" fill="#3C3C3C" />
      <path d="M55 46.3378V46.2791H51.5116C51.5116 47.3865 51.5107 48.1297 51.4714 48.7052C51.4332 49.2651 51.3647 49.5377 51.2903 49.7171C50.9953 50.4294 50.4294 50.9953 49.7171 51.2903C49.5377 51.3647 49.2651 51.4332 48.7052 51.4714C48.1297 51.5107 47.3865 51.5116 46.2791 51.5116H41.6279V55H46.3378C47.3714 55 48.2343 55 48.9427 54.9517C49.6801 54.9014 50.3767 54.7929 51.052 54.5132C52.6191 53.8641 53.8641 52.6191 54.5132 51.052C54.7929 50.3767 54.9014 49.6801 54.9517 48.9427C55 48.2343 55 47.3714 55 46.3378Z" fill="#3C3C3C" />
      <path d="M35.2326 53.2558C35.2326 54.2191 34.4517 55 33.4884 55C32.5251 55 31.7442 54.2191 31.7442 53.2558V46.2791H35.2326V53.2558Z" fill="#3C3C3C" />
      <path d="M53.2558 31.7442C52.2925 31.7442 51.5116 32.5251 51.5116 33.4884V41.6279H55V33.4884C55 32.5251 54.2191 31.7442 53.2558 31.7442Z" fill="#3C3C3C" />
      <path d="M40.1903 41.5433C40 42.0027 40 42.5851 40 43.75C40 44.9148 40 45.4973 40.1903 45.9567C40.444 46.5693 40.9307 47.056 41.5433 47.3097C42.0027 47.5 42.5851 47.5 43.75 47.5C44.9148 47.5 45.4973 47.5 45.9567 47.3097C46.5693 47.056 47.056 46.5693 47.3097 45.9567C47.5 45.4973 47.5 44.9148 47.5 43.75C47.5 42.5851 47.5 42.0027 47.3097 41.5433C47.056 40.9307 46.5693 40.444 45.9567 40.1903C45.4973 40 44.9148 40 43.75 40C42.5851 40 42.0027 40 41.5433 40.1903C40.9307 40.444 40.444 40.9307 40.1903 41.5433Z" fill="#3C3C3C" />
    </svg>
  );
}

export default SvgQRCode;
