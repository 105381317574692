import OffboardingCardDetails from "./OffboardingCardDetails";
import { GetPreviewDto } from "../../api/instances/dto/getCustomers.dto";
import React from "react";

interface OffboardingCardProps {
  previewData: GetPreviewDto;
}

export default function OffboardingCard({ previewData }: OffboardingCardProps) {
  const data = {
    rmm: {
      subscriptions: [],
      publications: [],
      deals: [],
    },
    afy: {
      subscriptions: [],
      publications: [],
      deals: [],
    },
    others: {
      subscriptions: [],
      publications: [],
      deals: [],
    },
  };

  const populate = () => {
    // look for subscriptions
    previewData?.customer?.subscriptions?.forEach((sub) => {
      if (sub.handle.includes("afy") || sub.handle.includes("authorify")) {
        data.afy.subscriptions.push(sub);
      } else if (sub.handle.includes("rm")) {
        data.rmm.subscriptions.push(sub);
      } else {
        data.others.subscriptions.push(sub);
      }
    });
    // look for publications
    previewData?.plan?.publications.forEach((pub) => {
      if (pub.status === "DELETED") return;

      if (pub?.source === "cover-editor") {
        data.afy.publications.push(pub);
      } else if (pub?.source === "digital-services") {
        data.rmm.publications.push(pub);
      } else {
        data.others.publications.push(pub);
      }
    });

    // look for deals
    previewData.customer.deals.forEach((deal) => {
      const chargifyId = deal?.properties?.chargify_subscription_id;
      if (
        data.afy.subscriptions.find(
          ({ subscriptionId }) => subscriptionId == chargifyId
        )
      ) {
        data.afy.deals.push(deal);
      } else if (
        data.rmm.subscriptions.find(
          ({ subscriptionId }) => subscriptionId == chargifyId
        )
      ) {
        data.rmm.deals.push(deal);
      } else {
        data.others.deals.push(deal);
      }
    });
  };

  const render = () => {
    populate();
    return (
      <>
        <OffboardingCardDetails title={"Book builder (DMP)"} data={data.afy} />
        <OffboardingCardDetails title={"Referral Marketing"} data={data.rmm} />
        <OffboardingCardDetails title={"Others"} data={data.others} />
      </>
    );
  };

  return <div className={"flex gap-4 w-full justify-around max-[1220px]:flex-col max-[1220px]:items-center"}>{render()}</div>;
}
