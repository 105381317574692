/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from "react";
import get from "lodash/get";
import { TextInput } from "flowbite-react";

import ImageUploaderModal from "../ImageUploaderModal";
import { defaultTextInputTheme } from "../../../../../../components/Theme/TextInput";
import { useMagazineImageContext } from "../../../../../../../context/MagazineImageHook";
import { MagazineImageContextType } from "../../../../../../../context/MagazineImageContext";

const SingleInput = ({
  step = "",
  formikHandler,
  uploadImageTitle,
  propertyKey = "",
  imageDimensions,
  uploadImageType,
  presentImageLocation,
}) => {
  const [brokerLogoModal, setBrokerLogoModal] = useState<string | null>(null);
  const { values = {}, setFieldValue, errors } = formikHandler || {};
  const targetLocation = step.concat(".", "_formFields");
  const [isEditImage, setIsEditImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const { setShowModal, setImageType, setSelectedUploadedImage } =
    useMagazineImageContext() as MagazineImageContextType;

  const handleChange = (e) => {
    const {
      target: { name = "", value = "" },
    } = e || {};
    const locationToSave = step.concat(".", "_formFields", ".", name);
    setFieldValue(locationToSave, value);
  };

  const uploadedImagePath = `${step}._formFields.${propertyKey}`;

  const toggleBrokerLogoModal = () => {
    const brokerLogo = brokerLogoModal === "photo" ? "logo" : "photo";
    setBrokerLogoModal(brokerLogo);
  };

  const handleImageUpload = useCallback((value: string, keyName: string) => {
    const locationToSave = step.concat(".", "_formFields", ".", keyName);
    setFieldValue(locationToSave, value);
    setSelectedUploadedImage("");
  }, []);

  const onRemoveImage = (e) => {
    setFieldValue(uploadedImagePath, "");
    e.stopPropagation();
  };

  const onEditImage = () => {
    setIsEditImage(true);
    setImageUrl(get(values, uploadedImagePath));
  };

  const toggleCancelEdit = () => {
    setIsEditImage(!isEditImage);
  };

  return (
    <>
      <div>
        <div className="w-full flex flex-col gap-4">
          <div className="flex flex-col items-start">
            <div className="text-neutral-800 text-sm font-semibold">{uploadImageTitle}</div>
            <div className="text-neutral-500 text-sm font-medium">
              Recommended size: {imageDimensions.width} x {imageDimensions.height}
            </div>
          </div>
          <ImageUploaderModal
            type={uploadImageType}
            modalTitle="Book Image"
            onCancel={toggleBrokerLogoModal}
            handleOpenModal={(type) => {
              setImageType(type);
              setShowModal(true);
            }}
            imageUrl={get(values, uploadedImagePath)}
            onSuccess={(value) => handleImageUpload(value, propertyKey)}
            onRemoveImage={onRemoveImage}
            onEditImage={(e) => {
              onEditImage();
              e.stopPropagation();
            }}
            imageUrlForEdit={imageUrl}
            isEditImage={isEditImage}
            toggleCancelEdit={toggleCancelEdit}
          />
        </div>
      </div>
      <div className="w-[49%]">
        <div className="flex flex-col gap-1">
          <label className="text-sm font-semibold text-neutral-800">Book link</label>
          <TextInput
            theme={defaultTextInputTheme}
            id="bookLink"
            name="bookLink"
            type="text"
            value={get(values, `${targetLocation}.bookLink`) || ""}
            onChange={handleChange}
            placeholder=""
          />
        </div>
        <div className="text-red-500 py-2 text-sm">{get(errors, `${targetLocation}.bookLink`) || ""}</div>
      </div>
    </>
  );
};

export default SingleInput;
