import React from 'react';
import classNames from 'classnames';

const SvgCheckIcon = ({ width = 15, height = 14, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 15 14"
    fill="none"
    className={classNames(className, 'stroke-current')}
  >
    <path
      d="M3.2998 7.7L5.74425 10.5L11.8554 3.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCheckIcon;
