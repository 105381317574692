import * as React from "react";
import type { SVGProps } from "react";

const SvgArrowTail = (props: SVGProps<SVGSVGElement>) => (
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} className={`w-3 h-3 ${props.className}`}>
    <path d="M13.25 4.99967L9.08333 0.833008M13.25 4.99967L9.08333 9.16634M13.25 4.99967L4.91667 4.99967C3.52778 4.99967 0.75 5.83301 0.75 9.16634" stroke="#FAFAFA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgArrowTail;
