import React from 'react';

interface SkeletonCoachesLoaderProps {
  count?: number;
}

const SkeletonCoachesLoader: React.FC<SkeletonCoachesLoaderProps> = ({ count = 10 }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <div
          key={index}
          className="w-full h-52 bg-neutral-100 rounded-lg flex flex-col justify-center items-center overflow-hidden animate-pulse p-4"
        >
          <div className="max-w-[228px] flex flex-col items-center whitespace-nowrap overflow-hidden mt-4">
            <div className="w-16 h-16 bg-zinc-300 rounded-full"></div>
            <div className="w-[110px] h-4 bg-gray-200 rounded-sm mt-2"></div>
            <div className="w-[138px] h-4 bg-gray-200 rounded-sm mt-1"></div>
          </div>
        </div>
    ))}
    </>
  );
};

export default SkeletonCoachesLoader;
