import React from 'react';
import classNames from 'classnames';

const SvgCloseCircle = ({ width = 16, height = 16, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(className, 'stroke-current')}
  >
    <path
      d="M12 4.00003L4 12M3.99997 4L11.9999 12"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default SvgCloseCircle;
