import React from 'react';

interface photoGalleryItem {
  _id: string;
  url: string;
  createdAt?: string;
  updatedAt?: string;
}

interface GalleryComponentProps {
  type: string;
  photoGallery: photoGalleryItem[];
  onSelect: (photo: string) => void;
}

const GalleryComponent: React.FC<GalleryComponentProps> = ({
  type,
  photoGallery,
  onSelect,
}) => {
  return (
    <div className="grid grid-cols-5 gap-4">
      {photoGallery.map((photo) => (
        <button
          key={photo._id}
          className="relative p-0 border-none bg-transparent cursor-pointer"
          onClick={() => onSelect(photo.url)}
          aria-label={`Select gallery item ${photo._id}`}
        >
          <img
            src={photo.url}
            alt={`gallery-item-${photo._id}`}
            className="w-full h-auto rounded-lg"
          />
        </button>
      ))}
    </div>
  );
};

export default GalleryComponent;
