import React from "react";
import { map, get } from "lodash-es";
import urljoin from "url-join";

import { magazineStatus, monthsList, rmMagazineStatus, MAGAZINE_STATUS } from "./constants";
import { postRmMagazineGenerate } from "../../../api/rm-magazine-report";
import { formatDate } from "../../../../utils/date";
import Button from "../../../components/Button";
import { HiOutlineEye } from "react-icons/hi";
import { SvgGenerateIcon } from "../../../../components/icons";
import { months } from "moment";
import { SelectionsType } from "./MagazineDetails/types";

interface MagazineReport {
  customer: string;
  month: string;
  year: string;
  id: string;
  date: string;
  status: string;
  updatedBy: string;
  email: string;
  firstName: string;
  lastName: string;
  preview: GeneratedMagazineType;
  updateDate: string;
}

type GeneratedMagazineType = {
  active: boolean;
  id?: string;
  status?: string;
};

type CustomerInfoType = {
  email: string;
  firstName: string;
  lastName: string;
};

type MagazineDataType<Key extends string, T> = {
  [K in Key]: T[];
};

export const handleShowDate = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const handleFormatDate = (date: string) => {
  return date.replace(/\//g, "-");
};

export const getFullMonth = {
  JAN: "january",
  FEB: "february",
  MAR: "march",
  APR: "april",
  MAY: "may",
  JUN: "june",
  JUL: "july",
  AUG: "august",
  SEP: "september",
  OCT: "october",
  NOV: "november",
  DEC: "december",
};

export const formatMagazinesData = <T,>(magazineData: MagazineDataType<string, T>) => {
  if (!Array.isArray(magazineData)) {
    return;
  }

  const magazineReportArray = map(magazineData, (detailMagazines) => {
    const magazineReport: MagazineReport = {
      customer: "",
      month: "",
      year: "",
      id: "",
      date: "",
      status: "",
      updatedBy: "",
      email: "",
      firstName: "",
      lastName: "",
      preview: {
        active: false,
        id: "",
        status: "",
      },
      updateDate: "",
    };

    Object.entries(detailMagazines).forEach(([magazineKey, magazineValue]) => {
      const nMagazineValue = magazineValue as keyof typeof detailMagazines;

      switch (magazineKey) {
        case "createdAt": {
          const nDate = nMagazineValue as string;
          magazineReport.date = new Date(nDate).toISOString().substring(0, 10);
          break;
        }
        case "createdByAutomation":
          magazineReport.updatedBy = nMagazineValue ? "script" : magazineReport.updatedBy;
          break;
        case "customerInfo": {
          const nMagValue: unknown = nMagazineValue;
          const nValue = nMagValue as CustomerInfoType;
          Object.assign(magazineReport, {
            email: nValue.email ?? magazineReport.email,
            firstName: nValue.firstName ?? magazineReport.firstName,
            lastName: nValue.lastName ?? magazineReport.lastName,
          });
          break;
        }
        case "generatedMagazine": {
          const generatedMagazine =
            Array.isArray(nMagazineValue) && nMagazineValue.find((magazine) => magazine.active === true);
          if (generatedMagazine) {
            magazineReport.preview = {
              active: generatedMagazine.active,
              id: generatedMagazine._id,
              status: generatedMagazine.status,
            };
          }
          break;
        }
        case "updatedAt": {
          const nDate = nMagazineValue as string;
          magazineReport.updateDate = new Date(nDate).toISOString().substring(0, 10);
          break;
        }
        default:
          magazineReport[magazineKey] = nMagazineValue;
      }

      return magazineReport;
    });

    Object.entries(detailMagazines).forEach(([magazineKey, magazineValue]) => {
      if (magazineKey !== "createdByAutomation" && magazineValue !== true) {
        magazineReport.updatedBy = "user";
      }
    });

    return magazineReport;
  });

  return magazineReportArray;
};

export const formatGeneratedMagazinesData = (magazineData) => {
  const magazineReportArray = map(magazineData, (detailMagazines) => {
    const magazineReport = {};

    Object.entries(detailMagazines).forEach(([magazineKey, magazineValue]) => {
      switch (magazineKey) {
        case "_id":
          magazineReport.id = magazineValue;
          break;
        case "createdAt":
          magazineReport.date = new Date(magazineValue).toISOString().substring(0, 10);
          break;
        case "status":
          magazineReport.status = magazineStatus[magazineValue];
          break;
        case "createdByAutomation":
          if (magazineValue === true) {
            magazineReport.updatedBy = "script";
          }
          break;
        case "isPreview":
          if (magazineValue) {
            magazineReport.preview = { active: true, id: magazineReport.id };
          } else {
            magazineReport.preview = { active: false };
          }
          break;
        case "updatedAt":
          magazineReport.updateDate = magazineValue;
          magazineReport.updateDate = new Date(magazineValue).toISOString().substring(0, 10);
          break;

        case "customerInfo":
          Object.entries(magazineValue).forEach(([customerInfoKey, customerInfoValue]) => {
            switch (customerInfoKey) {
              case "email":
                magazineReport.email = customerInfoValue;
                break;
              case "firstName":
                magazineReport.firstName = customerInfoValue;
                break;
              case "lastName":
                magazineReport.lastName = customerInfoValue;
                break;
              default:
                break;
            }
          });
          break;

        default:
          break;
      }
      return magazineReport;
    });
    Object.entries(detailMagazines).forEach(([magazineKey, magazineValue]) => {
      if (magazineKey !== "createdByAutomation" && magazineValue !== true) {
        magazineReport.updatedBy = "user";
      }
    });
    return magazineReport;
  });
  return magazineReportArray;
};

export const csvHeaders = [
  { label: "Date", key: "date" },
  { label: "Name", key: "firstName" },
  { label: "Customer Email Address", key: "email" },
  { label: "Status", key: "status" },
  { label: "Last Modified", key: "updateDate" },
  { label: "Updated By", key: "updatedBy" },
];
let temMagazineArray = [];
const removeFromPolling = (myArray, removeId) => {
  myArray.splice(
    myArray.findIndex((a) => a.id === removeId),
    1
  );
  localStorage.setItem("tempMagazineIds", JSON.stringify(myArray));
  if (document.getElementById(`${removeId}_spinner`)) {
    document.getElementById(`${removeId}_spinner`).style.display = "none";
    document.getElementById(`${removeId}_generate`).style.display = "block";
  }
};

const pushToGenerate = async (id, month, year, email) => {
  temMagazineArray = JSON.parse(localStorage.getItem("tempMagazineIds")) ?? [];
  const data = { id, status: MAGAZINE_STATUS.PENDING };
  const foundItem = temMagazineArray.find((item) => item.id === data.id);
  const pendingArray = temMagazineArray.filter((item) => item.status !== rmMagazineStatus.DONE);
  if (!foundItem && pendingArray.length < 5) {
    temMagazineArray.push(data);
  }
  localStorage.setItem("tempMagazineIds", JSON.stringify(temMagazineArray));
  try {
    const result = await postRmMagazineGenerate(monthsList[month], year, email);
    if (result) {
      localStorage.setItem("tempMagazineIds", JSON.stringify(temMagazineArray));
    }
  } catch (err) {
    removeFromPolling(temMagazineArray, id);
  }
};

const locationOrigin = window.location.origin;
const handleNavigate = (userData) => {
  const fullMonth = getFullMonth[userData.month];
  const generateQueryParam = !userData.preview.active ? "?status=generate" : "";

  const url = urljoin(
    locationOrigin,
    "rm-magazine-report",
    "details",
    userData.year,
    fullMonth,
    userData.customer,
    generateQueryParam
  );
  window.open(url, "_self");
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const tableColumns = [
  {
    header: "Member name",
    cell: (info) => info.getValue(),
    accessorFn: (row) => {
      return (
        <>
          <div className="text-sky-800 text-sm font-semibold font-['Figtree']">{row.firstName}</div>
          <div className="text-neutral-800 text-sm font-['Figtree']">{row.email || "-"}</div>
        </>
      );
    },
    customProp: "customerInfo.email",
  },
  {
    header: "Issue",
    cell: (info) => info.getValue(),
    accessorFn: (row) => {
      const fullMonth = getFullMonth[row.month];
      return (
        <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
          <span className="capitalize">{fullMonth}</span> {row?.year}
        </div>
      );
    },
    customProp: "month",
  },
  {
    header: "Status",
    cell: (info) => info.getValue(),
    accessorFn: (row) => {
      const isPrinted = isPrintingPast(row);

      if (isPrinted) {
        return (
          <div className="w-[106px] h-6 px-2 py-1 bg-emerald-50 rounded-md justify-center items-center inline-flex">
            <div className="text-center text-emerald-600 text-xs font-semibold font-['Figtree'] leading-none">
              Printed
            </div>
          </div>
        );
      }

      if (!row.preview.status) {
        return;
      }

      return (
        <>
          {row?.status.toLowerCase() === "editing" ? (
            <div className="w-[55px] h-6 px-2 py-1 bg-orange-100 rounded-md justify-center items-center inline-flex">
              <div className="text-center text-orange-600 text-xs font-semibold font-['Figtree'] leading-none">
                Editing
              </div>
            </div>
          ) : (
            <div className="w-[106px] h-6 px-2 py-1 bg-emerald-50 rounded-md justify-center items-center inline-flex">
              <div className="text-center text-emerald-600 text-xs font-semibold font-['Figtree'] leading-none">
                Sent for printing
              </div>
            </div>
          )}
        </>
      );
    },
    customProp: "status",
  },
  {
    header: "Last modified",
    cell: (info) => info.getValue(),
    accessorFn: (row) => {
      return (
        <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
          {formatDate(row?.updatedDate, "MMM DD, YYYY")}
        </div>
      );
    },
    customProp: "updatedDate",
  },
  {
    header: "Last updated by",
    cell: (info) => info.getValue(),
    accessorFn: (row) => {
      return (
        <>
          <div className="text-neutral-800 text-sm font-['Figtree']">{row.updatedBy || "-"}</div>
        </>
      );
    },
    customProp: "generatedMagazine.createdByAutomation",
  },
  {
    header: "Action",
    cell: (info) => info.getValue(),
    accessorFn: (row) => {
      let active = row?.preview?.active;
      const isPrinted = isPrintingPast(row);

      if (isPrinted) {
        active = true;
      }

      return (
        <div className="flex">
          <div>
            <Button
              variant="LIGHT"
              onClick={() => {
                handleNavigate(row);
              }}
              className="bg-neutral-100 border-0"
              disabled={isPrinted}
            >
              {active ? (
                <>
                  <HiOutlineEye className="mr-2" /> View details
                </>
              ) : (
                <>
                  <SvgGenerateIcon className="mr-2" /> Generate
                </>
              )}
            </Button>
          </div>
        </div>
      );
    },
  },
];

// replaceNewLineWithBR
export function replaceNewLineWithBR(str) {
  return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
}
// replaceBrWithNewLine
export function replaceBrWithNewLine(str) {
  return str.replace(/<br ?\/?>/g, "\n");
}

export function replaceFinalPayload(rest) {
  return rest.payload.selection.dynamicFields.reduce((acc, curr) => {
    if (curr.keyword === "frontInsideCoverText") {
      return [
        ...acc,
        {
          keyword: curr.keyword,
          value: replaceNewLineWithBR(curr.value),
        },
      ];
    }
    return acc;
  }, []);
}

export const convertObjectToArrayOfObject = (obj, firstKey = "keyword", secondKey = "value") => {
  const enteries = Object.entries(obj);
  let result: { [key: string]: any }[] = [];
  if (enteries.length) {
    result = enteries.map(([key, value]) => ({
      [firstKey]: key,
      [secondKey]: value,
    }));
  }
  return result;
};

export const getWordsCount = (string: string) => {
  return string.split(" ").filter((str) => str !== "")?.length;
};

type ArrayOfObjects<T extends object> = T[];
type ProcessFunction<T> = (val: any) => T;

export const addKeyToArrayOfObject = <T extends object, V>(
  array: ArrayOfObjects<T> = [],
  fetchValueFrom: keyof T,
  newKeyname: string,
  processValue: ProcessFunction<V> = (val) => val as V
): ArrayOfObjects<T & { [K in typeof newKeyname]: V }> => {
  return array.map((obj: T) => {
    const fetchedValue = get(obj, fetchValueFrom);
    const processedValue = processValue(fetchedValue);
    return { ...obj, [newKeyname]: processedValue };
  });
};

export const checkWordLimit = (textLastValue, textCurrentValue, maxLimit) => {
  const wordCount = getWordsCount(textCurrentValue);
  const limitExceeded = wordCount > maxLimit;
  const isRemovingWords = textCurrentValue.length < textLastValue.length;

  return { limitExceeded, isRemovingWords };
};

export const getFormattedValues = (dynamicFields) => {
  const formattedValues = {};
  dynamicFields?.forEach((field) => {
    formattedValues[field.keyword] = field.value;
  });
  return formattedValues;
};

export const fnConvertTextToList = (value: string) => {
  const lines = value.split("\n");
  const html = lines
    .filter((line) => line.trim() !== "")
    .map((line) => `<li>${line}</li>`)
    .join("");

  const ulEl = document.createElement("ul");
  ulEl.innerHTML = html;

  const tempDiv = document.createElement("div");
  tempDiv.appendChild(ulEl);

  const ulAsString = tempDiv.innerHTML;

  return ulAsString;
};

export const fnConvertListToText = (html: string) => {
  const ulEl = document.createElement("ul");
  ulEl.innerHTML = html;

  const ulText = [...ulEl.querySelectorAll("li")].map((li) => li.textContent).join("\n");

  return ulText;
};

export const urlToBlob = async (url: string): Promise<Blob> => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
  return await response.blob();
};

export const blobToFile = async (url: string, fileName: string): Promise<File> => {
  const blob = await urlToBlob(url);
  const modifiedBlob: any = blob;
  modifiedBlob.lastModifiedDate = new Date();
  modifiedBlob.name = fileName;
  return modifiedBlob as File;
};

export const convertHtmlListToText = (selectedCoverOption, lastSelectedCoverStripOption, ctxMagazineFromDB) => {
  const currentSelectedOption = get(selectedCoverOption, "formKeyword");
  const lastSelectedOption = get(lastSelectedCoverStripOption, "current");

  if (lastSelectedOption === currentSelectedOption) {
    const { selections } = ctxMagazineFromDB[0] as SelectionsType;

    const initialValueOfSelctedOptionForm = selections.find((f) => f.formKeyword === currentSelectedOption);

    if (!initialValueOfSelctedOptionForm) return [];

    if (initialValueOfSelctedOptionForm.dynamicFields) {
      const fields = initialValueOfSelctedOptionForm.dynamicFields.map((field) => {
        if (field.keyword === "features") {
          return {
            [field.keyword]: fnConvertListToText(field.value),
          };
        }

        return {
          [field.keyword]: field.value,
        };
      });

      return fields;
    }
  }
};

export function isPrintingPast(row) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  if (currentDay > 20) {
    currentMonth += 2;
  } else {
    currentMonth += 1;
  }

  const monthToNumber = {
    JAN: 1,
    FEB: 2,
    MAR: 3,
    APR: 4,
    MAY: 5,
    JUN: 6,
    JUL: 7,
    AUG: 8,
    SEP: 9,
    OCT: 10,
    NOV: 11,
    DEC: 12,
  };

  if (row.status === "SENT_FOR_PRINTING") {
    const rowYear = parseInt(row.year);
    const rowMonth = monthToNumber[row.month];

    if (rowYear < currentYear || (rowYear <= currentYear && rowMonth < currentMonth)) {
      return true;
    }
  }
  return false;
}
