import * as React from "react";
const SvgOffboarding = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#757575"
      d="M4.053 15.386a.75.75 0 1 0 1.06 1.06zM14.886 4.553 4.053 15.386l1.06 1.06L15.948 5.615zm2.697 5.947A7.583 7.583 0 0 1 10 18.083v1.5a9.083 9.083 0 0 0 9.083-9.083zM10 18.083A7.583 7.583 0 0 1 2.417 10.5h-1.5A9.083 9.083 0 0 0 10 19.583zM2.417 10.5A7.583 7.583 0 0 1 10 2.917v-1.5A9.083 9.083 0 0 0 .917 10.5zM10 2.917a7.583 7.583 0 0 1 7.583 7.583h1.5A9.083 9.083 0 0 0 10 1.417z"
    />
  </svg>
);
export default SvgOffboarding;
