import React, { useState } from 'react';
import { Label } from 'flowbite-react';
import Cropper from 'react-easy-crop';
import { SvgPlusIcon } from '../../../components/icons';
import useFileUpload from '../../utils/useFileUpload';
import UploadModalTemplate from './UploadModalTemplate';

type IconGalleryModalProps = {
  show: boolean;
  onClose: () => void;
  onSelectIcon: (url: string) => void;
  onUploadIcon: (url: string) => void;
};

type TabActiveType = 'upload' | 'icon-gallery';

const ICONS = [
  'Alarm.svg',
  'ArrowRightSquare.svg',
  'Assortments.svg',
  'Attache.svg',
  'Bell.svg',
  'BookClosed.svg',
  'Calc.svg',
  'Card.svg',
  'Cart.svg',
  'Chart.svg',
  'Checklist.svg',
  'Clipboard.svg',
  'ChevronsRight.svg',
  'CopyLink.svg',
  'Documents.svg',
  'Download.svg',
  'FourSquares.svg',
  'Gift.svg',
  'Graph.svg',
  'Mic.svg',
  'Mobile.svg',
  'NoteCorrugated.svg',
  'Party.svg',
  'PhoneSpeechBubble.svg',
  'Siren.svg',
  'Slate.svg',
  'Tag.svg',
  'Wand.svg',
];

const BASE_URL = 'https://afy-product-catalog-stg.s3.amazonaws.com/icons/';

const IconGalleryModal: React.FC<IconGalleryModalProps> = ({
  show,
  onClose,
  onSelectIcon,
  onUploadIcon,
}) => {
  const [tabActive, setTabActive] = useState<TabActiveType>('upload');
  const {
    imageSrc,
    crop,
    zoom,
    isSaving,
    handleFileChange,
    onCropComplete,
    setCrop,
    setZoom,
    showCroppedImage,
  } = useFileUpload(onUploadIcon, onClose);

  const handleIconSelection = (url: string) => {
    onSelectIcon(url);
    onClose();
  };

  return (
    <UploadModalTemplate
      show={show}
      onClose={onClose}
      title="Select Icon"
      isSaving={isSaving}
      onSave={showCroppedImage}
    >
      <div className="flex gap-3 mt-4 text-sm cursor-pointer">
        <button
          className={`pb-3 ${
            tabActive === 'upload' && 'border-primary-500 border-b-4'
          }`}
          onClick={() => setTabActive('upload')}
        >
          Upload
        </button>
        <button
          className={`pb-3 ${
            tabActive === 'icon-gallery' && 'border-primary-500 border-b-4'
          }`}
          onClick={() => setTabActive('icon-gallery')}
        >
          Icon Gallery
        </button>
      </div>
      {tabActive === 'upload' && !imageSrc && (
        <div className="w-full items-center justify-center max-h-[500px] flex">
          <Label
            htmlFor="dropzone-file"
            className="flex h-80 w-full cursor-pointer flex-col items-center justify-center rounded-lg border border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100"
          >
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              <SvgPlusIcon />
              <div className="action flex items-center gap-2">
                <div className="flex items-center text-primary-500 text-sm font-semibold">
                  Browse files
                </div>
                <div className="text-neutral-600 text-sm font-semibold">
                  or drag and drop
                </div>
              </div>
              <p className="text-neutral-400 text-center text-xs font-medium">
                Works with any .PNG, .JPG, or .JPEG file from the web <br />
                Recommended size: 280 x 280
              </p>
            </div>
            <input
              type="file"
              id="dropzone-file"
              className="hidden"
              accept="image/png, image/jpg, image/jpeg, image/webp"
              onChange={handleFileChange}
            />
          </Label>
        </div>
      )}
      {tabActive === 'upload' && imageSrc && (
        <div className="w-full flex flex-col items-center">
          <div className="relative w-full h-80">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="w-full flex items-center justify-center mt-4">
            <input
              type="range"
              min={1}
              max={3}
              step={0.01}
              value={zoom}
              onChange={(e) => setZoom(e.target.value)}
              className="zoom-range"
            />
          </div>
        </div>
      )}
      {tabActive === 'icon-gallery' && (
        <div className="grid grid-cols-10 gap-4">
          {ICONS.map((icon) => (
            <button
              key={icon}
              className="cursor-pointer p-0 border-none bg-transparent"
              onClick={() => handleIconSelection(`${BASE_URL}${icon}`)}
              aria-label={`Select icon ${icon}`}
            >
              <img
                src={`${BASE_URL}${icon}`}
                alt={icon}
                className="w-12 h-12"
              />
            </button>
          ))}
        </div>
      )}
    </UploadModalTemplate>
  );
};

export default IconGalleryModal;
