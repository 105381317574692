import Wrapper from "../../../../components/offboarding/Wrapper";
import { Customer } from "../../../../api/instances/dto/getCustomers.dto";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { createPreviewPlan, getPreview } from "../../../../api/offboarding";
import { useState } from "react";
import Loading from "../../../../components/offboarding/Loading";
import OffboardingCard from "../../../../components/offboarding/OffboardingCard";
import React from "react";
import { ArrowRight } from "../../../../../components/icons";

const Content = ({ previewIsRefetching, previewIsLoading, createPreviewIsLoading, previewData, hasOffboardingData }) => {
  if (previewIsRefetching || previewIsLoading || createPreviewIsLoading || previewData?.plan?.status !== "READY") {
    return <Loading isLoading />;
  }

  if (hasOffboardingData) {
    return <OffboardingCard previewData={previewData} />;
  }

  return <p>Unable to find resources allocated to this user</p>;
};

export default function OffboardingDetails() {
  let flag = false;

  const { id } = useParams();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [refetchCount, setRefetchCount] = useState(0);
  const maxRefetchCount = 12;
  const refetchTime = 5000;    

  const {
    data: previewData,
    isLoading: previewIsLoading,
    isRefetching: previewIsRefetching,
  } = useQuery(["getPreview"], () => getPreview(id), {
    onSuccess: (res) => {
      setCustomer(res.customer);
      if (!res?.plan?.status && !flag) {
        refetchCreatePreview();
        flag = true;
      }
      if (res?.plan?.status === "READY") {
        setRefetchCount(maxRefetchCount);
      }
      setRefetchCount((prev) => prev + 1);
    },
    refetchOnWindowFocus: false,
    refetchInterval: refetchCount < maxRefetchCount ? refetchTime : false,
  });

  const { isLoading: createPreviewIsLoading, refetch: refetchCreatePreview } =
    useQuery(["createPreviewPlan"], () => createPreviewPlan(id), {
      enabled: false,
    });


  const hasOffboardingData = previewData?.plan?.publications?.some((el) => el.status === "READY");

  return (
    <Wrapper>
      <div className="mb-8">
        <h1
          className={"font-extrabold text-2xl capitalize mb-[8px] under"}
        >{`${customer?.firstName} ${customer?.lastName}`}</h1>
        <div className="flex items-center gap-[10px] mb-[24px] text-sm font-semibold underline decoration-gray-300 underline-offset-4">
          <a href="/offboarding">Offboarding</a>
          <ArrowRight />
          <p>Details</p>
        </div>
        <div className={"rounded-t-lg bg-white border-b py-[22px] px-[24px]"}>
          <div className={""}>
            <p className={"capitalize"}>
              {`${customer?.firstName} ${customer?.lastName}`}
              <span
                className={"text-gray-400 lowercase"}
              >{` (${customer?.email})`}</span>
            </p>
            <p className={"text-sm text-gray-400"}>
              {customer?.chargifyId
                ? `Chargify subscription ID: ${customer?.chargifyId}`
                : `Stripe subscription ID: ${customer?.stripeId}`}
            </p>
          </div>
        </div>
        <div className="flex bg-white rounded-b-lg shadow justify-around p-6 gap-4">
          <Content
            previewIsRefetching={previewIsRefetching}
            previewIsLoading={previewIsLoading}
            createPreviewIsLoading={createPreviewIsLoading}
            previewData={previewData}
            hasOffboardingData={hasOffboardingData}
          />
        </div>
      </div>
    </Wrapper>
  );
}
