import * as React from 'react';
const SvgDesktopIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <g fill="#FAFAFA">
      <path d="M6.667 14.667c-2.357 0-3.536 0-4.268-.732-.474-.475-.641-1.136-.7-2.185h16.603c-.06 1.049-.226 1.71-.7 2.185-.733.732-1.911.732-4.268.732h-2.709V18h2.709a.625.625 0 0 1 0 1.25H6.667a.625.625 0 1 1 0-1.25h2.708v-3.333zM8.334 2.167h3.333c3.143 0 4.714 0 5.69.976.977.977.977 2.548.977 5.69v.834c0 .46 0 .874-.006 1.25H1.672q-.006-.562-.005-1.25v-.833c0-3.143 0-4.714.976-5.69.977-.977 2.548-.977 5.69-.977" />
    </g>
  </svg>
);
export default SvgDesktopIcon;
