import { useState } from "react";
import { useMutation } from "react-query";
import { Formik, Form } from "formik";
import { isEmpty } from "lodash-es";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import validationSchema from "../utils/validationSchema";
import Title from "../../../../components/Title";
import { createCoach, validateCoach } from "../../../../api/coach-maintenance";
import { TextInput, ToggleSwitch } from "flowbite-react";
import Button from "../../../../components/Button";
import ImageUploader from "../../../../components/ImageUploader";
import Loader from "../../../../components/Loaders";
import ToastNotification from "../../../../components/ToastNotification";

const CreateCoach = () => {
  const navigate = useNavigate();
  const [switchEnable, setSwitchEnable] = useState(false);

  const { mutate: mutateCreateCoach, isLoading: isLoadingSubmit } = useMutation(
    createCoach,

    {
      onError: () => {
        ToastNotification({
          type: "failure",
          message: "Error saving new coach member",
        });
      },
      onSuccess: ({ email }) => {
        mutateValidateCoach(email);
      },
    }
  );

  const { mutate: mutateValidateCoach, isLoading: isLoadingValidate } =
    useMutation((email: string) => validateCoach(email), {
      onError: () => {
        ToastNotification({
          type: "failure",
          message: "Coach data not created",
        });
      },
      onSuccess: () => {
        ToastNotification({
          type: "success",
          message: "Coach created successfully",
        });
        navigate("/coach-maintenance");
      },
    });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    meetingLink: "",
    profile: {
      fullImageUrl: "",
    },
    coachingSegments: [],
    enabled: false,
  };
  const handleCancel = () => {
    navigate("/coach-maintenance");
  };

  const coachSegmentsOption = [
    { value: "REALTOR", label: "REALTOR" },
    { value: "DENTIST", label: "DENTIST" },
  ];

  if (isLoadingSubmit || isLoadingValidate) {
    return <Loader />;
  }

  return (
    <div>
      <Title>Add New Coach</Title>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={mutateCreateCoach}
      >
        {({
          values,
          errors,
          dirty,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          const isDisabled = !isEmpty(errors) || !dirty;

          const handleEnableValueChange = (isEnabled) => {
            setSwitchEnable(!switchEnable);
            setFieldValue("enabled", isEnabled);
          };

          return (
            <Form onSubmit={handleSubmit}>
              <div className="flex">
                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="firstName">
                    First Name<span className="text-red-500">*</span>{" "}
                  </label>
                  <TextInput
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={values?.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.firstName && touched.firstName ? (
                    <span>{errors.firstName}</span>
                  ) : null}
                </div>
                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="lastName">
                    Last Name<span className="text-red-500">*</span>
                  </label>
                  <TextInput
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.lastName && touched.lastName ? (
                    <span>{errors.lastName}</span>
                  ) : null}
                </div>
              </div>

              <div className="flex">
                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="email">
                    Email<span className="text-red-500">*</span>{" "}
                  </label>
                  <TextInput
                    type="email"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email ? (
                    <span>{errors.email}</span>
                  ) : null}
                </div>
                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="meetingLink">
                    Meeting Link<span className="text-red-500">*</span>
                  </label>
                  <TextInput
                    type="text"
                    id="meetingLink"
                    name="meetingLink"
                    value={values.meetingLink}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.meetingLink && touched.meetingLink ? (
                    <span>{errors.meetingLink}</span>
                  ) : null}
                </div>
              </div>

              <div className="flex">
                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="coachingSegments">
                    Coaching Segments<span className="text-red-500">*</span>{" "}
                  </label>
                  <Select
                    defaultValue={coachSegmentsOption.filter((option) =>
                      values.coachingSegments.includes(option.value)
                    )}
                    isMulti
                    name="coachingSegments"
                    options={coachSegmentsOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map(
                        (option) => option.value
                      );
                      setFieldValue("coachingSegments", selectedValues);
                    }}
                  />
                  {errors.coachingSegments && touched.coachingSegments ? (
                    <span>{errors.coachingSegments}</span>
                  ) : null}
                </div>

                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="enabled">
                    Enabled<span className="text-red-500">*</span>
                  </label>
                  <ToggleSwitch
                    checked={switchEnable}
                    label="Toggle me"
                    onChange={handleEnableValueChange}
                    name="enabled"
                  />
                  {errors.enabled && touched.enabled ? (
                    <span>{errors.enabled}</span>
                  ) : null}
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="image">
                    Upload Profile Image<span className="text-red-500">*</span>
                  </label>
                  <div>
                    <div>
                      <ImageUploader
                        imagePlaceholder={values?.profile?.fullImageUrl}
                        label="Upload logo"
                        callback={(imageUrl) => {
                          setFieldValue("profile.fullImageUrl", imageUrl);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <div className="p-2">
                  <Button onClick={handleCancel}>Cancel</Button>
                </div>
                <div className="p-2">
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                  >
                    Save New Coach
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateCoach;
