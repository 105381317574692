import { useMutation, useQuery } from "react-query";
import {
  getOnboardMetricsByUniqueVisits,
  getOnboardMetricsByBooks,
  getOnboardMetricsByAutoLogin,
  getOnboardMetricsByPaidSales,
  getOnboardMetricsByCancellations,
  getOnboardMetrics,
  getOnboardMetricsByFilter,
  getOnboardMetricsBySuggestions,
  getPaidSalesForExportData,
  getAutoLoginForExportData,
  getCancellationsForExportData,
  getBooksGeneratedForExportData,
  getFilterForExportData,
  getUniqueVisitsForExportData,
} from "../../../api/onboarding-report";
import { DateType } from "react-tailwindcss-datepicker";

const useGetOnboardMetricsByUniqueVisits = (
  [startDate, endDate]: [DateType, DateType],
  currentPage,
  onSuccess,
  perPage,
  sortByFilter?: { [key: string]: string }
) =>
  useMutation(
    () =>
      getOnboardMetricsByUniqueVisits(
        startDate,
        endDate,
        currentPage,
        perPage,
        sortByFilter
      ),
    {
      onSuccess,
    }
  );

const useGetOnboardMetricsByBooks = (
  [startDate, endDate]: [DateType, DateType],
  currentPage,
  onSuccess,
  perPage,
  sortByFilter?: { [key: string]: string }
) =>
  useMutation(
    () =>
      getOnboardMetricsByBooks(
        startDate,
        endDate,
        currentPage,
        perPage,
        sortByFilter
      ),
    {
      onSuccess,
    }
  );

const useGetOnboardMetricsByAutoLogin = (
  [startDate, endDate]: [DateType, DateType],
  currentPage,
  onSuccess,
  perPage,
  sortByFilter?: { [key: string]: string }
) =>
  useMutation(
    () =>
      getOnboardMetricsByAutoLogin(
        startDate,
        endDate,
        currentPage,
        perPage,
        sortByFilter
      ),
    { onSuccess }
  );

const useGetOnboardMetricsByPaidSales = (
  [startDate, endDate]: [DateType, DateType],
  currentPage,
  onSuccess,
  perPage,
  sortByFilter?: { [key: string]: string }
) =>
  useMutation(
    () =>
      getOnboardMetricsByPaidSales(
        startDate,
        endDate,
        currentPage,
        perPage,
        sortByFilter
      ),
    { onSuccess }
  );

const useGetPaidSalesForExportData = (
  [startDate, endDate]: [DateType, DateType],
  onSuccess
) =>
  useMutation(() => getPaidSalesForExportData(startDate, endDate), {
    onSuccess,
  });

const useGetautoLoginForExportData = (
  [startDate, endDate]: [DateType, DateType],
  onSuccess
) =>
  useMutation(() => getAutoLoginForExportData(startDate, endDate), {
    onSuccess,
  });

const useGetCancellationsForExportData = (
  [startDate, endDate]: [DateType, DateType],
  onSuccess
) =>
  useMutation(() => getCancellationsForExportData(startDate, endDate), {
    onSuccess,
  });

const useGetBooksGeneratedForExportData = (
  [startDate, endDate]: [DateType, DateType],
  onSuccess
) =>
  useMutation(() => getBooksGeneratedForExportData(startDate, endDate), {
    onSuccess,
  });

const useGetFilterForExportData = (
  filter,
  [startDate, endDate]: [DateType, DateType],
  onSuccess
) =>
  useMutation(() => getFilterForExportData(filter, startDate, endDate), {
    onSuccess,
  });

const useGetUniqueVisitsForExportData = (
  [startDate, endDate]: [DateType, DateType],
  onSuccess
) =>
  useMutation(() => getUniqueVisitsForExportData(startDate, endDate), {
    onSuccess,
  });

const useGetOnboardMetricsByCancellations = (
  [startDate, endDate]: [DateType, DateType],
  currentPage,
  onSuccess,
  perPage,
  sortByFilter?: { [key: string]: string }
) =>
  useMutation(
    () =>
      getOnboardMetricsByCancellations(
        startDate,
        endDate,
        currentPage,
        perPage,
        sortByFilter
      ),
    {
      onSuccess,
    }
  );

const useGetOnboardMetrics = (
  [startDate, endDate]: [DateType, DateType],
  onSuccess
) =>
  useQuery(
    ["getOnboardMetrics", startDate, endDate],
    () => getOnboardMetrics(startDate, endDate),
    {
      onSuccess,
    }
  );

const useFilterTable = (
  filter,
  [startDate, endDate]: [DateType, DateType],
  currentPage,
  perPage,
  onSuccess,
  sortByFilter?: { [key: string]: string }
) =>
  useMutation(
    () =>
      getOnboardMetricsByFilter(
        filter,
        startDate,
        endDate,
        currentPage,
        perPage,
        sortByFilter
      ),
    {
      onSuccess,
    }
  );

const useFilterSuggestions = (field, keyword, onSuccess) =>
  useMutation(() => getOnboardMetricsBySuggestions(field, keyword), {
    onSuccess,
  });

export {
  useGetOnboardMetricsByBooks,
  useGetOnboardMetricsByUniqueVisits,
  useGetOnboardMetricsByAutoLogin,
  useGetOnboardMetricsByPaidSales,
  useGetOnboardMetricsByCancellations,
  useGetOnboardMetrics,
  useFilterTable,
  useFilterSuggestions,
  useGetPaidSalesForExportData,
  useGetautoLoginForExportData,
  useGetUniqueVisitsForExportData,
  useGetFilterForExportData,
  useGetBooksGeneratedForExportData,
  useGetCancellationsForExportData,
};
