import * as React from 'react';
const SvgArrowLeftWhite = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 10.5H4m0 0 5-5m-5 5 5 5"
    />
  </svg>
);
export default SvgArrowLeftWhite;
