import React from "react";
import './Style/ProgressBar.css';

function ProgressBar() {

  return (
    <div className='w-full max-w-24'>
      <div className='h-1.5 w-full bg-neutral-300 overflow-hidden rounded-full'>
        <div className='progress w-full h-full bg-primary-500 left-right rounded-full'></div>
      </div>
    </div>
  )
}

export default ProgressBar;