import React from 'react';
import { Modal } from 'flowbite-react';
import Button from '../Button';

interface UploadModalTemplateProps {
  show: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  isSaving: boolean;
  onSave: () => void;
}

const UploadModalTemplate: React.FC<UploadModalTemplateProps> = (props) => {
  const { show, onClose, title, children, isSaving, onSave } = props;

  return (
    <Modal show={show} onClose={onClose} size="lg" dismissible>
      <Modal.Header className="border-none pt-[25px] px-6">
        <div className="flex justify-between items-center w-full">
          <h2 className="text-neutral-800 text-base font-bold leading-tight">
            {title}
          </h2>
        </div>
      </Modal.Header>
      <Modal.Body className="px-6 py-0">{children}</Modal.Body>
      <Modal.Footer className="mt-[25px] justify-end">
        <Button variant="OUTLINED" onClick={onClose} disabled={isSaving}>
          Cancel
        </Button>
        <Button variant="DARK" onClick={onSave} disabled={isSaving}>
          {isSaving ? 'Saving...' : 'Save changes'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadModalTemplate;
