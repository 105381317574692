import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DebounceInput } from 'react-debounce-input';
import { Dropdown, Modal, Label, Select } from 'flowbite-react';
import { HiX } from 'react-icons/hi';
import Avatar from '../../../components/Avatar';
import {
  getAllCoaches,
  updateCoach,
  createCoach,
} from '../../../api/coach-maintenance';
import Title from '../../../components/Title';
import ToastNotification from '../../../components/ToastNotification';
import {
  EllipsisVert,
  EditIcon,
  MeetingLink,
  CancelIcon,
  GreenCheck,
  CheckBlack,
} from '../../../../components/icons';
import { ToastContainer } from 'react-toastify';
import { debounce } from 'lodash-es';
import TextInput from '../../../components/TextInput';
import noItemsImage from '../../../../assets/images/no-items.png';
import SkeletonCoachesLoader from '../../../components/SkeletonCoachesLoader';
import Button from '../../../components/Button';
import SwitchButton from '../../../components/SwitchButton';

export default function CoachMaintenance() {
  const queryClient = useQueryClient();
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const drawerRef = useRef(null);
  const [currentCoach, setCurrentCoach] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('Show All');
  const [searchQuery, setSearchQuery] = useState('');
  const [coaches, setCoaches] = useState([]);
  const [isMeetingLinkValid, setIsMeetingLinkValid] = useState(true);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
  const [coachToDisable, setCoachToDisable] = useState(null);
  const [coachDetails, setCoachDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    meetingLink: '',
    coachingSegments: [],
    enabled: true,
  });

  const [counts, setCounts] = useState({
    total: 0,
    Realtor: 0,
    Dentist: 0,
    MortgageBrokers: 0,
    ReferralMarketing: 0,
    Disabled: 0,
  });

  useEffect(() => {
    const newCounts = {
      total: coaches.length,
      Realtor: coaches.filter(
        (c) => c.enabled && c.coachingSegments.includes('REALTOR'),
      ).length,
      Dentist: coaches.filter(
        (c) => c.enabled && c.coachingSegments.includes('DENTIST'),
      ).length,
      MortgageBrokers: coaches.filter(
        (c) => c.enabled && c.coachingSegments.includes('MORTGAGE_BROKERS'),
      ).length,
      ReferralMarketing: coaches.filter(
        (c) => c.enabled && c.coachingSegments.includes('REFERRAL_MARKETING'),
      ).length,
      Disabled: coaches.filter((c) => !c.enabled).length,
    };
    setCounts(newCounts);
  }, [coaches]);

  useEffect(() => {
    if (currentCoach) {
      setCoachDetails({
        ...currentCoach,
        coachingSegments: currentCoach.coachingSegments.slice(),
      });
    } else {
      setCoachDetails({
        firstName: '',
        lastName: '',
        email: '',
        meetingLink: '',
        coachingSegments: [],
        enabled: true,
      });
    }
  }, [currentCoach]);

  const [toggleStates, setToggleStates] = useState({
    Realtor: false,
    Dentist: false,
    Mortgage_Brokers: false,
    Referral_Marketing: false,
    disabled: false,
  });

  useEffect(() => {
    if (currentCoach) {
      setToggleStates({
        Realtor:
          currentCoach.coachingSegments.includes('REALTOR') &&
          currentCoach.enabled,
        Dentist:
          currentCoach.coachingSegments.includes('DENTIST') &&
          currentCoach.enabled,
        Referral_Marketing:
          currentCoach.coachingSegments.includes('REFERRAL_MARKETING') &&
          currentCoach.enabled,
        Mortgage_Brokers:
          currentCoach.coachingSegments.includes('MORTGAGE_BROKERS') &&
          currentCoach.enabled,
        disabled: !currentCoach.enabled,
      });
    }
  }, [currentCoach]);

  const [newCoach, setNewCoach] = useState({
    firstName: '',
    lastName: '',
    email: '',
    meetingLink: '',
    coachingSegments: [],
    disabled: false,
  });

  const resetNewCoachForm = () => {
    setNewCoach({
      firstName: '',
      lastName: '',
      email: '',
      meetingLink: '',
      coachingSegments: [],
    });
  };

  const { isLoading: isCoachesLoading } = useQuery(
    'coaches',
    () => getAllCoaches(0, 99),
    {
      onSuccess: (data) => {
        const sortedCoaches = data.data.sort((a, b) =>
          a.firstName.localeCompare(b.firstName),
        );
        setCoaches(sortedCoaches);
      },
      onError: (error) => console.error('Error fetching data:', error),
      refetchOnWindowFocus: false,
    },
  );

  const debouncedSearch = debounce((query) => {
    setSearchQuery(query.toLowerCase());
  }, 300);

  const handleSearchChange = (event) => {
    debouncedSearch(event.target.value);
  };

  const handleFilterChange = (selectedFilter) => {
    setSelectedFilter(selectedFilter);
  };

  const resetFilters = () => {
    setSearchQuery('');
    setSelectedFilter('Show All');
  };

  const filteredCoaches = coaches
    .filter((coach) => {
      const matchSearch = `${coach.firstName} ${coach.lastName} ${coach.email}`
        .toLowerCase()
        .includes(searchQuery);
      switch (selectedFilter) {
        case 'Realtor':
          return (
            coach.enabled &&
            matchSearch &&
            coach.coachingSegments.includes('REALTOR')
          );
        case 'Dentist':
          return (
            coach.enabled &&
            matchSearch &&
            coach.coachingSegments.includes('DENTIST')
          );
        case 'Mortgage Brokers':
          return (
            coach.enabled &&
            matchSearch &&
            coach.coachingSegments.includes('MORTGAGE_BROKERS')
          );
        case 'Referral Marketing':
          return (
            coach.enabled &&
            matchSearch &&
            coach.coachingSegments.includes('REFERRAL_MARKETING')
          );
        case 'Disabled':
          return !coach.enabled && matchSearch;
        default:
          return matchSearch;
      }
    })
    .sort((a, b) => {
      if (a.enabled !== b.enabled) {
        return a.enabled ? -1 : 1;
      }
      return a.firstName.localeCompare(b.firstName);
    });

  const openDrawer = (coach = null) => {
    if (coach) {
      setCurrentCoach(coach);
      setShowEditDrawer(true);
      setToggleStates({
        Realtor: coach.coachingSegments.includes('REALTOR'),
        Dentist: coach.coachingSegments.includes('DENTIST'),
        Mortgage_Brokers: coach.coachingSegments.includes('MORTGAGE_BROKERS'),
        Referral_Marketing:
          coach.coachingSegments.includes('REFERRAL_MARKETING'),
      });
    } else {
      setCurrentCoach(null);
      setShowAddDrawer(true);
    }
  };

  const closeDrawers = () => {
    setShowAddDrawer(false);
    setShowEditDrawer(false);
    setCurrentCoach(null);
  };

  const copyMeetingLinkToClipboard = (link) => {
    navigator.clipboard.writeText(link);
    ToastNotification({ type: 'success', message: 'Link copied to clipboard' });
  };

  const createMutation = useMutation(
    () => {
      const coachData = {
        firstName: newCoach.firstName,
        lastName: newCoach.lastName,
        email: newCoach.email,
        meetingLink: newCoach.meetingLink,
        coachingSegments: newCoach.coachingSegments,
        enabled: !newCoach.disabled,
      };

      return createCoach(coachData);
    },
    {
      onSuccess: () => {
        ToastNotification({
          type: 'success',
          message: 'Coach added successfully!',
        });
        queryClient.invalidateQueries('coaches');
        closeDrawers();
        resetNewCoachForm();
      },
    },
  );
  const isCreateLoading = createMutation.isLoading;

  const updateMutation = useMutation(
    ({ coachId, body }) => {
      const encodedEmail = encodeURIComponent(coachId);
      return updateCoach(encodedEmail, body);
    },
    {
      onSuccess: () => {
        ToastNotification({
          type: 'success',
          message: 'Coach updated successfully!',
        });
        queryClient.invalidateQueries('coaches');
        closeDrawers();
      },
    },
  );
  const isUpdateLoading = updateMutation.isLoading;

  const saveChanges = () => {
    if (currentCoach) {
      const activeSegments = toggleStates.disabled
        ? []
        : Object.keys(toggleStates)
            .filter((key) => toggleStates[key] && key !== 'disabled')
            .map((key) => key.toUpperCase());

      const updatedData = {
        firstName: currentCoach.firstName,
        lastName: currentCoach.lastName,
        email: currentCoach.email,
        enabled: !toggleStates.disabled,
        meetingLink: currentCoach.meetingLink,
        coachingSegments: activeSegments,
      };

      updateMutation.mutate(
        { coachId: currentCoach.email, body: updatedData },
        {
          onError: (error) => {
            let errorMessage = 'Failed to update coach.';

            if (error.message.includes('coach has no calendar access')) {
              errorMessage =
                'Failed to enable coach: Missing necessary Google Calendar access.';
            } else if (
              error.message.includes('coach with mismatched meeting link')
            ) {
              errorMessage = 'Failed to update coach: Mismatched meeting link.';
            } else if (error.message.includes('Validation Failed')) {
              errorMessage = 'Failed to update coach: Validation error.';
            }

            ToastNotification({ type: 'error', message: errorMessage });
          },
        },
      );
    } else {
      createMutation.mutate(
        {
          ...newCoach,
          enabled: !newCoach.disabled,
        },
        {
          onError: (error) => {
            let errorMessage = 'Failed to add new coach.';

            if (error.message.includes('coach has no calendar access')) {
              errorMessage =
                'Failed to add new coach: Missing necessary Google Calendar access.';
            } else if (
              error.message.includes('coach with mismatched meeting link')
            ) {
              errorMessage =
                'Failed to add new coach: Mismatched meeting link.';
            } else if (error.message.includes('Validation Failed')) {
              errorMessage = 'Failed to add new coach: Validation error.';
            }

            ToastNotification({ type: 'error', message: errorMessage });
          },
        },
      );
    }
  };

  const handleDisableCoach = () => {
    const updatedCoach = { enabled: false };
    updateMutation.mutate({
      coachId: coachToDisable.email,
      body: updatedCoach,
    });
    setIsDisableModalOpen(false);
  };

  const formatCoachingSegments = (segments) => {
    if (!segments || segments.length === 0) {
      return 'no segments';
    }
    return segments
      .map((segment) =>
        segment
          .replace(/_/g, ' ')
          .toLowerCase()
          .replace(/\b\w/g, (letter) => letter.toUpperCase()),
      )
      .join(', ');
  };

  const handleEnableCoach = (coach) => {
    const updatedCoach = { enabled: true };
    updateMutation.mutate(
      { coachId: coach.email, body: updatedCoach },
      {
        onError: (error) => {
          let errorMessage = 'Failed to update coach.';
          if (error.message.includes('Google Calendar access')) {
            errorMessage =
              'Failed to enable coach: Missing necessary Google Calendar access.';
          }
          ToastNotification({ type: 'error', message: errorMessage });
        },
      },
    );
  };

  const handleToggleChange = (category) => {
    setToggleStates((prev) => {
      const newState = { ...prev };

      if (category === 'disabled') {
        newState.disabled = !prev.disabled;
        if (!newState.disabled) {
          newState.Realtor = currentCoach.coachingSegments.includes('REALTOR');
          newState.Dentist = currentCoach.coachingSegments.includes('DENTIST');
          newState.Referral_Marketing =
            currentCoach.coachingSegments.includes('REFERRAL_MARKETING');
          newState.Mortgage_Brokers =
            currentCoach.coachingSegments.includes('MORTGAGE_BROKERS');
        } else {
          newState.Realtor = false;
          newState.Dentist = false;
          newState.Referral_Marketing = false;
          newState.Mortgage_Brokers = false;
        }
      } else {
        newState[category] = !prev[category];
        const isAnySegmentActive =
          newState.Realtor ||
          newState.Dentist ||
          newState.Mortgage_Brokers ||
          newState.Referral_Marketing;
        newState.disabled = !isAnySegmentActive;
      }

      return newState;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        closeDrawers();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (currentCoach && currentCoach.meetingLink) {
      setIsMeetingLinkValid(isValidMeetingLink(currentCoach.meetingLink));
    } else {
      setIsMeetingLinkValid(false);
    }
  }, [currentCoach?.meetingLink]);

  useEffect(() => {
    const selected = Object.values(toggleStates).some((value) => value);
    setIsRoleSelected(selected);
  }, [toggleStates]);

  const [emailError, setEmailError] = useState('');
  const [meetingLinkError, setMeetingLinkError] = useState('');

  const isValidEmail = (email) => {
    return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email);
  };

  const isValidMeetingLink = (link) => {
    const meetingLinkValidation = link.includes('.zoom.us/my/');
    return meetingLinkValidation;
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setNewCoach((prev) => ({ ...prev, email }));
    setEmailError(
      isValidEmail(email) ? '' : 'Please enter a valid email address.',
    );
  };

  const handleMeetingLinkChange = (e) => {
    const link = e.target.value;
    setNewCoach((prev) => ({ ...prev, meetingLink: link }));
    setMeetingLinkError(
      isValidMeetingLink(link) ? '' : 'Please enter a valid meeting link.',
    );
  };

  const isDisabled = showEditDrawer
    ? !isMeetingLinkValid || !isRoleSelected || !currentCoach.meetingLink
    : !newCoach.firstName ||
      !newCoach.lastName ||
      !isValidEmail(newCoach.email) ||
      !isValidMeetingLink(newCoach.meetingLink) ||
      newCoach.coachingSegments.length === 0;

  let content;

  if (isCoachesLoading) {
    content = <SkeletonCoachesLoader />;
  } else if (filteredCoaches.length > 0) {
    content = filteredCoaches.map((coach) => (
      <div
        key={coach.email}
        className="w-full h-[232px] bg-white rounded-lg border border-gray-200 flex flex-col justify-start items-center overflow-hidden hover:shadow-[inset_0_0_0_1px_rgba(56,189,248,1)] hover:border-primary-500 p-4"
      >
        <div className="self-end h-[28px] w-[28px] flex justify-center items-center bg-neutral-100 rounded-md">
          <Dropdown
            arrowIcon={false}
            inline={true}
            label={<EllipsisVert className="text-center w-5 h-5" />}
            className="w-40 h-auto z-50"
          >
            <Dropdown.Item onClick={() => openDrawer(coach)}>
              <EditIcon className="w-4 h-4 mr-1" />
              <span className="pl-2">Edit</span>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => copyMeetingLinkToClipboard(coach.meetingLink)}
            >
              <MeetingLink className="w-4 h-4 mr-1" />
              <span className="pl-2">Meeting link</span>
            </Dropdown.Item>
            <Dropdown.Divider />
            {coach.enabled ? (
              <Dropdown.Item
                onClick={() => {
                  setCoachToDisable(coach);
                  setIsDisableModalOpen(true);
                }}
              >
                <CancelIcon className="w-4 h-4 mr-1" />
                <div className="text-red-500 pl-2">Disable Coach</div>
              </Dropdown.Item>
            ) : (
              <Dropdown.Item onClick={() => handleEnableCoach(coach)}>
                <GreenCheck className="w-4 h-4 mr-1" />
                <div className="text-emerald-600 pl-2">Enable Coach</div>
              </Dropdown.Item>
            )}
          </Dropdown>
        </div>
        <button
          className="max-w-[228px] flex flex-col items-center whitespace-nowrap overflow-hidden"
          onClick={() => openDrawer(coach)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') openDrawer(coach);
          }}
          aria-label={`Open details for ${coach.firstName} ${coach.lastName}`}
          style={{ cursor: 'pointer' }}
        >
          <Avatar name={`${coach.firstName} ${coach.lastName}`} />
          <div className="max-w-[228px]">
            <p className="m-2 text-lg font-semibold text-gray-900 capitalize truncate">
              {`${coach.firstName} ${coach.lastName}`}
            </p>
          </div>
          <span className="text-sm text-gray-500 overflow-hidden text-ellipsis whitespace-nowrap">
            {coach.email.length > 23
              ? `${coach.email.substring(0, 20)}...`
              : coach.email}
          </span>
          <div className="mt-[14px] flex flex-col gap-1">
            <div className="flex gap-2 whitespace-nowrap overflow-hidden max-w-[228px]">
              {!coach.enabled ? (
                <span className="opacity-75 text-xs text-zinc-400 leading-tight">
                  Disabled
                </span>
              ) : (
                coach.coachingSegments.slice(0, 2).map((segment) => (
                  <div
                    key={segment}
                    className="flex justify-start items-center gap-[8px] px-2 py-0.5 bg-neutral-100 text-xs font-semibold rounded-md text-neutral-800 max-w-[100px] text-ellipsis whitespace-nowrap"
                  >
                    <CheckBlack className="flex-shrink-0" />
                    <span className="truncate">
                      {segment
                        .replace(/_/g, ' ')
                        .toLowerCase()
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </span>
                  </div>
                ))
              )}
            </div>
            {coach.enabled && coach.coachingSegments.length > 2 && (
              <div className="text-center text-zinc-400 text-xs font-medium font-['Figtree'] leading-none">
                +{coach.coachingSegments.length - 2} more
              </div>
            )}
          </div>
        </button>
      </div>
    ));
  } else {
    content = (
      <div className="w-full h-[382px] col-span-full flex flex-col justify-center items-center gap-3.5">
        <img
          className="w-[182px] h-[121px] object-cover"
          src={noItemsImage}
          alt="No items found"
        />
        <div className="flex flex-col justify-start items-center gap-1">
          <div className="text-center text-neutral-800 text-base font-semibold font-['Figtree'] leading-tight">
            No results found.
          </div>
          <div className="text-center">
            <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
              Try a different keyword or{' '}
            </span>
            <button
              onClick={resetFilters}
              className="text-sky-400 text-sm font-medium underline leading-[23px] focus:outline-none"
            >
              reset filters
            </button>
            <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
              .
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full pt-4 min-h-screen flex-col inline-flex font-figtree">
      <div
        aria-hidden="true"
        className={`fixed inset-0 bg-black transition-opacity duration-300 ease-in-out ${
          showAddDrawer || showEditDrawer
            ? 'opacity-50'
            : 'opacity-0 pointer-events-none'
        }`}
        onClick={closeDrawers}
        tabIndex={-1}
      ></div>
      <div className="flex justify-between items-center mb-6 w-full">
        <Title>Coach Maintenance</Title>
        <Button
          onClick={() => openDrawer()}
          color="primary"
          className="text-black font-bold h-[46px] w-[105px] px-[1px] py-[13px] rounded-md"
        >
          <span className="text-neutral-800 text-sm font-bold leading-tight">
            Add coach
          </span>
        </Button>
      </div>

      <div className="w-full p-6 bg-white rounded-t-lg shadow-sm border">
        <div className="flex justify-between max-[1000px]:flex-col gap-4">
          <DebounceInput
            minLength={2}
            debounceTimeout={300}
            onChange={handleSearchChange}
            className="input w-[341px] h-[46px] px-5 py-[13px] bg-white rounded-md border border-gray-200 focus:shadow-[inset_0_0_0_1px_rgba(56,189,248,1)] focus:ring-transparent focus:border-sky-400 text-sm text-gray-900 placeholder-zinc-400 max-[1000px]:w-full"
            placeholder="Search name or email"
          />
          <Select
            id="coach-filter"
            value={selectedFilter}
            onChange={(e) => handleFilterChange(e.target.value)}
          >
            <option value="Show All">Show All ({counts.total})</option>
            <option value="Realtor">Realtor ({counts.Realtor})</option>
            <option value="Dentist">Dentist ({counts.Dentist})</option>
            <option value="Mortgage Brokers">
              Mortgage Brokers ({counts.MortgageBrokers})
            </option>
            <option value="Referral Marketing">
              Referral Marketing ({counts.ReferralMarketing})
            </option>
            <option disabled>────────────</option> {/* visual separator */}
            <option value="Disabled">
              Disabled Coaches ({counts.Disabled})
            </option>
          </Select>
        </div>
      </div>

      <div className="w-full p-6 bg-white rounded-b-lg shadow-sm border border-t-0 mb-[130px]">
        <div className="grid gap-3 grid-cols-[repeat(auto-fill,minmax(250px,1fr))] mt-4">
          {content}
          <Modal
            show={isDisableModalOpen}
            onClose={() => setIsDisableModalOpen(false)}
          >
            <Modal.Header>
              <Title>Disable Coach</Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Are you sure you want to disable {coachToDisable?.firstName}{' '}
                {coachToDisable?.lastName} from{' '}
                {formatCoachingSegments(coachToDisable?.coachingSegments)}?{' '}
                <br />
                You can re-enable this later.
              </p>
            </Modal.Body>
            <Modal.Footer className="border-0 border-none justify-end">
              <Button
                variant="LIGHT"
                onClick={() => setIsDisableModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className="text-neutral-50"
                variant="MODAL"
                onClick={handleDisableCoach}
              >
                Yes, Disable coach
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      {(showAddDrawer || showEditDrawer) && (
        <div className="fixed inset-0 flex items-center justify-end z-50">
          <div
            className={`fixed inset-y-0 right-0 w-[448px] bg-white shadow-xl z-50 transition-transform duration-300 ease-in-out ${
              showAddDrawer || showEditDrawer
                ? 'translate-x-0'
                : 'translate-x-full'
            }`}
            ref={drawerRef}
          >
            <div className="flex items-center justify-between p-3 border-b border-gray-200">
              <h2 className="text-lg pl-2 font-bold">
                {showEditDrawer ? 'Edit Coach' : 'Add new coach'}
              </h2>
              <button
                onClick={closeDrawers}
                className="rounded-md p-2.5 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-neutral-100"
              >
                <HiX className="w-5 h-5" />
              </button>
            </div>
            {showAddDrawer && (
              <div className="px-6 py-2 space-y-2">
                <div>
                  <label
                    htmlFor="firstName"
                    className="text-sm font-semibold text-neutral-800 mb-2"
                  >
                    First Name
                  </label>
                  <TextInput
                    type="text"
                    id="firstName"
                    placeholder="Enter first name"
                    value={newCoach.firstName}
                    onChange={(e) =>
                      setNewCoach((prev) => ({
                        ...prev,
                        firstName: e.target.value,
                      }))
                    }
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="text-sm font-semibold text-neutral-800 mb-2"
                  >
                    Last Name
                  </label>
                  <TextInput
                    type="text"
                    id="lastName"
                    placeholder="Enter last name"
                    value={newCoach.lastName}
                    onChange={(e) =>
                      setNewCoach((prev) => ({
                        ...prev,
                        lastName: e.target.value,
                      }))
                    }
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="text-sm font-semibold text-neutral-800 mb-2"
                  >
                    Email Address
                  </label>
                  <TextInput
                    type="email"
                    id="email"
                    placeholder="Enter email address"
                    value={newCoach.email}
                    onChange={handleEmailChange}
                    className={`mt-1 block w-full px-3 py-2 border ${
                      emailError ? 'border-red-500' : 'border-gray-300'
                    } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  {emailError && (
                    <p className="text-red-500 text-xs italic">{emailError}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="meetingLink"
                    className="text-sm font-semibold text-neutral-800 mb-2"
                  >
                    Meeting Link
                  </label>
                  <TextInput
                    type="text"
                    id="meetingLink"
                    placeholder="www.example.com"
                    value={newCoach.meetingLink}
                    onChange={handleMeetingLinkChange}
                    className={`mt-1 block w-full px-3 py-2 border ${
                      meetingLinkError ? 'border-red-500' : 'border-gray-300'
                    } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  {meetingLinkError && (
                    <p className="text-red-500 text-xs italic">
                      {meetingLinkError}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-sm font-semibold text-neutral-800 mb-2">
                    Assign coach to
                  </div>
                  <div className="toggle-container">
                    <div className="flex items-center gap-2">
                      <SwitchButton
                        id="realtor-switch"
                        checked={newCoach.coachingSegments.includes('REALTOR')}
                        onChange={() => {
                          const segments = newCoach.coachingSegments.includes(
                            'REALTOR',
                          )
                            ? newCoach.coachingSegments.filter(
                                (s) => s !== 'REALTOR',
                              )
                            : [...newCoach.coachingSegments, 'REALTOR'];
                          setNewCoach((prev) => ({
                            ...prev,
                            coachingSegments: segments,
                          }));
                        }}
                      />
                      <Label className="font-bold" htmlFor="realtor-switch">
                        Realtor
                      </Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <SwitchButton
                        id="mb-switch"
                        checked={newCoach.coachingSegments.includes(
                          'MORTGAGE_BROKERS',
                        )}
                        onChange={() => {
                          const segments = newCoach.coachingSegments.includes(
                            'MORTGAGE_BROKERS',
                          )
                            ? newCoach.coachingSegments.filter(
                                (s) => s !== 'MORTGAGE_BROKERS',
                              )
                            : [
                                ...newCoach.coachingSegments,
                                'MORTGAGE_BROKERS',
                              ];
                          setNewCoach((prev) => ({
                            ...prev,
                            coachingSegments: segments,
                          }));
                        }}
                      />
                      <Label className="font-bold" htmlFor="mb-switch">
                        Mortgage Brokers
                      </Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <SwitchButton
                        id="rm-switch"
                        checked={newCoach.coachingSegments.includes(
                          'REFERRAL_MARKETING',
                        )}
                        onChange={() => {
                          const segments = newCoach.coachingSegments.includes(
                            'REFERRAL_MARKETING',
                          )
                            ? newCoach.coachingSegments.filter(
                                (s) => s !== 'REFERRAL_MARKETING',
                              )
                            : [
                                ...newCoach.coachingSegments,
                                'REFERRAL_MARKETING',
                              ];
                          setNewCoach((prev) => ({
                            ...prev,
                            coachingSegments: segments,
                          }));
                        }}
                      />
                      <Label className="font-bold" htmlFor="rm-switch">
                        Referral Marketing
                      </Label>
                    </div>
                    <div className="flex pb-1 items-center gap-2">
                      <SwitchButton
                        id="dentist-switch"
                        checked={newCoach.coachingSegments.includes('DENTIST')}
                        onChange={() => {
                          const segments = newCoach.coachingSegments.includes(
                            'DENTIST',
                          )
                            ? newCoach.coachingSegments.filter(
                                (s) => s !== 'DENTIST',
                              )
                            : [...newCoach.coachingSegments, 'DENTIST'];
                          setNewCoach((prev) => ({
                            ...prev,
                            coachingSegments: segments,
                          }));
                        }}
                      />
                      <Label className="font-bold" htmlFor="dentist-switch">
                        Dentist
                      </Label>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="px-6 py-4">
              {showEditDrawer && (
                <>
                  <div className="text-sm font-semibold text-neutral-800 mb-2">
                    Details
                  </div>
                  <div className="bg-neutral-50 p-4 rounded-lg mb-4">
                    <div className="flex items-center gap-3 mb-3">
                      <Avatar
                        src={currentCoach.profile.fullImageUrl}
                        name={`${currentCoach.firstName} ${currentCoach.lastName}`}
                      />
                      <div>
                        <div className="text-lg font-bold">{`${currentCoach.firstName} ${currentCoach.lastName}`}</div>
                        <div className="text-sm text-gray-500">
                          {currentCoach.email}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-sm font-semibold text-neutral-800 mb-1">
                      Meeting link
                    </div>
                    <TextInput
                      type="text"
                      value={currentCoach.meetingLink || ''}
                      onChange={(e) =>
                        setCurrentCoach({
                          ...currentCoach,
                          meetingLink: e.target.value,
                        })
                      }
                      className={`block w-full px-3 py-2 ${
                        isMeetingLinkValid ? 'bg-white' : 'bg-red-100'
                      } border ${
                        isMeetingLinkValid
                          ? 'border-gray-300'
                          : 'border-red-500'
                      } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                    />
                    {!isMeetingLinkValid && (
                      <p className="text-red-500 text-xs italic">
                        Please enter a valid URL.
                      </p>
                    )}
                  </div>
                  <div className="text-sm font-semibold text-neutral-800 mb-2">
                    Assign coach to
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center pt-2 gap-2">
                      <SwitchButton
                        id="realtor-switch"
                        checked={toggleStates.Realtor}
                        onChange={(e) =>
                          handleToggleChange('Realtor', e.target.checked)
                        }
                      />
                      <Label className="font-bold" htmlFor="realtor-switch">
                        Realtor
                      </Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <SwitchButton
                        id="mortgage-brokers-switch"
                        checked={toggleStates.Mortgage_Brokers}
                        onChange={(e) =>
                          handleToggleChange(
                            'Mortgage_Brokers',
                            e.target.checked,
                          )
                        }
                      />
                      <Label
                        className="font-bold"
                        htmlFor="mortgage-brokers-switch"
                      >
                        Mortgage Brokers
                      </Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <SwitchButton
                        id="referral-marketing-switch"
                        checked={toggleStates.Referral_Marketing}
                        onChange={(e) =>
                          handleToggleChange(
                            'Referral_Marketing',
                            e.target.checked,
                          )
                        }
                      />
                      <Label
                        className="font-bold"
                        htmlFor="referral-marketing-switch"
                      >
                        Referral Marketing
                      </Label>
                    </div>
                    <div className="flex items-center pb-2 gap-2">
                      <SwitchButton
                        id="dentist-switch"
                        checked={toggleStates.Dentist}
                        onChange={(e) =>
                          handleToggleChange('Dentist', e.target.checked)
                        }
                      />
                      <Label className="font-bold" htmlFor="dentist-switch">
                        Dentist
                      </Label>
                    </div>
                    <div className="flex border-t pt-4 items-center gap-2">
                      <SwitchButton
                        id="disable-coach-switch"
                        checked={toggleStates.disabled}
                        onChange={(e) =>
                          handleToggleChange('disabled', e.target.checked)
                        }
                        red
                      />
                      <Label htmlFor="disable-coach-switch">
                        <span className="text-red-600 font-semibold">
                          Disable coach
                        </span>{' '}
                        <br /> This will remove them from all assigned segments.
                      </Label>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="absolute bottom-0 left-0 right-0 p-6 border-t border-gray-200">
              <Button
                onClick={() => saveChanges()}
                disabled={isDisabled || isCreateLoading || isUpdateLoading}
                color="primary"
                className="w-full font-bold justify-center rounded-md border border-transparent px-4 py-2 text-neutral-800 text-sm  disabled:bg-neutral-400 "
              >
                {showEditDrawer ? 'Save Changes' : 'Add New Coach'}
              </Button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer limit={1} />
    </div>
  );
}
