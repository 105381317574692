import React from "react";

const RMCardSkeletonLoader = () => {
  return (
    <div className="bg-neutral-100 w-1/3 p-[36px] cursor-pointer max-[1235px]:w-full max-[1235px]:border-r-0 max-[1235px]:border-b-2 max-[1235px]:items-center max-[1235px]:flex max-[1235px]:flex-col">
      <div className="flex items-center gap-2 text-sm">
        <div className="w-6 h-6 bg-gray-300 rounded-full animate-pulse" />
        <div className="w-40 h-6 bg-gray-300 rounded animate-pulse" />
      </div>
      <div className="w-24 h-10 mt-4 bg-gray-300 rounded animate-pulse" />
    </div>
  );
};

export default RMCardSkeletonLoader;
