import React from "react";
import get from "lodash/get";
import map from "lodash/map";
import Button from "../../../../../../components/Button";
import { useState } from "react";
import ProgressBar from "../../../../../../components/ProgressBar";
import { isArray } from "lodash-es";
import { CoverOptionType } from "../../types";

const RenderOptions = ({ optionsList = [], selectedOption, handleChange, onImageClick, displayType = "magazine" }) => {
  const [imageIsLoading, setImageisLoading] = useState(true);
  let wd = displayType === "magazine" ? "w-28 md:w-36 2xl:w-44" : "w-40 2xl:w-56";
  let ht = displayType === "magazine" ? "h-40 md:h-48 2xl:h-60" : "h-32";

  if (optionsList.length > 5) {
    wd = displayType === "magazine" ? "w-36 2xl:w-44" : "w-40 lg:w-44 xl:w-56";
    ht = displayType === "magazine" ? "h-48 2xl:h-60" : "h-32";
  }

  if (!isArray(optionsList)) return;

  return (
    <div className="flex gap-4 flex-nowrap overflow-auto justify-start lg:justify-start mt-1">
      {map(optionsList, (option: CoverOptionType) => {
        const isSelected = selectedOption?.id === option?.id;
        const optionImage = get(option, "displayImage") as string;

        return (
          <div key={option.id} className={`relative flex items-center ${wd} ${ht} rounded-xl group overflow-hidden}`}>
            {imageIsLoading && (
              <div className="absolute z-10 flex w-full items-center justify-center h-full backdrop-blur-sm opacity-90 overflow-hidden">
                <ProgressBar />
              </div>
            )}
            <div
              className={`flex items-center rounded-xl overflow-hidden ${wd} ${ht} p-0.5 ${
                isSelected ? "border-primary-500 border-4" : "border-neutral-300 border"
              }`}
            >
              <img
                alt={option.name}
                src={optionImage}
                className={`rounded-lg object-fill m-0 group-hover:scale-110 transform transition-scale duration-500 ease-in-out w-full ${
                  displayType === "magazine" ? "h-full" : "h-auto"
                }`}
                onLoad={() => setTimeout(() => setImageisLoading(false), 500)}
              />
            </div>
            {!isSelected && (
              <div className="absolute rounded-xl w-full h-full opacity-0 bg-black/0 group-hover:bg-black/20 group-hover:opacity-100 transition-opacity duration-700 ease-in-out top-0 left-0 flex flex-col gap-1 items-center justify-center p-2">
                <Button
                  variant="LIGHT"
                  onClick={() => onImageClick(optionImage)}
                  size="md"
                  type="button"
                  className={`w-fit ${
                    optionsList.length > 5 ? "min-w-24 xl:min-w-28" : "min-w-32"
                  } text-neutral-800 text-sm focus:ring-0 font-semibold hover:border-neutral-600 hover:bg-neutral-100 py-1`}
                >
                  Preview
                </Button>
                <Button
                  onClick={() => handleChange(option)}
                  type="button"
                  size="md"
                  className={`w-fit ${
                    optionsList.length > 5 ? "min-w-24 xl:min-w-28" : "min-w-32"
                  } focus:ring-0 bg-primary-500 text-neutral-800 hover:bg-primary-400 py-1 font-semibold`}
                >
                  Choose
                </Button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RenderOptions;
