import React from 'react';

interface SkeletonCardLoaderProps {
  count?: number;
}

const SkeletonCardLoader: React.FC<SkeletonCardLoaderProps> = ({ count = 5 }) => {
  return (
    <div className="p-6 flex flex-wrap justify-center overflow-hidden gap-4">
      {[...Array(count)].map((_, index) => (
        <div
          key={index}
          className="w-[350.67px] h-[86px] bg-neutral-100 rounded-lg flex-col justify-start items-start inline-flex animate-pulse"
          style={{ padding: "22px 24px" }}
        >
          <div className="justify-start items-center gap-4 inline-flex w-full">
            <div className="w-10 h-10 bg-zinc-300 rounded-full"></div>
            <div className="flex-col flex-grow justify-center items-start gap-0.5">
              <div className="w-[110px] h-4 bg-gray-200 rounded"></div>
              <div className="w-[138px] h-4 bg-gray-200 rounded mt-1"></div>
            </div>
            <div className="flex-none w-[68px] flex justify-end items-center gap-2">
              <div className="w-4 h-4 bg-gray-200 rounded"></div>
              <div className="w-6 h-6 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonCardLoader;
