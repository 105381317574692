import React from "react";

function DownloadIconGreen(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33337 8.00065C1.33337 4.85795 1.33337 3.28661 2.30968 2.3103C3.286 1.33398 4.85734 1.33398 8.00004 1.33398C11.1427 1.33398 12.7141 1.33398 13.6904 2.3103C14.6667 3.28661 14.6667 4.85795 14.6667 8.00065C14.6667 11.1433 14.6667 12.7147 13.6904 13.691C12.7141 14.6673 11.1427 14.6673 8.00004 14.6673C4.85734 14.6673 3.286 14.6673 2.30968 13.691C1.33337 12.7147 1.33337 11.1433 1.33337 8.00065ZM8.00004 4.16732C8.27618 4.16732 8.50004 4.39118 8.50004 4.66732V8.12688L9.64649 6.98043C9.84175 6.78517 10.1583 6.78517 10.3536 6.98043C10.5489 7.17569 10.5489 7.49227 10.3536 7.68754L8.35359 9.68754C8.25983 9.78131 8.13265 9.83398 8.00004 9.83398C7.86743 9.83398 7.74026 9.78131 7.64649 9.68754L5.64649 7.68754C5.45122 7.49227 5.45122 7.17569 5.64649 6.98043C5.84175 6.78517 6.15833 6.78517 6.35359 6.98043L7.50004 8.12688V4.66732C7.50004 4.39118 7.7239 4.16732 8.00004 4.16732ZM5.33337 10.834C5.05723 10.834 4.83337 11.0578 4.83337 11.334C4.83337 11.6101 5.05723 11.834 5.33337 11.834H10.6667C10.9428 11.834 11.1667 11.6101 11.1667 11.334C11.1667 11.0578 10.9428 10.834 10.6667 10.834H5.33337Z"
        fill="#38AB86"
      />
    </svg>
  );
}

export default DownloadIconGreen;
