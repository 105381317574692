import { Label, TextInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { useMutation } from "react-query";
import { isEmpty } from "lodash-es";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { HiCheckCircle, HiOutlineX, HiXCircle } from "react-icons/hi";
import { MdContentCopy } from "react-icons/md";

import SubTitle from "../../../components/SubTitle";
import Title from "../../../components/Title";
import { validationSchema } from "./validationSchema";
import { getError } from "../../../api/resource";
import { getContactByemail, updateAfyPassword } from "../../../api/hubspot";
import Button from "../../../components/Button";
import Loader from "../../../components/Loaders";
import Notification from "../../../components/Notification";
import { ToastType } from "../../../typesData/ToastNotificationTypes";
import ToastNotification from "../../../components/ToastNotification";
import { ToastContainer } from "react-toastify";

type InitialValues = {
  email: string | null;
  firstname: string;
  password: string;
  passwordConfirmation: string;
};

export default function MemberPassword() {
  const [error, setError] = useState({});
  const [email, setEmail] = useState("");
  const [contactEmail, setContactEmail] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [contactCurrentPassword, setContactCurrentPassword] = useState(null);
  const [contactName, setContactName] = useState(null);
  const [showPasswordDetails, setShowPasswordDetails] = useState(false);
  const [notificationType, setNotificationType] = useState<ToastType>("OTHER");
  const [message, setMessage] = useState("");

  const [passwordIsvalid, setpasswordIsvalid] = useState(false);
  // const [specialchar, setspecialchar] = useState(false);
  const [uppercase, setuppercase] = useState(false);
  const [lowercase, setlowercase] = useState(false);
  const [hasNumber, sethasNumber] = useState(false);
  const [copyStatus, setCopyStatus] = useState(false);
  const [changePwdPolicyColor, setChangePwdPolicyColor] = useState(true);

  const initialValues: InitialValues = {
    email: contactEmail,
    firstname: "",
    password: "",
    passwordConfirmation: "",
  };

  const [formvalues, setformvalues] = useState({
    password: "",
  });

  const resetForm = () => {
    setContactEmail(null);
    setContactCurrentPassword(null);
    setContactName(null);
    setuppercase(false);
    setlowercase(false);
    sethasNumber(false);
    setpasswordIsvalid(false);
    // setspecialchar(false);
    setformvalues({ password: "" });
  };

  const { mutateAsync: updateAfyPsswd, isLoading: isSaveLoading } =
    useMutation(updateAfyPassword);

  const { mutateAsync: getPassword, isLoading: isDataLoading } = useMutation(
    () => getContactByemail(email),
    {
      onSuccess: (data) => {
        if (data?.properties?.afy_customer_status?.value !== "Active") {
          setNotificationType("FAILURE");
          setMessage(`Member ${email} is deactivated`);
          setShowPasswordDetails(false);
          return;
        }
        setContactEmail(data.properties.email.value);
        setContactCurrentPassword(data.properties?.afy_password?.value);
        setContactName(data.properties.firstname.value);
        setShowPasswordDetails(true);
        setMessage("");
      },
      onError: (error) => {
        setNotificationType("FAILURE");
        if (error?.response?.data?.statusCode) {
          setMessage(`Member ${email} does not exist`);
        }
        setShowPasswordDetails(false);
      },
    }
  );

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputEmail = event.target.value;
    if (inputEmail === "") {
      setShowPasswordDetails(false);
    }
    setEmail(inputEmail);
    setIsValidEmail(validateEmail(inputEmail));
  };

  const PasswordPolicy = (
    <div className="pt-[20px] mb-[30px]">
      <div className="flex max-[510px]:flex-col">
        <div className="flex items-center mb-0 pr-2">
          {passwordIsvalid ? (
            <div className="flex items-center">
              <HiCheckCircle color="green" />
              <span className="ml-1 text-green-600 text-sm leading-5 font-normal">
                8 characters minimum
              </span>
            </div>
          ) : (
            <div className="flex items-center">
              {changePwdPolicyColor ? (
                <>
                  <HiXCircle color="gray" />
                  <span className="ml-1 text-gray-600 text-sm leading-5 font-normal">
                    8 characters minimum
                  </span>
                </>
              ) : (
                <>
                  <HiXCircle color="red" />
                  <span className="ml-1 text-red-600 text-sm leading-5 font-normal">
                    8 characters minimum
                  </span>
                </>
              )}
            </div>
          )}
        </div>

        <div className="flex items-center mb-0 pl-2 max-[510px]:pl-0">
          {uppercase ? (
            <div className="flex items-center">
              <HiCheckCircle color="green" />
              <span className="ml-1 text-green-600 text-sm leading-5 font-normal">
                One uppercase letter
              </span>
            </div>
          ) : (
            <div className="flex items-center">
              {changePwdPolicyColor ? (
                <>
                  <HiXCircle color="gray" />
                  <span className="ml-1 text-gray-600 text-sm leading-5 font-normal">
                    One uppercase letter
                  </span>
                </>
              ) : (
                <>
                  <HiXCircle color="red" />
                  <span className="ml-1 text-red-600 text-sm leading-5 font-normal">
                    One uppercase letter
                  </span>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="flex max-[510px]:flex-col">
        <div className="flex items-center mb-0 pr-2 max-[510px]:pl-0">
          {lowercase ? (
            <div className="flex items-center">
              <HiCheckCircle color="green" />
              <span className="ml-1 text-green-600 text-sm leading-5 font-normal">
                One lowercase letter
              </span>
            </div>
          ) : (
            <div className="flex items-center">
              {changePwdPolicyColor ? (
                <>
                  <HiXCircle color="gray" />
                  <span className="ml-1 text-gray-600 text-sm leading-5 font-normal">
                    One lowercase letter
                  </span>
                </>
              ) : (
                <>
                  <HiXCircle color="red" />
                  <span className="ml-1 text-red-600 text-sm leading-5 font-normal">
                    One lowercase letter
                  </span>
                </>
              )}
            </div>
          )}
        </div>

        <div className="flex items-center mb-0 pl-4 max-[510px]:pl-0">
          {hasNumber ? (
            <div className="flex items-center">
              <HiCheckCircle color="green" />
              <span className="ml-1 text-green-600 text-sm leading-5 font-normal">
                Contains a number or symbol
              </span>
            </div>
          ) : (
            <div className="flex items-center">
              {changePwdPolicyColor ? (
                <>
                  <HiXCircle color="gray" />
                  <span className="ml-1 text-gray-600 text-sm leading-5 font-normal">
                    Contains a number or symbol
                  </span>
                </>
              ) : (
                <>
                  <HiXCircle color="red" />
                  <span className="ml-1 text-red-600 text-sm leading-5 font-normal">
                    Contains a number or symbol
                  </span>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* <div className="flex">
        <div className="flex items-center mb-0 pr-2">
          {specialchar ? (
            <div className="flex items-center">
              <HiCheckCircle color="green" />
              <span className="ml-1 text-green-600 text-sm leading-5 font-normal">
                One special character
              </span>
            </div>
          ) : (
            <div className="flex items-center">
              {changePwdPolicyColor ? (
                <>
                  <HiXCircle color="gray" />
                  <span className="ml-1 text-gray-600 text-sm leading-5 font-normal">
                    One special character
                  </span>
                </>
              ) : (
                <>
                  <HiXCircle color="red" />
                  <span className="ml-1 text-red-600 text-sm leading-5 font-normal">
                    One special character
                  </span>
                </>
              )}
            </div>
          )}
        </div>
      </div> */}
    </div>
  );

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const onSubmit = () => {
    setError(validateEmail(email));
    if (Object.keys(error).length === 0) {
      getPassword();
    }
  };

  const handleSave = async (
    formData: InitialValues,
    helpers: FormikHelpers<InitialValues>
  ): Promise<void> => {
    const { setSubmitting, setStatus } = helpers;
    formData.email = contactEmail;
    try {
      const { email, password, passwordConfirmation } = formData;
      const filtered = { email, password, passwordConfirmation };

      updateAfyPsswd(filtered)
        .then(async () => {
          setStatus({ success: true });
          setContactCurrentPassword(null);
          setContactName(null);
          resetForm();
          setShowPasswordDetails(false);
          setNotificationType("SUCCESS");
          setMessage(`Successfully changed password for ${email}`);
        })
        .catch(() => {
          setNotificationType("FAILURE");
          setMessage(`Password not updated for ${email}`);
        });
    } catch (error) {
      const response = getError(error);
      setStatus({ success: false });
      resetForm();
      setSubmitting(false);
      setNotificationType("FAILURE");
      setMessage(`Password not updated for ${email}`);
      return response;
    }
  };

  function isOkPass(p) {
    const anUpperCase = /[A-Z]/;
    const aLowerCase = /[a-z]/;
    const aNumber = /[0-9]/;
    const aSpecial = /[!@#$%^&*()-_]/; // Removed unnecessary | characters in the regex

    let isValid = true;
    let hasUppercase = false;
    let hasLowercase = false;
    let hasNumber = false;
    const hasSpecialChar = false;

    if (p === "" || p === undefined) {
      setpasswordIsvalid(false);
    }

    if (p.length < 8) {
      isValid = false;
    }

    for (let i = 0; i < p.length; i++) {
      if (anUpperCase.test(p[i])) hasUppercase = true;
      else if (aLowerCase.test(p[i])) hasLowercase = true;
      else if (aNumber.test(p[i]) || aSpecial.test(p[i])) hasNumber = true;
    }

    setpasswordIsvalid(isValid);
    setuppercase(hasUppercase);
    setlowercase(hasLowercase);
    sethasNumber(hasNumber);
  }

  useEffect(() => {
    if (!isEmpty(formvalues) && contactCurrentPassword != null) {
      isOkPass(formvalues.password);
    }
  }, [formvalues.password, contactCurrentPassword, formvalues]);

  const onCopyText = () => {
    ToastNotification({ type: "success", message: "Link copied to clipboard" });
  };

  const handleFocusChange = () => {
    setChangePwdPolicyColor(false);
  };

  const clearInput = () => {
    setEmail("");
    setIsValidEmail(false);
  };

  return (
    <div className="pt-8 min-h-screen w-full">
      <div className="mb-8">
        <Title>Members</Title>
      </div>
      <div className="px-4 py-4 shadow-md bg-white rounded-lg">
        <div className="px-4 py-2">
          <div className="mb-2">
            <SubTitle>Change Password</SubTitle>
          </div>
        </div>
        <hr></hr>
        <div>
          {message && (
            <Notification type={notificationType} message={message} />
          )}
        </div>
        <div className="px-4 py-2">
          <div className="mb-4">
            <Label htmlFor="email1" color="grey" value="Search member" />
          </div>
          <div className="flex">
            <input
              id="email1"
              type="text"
              color="white"
              className="w-1/3 focus:outline-none px-5 py-[10px] bg-white border border-gray-300 rounded-md text-sm text-gray-900 placeholder-zinc-400 max-[1300px]:w-[300px]"
              value={email}
              onChange={handleEmailChange}
              placeholder="Email address"
            />
            {email && (
              <button
                onClick={clearInput}
                className="relative inset-y-0 right-0 -ml-6 pr-3 flex items-center"
              >
                <HiOutlineX className="h-5 w-5 text-gray-500" />
              </button>
            )}
          </div>
          <div className="mt-4">
            <Button
              variant="PRIMARY"
              disabled={!isValidEmail || isSaveLoading || isDataLoading}
              onClick={onSubmit}
              isProcessing={isSaveLoading || isDataLoading}
            >
              Show Password
            </Button>
          </div>
        </div>
      </div>

      {showPasswordDetails && (
        <div className="px-6 my-6 py-6 shadow-md bg-white rounded-lg">
          <div className="w-full px-6 py-[22px] border-b border-gray-200 justify-start items-center gap-6 inline-flex">
            <div className="flex-col justify-center items-start gap-0.5 inline-flex">
              <div className="text-zinc-900 text-base font-semibold font-['Figtree'] leading-tight">
                {contactName}
              </div>
              <div className="w-[250px] text-neutral-500 text-sm font-medium font-['Figtree'] leading-tight">
                {contactEmail}
              </div>
            </div>
          </div>

          <div className="w-1/2 mt-4 p-[17px] bg-white rounded-lg border border-gray-100 flex-col justify-start items-start gap-3.5 inline-flex max-[1300px]:min-w-[259px]">
            <div className="justify-start items-start gap-1 inline-flex">
              <div className="text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                Details
              </div>
            </div>
            <div className="self-stretch justify-center items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 h-px bg-gray-200" />
            </div>
            <div className="flex-col justify-start items-start gap-2 flex">
              <div className="justify-start items-center gap-4 inline-flex">
                <div className="w-[70px] self-stretch flex-col justify-center items-start gap-0.5 inline-flex">
                  <div className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                    Name
                  </div>
                </div>
                <div className="w-[250px] self-stretch flex-col justify-center items-start gap-0.5 inline-flex">
                  <div className="w-[166px] text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                    {contactName}
                  </div>
                </div>
              </div>
              <div className="w-[336px] justify-start items-center gap-4 inline-flex">
                <div className="grow shrink basis-0 self-stretch flex-col justify-center items-start gap-0.5 inline-flex">
                  <div className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                    Password
                  </div>
                </div>
                <div className="w-[250px] self-stretch justify-start items-center gap-2 flex">
                  <div className="flex items-center text-neutral-800 text-sm font-semibold font-['Figtree'] leading-tight">
                    {contactCurrentPassword}
                    <div className="px-2 cursor-pointer">
                      <CopyToClipboard
                        text={contactCurrentPassword}
                        onCopy={onCopyText}
                      >
                        <MdContentCopy />
                      </CopyToClipboard>
                    </div>
                    <div>{copyStatus && <p>copied</p>}</div>
                  </div>
                  <div className="w-5 h-5 justify-center items-center flex">
                    <div className="w-5 h-5 relative flex-col justify-start items-start flex" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSave}
            innerRef={(formikActions) =>
              formikActions
                ? setformvalues(formikActions.values)
                : setformvalues({})
            }
          >
            {({
              values,
              handleSubmit,
              handleChange,
              isValid,
              resetForm,
              dirty,
              isSubmitting,
            }) => {
              return (
                <Form onSubmit={handleSubmit} className="mt-6 py-2 w-full">
                  <div className="flex my-4">
                    <div className="mr-4 w-full">
                      <Label htmlFor="password" value="New Password" />
                      <div className="flex items-center">
                        <input
                          id="password"
                          name="password"
                          color="white"
                          className="w-1/3 focus:outline-none px-5 py-[10px] bg-white border border-gray-300 rounded-md text-sm text-gray-900 placeholder-zinc-400 max-[1300px]:w-[300px]"
                          type="text"
                          autoComplete="none"
                          placeholder="Enter new password"
                          value={values.password}
                          disabled={contactEmail === null}
                          onChange={handleChange}
                          onFocus={handleFocusChange}
                        />
                        {values.password && (
                          <button
                            onClick={resetForm}
                            className="relative inset-y-0 right-0 -ml-6 pr-3 flex items-center "
                          >
                            <HiOutlineX className="h-5 w-5 text-gray-500" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-1">{PasswordPolicy}</div>

                  <Button
                    type="submit"
                    variant="DARK"
                    onClick={handleSubmit}
                    disabled={
                      dirty
                        ? !isValid || isSubmitting
                        : false ||
                          contactEmail === null ||
                          values.password === ""
                    }
                  >
                    Save New Password
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
