import React from "react";
import { Route, Routes } from "react-router-dom";
import MemberPassword from "./pages/memberManagement/MemberPassword";
import TeamMeeting from "./pages/onboarding/teamMeeting";
import SalesReport from "./pages/reports/SalesReport";
import TeamMeetingReport from "./pages/onboarding/TeamMeetingReport";
import TeamPhoneCall from "./pages/onboarding/teamPhoneCalls";
import TeamPhoneCallsReport from "./pages/onboarding/teamPhoneCallsReport";
import RmMagaineReport from "./pages/reports/rmMagazineReport";
import RmMagaineDetails from "./pages/reports/rmMagazineReport/MagazineDetails";
import CoachMaitenence from "./pages/onboarding/CoachMaintenance";
import EditCoach from "./pages/onboarding/CoachMaintenance/EditCoach";
import CreateCoach from "./pages/onboarding/CoachMaintenance/CreateCoach";
import Offers from "./pages/marketing/Offers";
import SingleOffer from "./pages/marketing/SingleOffer";
import TripWireReport from "./pages/reports/tripWireReport";
import EditOffer from "./pages/marketing/EditOffer";
import CreateOffer from "./pages/marketing/CreateOffer";
import Offboarding from "./pages/v2/offboarding";
import OffboardingMember from "./pages/Offboarding/OffboardingMember";
import LoginPage from "../LoginPage";
import OffboardingDetails from "./pages/v2/offboarding/details";
import HomePage from "./pages/HomePage";
import MagazineRecipient from "./pages/reports/MagazineRecipient";
import UserLeads from "./pages/reports/MagazineRecipient/UserLeads";

export function Callback() {
  return (
    <div>
      <p>Call back</p>
    </div>
  );
}

const RoutesConfig = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/member-password-change" element={<MemberPassword />} />
    <Route path="/team-meetings" element={<TeamMeeting />} />
    <Route path="/sales-report" element={<SalesReport />} />
    <Route
      path="/team-meetings/:teamMemberEmail"
      element={<TeamMeetingReport />}
    />
    <Route path="/team-phone-calls" element={<TeamPhoneCall />} />
    <Route
      path="/team-phone-calls/:teamMemberEmail/:currToken?/:currPage?"
      element={<TeamPhoneCallsReport />}
    />
    <Route path="/tripwire-report" element={<TripWireReport />} />
    <Route path="/magazine-recipient" element={<MagazineRecipient />} />
    <Route path="/magazine-recipient/:userId" element={<UserLeads />} />
    <Route path="/rm-magazine-report" element={<RmMagaineReport />} />
    <Route
      path="/rm-magazine-report/details/:year?/:month?/:customerId?"
      element={<RmMagaineDetails />}
    />
    <Route path="/mc-calendar" element={<CoachMaitenence />} />
    <Route path="/coach-maintenance" element={<CoachMaitenence />} />
    <Route path="/coaches/edit/:id" element={<EditCoach />} />
    <Route path="/coaches/create" element={<CreateCoach />} />
    <Route path="/offers" element={<Offers />} />
    <Route path="/offers/new-offer" element={<CreateOffer />} />
    <Route path="/offers/new-offer/preview" element={<CreateOffer />} />
    <Route path="/offers/:id" element={<SingleOffer />} />
    tripwire-report
    <Route path="/editoffers/:id" element={<EditOffer />} />
    <Route path="/offboarding" element={<Offboarding />} />
    <Route path="/offboarding/:id" element={<OffboardingDetails />} />
    <Route path="/app/callback" element={<Callback />} />
    <Route path="/home" element={<HomePage />} />
  </Routes>
);

export default RoutesConfig;
