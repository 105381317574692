import * as React from "react";
import type { SVGProps } from "react";

const SvgGenerateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.45319 7.55555H1.70319V7.55556L2.45319 7.55555ZM2.45319 8.66667L1.92494 9.19908C2.21735 9.4892 2.68902 9.4892 2.98143 9.19908L2.45319 8.66667ZM4.1013 8.08797C4.39535 7.79622 4.39721 7.32135 4.10547 7.02731C3.81373 6.73327 3.33886 6.73141 3.04482 7.02315L4.1013 8.08797ZM1.86156 7.02315C1.56751 6.73141 1.09264 6.73327 0.800904 7.02731C0.509163 7.32135 0.511029 7.79622 0.80507 8.08797L1.86156 7.02315ZM12.1974 5.05946C12.4143 5.41232 12.8762 5.52252 13.2291 5.30558C13.582 5.08865 13.6921 4.62674 13.4752 4.27388L12.1974 5.05946ZM8.05256 1.25C4.55146 1.25 1.70319 4.06755 1.70319 7.55555H3.20319C3.20319 4.90706 5.36876 2.75 8.05256 2.75V1.25ZM1.70319 7.55556L1.70319 8.66667L3.20319 8.66667L3.20319 7.55555L1.70319 7.55556ZM2.98143 9.19908L4.1013 8.08797L3.04482 7.02315L1.92494 8.13426L2.98143 9.19908ZM2.98143 8.13426L1.86156 7.02315L0.80507 8.08797L1.92494 9.19908L2.98143 8.13426ZM13.4752 4.27388C12.3604 2.46049 10.348 1.25 8.05256 1.25V2.75C9.8091 2.75 11.346 3.67466 12.1974 5.05946L13.4752 4.27388Z" fill="#252525" />
    <path d="M13.5426 7.33301L14.0698 6.79959C13.7777 6.51081 13.3076 6.51081 13.0154 6.79959L13.5426 7.33301ZM11.8912 7.9107C11.5966 8.20188 11.5939 8.67675 11.885 8.97134C12.1762 9.26594 12.6511 9.26871 12.9457 8.97753L11.8912 7.9107ZM14.1395 8.97753C14.4341 9.26871 14.909 9.26594 15.2002 8.97134C15.4914 8.67675 15.4886 8.20188 15.194 7.9107L14.1395 8.97753ZM3.75806 10.9391C3.54053 10.5866 3.07843 10.4772 2.72593 10.6948C2.37344 10.9123 2.26403 11.3744 2.48156 11.7269L3.75806 10.9391ZM7.92184 14.7497C11.432 14.7497 14.2926 11.9348 14.2926 8.44412H12.7926C12.7926 11.0899 10.6202 13.2497 7.92184 13.2497V14.7497ZM14.2926 8.44412V7.33301H12.7926V8.44412H14.2926ZM13.0154 6.79959L11.8912 7.9107L12.9457 8.97753L14.0698 7.86642L13.0154 6.79959ZM13.0154 7.86642L14.1395 8.97753L15.194 7.9107L14.0698 6.79959L13.0154 7.86642ZM2.48156 11.7269C3.60076 13.5404 5.62001 14.7497 7.92184 14.7497V13.2497C6.15621 13.2497 4.61255 12.3238 3.75806 10.9391L2.48156 11.7269Z" fill="#252525" />
  </svg>
);

export default SvgGenerateIcon;