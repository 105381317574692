import { getInstance } from "./instance";

const instance = getInstance();

interface Terms {
  [key: string]: string | string[];
}

export const getUpsellReport = (
  startDate: string,
  endDate: string,
  page: number,
  perPage: number,
  excludeUpsell: boolean,
  terms?: Terms,
  sortByFilter?: { [key: string]: string }
) => {
  const url = "/abo/find-upsell-report";
  const isUpsellFilter =
    excludeUpsell === true ? { isUpsell: !excludeUpsell } : {};

  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: { [key: string]: any } = {
      startDate,
      endDate,
      page,
      perPage,
      ...isUpsellFilter,
    };

    if (sortByFilter) {
      params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
    }

    if (terms) {
      Object.entries(terms).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            params[`${key}[${index}]`] = item;
          });
        } else {
          params[key] = value;
        }
      });
    }

    return instance
      .get(url, { params })
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUpsellReportUnpaginated = (
  startDate: string,
  endDate: string,
  excludeUpsell: boolean,
  terms?: Terms
) => {
  const url = "/abo/find-upsell-report-unpaginated";
  const isUpsellFilter =
    excludeUpsell === true ? { isUpsell: !excludeUpsell } : {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: { [key: string]: any } = {
    startDate,
    endDate,
    ...isUpsellFilter,
  };

  if (terms) {
    Object.entries(terms).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          params[`${key}[${index}]`] = item;
        });
      } else {
        params[key] = value;
      }
    });
  }

  return instance
    .get(url, { params })
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getAdvancedSearch = (field: string, keyword?: string) => {
  const url = "/abo/find-upsell-advanced-search";
  try {
    const params = { keyword, field };

    return instance
      .get(url, { params })
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  } catch (e) {
    return Promise.reject(e);
  }
};
