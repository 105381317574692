import * as React from "react";
import type { SVGProps } from "react";

const SvgZoomSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    fill="none"
    stroke="currentColor"
    {...props}
    className={`w-3 h-3 ${props.className}`}  
  >
    <path
      fill="currentColor"
      d="M1.2 5.625v.51c0 1.677 0 2.515.463 3.08q.128.155.283.282c.564.463 1.403.463 3.08.463 1.676 0 2.514 0 3.078-.463a2 2 0 0 0 .283-.283c.358-.436.44-1.035.458-2.059l.341.168c.992.496 1.489.744 1.851.52.363-.224.363-.779.363-1.889v-.148c0-1.11 0-1.665-.363-1.889-.362-.224-.859.024-1.851.52l-.341.168c-.019-1.024-.1-1.623-.458-2.06a2 2 0 0 0-.283-.282C7.54 1.8 6.702 1.8 5.025 1.8c-1.676 0-2.515 0-3.079.463a2 2 0 0 0-.283.283C1.2 3.11 1.2 3.948 1.2 5.625"
    />
  </svg>
);

export default SvgZoomSmall;
