import { getInstance } from './instance';

const instance = getInstance();

interface OfferType {
  _id: string;
}

export const getOffersList = (
  perPage,
  page,
  sortByFilter?: { [key: string]: string },
) => {
  const params = { page, perPage };
  if (sortByFilter) {
    params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
  }
  return instance
    .get('abo/offersList', { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const updateOffer = (offer: OfferType) => {
  return instance
    .patch(`onboard/offer/${offer._id}`, offer)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};
