/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import get from "lodash/get";

import { checkWordLimit, getWordsCount } from "../../../utils";
import { TextInput, Textarea } from "flowbite-react";
import { defaultTextInputTheme } from "../../../../../../components/Theme/TextInput";
import { defaultTextAreaTheme } from "../../../../../../components/Theme/TextArea";
import ImageUploaderModal from "../ImageUploaderModal";
import { useMagazineImageContext } from "../../../../../../../context/MagazineImageHook";
import { MagazineImageContextType } from "../../../../../../../context/MagazineImageContext";

const ReferralPartner = ({ step = "", formikHandler, data }) => {
  const [isEditImage, setIsEditImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const { values = {}, setFieldValue, errors, setFieldError, validateForm } = formikHandler || {};
  const targetLocation = step.concat(".", "_formFields");

  const { setShowModal, setImageType, magazineType } = useMagazineImageContext() as MagazineImageContextType;

  /**
   * Handles change event, validate phone
   *
   * @param e - Change event object
   * @returns - newValue
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name = "", value = "", type = "" },
    } = e || {};

    let newValue = value;

    if (name === "partnerDescription") {
      const { limitExceeded, isRemovingWords } = checkWordLimit(`${targetLocation}.${name}`, value, 50);

      if (limitExceeded && !isRemovingWords) {
        setFieldError(`${targetLocation}.${name}`, "Partner Description must not be more than 50 words.");
        return;
      }
    }

    if (type === "tel") {
      const strippedInput = value.replace(/[^\d]/g, "");
      let maskedPhoneNumber = "";
      for (let i = 0; i < strippedInput.length && i < 10; i++) {
        if (i === 3 || i === 6) {
          maskedPhoneNumber += "-";
        }
        maskedPhoneNumber += strippedInput[i];
      }
      newValue = maskedPhoneNumber;
    }

    const locationToSave = step.concat(".", "_formFields", ".", name);
    setFieldValue(locationToSave, newValue);
  };

  /**
   * Handle keyup event, validate form
   */
  const handleKeyup = async () => {
    await validateForm().then((err: object) => {
      if (Object.prototype.hasOwnProperty.call(err, step)) {
        return true;
      }
      return false;
    });
  };

  /**
   * Handle image upload, set value to formikData, toggle modal
   *
   * @param value
   * @param keyName
   */
  const handleImageUpload = (value: string, keyName: string) => {
    const locationToSave = step.concat(".", "_formFields", ".", keyName);
    setFieldValue(locationToSave, value);
  };

  /**
   * Remove image, set value to formik, reset image url
   *
   * @param path
   */
  const onRemoveImage = (path: string) => {
    setFieldValue(path, "");
    setImageUrl(null);
    setIsEditImage(false);
  };

  /**
   * On edit image, set image url, toggle modal.
   *
   * @param type
   */
  const onEditImage = (type: string) => {
    setIsEditImage(true);
    setImageUrl(get(values, "listingImageJustListed"));
  };

  const partnerDescription = get(values, `${targetLocation}.partnerDescription`) || "";
  const partnerDescriptionWordCount = getWordsCount(partnerDescription);

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col items-start">
          <div className="text-neutral-800 text-sm font-semibold">Listing Photo</div>
          <div className="text-neutral-500 text-sm font-medium">Recommended size: 960 x 672</div>
        </div>
        <ImageUploaderModal
          type="backInsideCoverImageOption6"
          imageUrl={get(values, "backInsideCover._formFields.listingImageJustListed")}
          onSuccess={(value) => handleImageUpload(value, "listingImageJustListed")}
          onCancel={null}
          handleOpenModal={(type) => {
            setImageType(type);
            setShowModal(true);
          }}
          onRemoveImage={(e: { stopPropagation: () => void }) => {
            onRemoveImage("backInsideCoverImageOption6");
            e.stopPropagation();
          }}
          onEditImage={(e: { stopPropagation: () => void }) => {
            onEditImage("backInsideCoverImageOption6");
            e.stopPropagation();
          }}
          imageUrlForEdit={imageUrl}
          isEditImage={isEditImage}
          toggleCancelEdit={() => setIsEditImage(false)}
        />
      </div>
      <div className="w-full flex gap-4">
        <div className="w-full flex flex-col gap-1">
          <div className="relative w-full flex flex-col gap-1">
            <label className="text-sm font-semibold text-neutral-800">Partner name</label>
            <TextInput
              theme={defaultTextInputTheme}
              id="partnerName"
              name="partnerName"
              type="text"
              value={get(values, `${targetLocation}.partnerName`)}
              onChange={handleChange}
              placeholder=""
            />
          </div>
          <div className="text-red-500 py-2 text-sm">{get(errors, `${targetLocation}.partnerName`) || ""}</div>
        </div>
        <div className="w-full flex flex-col gap-1">
          <div className="relative w-full flex flex-col gap-1">
            <label className="text-sm font-semibold text-neutral-800">Partner header</label>
            <TextInput
              theme={defaultTextInputTheme}
              id="partnerHeader"
              name="partnerHeader"
              type="text"
              value={get(values, `${targetLocation}.partnerHeader`) || ""}
              onChange={handleChange}
              placeholder=""
            />
          </div>
          <div className="text-red-500 py-2 text-sm">{get(errors, `${targetLocation}.partnerHeader`) || ""}</div>
        </div>
      </div>
      <div className="w-full flex gap-4">
        <div className="w-full flex flex-col gap-1">
          <div className="relative w-full flex flex-col gap-1">
            <label className="text-sm font-semibold text-neutral-800">Marketing phone</label>
            <TextInput
              theme={defaultTextInputTheme}
              id="partnerPhone"
              name="partnerPhone"
              value={get(values, `${targetLocation}.partnerPhone`) || ""}
              type="tel"
              inputMode="tel"
              onChange={handleChange}
              onKeyUp={handleKeyup}
              placeholder="___-___-____"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              maxLength={12}
            />
          </div>
          <div className="text-red-500 py-2 text-sm">{get(errors, `${targetLocation}.partnerPhone`) || ""}</div>
        </div>
        <div className="w-full flex flex-col gap-1">
          <div className="relative w-full flex flex-col gap-1">
            <label className="text-sm font-semibold text-neutral-800">Partner email</label>
            <TextInput
              theme={defaultTextInputTheme}
              id="partnerEmail"
              name="partnerEmail"
              type="text"
              value={get(values, `${targetLocation}.partnerEmail`) || ""}
              onChange={handleChange}
              onKeyUp={handleKeyup}
              placeholder=""
            />
          </div>
          <div className="text-red-500 py-2 text-sm">{get(errors, `${targetLocation}.partnerEmail`) || ""}</div>
        </div>
      </div>
      <div className="w-full flex gap-4">
        <div className="w-full flex flex-col gap-1">
          <div className="relative w-full flex flex-col gap-1">
            <label className="text-sm font-semibold text-neutral-800">Partner address</label>
            <TextInput
              theme={defaultTextInputTheme}
              id="partnerAddress"
              name="partnerAddress"
              type="text"
              value={get(values, `${targetLocation}.partnerAddress`) || ""}
              onChange={handleChange}
              placeholder=""
            />
          </div>
          <div className="text-red-500 py-2 text-sm">{get(errors, `${targetLocation}.partnerAddress`) || ""}</div>
        </div>
      </div>
      <div className="w-full flex gap-4">
        <div className="w-full flex flex-col gap-1">
          <label className="text-sm font-semibold text-neutral-800">Description</label>
          <span className="self-stretch text-neutral-500 text-sm font-medium">
            If you work with a company (mortgage broker, electrician, title company, plumber, etc.) you'd like to
            advertise for, this is the place to do it. Type in a short endorsement of their business, and upload a photo
            of their business card/logo or of work they've completed.
          </span>
          <Textarea
            theme={defaultTextAreaTheme}
            id="partnerDescription"
            name="partnerDescription"
            value={partnerDescription}
            onChange={handleChange}
            rows={3}
            placeholder=""
          />
          <div className="flex justify-between items-center mt-1">
            <div className="text-red-500 py-2 text-sm">{get(errors, `${targetLocation}.partnerDescription`) || ""}</div>
            <div className="overflow-hidden text-neutral-400 text-ellipsis  text-sm font-medium">
              {partnerDescriptionWordCount}/50 words
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralPartner;
