import * as React from 'react';

const AuthorifyLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg 
    width="32" 
    height="40" 
    viewBox="0 0 32 40" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg" 
    {...props}
  >
    <path d="M11.8411 35.7324C13.1565 36.1798 14.5592 36.4128 15.9999 36.4128C17.4304 36.4128 18.8228 36.1817 20.128 35.7454V34.4579L11.8411 34.4458V35.7324Z" fill="#252525"/>
    <path d="M16.6481 15.1404L16.6351 22.6688C17.0955 22.7969 17.5095 23.0448 17.8279 23.4068L17.9161 23.5098L18.0062 23.6203L18.0451 23.6918C18.0888 23.7642 18.125 23.8375 18.1612 23.9136L18.2317 24.0547L18.2466 24.1086C18.5901 25.0638 18.2076 26.1471 17.244 26.7097C16.1134 27.365 14.6235 27.0457 13.9161 26.0004C13.2069 24.9505 13.5494 23.5693 14.6801 22.9148C14.901 22.7839 15.1359 22.705 15.3763 22.6475L15.3902 15.1385L14.3032 15.1357C13.4872 19.6361 11.2473 22.0153 8.34637 25.0174L11.8432 33.5846C19.0802 33.5948 12.3482 33.5846 20.131 33.5966L23.6594 25.0424C20.4884 21.6728 18.6977 19.4671 17.7388 15.1413L16.6481 15.1404Z" fill="#252525"/>
    <path d="M28.9182 16.4688L16.0001 0L3.08113 16.4688C-1.57425 22.4043 -0.891025 30.4776 4.70285 35.6677C7.81633 38.5565 11.9082 40 15.9992 40C20.0911 40 24.1811 38.5565 27.2965 35.6677C32.8904 30.4776 33.5745 22.4043 28.9182 16.4688ZM25.1883 33.7118C23.7421 35.0531 22.0136 36.0371 20.1282 36.621C18.8138 37.0295 17.426 37.2421 16.0001 37.2421C14.565 37.2421 13.1651 37.0239 11.8414 36.6108C9.96718 36.0278 8.24891 35.0457 6.81192 33.7118C2.26052 29.4918 1.70633 22.9232 5.49189 18.0961L16.0011 4.699L26.5102 18.0961C30.2949 22.9232 29.7397 29.4927 25.1883 33.7118Z" fill="#252525"/>
  </svg>
);

export default AuthorifyLogo;