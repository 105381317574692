import * as React from "react";
import type { SVGProps } from "react";

const SvgInbound = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"  // Defines the viewable area used to scale the SVG
    fill="none"           // Specifies that the fill of the SVG should be none (paths will be defined by stroke)
    stroke="currentColor" // Use `currentColor` to inherit color from parent
    strokeWidth={1.8}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}            // Spread additional props passed to the component
    className={`w-3 h-3 ${props.className}`}  // Apply size classes and additional classes from props
  >
    <path d="M9 3L3 9m0 0V4.5M3 9h4.5" />
  </svg>
);

export default SvgInbound;
