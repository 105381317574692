import { getInstance } from "./instance";

const instance = getInstance();

export const getZoomRecordsByEmail = async (email: string, page: string, perPage: string, topic: string, startDate: string, endDate: string) => {
    const url = `zoom/screen-recording/get-records/${email}`;
    const params = { page, perPage, topic, startDate, endDate };
  
    return instance
      .get(url, { params })
      .then((res) => {
          return res.data;
      })
      .catch((error) => {
          return Promise.reject(error);
      });
  };
  
export const getPlayZoomUrl = async (id: string) => {
    const url = `zoom/screen-recording/play-video/${id}`;
  
    return instance
      .get(url)
      .then((res) => {
          return res.data;
      })
      .catch((error) => {
          return Promise.reject(error);
      });
  };

export const getZoomTeamMembersList = async () => {
    const url = "abo/screen-recording/get-coaches-list";

    return instance
      .get(url)
      .then((res) => {
          if (Array.isArray(res.data)) {
              return res.data.map(member => ({
                  email: member.hostEmail,
                  fullName: member.fullName,
                  meetingCount: member.meetingCount,
                  phoneCalls: member.phoneCalls
              }));
          } else {
              throw new Error('Data is not in expected format');
          }
      })
      .catch((error) => {
          throw error;
      });
};

export const getZoomPhoneMembersList = async (email: string) => {
    const url = "zoom/phone-call/zoom-user";
    const params = { email };
  
    return instance
      .get(url, { params })
      .then((res) => {
          return res.data;
      })
      .catch((error) => {
          return Promise.reject(error);
      });
  };

  export const getZoomCallRecordsByEmail = async (email: string, page: string, perPage: string, startDate: string, endDate: string, nextPageToken: string, searchNumber: string) => {
    const url = `zoom/phone-call/get-call-logs/${email}/${nextPageToken}`;
    const params = { page, perPage, startDate, endDate, phoneNumber: searchNumber };

    return instance
      .get(url, { params })
      .then((res) => {
          return res.data;
      })
      .catch((error) => {
          return Promise.reject(error);
      });
};


export const getPlayCallZoomUrl = async (id: string) => {
    const url = `zoom/phone-call/download-call/${id}`;
  
    return instance
      .get(url)
      .then((res) => {
          return res.data;
      })
      .catch((error) => {
          return Promise.reject(error);
      });
};
