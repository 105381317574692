import { useFusionAuth } from '@fusionauth/react-sdk';
import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import SidebarNav from '../components/Sidebar';
import TopNavbar from '../components/TopNavbar';
import RoutesConfig from '../RouteConfig';
import LoginPage from '../../LoginPage';

export default function DashboardLayout() {
  const { isAuthenticated } = useFusionAuth();
  const [openMobile, setOpenMobile] = useState(false);

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  const handleOpenMobile = () => {
    setOpenMobile(!openMobile);
  };

  return (
    <div id="page-container">
      <TopNavbar mobileDevice={handleOpenMobile} />
      <div className="flex mt-[55px] relative">
        <div className="w-[272px] z-10 absolute">
          <SidebarNav
            openMobileDevice={openMobile}
            menuMobileClicked={handleOpenMobile}
          />
        </div>
        <div className="bg-neutral-50 w-full h-full px-8 py-4 overflow-hidden ml-[272px] max-[1024px]:ml-0 max-[1024px]:px-4">
          <RoutesConfig />
        </div>
      </div>
    </div>
  );
}
