import React, { Dispatch, useState, useContext, useEffect } from "react";
import { Button, Dropdown } from '../../../../../components'
import { closeButton } from '../../../../../../assets/images'
import { OfferContext } from '../../utils/OfferProvider'
import { getUniqueCategories } from '../../utils'

interface BookPackgesModalProps {
    showModal: boolean,
    setShowModal: Dispatch<boolean>,
    handleBlur?: Function;
    availableBookPackages: string[];
    defaultSelectedBooks: any;
}

const BookPackgesModal: React.FC<BookPackgesModalProps> = ({
    showModal,
    setShowModal,
    handleBlur,
    availableBookPackages,
    defaultSelectedBooks,
}) => {
    const [selectedBookPackageNames, setSelectedBookPackageNames] = useState<string[]>([]);

    const {
        booksOptions,
        offerBookPackages,
        setOfferBookPackages,
        selectedBooks,
        setSelectedBooks,
        setAllowedCategories,
    } = useContext(OfferContext);
    
    useEffect(() => {
        setSelectedBookPackageNames(selectedBooks);
    }, [selectedBooks])


    const handleResetToDefault = () => {
        setSelectedBooks(defaultSelectedBooks);
        setShowModal(false);
    }

    const handleSaveChanges = () => {
        const uniqueCategories = getUniqueCategories(offerBookPackages);
        setAllowedCategories(uniqueCategories);
        setSelectedBooks(selectedBookPackageNames);
        setShowModal(false);
    }

    const findOrCreateBookPackage = (name, prevPackages, availablePackages) => {
        const existingPackage = prevPackages.find((pkg) => pkg?.alias === name);
        if (existingPackage) {
            return existingPackage;
        }
        for (let packages of availablePackages) {
            const newPackage = packages?.packages?.find((pkg) => pkg?.alias === name);

            if (newPackage) {
                return newPackage;
            }
        }
        return null;
    };

    const handleSelectBookPackages = (selectedValues) => {
        setOfferBookPackages((prev) => {
            const updatedPackages = selectedValues
                .map((name) => findOrCreateBookPackage(name, prev, availableBookPackages))
                .filter(Boolean);
            return updatedPackages;
        });
        setSelectedBookPackageNames(selectedValues);
    };

    if (showModal) return (
        <div className="fixed top-0 left-0 bg-black bg-opacity-50 w-full h-full z-20 flex items-center max-sm:items-start justify-center">
            <div className="relative flex w-[630px] max-md:w-full
             max-h-[90dvh] flex-col rounded-lg bg-white shadow z-30">
                <div className="mx-6 pt-6 pb-3 flex justify-between items-center font-bold border-b border-neutral-200">
                    <span className="text-base font-bold">Edit packages</span>
                    <div
                        className="cursor-pointer"
                        onClick={() => setShowModal(false)}
                    >
                        <img src={closeButton} alt="close" />
                    </div>
                </div>
                <div className="mx-[30px] mb-6 pt-[18px]" >
                    <span>This will only affect this offer</span>
                    <div className="z-40 mt-[18px]">
                        <Dropdown
                            options={booksOptions}
                            onSelect={
                                (values) => {
                                    handleSelectBookPackages(values);
                                    setSelectedBookPackageNames(values)
                                }}
                            onBlur={handleBlur}
                            selectedValues={selectedBookPackageNames}
                            multiple
                            dropdownClasses='max-h-[320px] overflow-y-auto'
                            inputFit
                        />
                    </div>

                    <div className="flex justify-between mt-6">
                        <Button variant="GRAY" onClick={handleResetToDefault}>Reset to default</Button>
                        <div className="flex gap-2">
                            <Button variant="OUTLINED" onClick={() => setShowModal(false)}>Cancel</Button>
                            <Button onClick={handleSaveChanges}>Save changes</Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
    return;
}

export default BookPackgesModal;