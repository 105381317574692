import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  productTitle: Yup.string()
    .trim()
    .required('Please enter hero/product title'),
  productDescription: Yup.string()
    .trim(),
  templateId: Yup.string()
    .trim()
    .required('Template ID is required'),
});

export default validationSchema;
