import React from 'react';
import classNames from 'classnames';

interface SkeletonLoaderProps {
  rows?: number;
  columns?: number;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ rows = 5, columns = 5 }) => {
  return (
    <div className="p-6 bg-neutral-100 animate-pulse">
      {[...Array(rows)].map((_, rowIndex) => (
        <div key={rowIndex} className="flex gap-2 mb-4">
          {[...Array(columns)].map((_, colIndex) => (
            <div
              key={colIndex}
              className={classNames(
                'bg-gray-200 h-8 rounded',
                colIndex === 0 ? 'w-1/4' : 'w-full'
              )}
            ></div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SkeletonLoader;
