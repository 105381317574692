import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Formik, Form } from "formik";
import { isEmpty } from "lodash-es";
import { ButtonGroup, Textarea, TextInput } from "flowbite-react";

import Title from "../../../components/Title";
import validationSchema from "./utils/validationSchema";
import ImageUploader from "../../../components/ImageUploader";
import Button from "../../../components/Button";
import { getBookPackages } from "../../../api/offer";
import { useMutation, useQuery } from "react-query";
import { updateOffer } from "../../../api/place-order-offer";
import ToastNotification from "../../../components/ToastNotification";

const EditOffer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const offers = location?.state;

  const [inclusion, setInclusion] = useState(offers?.whatsIncluded);
  const [inclusionText, setInclusionText] = useState("");
  const [coachSegmentsOption, setCoachSegmentsOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  const {
    data: bookPackages,
    refetch,
    isLoading: isBookPackageLoading,
  } = useQuery(["getBookPackages", []], getBookPackages);

  useEffect(() => {
    if (!isBookPackageLoading) {
      const coachSegmentsOptionlist = bookPackages?.books.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setCoachSegmentsOption(coachSegmentsOptionlist);
      setSelectedOption(
        coachSegmentsOptionlist.filter((option) =>
          offers?.packages.includes(option.value)
        )
      );
    }
  }, [isBookPackageLoading]);

  const { mutateAsync } = useMutation((payload) => updateOffer(payload));

  const handleCancel = () => {
    navigate("/offers");
  };

  const handleCreateOffer = async (body) => {
    const payload = { ...body, whatsIncluded: inclusion };
    payload.workFlow = { upsell: "45345" };
    payload.trial = 0;
    payload.description2 = "";

    await mutateAsync(payload)
      .then(() => {
        refetch();
        ToastNotification({
          type: "success",
          message: "Offer updated successfully!",
        });
        navigate("/offers");
      })
      .catch(() => {
        ToastNotification({
          type: "failure",
          message: "Offer not updated",
        });
      });
  };

  const handleInclusion = (e) => {
    const value = e.target.value;
    setInclusionText(value);
  };

  const removeInclusion = (index) => {
    if (index > -1) {
      const result = [...inclusion];
      result.splice(index, 1);
      setInclusion(result);
    }
  };

  const addToInclusionList = () => {
    if (inclusionText && !inclusion.includes(inclusionText)) {
      setInclusion([...inclusion, inclusionText]);
      setInclusionText("");
    }
  };

  return (
    <div>
      <div className="flex w-full">
        <div className="w-1/2">
          <Title>Edit Offers</Title>
        </div>
      </div>
      <Formik
        validationSchema={validationSchema}
        initialValues={offers}
        onSubmit={handleCreateOffer}
      >
        {({
          values,
          errors,
          dirty,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          const isDisabled = !isEmpty(errors) || !dirty;

          const handleInclusion = (e) => {
            const value = e.target.value;
            setInclusionText(value);
          };

          return (
            <Form onSubmit={handleSubmit}>
              <div className="border rounded-sm p-2 m-2">
                <div className="flex">
                  <div>
                    <h1 className="text-xl font-bold">Offer Details</h1>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-col w-1/2 p-2">
                    <label htmlFor="title">Offer Name </label>
                    <TextInput
                      type="text"
                      id="title"
                      name="title"
                      value={values?.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.title && touched.title ? (
                      <span>{errors.title}</span>
                    ) : null}
                  </div>
                  <div className="flex flex-col w-1/2 p-2">
                    <label htmlFor="code">Offer Code</label>
                    <TextInput
                      type="text"
                      id="code"
                      name="code"
                      value={values?.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.code && touched.code ? (
                      <span>{errors.code}</span>
                    ) : null}
                  </div>
                </div>

                <div className="flex">
                  <div className="flex flex-col w-1/2 p-2">
                    <label htmlFor="description1">Offer Description </label>
                    <Textarea
                      rows={4}
                      id="description1"
                      name="description1"
                      value={values?.description1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.description1 && touched.description1 ? (
                      <span>{errors.description1}</span>
                    ) : null}
                  </div>
                  <div className="flex flex-col w-1/2 p-2">
                    <label htmlFor="image">Upload Image</label>
                    <div>
                      <div>
                        <ImageUploader
                          imagePlaceholder={values?.image}
                          label="Upload Image"
                          callback={(imageUrl) => {
                            setFieldValue("image", imageUrl);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex flex-col w-1/2 p-2">
                    <div>
                      <label htmlFor="inclusion">
                        Offer Inclusion (add multiple inclusions)
                      </label>
                    </div>
                    <div className="flex w-full">
                      <TextInput
                        type="text"
                        id="inclusion"
                        name="inclusion"
                        value={inclusionText}
                        onChange={handleInclusion}
                        onBlur={handleBlur}
                      />
                      <Button onClick={addToInclusionList}> + </Button>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2 p-2">
                    <label htmlFor="credits">Offer Credits</label>
                    <TextInput
                      type="text"
                      id="credits"
                      name="credits"
                      value={values?.credits}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.credits && touched.credits ? (
                      <span>{errors.credits}</span>
                    ) : null}
                  </div>
                </div>

                <div className="flex">
                  <div className="flex flex-col w-1/3">
                    {inclusion?.length
                      ? inclusion?.map((included, index) => (
                          <div
                            className="flex items-center justify-end relative"
                            key={included}
                          >
                            <div className="relative font-normal text-sm w-full bg-gray-200 border border-gray-300 rounded-md mb-4 px-2 py-1">
                              <p>{included}</p>
                            </div>
                            <Button onClick={() => removeInclusion(index)}>
                              -
                            </Button>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>

              <div className="border rounded-sm p-2 m-2">
                <div className="flex">
                  <div>
                    <h1 className="text-xl font-bold">Book Packages</h1>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-col w-full p-2">
                    <label htmlFor="packages">
                      Book Lists (add multiple selections){" "}
                    </label>
                    <Select
                      value={selectedOption}
                      isMulti
                      name="packages"
                      options={coachSegmentsOption}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions.map(
                          (option) => option.value
                        );
                        setFieldValue("packages", selectedValues);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="border rounded-sm p-2 m-2">
                <div className="flex">
                  <div>
                    <h1 className="text-xl font-bold">Offer Products</h1>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-col w-1/2 p-2">
                    <label htmlFor="productCode">Product Code </label>
                    <TextInput
                      type="text"
                      id="productCode"
                      name="productCode"
                      value={values?.products?.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="flex flex-col w-1/2 p-2">
                    <label htmlFor="productDescription">
                      Product Description
                    </label>
                    <TextInput
                      type="text"
                      id="productDescription"
                      name="productDescription"
                      value={values?.products?.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="flex">
                  <div className="flex flex-col w-1/2 p-2">
                    <label htmlFor="productPrice">Product Price </label>
                    <TextInput
                      type="text"
                      id="productPrice"
                      name="productPrice"
                      value={values?.products?.price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="flex flex-col w-1/2 p-2">
                    <label htmlFor="priceDecription">Product Description</label>
                    <TextInput
                      type="text"
                      id="priceDecription"
                      name="priceDecription"
                      value={values?.products?.priceDecription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>

              <div className="border rounded-sm p-2 m-2">
                <div className="flex">
                  <div>
                    <h1 className="text-xl font-bold">Webinar</h1>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-col w-1/2 p-2">
                    <label htmlFor="webinarTitle">Webinar Title </label>
                    <TextInput
                      type="text"
                      id="webinarTitle"
                      name="webinarTitle"
                      value={values?.webinar?.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="flex flex-col w-1/2 p-2">
                    <label htmlFor="webinarCode">Webinar Code</label>
                    <TextInput
                      type="text"
                      id="webinarCode"
                      name="webinarCode"
                      value={values?.webinar?.id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="flex">
                  <div className="flex flex-col w-1/2 p-2">
                    <label htmlFor="webinarDescription">
                      Webinar Description{" "}
                    </label>
                    <Textarea
                      rows={4}
                      id="webinarDescription"
                      name="webinarDescription"
                      value={values?.webinar?.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="flex flex-col w-1/2 p-2">
                    <label htmlFor="descriptionURL">
                      Webinar Description URL
                    </label>
                    <TextInput
                      type="text"
                      id="descriptionURL"
                      name="descriptionURL"
                      value={values?.webinar?.image}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>

              <div className="border rounded-sm p-2 m-2">
                <div className="flex">
                  <div>
                    <h1 className="text-xl font-bold">Upsell Offer</h1>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-col w-full p-2">
                    <label htmlFor="upsellOffer">Upsell Offer Code</label>
                    <TextInput
                      type="text"
                      id="upsellOffer"
                      name="upsellOffer"
                      value={values?.upsell}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>

              <div className="flex"></div>
              <div className="flex justify-end">
                <div className="p-2">
                  <Button onClick={handleCancel}>Cancel</Button>
                </div>
                <div className="p-2">
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                  >
                    Update Offer Details
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditOffer;
