import classNames from 'classnames';

export const getHeaderClassNames = (index: number, columnNameKey: string, headerCustomProp: string | undefined) => {
  return classNames(
    "whitespace-nowrap px-6 text-sm font-semibold leading-tight py-3 border-y bg-white",
    {
      "shadow-sm border-r border-y bg-white md:sticky md:left-0": index === 0,
      "bg-slate-400": columnNameKey === headerCustomProp,
      "bg-slate-200": columnNameKey !== headerCustomProp
    }
  );
};

export const getCellClassNames = (index: number) => {
  return classNames(
    "py-[18px] px-[24px]",
    {
      "shadow-sm border-r border-b bg-white md:sticky md:left-0": index === 0,
      "bg-white border-b": index !== 0
    }
  );
};
