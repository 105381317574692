import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Formik, Form } from "formik";
import { isEmpty } from "lodash-es";
import { useMutation, useQuery } from "react-query";
import { TextInput, ToggleSwitch } from "flowbite-react";

import validationSchema from "../utils/validationSchema";
import Title from "../../../../components/Title";
import { getCoachById, updateCoach } from "../../../../api/coach-maintenance";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loaders";
import ImageUploader from "../../../../components/ImageUploader";
import ToastNotification from "../../../../components/ToastNotification";

const EditCoach = () => {
  const navigate = useNavigate();
  const { id: coachID } = useParams();
  const [switchEnable, setSwitchEnable] = useState(false);
  const [coachData, setCoachData] = useState(false);

  const { isLoading: isCoachLoading } = useQuery(
    ["getCoachById", coachID],
    () => getCoachById(coachID),
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        const { coachValidation, ...result } = data;
        setCoachData(result);
        setSwitchEnable(data.enabled);
      },
    }
  );

  const { mutate: mutateCoach } = useMutation(
    (body) => {
      updateCoach(coachID, body);
    },
    {
      onSuccess: () => {
        ToastNotification({
          type: "success",
          message: "Coach details updated successfully",
        });
        navigate("/coach-maintenance", { replace: true });
      },
      onError: () => {
        ToastNotification({
          type: "failure",
          message: "Coach details not updated",
        });
      },
    }
  );

  const handleCancel = () => {
    navigate("/coach-maintenance");
  };

  if (isCoachLoading) {
    return <Loader />;
  }

  const coachSegmentsOption = [
    { value: "REALTOR", label: "REALTOR" },
    { value: "DENTIST", label: "DENTIST" },
  ];

  return (
    <div>
      <div className="flex w-full">
        <div className="w-1/2">
          <Title>Edit Coach Maintenance</Title>
        </div>
      </div>
      <Formik
        validationSchema={validationSchema}
        initialValues={coachData}
        onSubmit={mutateCoach}
      >
        {({
          values,
          errors,
          dirty,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          const isDisabled = !isEmpty(errors) || !dirty;


          const handleEnableValueChange = (isEnabled: boolean) => {
            setSwitchEnable(!switchEnable);
            setFieldValue("enabled", isEnabled);
          };

          return (
            <Form onSubmit={handleSubmit}>
              <div className="flex">
                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="firstName">
                    First Name<span className="text-red-500">*</span>{" "}
                  </label>
                  <TextInput
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.firstName && touched.firstName ? (
                    <span>{errors.firstName}</span>
                  ) : null}
                </div>
                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="lastName">
                    Last Name<span className="text-red-500">*</span>
                  </label>
                  <TextInput
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors?.lastName && touched?.lastName ? (
                    <span>{errors.lastName}</span>
                  ) : null}
                </div>
              </div>

              <div className="flex">
                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="email">
                    Email<span className="text-red-500">*</span>{" "}
                  </label>
                  <TextInput
                    type="email"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email ? (
                    <span>{errors.email}</span>
                  ) : null}
                </div>
                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="meetingLink">
                    Meeting Link<span className="text-red-500">*</span>
                  </label>
                  <TextInput
                    type="text"
                    id="meetingLink"
                    name="meetingLink"
                    value={values.meetingLink}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.meetingLink && touched.meetingLink ? (
                    <span>{errors.meetingLink}</span>
                  ) : null}
                </div>
              </div>

              <div className="flex">
                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="coachingSegments">
                    Coaching Segments<span className="text-red-500">*</span>{" "}
                  </label>
                  <Select
                    defaultValue={coachSegmentsOption.filter((option) =>
                      values.coachingSegments.includes(option.value)
                    )}
                    isMulti
                    name="coachingSegments"
                    options={coachSegmentsOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map(
                        (option) => option.value
                      );
                      setFieldValue("coachingSegments", selectedValues);
                    }}
                  />
                  {errors.coachingSegments && touched.coachingSegments ? (
                    <span>{errors.coachingSegments}</span>
                  ) : null}
                </div>
                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="enabled">
                    Enabled<span className="text-red-500">*</span>
                  </label>
                  <ToggleSwitch
                    checked={switchEnable}
                    label="Toggle me"
                    onChange={handleEnableValueChange}
                    name="enabled"
                  />
                  {errors.enabled && touched.enabled ? (
                    <span>{errors.enabled}</span>
                  ) : null}
                </div>
              </div>

              <div className="flex">
                <div className="flex flex-col w-1/2 p-2">
                  <label htmlFor="image">
                    Upload Profile Image<span className="text-red-500">*</span>
                  </label>
                  <div>
                    <div>
                      <ImageUploader
                        imagePlaceholder={values?.profile?.fullImageUrl}
                        label="Upload logo"
                        callback={(imageUrl) => {
                          setFieldValue("profile.fullImageUrl", imageUrl);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <div className="p-2">
                  <Button onClick={handleCancel}>Cancel</Button>
                </div>
                <div className="p-2">
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                  >
                    Update Coach Details
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditCoach;
