import * as React from "react";
import type { SVGProps } from "react";
const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#252525"
      d="m11.37 8.604-.303.302s-.722.718-2.692-1.241-1.248-2.677-1.248-2.677l.191-.19c.471-.468.516-1.22.105-1.77l-.84-1.122c-.51-.68-1.492-.77-2.075-.19L3.46 2.756c-.289.288-.483.661-.46 1.074.061 1.058.54 3.333 3.21 5.988 2.83 2.815 5.487 2.927 6.573 2.825.344-.032.643-.207.883-.446l.947-.942c.64-.635.46-1.725-.358-2.17l-1.274-.692a1.36 1.36 0 0 0-1.611.211"
    />
  </svg>
);
export default SvgPhone;
