import axios, { AxiosInstance } from 'axios';

const instance: AxiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_DIGITAL_SERVICES_URL}product-catalog/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  // withCredentials: true,
});

export const getProductCatalogInstance = (): AxiosInstance => instance;
