import { getInstance } from "./instance";

const instance = getInstance();

export const createUploads = async ({ payload, callbackTargetKey, callbackPayload }) => {
  const { data: createUpload = {} } = await instance.post("/abo/upload/customer/file", payload);
  const callbackLink = createUpload[callbackTargetKey] || "";
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": payload.contentType,
    },
    body: callbackPayload,
  };

  const response = await fetch(callbackLink, requestOptions);

  if (response.status === 200) {
    return createUpload.downloadUrl;
  }
  return false;
};

export const getUploadedImages = (customerId: string) => {
  return instance
    .get(`/abo/uploads/${customerId}/photo-gallery`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const uploadImage = (body = {}) => {
  const url = "/upload/file";
  return instance
    .post(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const fetchPresetLogos = async () => {
  // TODO: fix to correct api endpoint.
  const url = "/book/logos";
  return instance.get(url);
};
