import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { Dropdown } from "flowbite-react";
import { Link, useParams } from "react-router-dom";
import { get, isEmpty } from "lodash-es";
import { DateTime } from "luxon";

// API
import { getGeneratedMagazineAdmin, uploadCustomCover } from "../../../../../api/rm-magazine-report";
import { sendBackToEdit } from "../../../../../api/referral-marketing-magazines";
import { createUploads } from "../../../../../api/uploads";

import env from "../../../../../../environments";

// Components
import Button from "../../../../../components/Button";
import PublishLoader from "../../../../../components/PublishLoader";
import LinkInput from "../../../../../components/LinkInput";
import { formatDateTime } from "../../../../../../utils/date";
import { SvgArrowTail, SvgDownload, SvgReplacePdf } from "../../../../../../components/icons";

import { ObjectType, QueryKeyType, MagazineType } from "../types";
import { rmMagazineDetailStatus } from "./Steps/constants";

type PayloadType = {
  bucket?: unknown;
  isPrivate?: boolean;
  contentType: string;
  ext?: string;
  path?: string;
  file: File;
  customer: ObjectType;
};

type RequestType = {
  payload: PayloadType;
  callbackTargetKey: string;
  callbackPayload: unknown;
};

function ContentMagazineDetails() {
  const [customer, setCustomer] = useState<ObjectType>({});
  const [refetchGeneratedMagazine, setRefetchGeneratedMagazine] = useState(false);
  const [currentMagazineStatus, setCurrentMagazineStatus] = useState("");
  const [isUploading, setIsLoading] = useState(false);
  const [coverOnlyUrl, setCoverUrl] = useState("");
  const [magazineDataNotFound, setMagazineDataNotFound] = useState(false);
  const [magazine, setMagazine] = useState<MagazineType>({
    id: "",
    pdf: "",
    frontInsideCoverText: "",
    additionalInformation: "",
    isFormUpdated: false,
    previewPdf: "",
    maxCoverLetterLength: 1500,
    baseReplacers: [],
    createdAt: "",
    updatedAt: "",
    month: "",
  });

  // Get the year, month and customerId from the URL
  const { year, month, customerId } = useParams();

  // Initial value for sendToPrint.
  let sendToPrint = false;

  /**
   * Settings query params for fetching magazine details
   *
   * @param array - ['getGeneratedMagazineAdmin', year, month, customerId]
   * @param getGeneratedMagazineAdmin - function to fetch magazine details
   *
   * @returns object - magazine data.
   */
  const getMagazineQueryKey: QueryKeyType = ["getGeneratedMagazineAdmin", year || "", month || "", customerId || ""];
  const getMagazineFn = async (): Promise<unknown> => {
    const result = await getGeneratedMagazineAdmin({
      queryKey: getMagazineQueryKey,
    });
    return result;
  };

  /**
   * Fetch magazine details
   *
   * @param getMagazineQueryKey - query key for fetching magazine details
   * @requires getMagazineFn - function to fetch magazine details
   *
   */
  const {
    data: magazineFromDatabase = {},
    refetch: refetchMagazineFromDb,
    isLoading: magazineDataLoading,
  } = useQuery(getMagazineQueryKey, getMagazineFn, {
    onSuccess: (generatedMagazineData: any) => {
      const updatedMagazineData = {
        ...magazine,
        additionalInformation: get(generatedMagazineData, "additionalInformation") || "",
        bookUrl: get(generatedMagazineData, "bookUrl") || "",
        pageUrl: get(generatedMagazineData, "pageUrl") || "",
        ...get(generatedMagazineData, "magazine", {}),
        previewPdf: get(generatedMagazineData, "url") || "",
      };

      setCurrentMagazineStatus(get(generatedMagazineData, "magazine.status", ""));
      setMagazine(updatedMagazineData);

      const isStatusDone = generatedMagazineData?.status === "DONE";

      if (isStatusDone) {
        setRefetchGeneratedMagazine(false);
      }
    },
    onError: () => {
      setMagazineDataNotFound(true);
    },
  });

  /**
   * Update customer magazine cover
   *
   * @param customCoverData - object with userEmail, month, year, magazineURL, coversURL
   * @returns object - includes updated magazine cover and url
   */
  const { mutate: mutateUploadCustomCover } = useMutation(uploadCustomCover, {
    onError: () => {},
    onSuccess: () => {},
  });

  /**
   * Update status to Editing
   *
   * @param payload - object with id and status
   * @returns object - magazine data new status
   */
  const { mutate: sendBackToEditing, isLoading: sendingBackForEditing } = useMutation(
    (payload: { id: string; status: string }) => sendBackToEdit(payload),
    {
      onSuccess: () => {
        refetchMagazineFromDb();
      },
      onError: () => {},
    }
  );

  /**
   * Handle Send back to edit function
   *
   * @returns void
   */
  const sendBackMagazineForEditing = async () => {
    const payload = {
      id: magazine?.id,
      status: rmMagazineDetailStatus.EDITING,
    };
    return sendBackToEditing(payload);
  };

  /**
   * File handling function
   *
   * @param e : file input element
   *
   */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handlePDFUploadConfirm(e.target.files[0]);
    }
  };

  /**
   * Handle file cover pdf upload
   *
   * @param files
   * @returns
   */
  const handlePDFUploadConfirm = async (file: File) => {
    if (!file) return;

    setIsLoading(true);
    if (file.type === "application/pdf") {
      try {
        const req: RequestType = {
          payload: {
            bucket: env.DS_BUCKET,
            isPrivate: false,
            contentType: file.type,
            ext: "pdf",
            path: "",
            file: file,
            customer: {
              _id: customerId,
            },
          },
          callbackTargetKey: "uploadUrl",
          callbackPayload: file,
        };

        const response = await createUploads(req);

        if (response) {
          setCoverUrl(response);
          const customCoverData = {
            userEmail: magazine?.baseReplacers?.[0]?.email,
            month: magazine.month,
            year: DateTime.fromISO(magazine?.createdAt).toFormat("yyyy"),
            magazineURL: magazine.previewPdf,
            coversURL: response,
            customer: {
              _id: customerId,
            },
          };
          mutateUploadCustomCover(customCoverData);
        }
      } catch (err) {
        throw new Error(err);
      }
    }
    setIsLoading(false);
  };

  /**
   * Handles the download of the magazine
   */
  const handleDownloadMagazine = () => {
    window.open(magazineFromDatabase.url, "_blank");
  };

  /**
   * Handles the download of the cover
   */
  const handleDownloadCover = () => {
    window.open(coverOnlyUrl, "_blank");
  };

  useEffect(() => {
    /**
     * Fetch customer details,
     * set details to state
     */
    if (!magazineDataLoading && !isEmpty(magazineFromDatabase)) {
      const {
        magazine: { baseReplacers },
        coversOnlyUrl,
      } = magazineFromDatabase as MagazineType;
      setCustomer(baseReplacers[0]);
      setCoverUrl(coversOnlyUrl);
    }
  }, [magazineFromDatabase, magazineDataLoading]);

  if (currentMagazineStatus === rmMagazineDetailStatus.PRINTING) {
    sendToPrint = true;
  }

  if (!magazineDataLoading && magazineDataNotFound) {
    return (
      <div className="relative w-full h-[calc(100vh-4rem)] min-h-3/4 overflow-hidden">
        <div className="text-neutral-800 text-2xl font-extrabold leading-7 mb-6">Magazine report</div>
        <div className="w-full h-full flex flex-col justify-center bg-white rounded-lg p-6 shadow-box">
          <div className="frame_280 flex flex-col justify-center items-center self-stretch gap-4">
            <div className="frame_824 flex flex-col justify-center items-center self-stretch">
              <div className="frame_191 flex flex-col items-center self-stretch">
                <div className="self-stretch text-neutral-800 text-center  text-base font-semibold">
                  No active magazine for this month of {month} {year}
                </div>
                <div className="text-neutral-600 text-centertext-sm font-medium leading-[1.4375rem]">
                  Customer not found
                </div>
              </div>
            </div>
            <Link to="/rm-magazine-report">Back to report</Link>
          </div>
        </div>
      </div>
    );
  }

  if (magazineDataLoading || sendingBackForEditing || isUploading) {
    let message = "Publishing customer magazine...";

    if (sendingBackForEditing) {
      message = "Sending back to editing...";
    } else if (magazineDataLoading) {
      message = "Loading customer magazine data...";
    }

    return (
      <div className="flex w-full h-[calc(100vh-12em)] flex-col pb-10">
        <div className="z-20 w-full h-full">
          <PublishLoader message={message} />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full overflow-hidden">
      <div className="text-neutral-800 text-2xl font-extrabold leading-7 mb-6 flex flex-col items-center gap-3 lg:flex-row lg:justify-between">
        <div className="capitalize">
          {month} {year} - {!magazineDataLoading && <>{customer.agentName}</>}
        </div>
        <div className="flex gap-2 max-[500px]:flex-col">
          <Button variant="LIGHT" className="flex gap-4 items-center focus:ring-0">
            <SvgReplacePdf className="w-4 h-4" />
            <label htmlFor="pdfCover" className="ml-2 font-semibold h-auto cursor-pointer">
              Replace cover (.PDF)
            </label>
            <input
              type="file"
              id="pdfCover"
              name="pdfCover"
              accept="application/pdf"
              className="hidden"
              onChange={handleFileChange}
            />
          </Button>
          <Button variant="DARK" onClick={sendBackMagazineForEditing}>
            <SvgArrowTail className="mr-2" /> Send back for editing
          </Button>
        </div>
      </div>
      <div className="w-full flex gap-4 max-[1300px]:flex-col">
        <div className="content w-3/4 h-full bg-white border rounded-lg p-6 shadow-box max-[1300px]:w-full">
          <div className="relative w-full flex justify-between items-center mb-3.5 max-[500px]:flex-col">
            <div className="text-neutral-800 text-base font-semibold">Preview</div>
            <div className="flex gap-2 justify-end">
              <Button variant="LIGHT">
                <Link to={`/rm-magazine-report/details/${year}/${month}/${customerId}?status=edit`}>Edit magazine</Link>
              </Button>
              <Dropdown
                color={"light"}
                label="Download"
                dismissOnClick={false}
                theme={{
                  floating: {
                    base: "flex ms-8 rounded-lg",
                  },
                }}
                disabled={!coverOnlyUrl}
              >
                <Dropdown.Item icon={SvgDownload} onClick={handleDownloadCover}>
                  Download cover
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item icon={SvgDownload} onClick={handleDownloadMagazine}>
                  Download magazine
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>
          <div className="h-[480px]">
            {!isEmpty(magazine.previewPdf) && <afy-flip-book key="magazine-step-pdf" pdfurl={magazine.previewPdf} />}
          </div>
        </div>
        <div className="max-[1300px]:w-full w-1/3 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-6">
            <div className="w-full flex flex-col items-start gap-5 bg-white shadow-box rounded-lg self-stretch p-6">
              <div className="w-full flex gap-4 justify-between items-center">
                <div className="text-neutral-600 text-base">Status</div>
                <div className="flex justify-center items-center">
                  <div
                    className={`flex justify-center items-center ${
                      sendToPrint
                        ? "bg-success-50 text-success-600"
                        : "bg-secondary-orange-50 text-secondary-orange-600"
                    } px-4 py-2 rounded-lg`}
                  >
                    {currentMagazineStatus && (
                      <span className="font-semibold text-sm">
                        {currentMagazineStatus === rmMagazineDetailStatus.PRINTING ? (
                          "Sent for printing"
                        ) : (
                          <span className="capitalize">{currentMagazineStatus}</span>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="border-t w-full flex flex-col items-center bg-white shadow-box rounded-lg" />
              <div className="w-full flex gap-4 justify-between items-center">
                <div className="text-neutral-600 text-base">Last edited</div>
                <div className="overflow-hidden text-neutral-400 text-ellipsis  text-sm font-medium text-neutral-700">
                  {formatDateTime(new Date(magazineFromDatabase.updatedAt))}
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col items-start gap-5 bg-white shadow-box rounded-lg self-stretch p-6">
              <div className="w-full">
                <LinkInput
                  value={magazineFromDatabase.pageUrl}
                  label="Lead capture website"
                  placeholder=""
                  id="lead-capture-webisite"
                  options={{
                    tooltip:
                      "Generate more leads and grow your business. You'll get notified instantly whenever you receive a new lead.",
                  }}
                />
                {magazineFromDatabase.pageUrl && (
                  <Link
                    to={magazineFromDatabase.pageUrl}
                    className="text-sm text-neutral-800 font-semibold text-underline mt-2 block pb-0.5 border-b w-12"
                  >
                    Preview
                  </Link>
                )}
              </div>
              <div className="w-full">
                <LinkInput
                  value={magazineFromDatabase.bookUrl}
                  label="Digital magazine"
                  placeholder=""
                  id="digital-magazine"
                  options={{
                    tooltip:
                      "Easily showcase your expertise and build trust with clients who are unable to meet with you in person.",
                  }}
                />
                {magazineFromDatabase.bookUrl && (
                  <Link
                    to={magazineFromDatabase.bookUrl}
                    className="text-sm text-neutral-800 font-semibold text-underline mt-2 block pb-0.5 border-b w-12"
                  >
                    Preview
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentMagazineDetails;
