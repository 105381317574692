import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ZoomIcon, XIcon } from "../../../../components/icons";
import { getZoomTeamMembersList } from "../../../api/zoom";
import Avatar from "../../../components/Avatar";
import Title from "../../../components/Title";
import Loader from "../../../components/Loaders";
import SkeletonCardLoader from "../../../components/SkeletonCardLoader";
import TextInput from "../../../components/TextInput";


export default function TeamMeeting() {
  const [searchValue, setSearchValue] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]);
  const navigate = useNavigate();

  const { data: allTeamMembers, isError, error, isLoading } = useQuery("getAllTeamMembers", getZoomTeamMembersList, {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (!allTeamMembers) {
      return;
    }
    
    const lowercasedFilter = searchValue.toLowerCase();
    const filteredData = allTeamMembers.filter(member =>
      member.fullName.toLowerCase().includes(lowercasedFilter) || member.email.toLowerCase().includes(lowercasedFilter)
    );
    setFilteredMembers(filteredData);
  }, [searchValue, allTeamMembers]);

  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const clearInput = () => {
    setSearchValue("");
  };

  const handleNavigate = (email) => {
    navigate(`/team-meetings/${encodeURIComponent(email)}`, { replace: true });
  };

  if (isError) return <div>Error: {error.message}</div>;

  return (
    <div className="max-w-[2500px]">
      <div className="pt-8 pb-8 w-full">
        <Title>Team Meetings</Title>
      </div>
      <div className="pb-5 w-full bg-[#F9FAFB] h-full min-h-screen">
        <div className="w-full bg-white rounded-lg shadow">
          <div className="px-6 py-5 border-b border-gray-200 flex justify-between items-center">
            <div className="relative w-[341px] max-[450px]:w-full">
              <TextInput
                placeholder="Search name or email"
                value={searchValue}
                onChange={onChangeSearchInput}
              />
              {searchValue && (
                <button
                  onClick={clearInput}
                  className="absolute inset-y-0 right-0 pr-6 flex items-center"
                >
                  <XIcon className="h-5 w-5 text-gray-500" />
                </button>
              )}
            </div>
          </div>
          <div className="p-6 grid gap-4 grid-cols-[repeat(auto-fill,minmax(350px,1fr))] overflow-x-auto">
            {isLoading ? (
              <SkeletonCardLoader count={4} />
            ) : filteredMembers.length > 0 ? (
              filteredMembers.map((member, idx) => (
                <div
                  key={idx}
                  onClick={() => member.meetingCount > 0 && handleNavigate(member.email)}
                  className={`w-full h-[86px] bg-white rounded-lg border border-gray-200 flex-col justify-start items-start inline-flex ${member.meetingCount > 0 ? 'hover:shadow-[inset_0_0_0_1px_rgba(56,189,248,1)] hover:border-sky-400 cursor-pointer' : 'opacity-50'}`}
                  style={{ padding: "22px 24px" }}
                >
                  <div className="justify-start items-center gap-4 inline-flex w-full">
                    <Avatar name={member.fullName} src="/assets/avatar-placeholder.png" />
                    <div className="flex-col flex-grow justify-center items-start gap-0.5">
                      <div className="text-zinc-900 max-w-[150px] text-sm truncate font-semibold">
                        {member.fullName}
                      </div>
                      <div
                        className="text-neutral-500 text-sm truncate"
                        style={{ maxWidth: "150px" }}
                      >
                        {member.email}
                      </div>
                    </div>
                    <div className="flex-none w-[68px] flex justify-end items-center gap-2">
                      <ZoomIcon className="w-4 h-4 text-black" />
                      <div className="text-neutral-800 text-lg font-bold">
                        {member.meetingCount}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-sm text-neutral-500 col-span-3 text-center">
                No team members found.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
  
}
