import * as React from "react";
const SvgArrowDownBlack = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#252525"
      fillRule="evenodd"
      d="M12.195 7.205a.7.7 0 0 1 0 .99l-4.2 4.2a.7.7 0 0 1-.99 0l-4.2-4.2a.7.7 0 1 1 .99-.99L6.8 10.21V2.1a.7.7 0 1 1 1.4 0v8.11l3.005-3.005a.7.7 0 0 1 .99 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowDownBlack;
