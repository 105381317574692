import React, { Dispatch, useContext } from "react";
import { Button } from '../../../../../components';
import { OfferContext } from '../../utils/OfferProvider';
import DefaultModal from '../../../../../components/modals/DefaultModal';

interface ChangeProductModalProps {
    showModal: boolean,
    setShowModal: Dispatch<boolean>,
    productTemporaryValue: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    findPackagesUsingProduct: (productCode: string) => void;
    setSelectedPackageNames: Dispatch<string[]>;
}

const ChangeProductModal: React.FC<ChangeProductModalProps> = ({
    showModal,
    setShowModal,
    productTemporaryValue,
    handleChange,
    findPackagesUsingProduct,
    setSelectedPackageNames,
}) => {

    const {
        setOfferProduct,
        setOfferBookCategories,
        setOfferBookPackages,
        setOfferBookPackageNames,
        setBooksOptions,
        setDefaultPackages,
        availableBookPackages,
        setAllowedCategories,
    } = useContext(OfferContext);

    const handleChangeProduct = () => {
        handleChange({
            target: { name: 'productCode', value: productTemporaryValue },
        } as React.ChangeEvent<HTMLInputElement>);

        setAllowedCategories([]);
        setBooksOptions(availableBookPackages);
        setOfferProduct(productTemporaryValue);
        setOfferBookCategories([]);
        setOfferBookPackages([]);
        setOfferBookPackageNames([]);
        setSelectedPackageNames([]);
        setDefaultPackages([]);
        setBooksOptions([]);
        findPackagesUsingProduct(productTemporaryValue);

        setShowModal(false);
    };

    return (
        <DefaultModal
            showModal={showModal}
            setShowModal={setShowModal}
            size='lg'
            title="Change Product"
            dismissible={false}
        >
            <div className="flex flex-col gap-4 text-sm font-medium">
                <span>You’ve made changes to the packages.</span>
                <span>
                    Are you sure you want to select a different product? This will remove any packages you’ve added.
                </span>
            </div>

            <div className="flex gap-2 justify-end mt-[34px]">
                <Button
                    variant="OUTLINED"
                    onClick={() => setShowModal(false)}
                >
                    Cancel
                </Button>
                <Button
                    variant="DANGER"
                    onClick={handleChangeProduct}
                >
                    Change product
                </Button>
            </div>

        </DefaultModal>)

}

export default ChangeProductModal;