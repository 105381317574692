import React from "react";

function SalesCard({
  titleIcon: TitleIcon,
  title,
  value,
  offerIcon: OfferIcon,
  date,
}) {
  return (
    <div>
      <div className="self-stretch h-[60px] flex-col justify-start items-start gap-1 flex">
        <div className="justify-start items-center gap-2 inline-flex">
          <div className="justify-start items-center gap-2.5 flex">
            {TitleIcon && <TitleIcon className="w-5 h-5 relative" />}
          </div>
          <div className="text-neutral-800 text-sm font-medium font-['Figtree'] leading-tight tracking-tight">
            {title}
          </div>
        </div>
        <div className="self-stretch justify-start items-center gap-2 inline-flex">
          <div className="text-neutral-800 text-3xl font-bold font-['Figtree'] leading-9">
            {value}
          </div>
        </div>
      </div>
      <div className="text-zinc-500 text-xs font-medium font-['Figtree'] leading-none tracking-tight">
        {date}
      </div>
    </div>
  );
}

export default SalesCard;
