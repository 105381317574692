import React from 'react';
import { Card } from 'flowbite-react';
import { HomeLogo } from '../../components/icons/index';
import { AuthorifyText } from '../../components/icons/index';
import Cookies from "js-cookie";
import Title from '../components/Title';

const HomePage = () => {
  const userDataStr = Cookies.get("user");
  const userData = userDataStr ? JSON.parse(userDataStr) : null;
  const firstName = userData?.given_name || "User";

  return (
    <div className="flex justify-center items-start mt-20 h-screen">
      <Card>
        <div className="flex flex-col items-center p-6 w-full max-w-4xl">
          <div className="flex -space-x-1.5 mb-4">
            <HomeLogo className="w-10 h-10" />
            <AuthorifyText />
          </div>
          <div className="flex flex-col items-center">
            <div className="text-xl font-semibold text-center">
              <span>Hi {firstName} 👋 </span>
              <span>Welcome to the Authorify Back Office</span>
            </div>
          </div>
          <p className="mt-4 text-sm text-gray-600 text-center">
            This is the beginning of many more features to come in assisting you for serving our members.
          </p>
        </div>
      </Card>
    </div>
  );
}

export default HomePage;
