import React from "react";
import { AuthorifyLoadingIcon, SpinnerIcon } from '../../components/icons';

function Loader() {
  return (
    <div className="fixed inset-x-0 inset-y-0 flex items-center justify-center z-10">
      <div className="flex flex-col gap-2 items-center justify-center bg-white w-80 h-52 rounded-xl shadow-box py-8">
        <div className='relative flex justify-center items-center w-fit'>
          <div className="animate-spin">
            <SpinnerIcon />
          </div>
          <div className="absolute">
            <AuthorifyLoadingIcon />
          </div>
        </div>
        
        <span className="text-neutral-800 text-base font-bold mt-6">
          Processing your request...
        </span>
        <span className="text-neutral-500 text-sm font-medium">
          Please don't close this window
        </span>
      </div>
    </div>
  )
}

export default Loader;
