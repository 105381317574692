/*
  Figma:
  https://www.figma.com/file/tHGwXQNQDK8zI7VbP7NcIb/AFY-TEAM-%E2%80%93-DMP-1.5-%26-Back-Office?node-id=8202%3A12831&mode=dev

  Flowbyte React base Theme:
  https://www.flowbite-react.com/docs/components/forms#text-input-theme
*/

// Matches default "Text Input" theme in design system
// NOTE: WIP, error states and sizes other than "md" are not considered here.
const defaultTextInputTheme = {
  field: {
    base:
      'w-full h-11 rounded-md focus:border-primary-400 disabled:bg-neutral-200 disabled:text-neutral-400',
    input: {
      base: 'text-sm w-full h-11 px-5 py-3 focus:border-primary-400 focus:ring-primary-400',
      sizes: {
        md: 'py-[13px] px-5 text-sm',
      },
      colors: {
        gray: `border-neutral-200 bg-white text-neutral-800
               focus:border-sky-400 active:border-sky-400`,
        failure:
          'border-error-500 bg-red-50 text-red-500 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500',
      },
    },
  },
};

export { defaultTextInputTheme };
