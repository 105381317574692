import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import {
  ArrowLeftWhite,
  DesktopIcon,
  MobileIcon,
} from '../../../../../../components/icons';
import ToastNotification from '../../../../../components/ToastNotification';
import {
  createOffer,
  previewTemplate,
  publishOffer,
  saveDraft,
} from '../../../../../api/productCatalog/actions';
import { OfferContext } from '../../utils/OfferProvider';

const OfferPreview = () => {
  const {
    templateId,
    setStep,
    offerName,
    offerCode,
    templateVariables,
    offerProduct,
    offerTrialEnabled,
    offerTrialPrice,
    productValueInCents,
    offerCountry,
    offerTrialCredits,
    emphasis,
    bookIdPackages,
  } = useContext(OfferContext) as {
    templateId: string;
    setStep: (step: number) => void;
    offerName: string;
    offerCode: string;
    templateVariables: {
      handle: string;
      value: string;
      isObject?: boolean;
    }[];
    offerProduct: string;
    offerTrialEnabled: boolean;
    offerTrialPrice: string;
    offerTrialCredits: number;
    emphasis: string[];
    bookIdPackages: string[] | null;
  };

  const [previewContent, setPreviewContent] = useState<string>('');
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPreview = async () => {
      if (templateId && templateVariables) {
        try {
          const previewTemplateId =
            templateId === '668b9ee38cd3037dded6d8fd'
              ? '66b99d73daf3405efbf2fb14'
              : templateId;

          const response = await previewTemplate(
            previewTemplateId,
            templateVariables.reduce((acc, { handle, value }) => {
              acc[handle] = value;
              return acc;
            }, {} as Record<string, string>),
          );
          setPreviewContent(response.data);
        } catch (error) {
          ToastNotification({
            type: 'error',
            message: 'Error fetching preview',
          });
        }
      }
    };
    fetchPreview();
  }, [templateId, templateVariables]);

  const handleViewSwitch = (view: 'desktop' | 'mobile') => {
    setIsMobileView(view === 'mobile');
  };

  const { mutate: publishOfferMutation } = useMutation(publishOffer);

  const { mutate: createOfferMutation } = useMutation(createOffer, {
    onSuccess: () => {
      setTimeout(() => {
        publishOfferMutation(offerCode);
      }, 2000);
      navigate('/offers', { state: { showModal: true, offerCode: offerCode } });
    },
    onError: () => {
      ToastNotification({
        type: 'error',
        message: 'Error publishing offer',
      });
    },
  });

  const payload = {
    name: offerName,
    template: templateId,
    templateVariables,
    valueInCents: productValueInCents,
    offerCode: offerCode,
    product: offerProduct,
    trialParameters: offerTrialEnabled
      ? {
          priceInCents: parseInt(offerTrialPrice) * 100,
          credits: +offerTrialCredits,
          interval: 30,
          intervalUnit: 'day',
        }
      : undefined,
    emphasis: emphasis,
    packages: bookIdPackages,
    country: offerCountry,
  }
  
  if(!payload.packages) {
    delete payload.packages;
  }

  const handleSaveDraft = async () => {
    await saveDraft(payload);
  };

  const handlePublish = async () => {
    createOfferMutation(payload)
  };

  useEffect(() => {
    const overlay = overlayRef.current;

    const disablePointerEvents = () => {
      if (overlay) {
        overlay.style.pointerEvents = 'none';
      }
    };

    const enablePointerEvents = () => {
      if (overlay) {
        overlay.style.pointerEvents = 'auto';
      }
    };

    window.addEventListener('mousedown', disablePointerEvents);
    window.addEventListener('mouseup', enablePointerEvents);

    return () => {
      window.removeEventListener('mousedown', disablePointerEvents);
      window.removeEventListener('mouseup', enablePointerEvents);
    };
  }, []);

  useEffect(() => {
    const handleScroll = (e: WheelEvent) => {
      e.preventDefault();
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.scrollBy(0, e.deltaY);
      }
    };

    const scrollContainer = overlayRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', handleScroll, {
        passive: false,
      });
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  return (
    <div className="fixed inset-0 z-50 bg-neutral-50 flex flex-col justify-start items-center gap-[49px]">
      <div className="self-stretch h-16 flex-col justify-start items-center inline-flex">
        <div className="self-stretch h-16 px-6 pt-2 pb-[9px] bg-neutral-800 border-b justify-between items-center inline-flex">
          <button
            className="justify-start items-center gap-2 flex cursor-pointer"
            onClick={() => setStep(2)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') setStep(2);
            }}
            aria-label="Back"
          >
            <ArrowLeftWhite className="w-5 h-5" />
            <div className="text-neutral-50 text-sm font-bold leading-tight">
              Back
            </div>
          </button>
          <div className="justify-center items-center gap-1 flex">
            <button
              className={`h-[46px] pl-4 pr-[18px] py-[13px] rounded-md justify-center items-center gap-2 flex cursor-pointer ${
                !isMobileView ? 'bg-neutral-700' : ''
              }`}
              onClick={() => handleViewSwitch('desktop')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleViewSwitch('desktop');
              }}
              aria-label="Desktop View"
            >
              <DesktopIcon className="w-5 h-5" />
              <div className="text-neutral-50 text-sm font-bold leading-tight">
                Desktop
              </div>
            </button>
            <button
              className={`h-[46px] pl-4 pr-[18px] py-[13px] rounded-md justify-center items-center gap-2 flex cursor-pointer ${
                isMobileView ? 'bg-neutral-700' : ''
              }`}
              onClick={() => handleViewSwitch('mobile')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleViewSwitch('mobile');
              }}
              aria-label="Mobile View"
            >
              <MobileIcon className="w-5 h-5" />
              <div className="text-neutral-50 text-sm font-bold leading-tight">
                Mobile
              </div>
            </button>
          </div>
          <div className="justify-start items-center gap-2 flex">
            <div className="justify-start items-center gap-2 flex">
              {/* <button
                className="h-[46px] px-[18px] py-[13px] bg-neutral-700 rounded-md justify-center items-center flex cursor-pointer"
                onClick={handleSaveDraft}
                aria-label="Save as Draft"
              >
                <div className="text-neutral-50 text-sm font-bold leading-tight">
                  Save as draft
                </div>
              </button> */}
              <button
                className="h-[46px] px-[18px] py-[13px] bg-sky-400 rounded-md justify-center items-center flex cursor-pointer"
                onClick={handlePublish}
                aria-label="Publish"
              >
                <div className="text-neutral-800 text-sm font-bold leading-tight">
                  Publish
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`relative flex justify-center items-center h-[95%] rounded-lg shadow-xl ${
          isMobileView ? 'w-[475px]' : 'w-[95%]'
        }`}
        ref={overlayRef}
      >
        {previewContent ? (
          <div className="relative w-full h-full">
            <iframe
              ref={iframeRef}
              id="offer-preview-iframe"
              title="Offer Preview"
              srcDoc={previewContent}
              style={{
                width: isMobileView ? '475px' : '100%',
                height: '100%',
                border: 'none',
                pointerEvents: 'none',
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'transparent',
                pointerEvents: 'auto',
              }}
              onMouseDown={(e) => e.preventDefault()}
              aria-hidden="true"
            />
          </div>
        ) : (
          <p>Loading preview...</p>
        )}
      </div>
      <ToastContainer limit={1} />
    </div>
  );
};

export default OfferPreview;
