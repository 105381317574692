/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useContext } from "react";
import Cropper from "react-easy-crop";
import { Modal } from "flowbite-react";
import { getCroppedImg, getValidationSchema } from "../../../components/utils";
import Button from "../../../../../../../components/Button";
import ToastNotification from "../../../../../../../components/ToastNotification";
import { blobToFile } from "../../../../utils";
import env from "../../../../../../../../environments/index";
import { createUploads } from "../../../../../../../api/uploads";
import { useMagazineImageContext } from "../../../../../../../../context/MagazineImageHook";
import PublishLoader from "../../../../../../../components/PublishLoader";
import clsx from "clsx";

type CropImageComponentProps = {
  imageType: string;
  title: string;
};

const CropImageComponent: React.FC<CropImageComponentProps> = ({ imageType, title }: CropImageComponentProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.5);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCropping, setCropping] = useState(false);

  const {
    setUploadImageSource,
    setSelectedUploadedImage,
    setShowModal,
    setImageType,
    magazineType,
    imageSrc,
    magazineParams,
  } = useMagazineImageContext();

  const gcd = function (a: number, b: number): number {
    return b === 0 ? a : gcd(b, a % b);
  };

  /**
   * hanldes crop complete event
   *
   * @param croppedArea
   * @param croppedAreaPixels
   */
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    if (croppedAreaPixels.width >= imageSchema.allowedResolutions?.width) {
      setCroppedAreaPixels(croppedAreaPixels);
    }
  };

  /**
   * Get cropped image for upload...
   */
  const showCroppedImage = async () => {
    setCropping(true);
    try {
      const uploadedImage = (await getCroppedImg(imageSrc, croppedAreaPixels, rotation)) as string;

      const file = await blobToFile(uploadedImage, magazineType);
      try {
        const file_extension = file.type.split("/")[1];
        const req = {
          payload: {
            bucket: env.DS_BUCKET,
            isPrivate: false,
            ext: file_extension,
            contentType: file.type,
            path: "",
            customer: {
              _id: magazineParams?.customerId,
            },
          },
          callbackTargetKey: "uploadUrl",
          callbackPayload: file,
        };
        const response = await createUploads(req);

        if (response) {
          ToastNotification({
            type: "success",
            message: "Cropping image successful.",
          });
          setCropping(false);
          setShowModal(false);
          setSelectedUploadedImage(response);
        }
      } catch (err) {
        setCropping(false);
        throw new Error(err);
      }
    } catch (e) {
      setCropping(false);
      ToastNotification({
        type: "error",
        message: e.message,
      });
    }
  };

  const imageSchema = getValidationSchema(imageType);

  const onSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZoom(parseFloat(e.target.value));
  };

  const aspectRatio = imageSchema.allowedResolutions?.height > imageSchema.allowedResolutions?.width ? 3 / 4 : 4 / 3;

  const cropperImageProps = {
    image: imageSrc,
    crop: crop,
    zoom: zoom,
    aspect: aspectRatio,
    showGrid: true,
    objectFit: "cover",
    onCropChange: setCrop,
    onCropComplete: onCropComplete,
  };

  if (imageSchema.allowedResolutions!.width! <= 540) {
    cropperImageProps.cropSize = {
      width: imageSchema.allowedResolutions?.width,
      height: imageSchema.allowedResolutions?.height,
    };
  }

  return (
    <>
      <Modal.Header className="pt-8 pb-0">
        <div className="text-neutral-800 text-base font-semibold">{title}</div>
      </Modal.Header>
      <Modal.Body className="relative">
        <div
          className={clsx(
            "absolute z-50 items-center justify-center w-full h-full",
            { hidden: !isCropping },
            { flex: isCropping }
          )}
        >
          <PublishLoader message="Cropping image..." />
        </div>
        <div className="w-full flex items-center justify-center h-[600px]">
          <div className="relative w-[590px] h-[600px]">
            <Cropper {...cropperImageProps} />
          </div>
        </div>
        <div className="controls mt-6 w-full">
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.01}
            aria-labelledby="Zoom"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onSliderChange(e);
            }}
            className="zoom-range transparent h-1 w-full cursor-pointer border-white rounded-lg border-transparent accent-primary-500 hover:accent-primary-800 active:accent-primary-500"
          />
        </div>
        <div className="flex gap-2 justify-end mt-6">
          <Button type="button" variant="LIGHT" onClick={() => setUploadImageSource(null)}>
            Cancel
          </Button>
          <Button variant="DARK" type="submit" onClick={() => showCroppedImage()} disabled={isCropping}>
            Save changes
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

export default CropImageComponent;
