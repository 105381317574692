import { Button as FlowbiteButton } from "flowbite-react";
import React from "react";
import { ReactNode } from "react";

interface ButtonProps {
  children: ReactNode;
  onClick: () => void;
  disabled: boolean;
  isProcessing: boolean;
}
export default function Button({children, onClick, isProcessing, disabled}: ButtonProps) {
  return (
    <FlowbiteButton onClick={onClick} isProcessing={isProcessing} disabled={disabled} className="bg-primary-500 text-black font-bold justify-center items-center">
      {children}
    </FlowbiteButton>
  );
}
