import * as React from "react";
import type { SVGProps } from "react";
const Xmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg" 
    width="15" 
    height="16" 
    viewBox="0 0 15 16" 
    fill="none"
    {...props}
  >
    <path d="M11.25 4.25003L3.75 11.75M3.74997 4.25L11.2499 11.75" stroke="#E4483D" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
);
export default Xmark;