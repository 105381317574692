import * as React from "react";
import type { SVGProps } from "react";
const SvgEditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#edit-icon_svg__a)">
      <path
        stroke="#252525"
        strokeWidth={1.5}
        d="m9.573 2.72.618-.619A2.622 2.622 0 1 1 13.9 5.81l-.618.618M9.573 2.719s.078 1.313 1.236 2.472c1.159 1.158 2.472 1.236 2.472 1.236M9.573 2.719 3.893 8.4c-.385.385-.578.577-.743.79a4.4 4.4 0 0 0-.5.807c-.115.243-.2.5-.373 1.017l-.73 2.187m11.734-6.774-5.68 5.68c-.386.385-.578.578-.79.743q-.376.294-.808.5c-.243.115-.5.2-1.017.373l-2.187.73m0 0-.535.177a.707.707 0 0 1-.894-.894l.178-.535m1.25 1.251-1.25-1.25"
      />
    </g>
    <defs>
      <clipPath id="edit-icon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEditIcon;
