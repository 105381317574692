import * as React from "react";
import type { SVGProps } from "react";
const SvgReports = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      stroke="#757575"
      strokeWidth={1.5}
      d="M1.667 10.5c0-3.928 0-5.892 1.22-7.113 1.22-1.22 3.185-1.22 7.113-1.22s5.893 0 7.113 1.22 1.22 3.185 1.22 7.113 0 5.893-1.22 7.113-3.185 1.22-7.113 1.22-5.893 0-7.113-1.22-1.22-3.184-1.22-7.113Z"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m5.833 12.167 1.498-1.797c.593-.712.89-1.068 1.28-1.068s.687.356 1.28 1.068l.217.26c.594.712.89 1.068 1.28 1.068.391 0 .688-.356 1.281-1.068l1.498-1.797"
    />
  </svg>
);
export default SvgReports;
