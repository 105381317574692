import * as React from "react";
import type { SVGProps } from "react";
const SvgMembersActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <circle cx={7.501} cy={5.5} r={3.333} fill="#252525" />
    <ellipse cx={7.501} cy={14.668} fill="#252525" rx={5.833} ry={3.333} />
    <path
      fill="#252525"
      d="M17.5 14.667c0 1.38-1.697 2.5-3.768 2.5.61-.667 1.03-1.504 1.03-2.499s-.42-1.834-1.032-2.501c2.071 0 3.77 1.12 3.77 2.5M15 5.5a2.5 2.5 0 0 1-3.357 2.35 4.74 4.74 0 0 0 .62-2.35 4.74 4.74 0 0 0-.62-2.35A2.5 2.5 0 0 1 15 5.5"
    />
  </svg>
);
export default SvgMembersActive;
